import React from 'react';
import PropTypes from 'prop-types';

import {Input, Select} from '../../styles';

function EventForm({focusedEvent, organisationReferences, handleChange}) {
  return (
    <div>
      <Input
        name="name"
        palette='secondary'
        label="Name"
        type="text"
        value={focusedEvent?.name || ''}
        onChange={(value) => handleChange('name', value)}
      />
      <Select
        name="state"
        palette='secondary'
        label="State"
        value={focusedEvent?.state || ''}
        onChange={(value) => handleChange('state', value)}
      >
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </Select>
    </div>
  );
}

EventForm.propTypes = {
  focusedEvent: PropTypes.shape({}),
  areaReferences: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default EventForm;
