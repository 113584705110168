import React from 'react';
import PropTypes from 'prop-types';

import {Input, Button} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function OfficeForm({location, areaReferences, handleChange}) {
  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            disabled={true}
            name="area"
            palette='secondary'
            label="Area"
            type="text"
            value={location?.area ? areaReferences[location?.area]?.name || '...processing' : 'No area selected'}
          />
        </GridCell>
        <GridCell style={{marginTop: 7}}>
          <Button palette='primary' onClick={() => handleChange('area', undefined)}>
            Change Area
          </Button>
        </GridCell>
      </GridContainer>
    </div>
  );
}

OfficeForm.propTypes = {
  location: PropTypes.shape({}),
  areaReferences: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default OfficeForm;
