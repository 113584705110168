import React from 'react';
import PropTypes from 'prop-types';

import {Select} from '../../styles';

function ContactPrimaryForm({primaryContact, handleChange}) {
  return (
    <div>
      <Select
        name="primaryPhone"
        palette='secondary'
        label="Primary Phone"
        type="text"
        value={primaryContact?.primaryPhone || ''}
        onChange={(value) => handleChange('primaryPhone', value)}
      >
        <option value="cell">Cell Phone</option>
        <option value="home">Home Phone</option>
        <option value="work">Work Phone</option>
      </Select>
      <Select
        name="primaryEmail"
        palette='secondary'
        label="Primary Email"
        type="text"
        value={primaryContact?.primaryEmail || ''}
        onChange={(value) => handleChange('primaryEmail', value)}
      >
        <option value="me">Login Email</option>
        <option value="home">Home Email</option>
        <option value="work">Work Email</option>
      </Select>
    </div>
  );
}

ContactPrimaryForm.propTypes = {
  primaryContact: PropTypes.shape({}),
}

export default ContactPrimaryForm;
