import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

import {paletteData, textData} from '../../../styles';

function Button({children, to, style, palette, onClick, size}) {
  const [styleData] = useState({
    backgroundColor: palette ? paletteData[palette]['standard'].background : paletteData['blank']['standard'].background,
    color: palette ? paletteData[palette]['standard'].foreground : paletteData['blank']['standard'].foreground,
    border: 'none',
    outline: 'none',
    textDecoration: 'none',
    display: 'block',
    cursor: 'pointer',
    transition: '0.3s',
    padding: size === 'small' ? 6 : 10,
    fontSize: size === 'small' ? '.8em' : '1em',
    textAlign: 'center',
    fontFamily: textData.button.fontFamily,
    ...style,
  });

  return (
    <Link
      style={styleData}
      onClick={() => {
        if(onClick !== undefined) {
          onClick();
        }
      }}
      to={to}
    >
      {children}
    </Link>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
  palette: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default Button;
