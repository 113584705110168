import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {textData, Typography, Slider, Fab, MenuDropdown, Button} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';
import {generateImageBlock} from '../../';

function SplashBlockEdit({
  block,
  removeBlock,
  blockIndex,
  maxIndex,
  changeSection,
  changeParent,
  changeBlockIndex,
  stripImageURL,

  dialogData,
  dialogOpen,
  setDialogData,
  setDialogOpen
}) {
  const [colorPickerMenuOpen, setColorPickerMenuOpen] = useState(false);
  const [splashBrightness, setSplashBrightness] = useState(undefined);

  useEffect(() => {
    if(splashBrightness === undefined) {
      let brightnessTemp = (block?.options?.style?.backgroundColor || '').replace('rgba(', '').replace(')', '');
      if(brightnessTemp.length > 0) {
        let brightnessArray = brightnessTemp.split(',');
        if(parseInt(brightnessArray[0]) > 0) {
          setSplashBrightness(parseFloat(brightnessArray[3]) * 100);
        } else {
          setSplashBrightness(parseFloat(brightnessArray[3]) * -100);
        }
      } else {
        setSplashBrightness(0);
      }
    }
  }, [block, splashBrightness, setSplashBrightness]);

  return (
    <div style={{position: 'relative'}}>
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          minWidth: 20,
          padding: 5,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 5,
          background: 'white',
        }}
      >
        <GridContainer>
          <GridCell center={true}>
            <Fab
              size='small'
              onClick={() => setColorPickerMenuOpen(true)}
            >
              <span className="material-icons md-24">palette</span>
            </Fab>
            {colorPickerMenuOpen &&
              <MenuDropdown
                open={colorPickerMenuOpen}
                handleClose={() => setColorPickerMenuOpen(false)}
                position={{top: 45}}
                style={{padding: 10, marginTop: 10, borderRadius: 5, zIndex: 100}}
              >
                <Button
                  onClick={() => {
                    setDialogData({
                      type: 'colorPicker',
                      title: 'Change Title Color',
                      data: {
                        callback: (value) => changeSection('update', blockIndex, `children.0.children.${block.children[0].children.length - 2}.options.style.color`, value),
                      },
                      value: {
                        color: block.children[0].children[block.children[0].children.length - 2].options.style.color,
                      },
                    });
                    setDialogOpen(true);
                  }}
                >
                  Title
                </Button>
                <Button
                  onClick={() => {
                    setDialogData({
                      type: 'colorPicker',
                      title: 'Change Background Color',
                      data: {
                        callback: (value) => changeSection('update', blockIndex, `children.0.children.${block.children[0].children.length - 1}.options.style.color`, value),
                      },
                      value: {
                        color: block.children[0].children[block.children[0].children.length - 1].options.style.color,
                      },
                    });
                    setDialogOpen(true);
                  }}
                >
                  Tagline
                </Button>
              </MenuDropdown>
            }
          </GridCell>
          <GridCell center={true}>
            <Fab
              size='small'
              onClick={() => {
                const {imageId, imageOptions} = stripImageURL(block?.options?.style?.backgroundImage);
                setDialogData({
                  type: 'selectImage',
                  title: 'Add Background Image',
                  data: {
                    callback: (value) => {
                      const valueProcessed = value || {};
                      if(!value?.imageId) {
                        valueProcessed.imageId = 'media-assets/default_e2qiho.jpg';
                        valueProcessed.imageOptions = 'c_fill,w_2048,q_auto:good';
                      }
                      const imageURL = `url("https://res.cloudinary.com/taitokerau-tatou/image/upload/${valueProcessed.imageOptions}/${valueProcessed.imageId}")`;
                      changeSection('update', blockIndex, 'options.style.backgroundImage', imageURL);
                    }
                  },
                  value: {
                    search: '',
                    imageId,
                    imageOptions,
                    alt: 'Background Image',
                  },
                });
                setDialogOpen(true);
              }}
            >
              <span className="material-icons md-24">photo</span>
            </Fab>
          </GridCell>
          <GridCell center={true}>
            <Fab
              size='small'
              onClick={() => {
                let imageId = undefined;
                let imageOptions = undefined;
                if(block.children[0].children?.length === 3) {
                  const response = stripImageURL(block.children[0].children[0].options.src);
                  imageId = response.imageId;
                  imageOptions = response.imageOptions;
                }
                setDialogData({
                  type: 'selectImage',
                  title: 'Add Logo',
                  data: {
                    callback: (value) => {
                      if(value.imageId) {
                        const childrenProcessed = block.children[0].children;
                        const child = generateImageBlock({
                          src: `https://res.cloudinary.com/taitokerau-tatou/image/upload/${value.imageOptions || 'q_auto:good,c_fit,w_800'}/${value.imageId}`,
                          alt: 'logo',
                          description: '',
                          styleContainer: {
                            maxWidth: 400,
                            margin: 'auto',
                          },
                          styleImage: {
                            width: '100%',
                          },
                        });
                        if(block.children[0].children?.length === 2){
                          childrenProcessed.splice(0, 0, child);
                        } else if (block.children[0].children?.length === 3) {
                          childrenProcessed.splice(0, 1, child);
                        }
                        changeSection('update', blockIndex, 'children.0.children', childrenProcessed);
                      } else if(block.children[0].children?.length === 3) {
                        const childrenProcessed = block.children[0].children;
                        childrenProcessed.splice(0, 1);
                        changeSection('update', blockIndex, 'children.0.children', childrenProcessed);
                      }
                    }
                  },
                  value: {
                    search: '',
                    imageId,
                    imageOptions,
                    alt: 'Current Image',
                  },
                });
                setDialogOpen(true);
              }}
            >
              <span className="material-icons md-24">account_circle</span>
            </Fab>
          </GridCell>
          <GridCell center={true}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Typography style={{textAlign: 'center'}}>
                Brightness
              </Typography>
              <span
                className="material-icons md-24" style={{fontSize: 20, cursor: 'pointer'}}
                onClick={() => {
                  setSplashBrightness(0);
                  const tempStyle = block.options.style;
                  delete tempStyle.backgroundBlendMode;
                  delete tempStyle.backgroundColor;
                  changeSection('update', blockIndex, 'options.style', tempStyle);
                }}
              >
                restart_alt
              </span>
            </div>

            <Slider
              max={100}
              min={-100}
              value={splashBrightness || 0}
              onChange={(value) => {
                setSplashBrightness(value);
                const tempStyle = block.options.style;
                if(value === 0) {
                  delete tempStyle.backgroundBlendMode;
                  delete tempStyle.backgroundColor;
                } else if (value < 0) {
                  tempStyle.backgroundBlendMode = 'darken';
                  tempStyle.backgroundColor = `rgba(0,0,0,${-(value / 100)})`;
                } else if (value > 0) {
                  tempStyle.backgroundBlendMode = 'lighten';
                  tempStyle.backgroundColor = `rgba(255,255,255,${(value / 100)})`;
                }
                changeSection('update', blockIndex, 'options.style', tempStyle);
              }}
            />
          </GridCell>
          <GridCell center={true}>
            <Fab
              size='small'
              style={{background: '#c6c6c6', cursor: 'pointer'}}
              onClick={() => {
                setDialogData({
                  type: 'moveSection',
                  title: 'Move Section',
                  data: {
                    maxIndex,
                    currentIndex: blockIndex + 1,
                    callbackMove: (shift) => changeBlockIndex(shift),
                    callbackRemove: () => removeBlock(blockIndex),
                  },
                  value: {
                    index: blockIndex + 1,
                  },
                });
                setDialogOpen(true);
              }}
            >
              <Typography style={{textAlign: 'center'}}>
                {blockIndex + 1}
              </Typography>
            </Fab>
          </GridCell>
        </GridContainer>
      </div>
      <GridContainer
        style={{
          ...block?.options?.style,
          paddingTop: 100,
          paddingBottom: 100,
        }}
      >
        <GridCell
          weight={1}
          center={block.children[0].options.center}
          centerWeights={block.children[0].options.centerWeights}
          style={{...block.children[0].options?.style}}
        >
          {block.children[0].children?.length === 3 &&
            <div style={{...block.children[0].children[0].options.style}}>
              {block.children[0].children[0].children ?
                <img
                  style={{...block.children[0].children[0].children[0].options.style}}
                  src={block.children[0].children[0].children[0].options.src} alt={block.children[0].children[0].children[0].options.alt}
                /> :
                <div style={{color: 'red', background: 'white', padding: 10, fontSize: '5em'}}>
                  Logo broken
                </div>
              }
            </div>
          }
          <input
            style={{
              ...block.children[0].children[block.children[0].children.length - 2].options.style,
              background: 'rgba(0,0,0,0)',
              border: 'none',
              outline: 'none',
              fontSize: (block.children[0].children[block.children[0].children.length - 2].options.size === 'heading' ? textData.heading.fontSize :
                         block.children[0].children[block.children[0].children.length - 2].options.size === 'subHeading' ? textData.subHeading.fontSize :
                         block.children[0].children[block.children[0].children.length - 2].options.size === 'title' ? textData.title.fontSize :
                         block.children[0].children[block.children[0].children.length - 2].options.size === 'text' ? textData.text.fontSize :
                         block.children[0].children[block.children[0].children.length - 2].options.size === 'subText' ? textData.subText.fontSize :
                         '1em'),
            }}
            value={block.children[0].children[block.children[0].children.length - 2].text}
            onChange={(e) => {
              const value = e.target.value;
              const path = `children.0.children.${block.children[0].children.length - 2}.text`;
              changeSection('update', blockIndex, path, value);
            }}
          />
          <input
            style={{
              ...block.children[0].children[block.children[0].children.length - 1].options.style,
              background: 'rgba(0,0,0,0)',
              border: 'none',
              outline: 'none',
              fontSize: (block.children[0].children[block.children[0].children.length - 1].options.size === 'heading' ? textData.heading.fontSize :
                         block.children[0].children[block.children[0].children.length - 1].options.size === 'subHeading' ? textData.subHeading.fontSize :
                         block.children[0].children[block.children[0].children.length - 1].options.size === 'title' ? textData.title.fontSize :
                         block.children[0].children[block.children[0].children.length - 1].options.size === 'text' ? textData.text.fontSize :
                         block.children[0].children[block.children[0].children.length - 1].options.size === 'subText' ? textData.subText.fontSize :
                         '1em'),
            }}
            value={block.children[0].children[block.children[0].children.length - 1].text}
            onChange={(e) => {
              const value = e.target.value;
              const path = `children.0.children.${block.children[0].children.length - 1}.text`;
              changeSection('update', blockIndex, path, value);
            }}
          />
        </GridCell>
      </GridContainer>
    </div>
  );
}

SplashBlockEdit.propTypes = {
  block: PropTypes.shape({}),
  changeSection: PropTypes.func,
  changeParent: PropTypes.func,
};

export default SplashBlockEdit;
