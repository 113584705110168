import React from 'react';
import PropTypes from 'prop-types';
import {ChromePicker} from 'react-color'

import {Input, Typography, Select, CheckBox} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

import {times, timezones} from '../../../tools/calendarTimes';

function BookingCalendarForm({bookingCalendar, handleChange, focusedOrganisation}) {
  const bookingTimes = [
    {value: 1, text: '15 Minutes'},
    {value: 2, text: '30 Minutes'},
    {value: 3, text: '45 Minutes'},
    {value: 4, text: '1 Hour'},
    {value: 5, text: '1 Hour 15 Minutes'},
    {value: 6, text: '1 Hour 30 Minutes'},
    {value: 7, text: '1 Hour 45 Minutes'},
    {value: 8, text: '2 Hours'},
    {value: 9, text: '2 Hours 15 Minutes'},
    {value: 10, text: '2 Hours 30 Minutes'},
    {value: 11, text: '2 Hours 45 Minutes'},
    {value: 12, text: '3 Hours'},
    {value: 13, text: '3 Hours 15 Minutes'},
    {value: 14, text: '3 Hours 30 Minutes'},
    {value: 15, text: '3 Hours 45 Minutes'},
    {value: 16, text: '4 Hours'},
    {value: 96, text: '1 Day', textAlt: '1 apointment per day'},
  ];

  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            name="name"
            palette='secondary'
            label="Name"
            type="text"
            value={bookingCalendar?.name || ''}
            onChange={(value) => {
              handleChange('name', value);
            }}
          />
          <GridContainer>
            <GridCell weight={1}>
              <Select
                forceShrink
                style={{minWidth: 150}}
                name="minimumStartTime"
                palette='secondary'
                label="Start of day"
                type="text"
                value={bookingCalendar?.minimumStartTime}
                onChange={(value) => handleChange('minimumStartTime', value)}
              >
                {times.map((t, tIndex) => (
                  <option key={tIndex} value={t.value}>{t.blockStartHuman}</option>
                ))}
              </Select>
            </GridCell>
            <GridCell weight={1}>
              <Select
                forceShrink
                style={{minWidth: 150}}
                name="maximumEndTime"
                palette='secondary'
                label="End of day"
                type="text"
                value={bookingCalendar?.maximumEndTime}
                onChange={(value) => handleChange('maximumEndTime', value)}
              >
                {times.filter((t, tIndex) => {
                  return t.value >= (bookingCalendar?.minimumStartTime || 0);
                }).map((t, tIndex) => (
                  <option key={tIndex} value={t.value}>{t.blockEndHuman}</option>
                ))}
              </Select>
            </GridCell>
            <GridCell>
              <Select
                style={{minWidth: 170}}
                name="timezone"
                palette='secondary'
                label="Timezone"
                value={bookingCalendar?.timezone || ''}
                onChange={(value) => {
                  handleChange('timezone', value);
                }}
              >
                {timezones.map((t, tIndex) => (
                  <option key={tIndex} value={t}>{t}</option>
                ))}
              </Select>
            </GridCell>
          </GridContainer>
          <div>
            <Typography style={{padding: 5, fontWeight: 500}}>
              Available Booking Days
            </Typography>
            <GridContainer>
              <GridCell>
                <CheckBox
                  name='monday'
                  label='Monday'
                  value={bookingCalendar?.availableDays?.monday || false}
                  onChange={(value) => {
                    const tempDays = bookingCalendar?.availableDays || {};
                    tempDays.monday = value;
                    handleChange('availableDays', tempDays);
                  }}
                />
              </GridCell>
              <GridCell>
                <CheckBox
                  name='tuesday'
                  label='Tuesday'
                  value={bookingCalendar?.availableDays?.tuesday || false}
                  onChange={(value) => {
                    const tempDays = bookingCalendar?.availableDays || {};
                    tempDays.tuesday = value;
                    handleChange('availableDays', tempDays);
                  }}
                />
              </GridCell>
              <GridCell>
                <CheckBox
                  name='wednesday'
                  label='Wednesday'
                  value={bookingCalendar?.availableDays?.wednesday || false}
                  onChange={(value) => {
                    const tempDays = bookingCalendar?.availableDays || {};
                    tempDays.wednesday = value;
                    handleChange('availableDays', tempDays);
                  }}
                />
              </GridCell>
              <GridCell>
                <CheckBox
                  name='thursday'
                  label='Thursday'
                  value={bookingCalendar?.availableDays?.thursday || false}
                  onChange={(value) => {
                    const tempDays = bookingCalendar?.availableDays || {};
                    tempDays.thursday = value;
                    handleChange('availableDays', tempDays);
                  }}
                />
              </GridCell>
              <GridCell>
                <CheckBox
                  name='friday'
                  label='Friday'
                  value={bookingCalendar?.availableDays?.friday || false}
                  onChange={(value) => {
                    const tempDays = bookingCalendar?.availableDays || {};
                    tempDays.friday = value;
                    handleChange('availableDays', tempDays);
                  }}
                />
              </GridCell>
              <GridCell>
                <CheckBox
                  name='saturday'
                  label='Saturday'
                  value={bookingCalendar?.availableDays?.saturday || false}
                  onChange={(value) => {
                    const tempDays = bookingCalendar?.availableDays || {};
                    tempDays.saturday = value;
                    handleChange('availableDays', tempDays);
                  }}
                />
              </GridCell>
              <GridCell>
                <CheckBox
                  name='sunday'
                  label='Sunday'
                  value={bookingCalendar?.availableDays?.sunday || false}
                  onChange={(value) => {
                    const tempDays = bookingCalendar?.availableDays || {};
                    tempDays.sunday = value;
                    handleChange('availableDays', tempDays);
                  }}
                />
              </GridCell>
            </GridContainer>
            <br/>
            <GridContainer>
              <GridCell weight={1}>
                <Select
                  style={{minWidth: 170}}
                  name="minimumBookingTime"
                  palette='secondary'
                  label="Minimum Booking Time"
                  type="number"
                  value={bookingCalendar?.minimumBookingTime || ''}
                  onChange={(value) => handleChange('minimumBookingTime', value)}
                >
                  {bookingTimes.map((t, tIndex) => (
                    <option key={tIndex} value={t.value}>{t.text}</option>
                  ))}
                </Select>
              </GridCell>
              <GridCell weight={1}>
                <Select
                  style={{minWidth: 170}}
                  name="maximumBookingTime"
                  palette='secondary'
                  label="Maximum Booking Time"
                  type="number"
                  value={bookingCalendar?.maximumBookingTime || ''}
                  onChange={(value) => handleChange('maximumBookingTime', value)}
                >
                  {bookingTimes.filter((t, tIndex) => {
                    return t.value >= (bookingCalendar?.minimumBookingTime || 0);
                  }).map((t, tIndex) => (
                    <option key={tIndex} value={t.value}>{t.text}</option>
                  ))}
                </Select>
              </GridCell>
              {/*
                <GridCell weight={1}>
                  <Select
                    forceShrink={true}
                    style={{minWidth: 170}}
                    name="bookingLeadOut"
                    palette='secondary'
                    label="Time Between Apointments"
                    type="number"
                    value={bookingCalendar?.bookingLeadOut}
                    onChange={(value) => handleChange('bookingLeadOut', value)}
                  >
                    <option value={0}>Imediate</option>
                    {bookingTimes.map((t, tIndex) => (
                      <option key={tIndex} value={t.value}>{t.textAlt || t.text}</option>
                    ))}
                  </Select>
                </GridCell>
              */}
              {/*
                <GridCell weight={1}>
                  <Input
                    style={{minWidth: 170}}
                    name="similtaniousBookings"
                    palette='secondary'
                    label="Bookings at the same time"
                    type="number"
                    value={bookingCalendar?.similtaniousBookings || ''}
                    onChange={(value) => {
                      handleChange('similtaniousBookings', value);
                    }}
                  />
                </GridCell>
              */}
            </GridContainer>
            <Input
              name="bookingLeadIn"
              palette='secondary'
              label="Days in advance a booking needs to be made"
              type="number"
              value={bookingCalendar?.bookingLeadIn || ''}
              onChange={(value) => {
                handleChange('bookingLeadIn', value);
              }}
            />
            <CheckBox
              name='multipleBookingsPerOrder'
              label='Allow bookings on multiple days'
              value={bookingCalendar?.multipleBookingsPerOrder}
              onChange={(value) => handleChange('multipleBookingsPerOrder', value)}
            />
            {/*
              {bookingCalendar?.multipleBookingsPerOrder &&
                <CheckBox
                  name='multipleBookingsAreConsecutive'
                  label='Bookings on multiple days must be in a row'
                  value={bookingCalendar?.multipleBookingsAreConsecutive}
                  onChange={(value) => handleChange('multipleBookingsAreConsecutive', value)}
                />
              }
            */}
          </div>
        </GridCell>
        <GridCell style={{padding: 10}}>
          <div style={{background: bookingCalendar?.background || '#bfeb98', padding: 10, textAlign: 'center'}}>
            <Typography style={{color: bookingCalendar?.invertText ? 'white' : 'black'}}>
              Highlight Colour
            </Typography>
          </div>

          <ChromePicker
            color={bookingCalendar?.background || '#bfeb98'}
            onChangeComplete={(color) => handleChange('background', color.hex)}
          />
          <CheckBox
            name='invertText'
            label='White Text'
            value={bookingCalendar?.invertText || false}
            onChange={(value) => handleChange('invertText', value)}
          />
        </GridCell>
      </GridContainer>
    </div>
  );
}

BookingCalendarForm.propTypes = {
  focusedOrganisation: PropTypes.shape({}),
  bookingCalendar: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default BookingCalendarForm;
