import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';

import {GridContainer, GridCell} from '../../../../../components/grid';
import {paletteData, Button, Typography, Dialog, Input } from '../../../../../components/styles';
import {LayoutEdit} from '../../../../../components/layout';

import HeaderForm from '../../../../../components/forms/header';

function EditAreaLayoutSubPanel({
  me,
  focusedArea,
  handleChangeFocusedArea,
  processing,
  setProcessing
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [imageLibrary, setImages] = useState(undefined);
  const [page] = useState(0);

  useEffect(() => {
    if(!imageLibrary) {
      const gallery = focusedArea.gallery;
      setImages(gallery);
    }
  }, [focusedArea, imageLibrary]);

  const changePage = (page, type, index, path, value) => {
    if(type === 'insert') {
      const tempLayout = focusedArea.layout || {
        header: {},
        multiPageEnabled: false,
        pages: [{
          title: 'home',
          sections: [],
        }],
      };
      if(!tempLayout.pages) {
        tempLayout.pages = [{
          title: 'home',
          sections: [],
        }];
      };
      tempLayout.pages[page].sections.splice(index, 0, value);
      handleChangeFocusedArea('layout', tempLayout);
    } else if (type === 'update') {
      const tempLayout = focusedArea.layout;
      if(path === undefined) {
        tempLayout.pages[page].sections = value;
      } else {
        if(path === '') {
          objectPath.set(tempLayout.pages[page].sections, `${index}`, value);
        } else {
          objectPath.set(tempLayout.pages[page].sections, `${index}.${path}`, value);
        }
      }
      handleChangeFocusedArea('layout', tempLayout);
    }
  }

  const changeParent = (type, path, value) => {
    if(type === 'insert') {
      const tempArray = objectPath.get(focusedArea, path) || [];
      tempArray.push(value);
      objectPath.set(focusedArea, path, tempArray);
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell center={true}>
            <a
              href={`https://rerengatahi.nz/area/${focusedArea._id}`}
              style={{textDecoration: 'none'}}
              target="_blank"
              rel='noopener noreferrer'
            >
              <Typography style={{color: paletteData.primary.standard.foreground}}>
                Layout
              </Typography>
            </a>
          </GridCell>
          <GridCell center={true}>
            <a
              href={`https://rerengatahi.nz/area/${focusedArea._id}`}
              style={{textDecoration: 'none'}}
              target="_blank"
              rel='noopener noreferrer'
            >
              <span className="material-icons md-24" style={{color: paletteData.primary.standard.foreground, marginLeft: 5}}>visibility</span>
            </a>
          </GridCell>
          <GridCell weight={1}/>
        </GridContainer>
      </div>

      <LayoutEdit
        imageLibrary={imageLibrary}
        videoLibrary={focusedArea?.videoLibrary || []}
        sections={(focusedArea?.layout?.pages && focusedArea?.layout?.pages[page]?.sections) || []}
        changeSection={(type, index, path, value) => changePage(page, type, index, path, value)}
        changeParent={(type, path, value) => changeParent(type, path, value)}
      />

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'editHeader' &&
            <div style={{padding: 10}}>
              <HeaderForm
                header={dialogData?.value || {}}
                handleChange={(name, value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...dialogData.value,
                      [name]: value,
                    },
                  });
                }}
                processing={processing}
              />
            </div>
          }
          {dialogData?.type === 'movePage' &&
            <div style={{padding: 10}}>
              <Input
                type='number'
                label='New Index'
                value={dialogData?.value?.index}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      index: value - 1,
                      humanIndex: value,
                    }
                  });
                }}
              />
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'editHeader' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                   onClick={() => {
                     const tempLayout = {
                       ...focusedArea.layout,
                       header: dialogData?.value,
                     };
                     handleChangeFocusedArea('layout', tempLayout);
                     setDialogData(undefined);
                     setDialogOpen(false);
                  }}
                >
                  Edit Header
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'messageWithCallback' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                   onClick={() => {
                     dialogData?.data?.callback();
                     setDialogData(undefined);
                     setDialogOpen(false);
                  }}
                >
                  Submit
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditAreaLayoutSubPanel.propTypes = {
  me: PropTypes.shape({}),
  focusedArea: PropTypes.shape({}),
  handleChangeFocusedArea: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
};

export default EditAreaLayoutSubPanel;
