import React from 'react';
import PropTypes from 'prop-types';

import {Input} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function DemographicsForm({demographics, handleChange}) {
  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            name="namefirst"
            palette='secondary'
            label="First Name"
            type="text"
            value={demographics?.nameFirst || ''}
            onChange={(value) => handleChange('nameFirst', value)}
          />
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="nameMiddle"
            palette='secondary'
            label="Middle Names"
            type="text"
            value={demographics?.nameMiddle || ''}
            onChange={(value) => handleChange('nameMiddle', value)}
          />
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="nameMiddle"
            palette='secondary'
            label="Surname"
            type="text"
            value={demographics?.nameLast || ''}
            onChange={(value) => handleChange('nameLast', value)}
          />
        </GridCell>
      </GridContainer>
      <Input
        forceShrink={true}
        name="dob"
        palette='secondary'
        label="Date of birth"
        type="date"
        value={demographics?.dob || ''}
        onChange={(value) => {
          handleChange('dob', value);
        }}
      />
    </div>
  );
}

DemographicsForm.propTypes = {
  demographics: PropTypes.shape({}),
}

export default DemographicsForm;
