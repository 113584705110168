import {
  SET_USERS,
  ADD_USERS,
  ADD_USER,
  REPLACE_USER,
  REMOVE_USER,

  SET_AREAS,
  ADD_AREAS,
  ADD_AREA,
  REPLACE_AREA,
  REMOVE_AREA,

  SET_ORGANISATIONS,
  ADD_ORGANISATIONS,
  ADD_ORGANISATION,
  REPLACE_ORGANISATION,
  REMOVE_ORGANISATION,

  SET_CATEGORIES,
  ADD_CATEGORIES,
  ADD_CATEGORY,
  REPLACE_CATEGORY,
  REMOVE_CATEGORY,

  SET_EVENTS,
  ADD_EVENTS,
  ADD_EVENT,
  REPLACE_EVENT,
  REMOVE_EVENT,

  SET_PRODUCTS,
  ADD_PRODUCTS,
  ADD_PRODUCT,
  REPLACE_PRODUCT,
  REMOVE_PRODUCT,

  SET_PRODUCTGROUPS,
  ADD_PRODUCTGROUPS,
  ADD_PRODUCTGROUP,
  REPLACE_PRODUCTGROUP,
  REMOVE_PRODUCTGROUP,

  SET_POST_SCHEMES,
  ADD_POST_SCHEMES,
  ADD_POST_SCHEME,
  REPLACE_POST_SCHEME,
  REMOVE_POST_SCHEME,

  SET_BOOKING_CALENDARS,
  ADD_BOOKING_CALENDARS,
  ADD_BOOKING_CALENDAR,
  REPLACE_BOOKING_CALENDAR,
  REMOVE_BOOKING_CALENDAR,

  SET_ORDERS,
  ADD_ORDERS,
  ADD_ORDER,
  REPLACE_ORDER,
  REMOVE_ORDER,
  PRUNE_BOOKING_FROM_ORDER,

  SET_REVIEWS,
  ADD_REVIEWS,
  ADD_REVIEW,
  REPLACE_REVIEW,
  REMOVE_REVIEW,

  SET_ARTICLES,
  ADD_ARTICLES,
  ADD_ARTICLE,
  REPLACE_ARTICLE,
  REMOVE_ARTICLE,

  SET_BOOKINGS,
  ADD_BOOKING,
  REPLACE_BOOKING,
  REMOVE_BOOKING,
  REMOVE_BOOKINGS,

  SET_COMMUNITIES,
  ADD_COMMUNITY,
  ADD_COMMUNITIES,
  REPLACE_COMMUNITY,
  REMOVE_COMMUNITY,

  SET_POTENTIAL_COMMUNITIES,
  ADD_POTENTIAL_COMMUNITY,
  ADD_POTENTIAL_COMMUNITIES,
  REPLACE_POTENTIAL_COMMUNITY,
  REMOVE_POTENTIAL_COMMUNITY,

  SET_SEARCH,
  RESET_LISTS,
} from "./actionTypes";

const initialState = {
  users: undefined,
  organisations: undefined,
  areas: undefined,
  categories: undefined,
  articles: undefined,
  events: undefined,
  products: undefined,
  productGroups: undefined,
  orders: undefined,
  reviews: undefined,
  postSchemes: undefined,
  bookingCalendars: undefined,
  bookings: undefined,
  communities: undefined,
  potentialCommunities: undefined,
  queryLimit: 50,
  search: {
    users: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    organisations: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    areas: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    categories: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    articles: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    events: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    products: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    productGroups: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    orders: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    reviews: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    postSchemes: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    bookingCalendars: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    bookings: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    communities: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    potentialCommunities: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
  }
}

export default function listsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH: {
      const searchProcessed = state.search;
      searchProcessed[action.payload.name] = action.payload.value;
      return {
        ...state,
        search: searchProcessed,
      }
    }
    case RESET_LISTS: {
      return {
        users: undefined,
        organisations: undefined,
        areas: undefined,
        categories: undefined,
        articles: undefined,
        events: undefined,
        products: undefined,
        productGroups: undefined,
        orders: undefined,
        reviews: undefined,
        postSchemes: undefined,
        bookingCalendars: undefined,
        bookings: undefined,
        communities: undefined,
        potentialCommunities: undefined,
        queryLimit: 50,
        search: {
          users: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          organisations: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          areas: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          categories: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          articles: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          events: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          products: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          productGroups: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          orders: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          reviews: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          postSchemes: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          bookingCalendars: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          bookings: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          communities: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          potentialCommunities: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
        }
      }
    }

    //--------------------- users --------------------
    case SET_USERS: {
      return {
        ...state,
        users: action.payload,
      }
    }
    case ADD_USER: {
      const usersProcessed = state.users || [];
      usersProcessed.push(action.payload);
      usersProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        users: usersProcessed,
      }
    }
    case ADD_USERS: {
      return {
        ...state,
        users: (state.users || []).concat(action.payload),
      }
    }
    case REPLACE_USER: {
      const usersProcessed = state.users || [];
      const uIndex = usersProcessed.findIndex((u) => {
        return action.payload.user._id === u._id;
      });
      if(uIndex !== -1) {
        usersProcessed.splice(uIndex, 1, action.payload.user);
      }
      return {
        ...state,
        users: usersProcessed,
      }
    }
    case REMOVE_USER: {
      const usersProcessed = state.users || [];
      const uIndex = usersProcessed.findIndex((u) => {
        return action.payload.user._id === u._id;
      });
      if(uIndex !== -1) {
        usersProcessed.splice(uIndex, 1);
      }
      return {
        ...state,
        users: usersProcessed,
      }
    }


    //--------------------- areas --------------------
    case SET_AREAS: {
      return {
        ...state,
        areas: action.payload,
      }
    }
    case ADD_AREA: {
      const areasProcessed = state.areas || [];
      areasProcessed.push(action.payload);
      areasProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        areas: areasProcessed,
      }
    }
    case ADD_AREAS: {
      return {
        ...state,
        areas: (state.areas || []).concat(action.payload),
      }
    }
    case REPLACE_AREA: {
      const areasProcessed = state.areas || [];
      const aIndex = areasProcessed.findIndex((u) => {
        return action.payload.area._id === u._id;
      });
      if(aIndex !== -1) {
        areasProcessed.splice(aIndex, 1, action.payload.area);
      }
      return {
        ...state,
        areas: areasProcessed,
      }
    }
    case REMOVE_AREA: {
      const areasProcessed = state.areas || [];
      const aIndex = areasProcessed.findIndex((u) => {
        return action.payload.area._id === u._id;
      });
      if(aIndex !== -1) {
        areasProcessed.splice(aIndex, 1);
      }
      return {
        ...state,
        areas: areasProcessed,
      }
    }

    //--------------------- organisations --------------------
    case SET_ORGANISATIONS: {
      return {
        ...state,
        organisations: action.payload,
      }
    }
    case ADD_ORGANISATION: {
      const organisationsProcessed = state.organisations || [];
      organisationsProcessed.push(action.payload);
      organisationsProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        organisations: organisationsProcessed,
      }
    }
    case ADD_ORGANISATIONS: {
      return {
        ...state,
        organisations: (state.organisations || []).concat(action.payload),
      }
    }
    case REPLACE_ORGANISATION: {
      const organisationsProcessed = state.organisations || [];
      const oIndex = organisationsProcessed.findIndex((o) => {
        return action.payload.organisation._id === o._id;
      });
      if(oIndex !== -1) {
        organisationsProcessed.splice(oIndex, 1, action.payload.organisation);
      }
      return {
        ...state,
        organisations: organisationsProcessed,
      }
    }
    case REMOVE_ORGANISATION: {
      const organisationsProcessed = state.organisations || [];
      const oIndex = organisationsProcessed.findIndex((o) => {
        return action.payload.organisation._id === o._id;
      });
      if(oIndex !== -1) {
        organisationsProcessed.splice(oIndex, 1);
      }
      return {
        ...state,
        organisations: organisationsProcessed,
      }
    }


    //--------------------- categories --------------------
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      }
    }
    case ADD_CATEGORY: {
      const categoriesProcessed = state.categories || [];
      categoriesProcessed.push(action.payload);
      categoriesProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        categories: categoriesProcessed,
      }
    }
    case ADD_CATEGORIES: {
      return {
        ...state,
        categories: (state.categories || []).concat(action.payload),
      }
    }
    case REPLACE_CATEGORY: {
      const categoriesProcessed = state.categories || [];
      const cIndex = categoriesProcessed.findIndex((c) => {
        return action.payload.category._id === c._id;
      });
      if(cIndex !== -1) {
        categoriesProcessed.splice(cIndex, 1, action.payload.category);
      }
      return {
        ...state,
        categories: categoriesProcessed,
      }
    }
    case REMOVE_CATEGORY: {
      const categoriesProcessed = state.categories || [];
      const cIndex = categoriesProcessed.findIndex((c) => {
        return action.payload.category._id === c._id;
      });
      if(cIndex !== -1) {
        categoriesProcessed.splice(cIndex, 1);
      }
      return {
        ...state,
        categories: categoriesProcessed,
      }
    }

    //--------------------- events --------------------
    case SET_EVENTS: {
      return {
        ...state,
        events: action.payload,
      }
    }
    case ADD_EVENT: {
      const eventsProcessed = state.events || [];
      eventsProcessed.push(action.payload);
      eventsProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        events: eventsProcessed,
      }
    }
    case ADD_EVENTS: {
      return {
        ...state,
        events: (state.events || []).concat(action.payload),
      }
    }
    case REPLACE_EVENT: {
      const eventsProcessed = state.events || [];
      const eIndex = eventsProcessed.findIndex((c) => {
        return action.payload.event._id === c._id;
      });
      if(eIndex !== -1) {
        eventsProcessed.splice(eIndex, 1, action.payload.event);
      }
      return {
        ...state,
        events: eventsProcessed,
      }
    }
    case REMOVE_EVENT: {
      const eventsProcessed = state.events || [];
      const eIndex = eventsProcessed.findIndex((c) => {
        return action.payload.event._id === c._id;
      });
      if(eIndex !== -1) {
        eventsProcessed.splice(eIndex, 1);
      }
      return {
        ...state,
        events: eventsProcessed,
      }
    }

    //--------------------- products --------------------
    case SET_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      }
    }
    case ADD_PRODUCT: {
      const productsProcessed = state.products || [];
      productsProcessed.push(action.payload);
      productsProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        products: productsProcessed,
      }
    }
    case ADD_PRODUCTS: {
      return {
        ...state,
        products: (state.products || []).concat(action.payload),
      }
    }
    case REPLACE_PRODUCT: {
      const productsProcessed = state.products || [];
      const pIndex = productsProcessed.findIndex((c) => {
        return action.payload.product._id === c._id;
      });
      if(pIndex !== -1) {
        productsProcessed.splice(pIndex, 1, action.payload.product);
      }
      return {
        ...state,
        products: productsProcessed,
      }
    }
    case REMOVE_PRODUCT: {
      const productsProcessed = state.products || [];
      const pIndex = productsProcessed.findIndex((c) => {
        return action.payload.product._id === c._id;
      });
      if(pIndex !== -1) {
        productsProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        products: productsProcessed,
      }
    }

    //--------------------- product groups --------------------
    case SET_PRODUCTGROUPS: {
      return {
        ...state,
        productGroups: action.payload,
      }
    }
    case ADD_PRODUCTGROUP: {
      const productGroupsProcessed = state.productGroups || [];
      productGroupsProcessed.push(action.payload);
      productGroupsProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        productGroups: productGroupsProcessed,
      }
    }
    case ADD_PRODUCTGROUPS: {
      return {
        ...state,
        productGroups: (state.productGroups || []).concat(action.payload),
      }
    }
    case REPLACE_PRODUCTGROUP: {
      const productGroupsProcessed = state.productGroups || [];
      const pIndex = productGroupsProcessed.findIndex((c) => {
        return action.payload.productGroup._id === c._id;
      });
      if(pIndex !== -1) {
        productGroupsProcessed.splice(pIndex, 1, action.payload.productGroup);
      }
      return {
        ...state,
        productGroups: productGroupsProcessed,
      }
    }
    case REMOVE_PRODUCTGROUP: {
      const productGroupsProcessed = state.productGroups || [];
      const pIndex = productGroupsProcessed.findIndex((c) => {
        return action.payload.productGroup._id === c._id;
      });
      if(pIndex !== -1) {
        productGroupsProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        productGroups: productGroupsProcessed,
      }
    }

    //--------------------- postSchemes --------------------
    case SET_POST_SCHEMES: {
      return {
        ...state,
        postSchemes: action.payload,
      }
    }
    case ADD_POST_SCHEME: {
      const postSchemesProcessed = state.postSchemes || [];
      postSchemesProcessed.push(action.payload);
      postSchemesProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        postSchemes: postSchemesProcessed,
      }
    }
    case ADD_POST_SCHEMES: {
      return {
        ...state,
        postSchemes: (state.postSchemes || []).concat(action.payload),
      }
    }
    case REPLACE_POST_SCHEME: {
      const postSchemesProcessed = state.postSchemes || [];
      const pIndex = postSchemesProcessed.findIndex((c) => {
        return action.payload.postScheme._id === c._id;
      });
      if(pIndex !== -1) {
        postSchemesProcessed.splice(pIndex, 1, action.payload.postScheme);
      }
      return {
        ...state,
        postSchemes: postSchemesProcessed,
      }
    }
    case REMOVE_POST_SCHEME: {
      const postSchemesProcessed = state.postSchemes || [];
      const pIndex = postSchemesProcessed.findIndex((c) => {
        return action.payload.postScheme._id === c._id;
      });
      if(pIndex !== -1) {
        postSchemesProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        postSchemes: postSchemesProcessed,
      }
    }

    //--------------------- booking calendars --------------------
    case SET_BOOKING_CALENDARS: {
      return {
        ...state,
        bookingCalendars: action.payload,
      }
    }
    case ADD_BOOKING_CALENDAR: {
      const bookingCalendarsProcessed = state.bookingCalendars || [];
      bookingCalendarsProcessed.push(action.payload);
      bookingCalendarsProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        bookingCalendars: bookingCalendarsProcessed,
      }
    }
    case ADD_BOOKING_CALENDARS: {
      return {
        ...state,
        bookingCalendars: (state.bookingCalendars || []).concat(action.payload),
      }
    }
    case REPLACE_BOOKING_CALENDAR: {
      const bookingCalendarsProcessed = state.bookingCalendars || [];
      const pIndex = bookingCalendarsProcessed.findIndex((c) => {
        return action.payload.bookingCalendar._id === c._id;
      });
      if(pIndex !== -1) {
        bookingCalendarsProcessed.splice(pIndex, 1, action.payload.bookingCalendar);
      }
      return {
        ...state,
        bookingCalendars: bookingCalendarsProcessed,
      }
    }
    case REMOVE_BOOKING_CALENDAR: {
      const bookingCalendarsProcessed = state.bookingCalendars || [];
      const pIndex = bookingCalendarsProcessed.findIndex((c) => {
        return action.payload.bookingCalendar._id === c._id;
      });
      if(pIndex !== -1) {
        bookingCalendarsProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        bookingCalendars: bookingCalendarsProcessed,
      }
    }

    //--------------------- reviews --------------------
    case SET_REVIEWS: {
      return {
        ...state,
        reviews: action.payload,
      }
    }
    case ADD_REVIEW: {
      const reviewsProcessed = state.reviews || [];
      reviewsProcessed.push(action.payload);
      reviewsProcessed.sort((a, b) => b.createdAt - a.createdAt);
      return {
        ...state,
        reviews: reviewsProcessed,
      }
    }
    case ADD_REVIEWS: {
      return {
        ...state,
        reviews: (state.reviews || []).concat(action.payload),
      }
    }
    case REPLACE_REVIEW: {
      const reviewsProcessed = state.reviews || [];
      const pIndex = reviewsProcessed.findIndex((c) => {
        return action.payload.review._id === c._id;
      });
      if(pIndex !== -1) {
        reviewsProcessed.splice(pIndex, 1, action.payload.review);
      }
      return {
        ...state,
        reviews: reviewsProcessed,
      }
    }
    case REMOVE_REVIEW: {
      const reviewsProcessed = state.reviews || [];
      const pIndex = reviewsProcessed.findIndex((c) => {
        return action.payload.review._id === c._id;
      });
      if(pIndex !== -1) {
        reviewsProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        reviews: reviewsProcessed,
      }
    }


    //--------------------- articles --------------------
    case SET_ARTICLES: {
      return {
        ...state,
        articles: action.payload,
      }
    }
    case ADD_ARTICLE: {
      const articlesProcessed = state.articles || [];
      articlesProcessed.push(action.payload);
      articlesProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        articles: articlesProcessed,
      }
    }
    case ADD_ARTICLES: {
      return {
        ...state,
        articles: (state.articles || []).concat(action.payload),
      }
    }
    case REPLACE_ARTICLE: {
      const articlesProcessed = state.articles || [];
      const aIndex = articlesProcessed.findIndex((c) => {
        return action.payload.article._id === c._id;
      });
      if(aIndex !== -1) {
        articlesProcessed.splice(aIndex, 1, action.payload.article);
      }
      return {
        ...state,
        articles: articlesProcessed,
      }
    }
    case REMOVE_ARTICLE: {
      const articlesProcessed = state.articles || [];
      const pIndex = articlesProcessed.findIndex((c) => {
        return action.payload.article._id === c._id;
      });
      if(pIndex !== -1) {
        articlesProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        articles: articlesProcessed,
      }
    }


    //--------------------- orders --------------------
    case SET_ORDERS: {
      return {
        ...state,
        orders: action.payload,
      }
    }
    case ADD_ORDER: {
      const ordersProcessed = state.orders || [];
      ordersProcessed.push(action.payload);
      ordersProcessed.sort((a, b) => a.createdAt - b.createdAt);
      return {
        ...state,
        orders: ordersProcessed,
      }
    }
    case ADD_ORDERS: {
      return {
        ...state,
        orders: (state.orders || []).concat(action.payload),
      }
    }
    case REPLACE_ORDER: {
      const ordersProcessed = state.orders || [];
      const oIndex = ordersProcessed.findIndex((c) => {
        return action.payload.order._id === c._id;
      });
      if(oIndex !== -1) {
        ordersProcessed.splice(oIndex, 1, action.payload.order);
      }
      return {
        ...state,
        orders: ordersProcessed,
      }
    }
    case REMOVE_ORDER: {
      const ordersProcessed = state.orders || [];
      const oIndex = ordersProcessed.findIndex((c) => {
        return action.payload.order._id === c._id;
      });
      if(oIndex !== -1) {
        ordersProcessed.splice(oIndex, 1);
      }
      return {
        ...state,
        orders: ordersProcessed,
      }
    }

    case PRUNE_BOOKING_FROM_ORDER: {
      const ordersProcessed = state.orders || [];
      const bookingId = action.payload.bookingId;
      const orderId = action.payload.orderId;
      const orderIndex = ordersProcessed.findIndex((o, oIndex) => {
        return o._id === orderId;
      });
      if(orderIndex !== -1) {
        const orderProcessed = ordersProcessed[orderIndex];
        for(let i = 0; i < orderProcessed?.products?.length; i += 1) {
          if(orderProcessed.products[i]?.selectedBooking?.bookingId === bookingId) {
            delete orderProcessed.products[i].selectedBooking.bookingId;
          }
        }
        ordersProcessed.splice(orderIndex, 1, orderProcessed);
      }
      return {
        ...state,
        orders: ordersProcessed,
      }
    }

    //--------------------- bookings --------------------
    case SET_BOOKINGS: {
      const bookingsProcessed = {
        ...(state.bookings || {}),
        ...(action.payload || {})
      };
      return {
        ...state,
        bookings: bookingsProcessed,
      }
    }
    case ADD_BOOKING: {
      const bookingsProcessed = state.bookings || {};
      const times = action.payload.times || [];
      const datesToSort = [];
      for(let i = 0; i < times.length; i += 1) {
        if(!bookingsProcessed[times[i].date]) {
          bookingsProcessed[times[i].date] = {
            loaded: false,
            list: [],
          }
        }
        const bookingProcessed = {
          _id: action.payload._id,
          organisationId: action.payload.organisationId,
          orderId: action.payload.orderId,
          userId: action.payload.userId,
          bookingCalendarId: action.payload.bookingCalendarId,
          state: action.payload.state,
          ignoreSimiltaniousBookings: action.payload.ignoreSimiltaniousBookings,
          type: action.payload.type,
          notes: action.payload.notes,
          time: times[i],
        }
        bookingsProcessed[times[i].date].list.push(bookingProcessed);
        datesToSort.push(times[i].date);
      }
      for(let i = 0; i < datesToSort.length; i += 1) {
        bookingsProcessed[datesToSort[i]].list.sort(function(a, b) {
          if(a.time.start > b.time.start) {
            return 1;
          } else if(a.time.start < b.time.start) {
            return -1;
          }
          return 0;
        });
      }
      return {
        ...state,
        bookings: bookingsProcessed,
      }
    }
    case REPLACE_BOOKING: {
      const bookingsProcessed = state.bookings || {};
      const origonalBooking = action.payload.origonalBooking;
      const newBooking = action.payload.newBooking;

      // remove old booking data
      const id = origonalBooking._id;
      const dates = [];
      for(let i = 0; i < origonalBooking.times.length; i += 1) {
        dates.push(origonalBooking.times[i].date);
      }
      for(let i = 0; i < dates.length; i += 1) {
        const date = dates[i];
        for(let i = 0; i < bookingsProcessed[date].list.length; i += 1) {
          if(bookingsProcessed[date].list[bookingsProcessed[date].list.length - 1 - i]._id === id) {
            bookingsProcessed[date].list.splice((bookingsProcessed[date].list.length - 1 - i), 1);
          }
        }
      }
      // add new booking data
      const times = newBooking.times || [];
      const datesToSort = [];
      for(let i = 0; i < times.length; i += 1) {
        if(!bookingsProcessed[times[i].date]) {
          bookingsProcessed[times[i].date] = {
            loaded: false,
            list: [],
          }
        }
        const bookingProcessed = {
          _id: newBooking._id,
          organisationId: newBooking.organisationId,
          orderId: newBooking.orderId,
          userId: newBooking.userId,
          bookingCalendarId: newBooking.bookingCalendarId,
          state:  newBooking.state,
          ignoreSimiltaniousBookings: newBooking.ignoreSimiltaniousBookings,
          type: newBooking.type,
          notes: newBooking.notes,
          time: times[i],
        }
        bookingsProcessed[times[i].date].list.push(bookingProcessed);
        datesToSort.push(times[i].date);
      }
      for(let i = 0; i < datesToSort.length; i += 1) {
        bookingsProcessed[datesToSort[i]].list.sort(function(a, b) {
          if(a.time.start > b.time.start) {
            return 1;
          } else if(a.time.start < b.time.start) {
            return -1;
          }
          return 0;
        });
      }
      return {
        ...state,
        bookings: bookingsProcessed,
      }
    }
    case REMOVE_BOOKING: {
      const bookingsProcessed = state.bookings || {};
      const date = action.payload.date;
      const index = action.payload.index;
      bookingsProcessed[date].list.splice(index, 1);
      return {
        ...state,
        bookings: bookingsProcessed,
      }
    }
    case REMOVE_BOOKINGS: {
      const bookingsProcessed = state.bookings || {};
      const dates = action.payload.dates;
      const id = action.payload._id;

      for(let i = 0; i < dates.length; i += 1) {
        const date = action.payload.dates[i];
        for(let i = 0; i < bookingsProcessed[date].list.length; i += 1) {
          if(bookingsProcessed[date].list[bookingsProcessed[date].list.length - 1 - i]._id === id) {
            bookingsProcessed[date].list.splice((bookingsProcessed[date].list.length - 1 - i), 1);
          }
        }
      }

      return {
        ...state,
        bookings: bookingsProcessed,
      }
    }


    //--------------------- communities --------------------
    case SET_COMMUNITIES: {
      return {
        ...state,
        communities: action.payload,
      }
    }
    case ADD_COMMUNITY: {
      const communitiesProcessed = state.communities || [];
      communitiesProcessed.push(action.payload);
      communitiesProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        communities: communitiesProcessed,
      }
    }
    case ADD_COMMUNITIES: {
      return {
        ...state,
        communities: (state.communities || []).concat(action.payload),
      }
    }
    case REPLACE_COMMUNITY: {
      const communitiesProcessed = state.communities || [];
      const aIndex = communitiesProcessed.findIndex((c) => {
        return action.payload.community._id === c._id && action.payload.community.type === c.type;
      });
      if(aIndex !== -1) {
        communitiesProcessed.splice(aIndex, 1, action.payload.community);
      }
      return {
        ...state,
        communities: communitiesProcessed,
      }
    }
    case REMOVE_COMMUNITY: {
      const communitiesProcessed = state.communities || [];
      const pIndex = communitiesProcessed.findIndex((c) => {
        return action.payload.community._id === c._id;
      });
      if(pIndex !== -1) {
        communitiesProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        communities: communitiesProcessed,
      }
    }


    //--------------------- potential communities --------------------
    case SET_POTENTIAL_COMMUNITIES: {
      return {
        ...state,
        potentialCommunities: action.payload,
      }
    }
    case ADD_POTENTIAL_COMMUNITY: {
      const potentialCommunitiesProcessed = state.potentialCommunities || [];
      potentialCommunitiesProcessed.push(action.payload);
      potentialCommunitiesProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        potentialCommunities: potentialCommunitiesProcessed,
      }
    }
    case ADD_POTENTIAL_COMMUNITIES: {
      return {
        ...state,
        potentialCommunities: (state.potentialCommunities || []).concat(action.payload),
      }
    }
    case REPLACE_POTENTIAL_COMMUNITY: {
      const potentialCommunitiesProcessed = state.potentialCommunities || [];
      const aIndex = potentialCommunitiesProcessed.findIndex((c) => {
        return action.payload.potentialCommunity._id === c._id;
      });
      if(aIndex !== -1) {
        potentialCommunitiesProcessed.splice(aIndex, 1, action.payload.potentialCommunity);
      }
      return {
        ...state,
        potentialCommunities: potentialCommunitiesProcessed,
      }
    }
    case REMOVE_POTENTIAL_COMMUNITY: {
      const potentialCommunitiesProcessed = state.potentialCommunities || [];
      const pIndex = potentialCommunitiesProcessed.findIndex((c) => {
        return action.payload.potentialCommunity._id === c._id;
      });
      if(pIndex !== -1) {
        potentialCommunitiesProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        potentialCommunities: potentialCommunitiesProcessed,
      }
    }

    // ------------------- fallback -------------------------
    default:
      return state
  }
}
