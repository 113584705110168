import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import AreaDetailsForm from '../../../../../components/forms/areaDetails';
import PositionForm from '../../../../../components/forms/position';
import SearchStore from '../../../../../components/search/store';

import apiRequest from '../../../../../tools/apiRequest';

import {paletteData, Typography, Input, Button, Dialog, ProgressBar, CheckBox} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function EditAreaInfoSubPanel({
    focusedArea,
    handleChangeFocusedArea,
    processing,
    setProcessing,
    users,
    setUserReferences,
    userReferences,
  }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [expandSearch, setExpandSearch] = useState(false);

  const [moderatorsInitalLoad, setModeratorsInitalLoad] = useState(false);
  const [moderatorsLoaded, setModeratorsLoaded] = useState(false);
  useEffect(() => {
    if(!moderatorsInitalLoad) {
      setModeratorsInitalLoad(true);
      if(focusedArea.moderators?.length > 0) {
        const usersToFetch = [];
        for(let i = 0; i < focusedArea.moderators.length; i += 1) {
          if(!userReferences[focusedArea.moderators[i]]){
            usersToFetch.push(focusedArea.moderators[i])
          }
        }
        if(usersToFetch.length > 0) {
          const query = {_id: {$in: usersToFetch}};
          apiRequest({type: 'get', action: 'users', data: {query, sort: {name: 1}}})
          .then((result) => {
            setUserReferences(result.data.users);
            setModeratorsLoaded(true);
          }).catch((error) => {
            setModeratorsLoaded(true);
          });
        } else {
          setModeratorsLoaded(true);
        }
      } else {
        setModeratorsLoaded(true);
      }
    }
  }, [focusedArea, moderatorsInitalLoad, userReferences, setUserReferences]);
  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Area Details
            </Typography>
          </GridCell>
          <GridCell center={true}>
            <CheckBox
              labelStyle={{color: paletteData.primary.standard.foreground}}
              name='hidden'
              label='Hidden'
              value={focusedArea.hidden || false}
              onChange={(value) => handleChangeFocusedArea('hidden', value)}
            />
          </GridCell>
        </GridContainer>
      </div>
      <AreaDetailsForm
        focusedArea={focusedArea}
        handleChange={(name, value) => handleChangeFocusedArea(name, value)}
      />
      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center={true}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Community Moderators
            </Typography>
          </GridCell>
          <GridCell>
            <Button size='small' palette='secondary' onClick={() => {
              setDialogData({
                type: 'addModerator',
                title: 'Add Community Moderator',
              });
              setDialogOpen(true);
            }}>
              Add Moderator
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {!moderatorsLoaded &&
        <div style={{padding: 10}}>
          <Typography>
            ...loading
          </Typography>
          <ProgressBar palette='secondary'/>
        </div>
      }
      {moderatorsLoaded &&
        <div>
          {focusedArea.moderators?.length > 0 && focusedArea.moderators.map((m, mIndex) => (
            <GridContainer key={mIndex} style={{padding: 10}}>
              <GridCell weight={1} center={true}>
                <Typography>
                  {userReferences[m]?.name || '...loading'}
                </Typography>
              </GridCell>
              <GridCell>
                <Button size='small' palette='primary' onClick={() => {
                  const  moderatorsTemp = focusedArea.moderators || [];
                  moderatorsTemp.splice(mIndex, 1);
                  handleChangeFocusedArea('moderators', moderatorsTemp);
                }}>
                  remove
                </Button>
              </GridCell>

            </GridContainer>
          ))}
          {(!focusedArea.moderators || focusedArea.moderators.length === 0) &&
            <div style={{padding: 10}}>
              <Typography>
                No Community Moderators Assigned
              </Typography>
            </div>
          }
        </div>
      }

      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography style={{color: paletteData.primary.standard.foreground}}>
          Position
        </Typography>
      </div>
      <Input
        name="positionAddress"
        palette='secondary'
        label="Address to generate position"
        type="text"
        value={focusedArea.positionAddress || ''}
        onChange={(value) => handleChangeFocusedArea('positionAddress', value)}
      />
      <PositionForm
        position={focusedArea.position}
        address={focusedArea.positionAddress}
        handleChange={(name, value) => {
          if(name === 'position') {
            handleChangeFocusedArea('position', value);
          } else {
            const tempPosition = focusedArea.position || {};
            tempPosition[name] = value;
            handleChangeFocusedArea('position', tempPosition);
          }
        }}
      />

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData.type === 'addModerator' &&
            <div style={{padding: 10}}>
              <SearchStore databaseArea='users' processing={processing} setProcessing={setProcessing} expandSearch={expandSearch} setExpandSearch={setExpandSearch}/>
              {processing &&
                <div style={{marginLeft: 5, marginRight: 5}}>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {users?.length > 0 && users.map((u, uIndex) => (
                <div key={uIndex} style={{padding: 10, margin: 5, borderStyle: 'solid', borderRadius: 5, borderWidth: 1, cursor: 'pointer'}} onClick={() => {
                  const  moderatorsTemp = focusedArea.moderators || [];
                  moderatorsTemp.push(u._id);
                  handleChangeFocusedArea('moderators', moderatorsTemp);
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}>
                  {u.name}
                </div>
              ))}
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditAreaInfoSubPanel.propTypes = {
  focusedArea: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedArea: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditAreaInfoSubPanel;
