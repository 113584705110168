import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';

import {GridContainer, GridCell} from '../../../../../components/grid';
import {paletteData, Button, Typography, Dialog, Fab, Input, Select} from '../../../../../components/styles';
import {LayoutEdit} from '../../../../../components/layout';

import HeaderForm from '../../../../../components/forms/header';

function EditArticleLayoutSubPanel({
  me,
  focusedArticle,
  handleChangeFocusedArticle,
  processing,
  setProcessing
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [imageLibrary, setImages] = useState(undefined);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if(!imageLibrary) {
      const gallery = focusedArticle.gallery;
      setImages(gallery);
    }
  }, [focusedArticle, imageLibrary]);

  const changePage = (page, type, index, path, value) => {
    if(type === 'insert') {
      const tempLayout = focusedArticle.layout || {
        header: {},
        multiPageEnabled: false,
        pages: [{
          title: 'home',
          sections: [],
        }],
      };
      if(!tempLayout.pages) {
        tempLayout.pages = [{
          title: 'home',
          sections: [],
        }];
      };
      tempLayout.pages[page].sections.splice(index, 0, value);
      handleChangeFocusedArticle('layout', tempLayout);
    } else if (type === 'update') {
      const tempLayout = focusedArticle.layout;
      if(path === undefined) {
        tempLayout.pages[page].sections = value;
      } else {
        if(path === '') {
          objectPath.set(tempLayout.pages[page].sections, `${index}`, value);
        } else {
          objectPath.set(tempLayout.pages[page].sections, `${index}.${path}`, value);
        }
      }
      handleChangeFocusedArticle('layout', tempLayout);
    }
  }

  const changeParent = (type, path, value) => {
    if(type === 'insert') {
      const tempArray = objectPath.get(focusedArticle, path) || [];
      tempArray.push(value);
      objectPath.set(focusedArticle, path, tempArray);
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell center={true}>
            <a
              href={`https://rerengatahi.nz/article/${focusedArticle._id}`}
              style={{textDecoration: 'none'}}
              target="_blank"
              rel='noopener noreferrer'
            >
              <Typography style={{color: paletteData.primary.standard.foreground}}>
                Layout
              </Typography>
            </a>
          </GridCell>
          <GridCell center={true}>
            <a
              href={`https://rerengatahi.nz/article/${focusedArticle._id}`}
              style={{textDecoration: 'none'}}
              target="_blank"
              rel='noopener noreferrer'
            >
              <span className="material-icons md-24" style={{color: paletteData.primary.standard.foreground, marginLeft: 5}}>visibility</span>
            </a>
          </GridCell>
          <GridCell weight={1}/>

          {focusedArticle?.layout?.multiPageEnabled &&
            <GridCell center={true}>
              <Select
                style={{minWidth: 100}}
                palette='primary'
                label="Page Index"
                value={page}
                onChange={(value) => setPage(value)}
              >
                {focusedArticle?.layout?.pages?.length > 0 && focusedArticle.layout.pages.map((p, pIndex) => (
                  <option key={pIndex} value={pIndex} style={{color: 'black'}}>{pIndex + 1}</option>
                ))}
              </Select>
            </GridCell>
          }
          {focusedArticle?.layout?.multiPageEnabled &&
            <GridCell center={true}>
              <Input
                palette='primary'
                label="Page Title"
                value={focusedArticle?.layout?.pages[page]?.title}
                onChange={(value) => {
                  const layoutProcessed = focusedArticle.layout;
                  layoutProcessed.pages[page].title = value;
                  handleChangeFocusedArticle('layout', layoutProcessed);
                }}
              />
            </GridCell>
          }
          {focusedArticle?.layout?.multiPageEnabled && page !== 0 &&
            <GridCell center={true}>
              <Fab
                size='small'
                onClick={() => {
                  setDialogData({
                    type: 'messageWithCallback',
                    title:  'Delete Page',
                    message: 'Deleting a page is perminant are you sure you wish to proceed?',
                    data: {
                      callback: () => {
                        const layoutProcessed = focusedArticle?.layout || {pages: []};
                        layoutProcessed.pages.splice(page, 1);
                        handleChangeFocusedArticle('layout', layoutProcessed);
                        setPage(page - 1);
                      }
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                <span className="material-icons md-24" style={{color: paletteData.primary.standard.foreground}}>delete_sweep</span>
              </Fab>
            </GridCell>
          }
          {focusedArticle?.layout?.multiPageEnabled &&
            <GridCell center={true}>
              <Fab
                size='small'
                onClick={() =>{
                  setDialogData({
                    type: 'movePage',
                    title:  'Move Page',
                    data: {
                      currentIndex: page,
                    },
                    value: {
                      index: page,
                      humanIndex: (page + 1),
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                <span className="material-icons md-24" style={{color: paletteData.primary.standard.foreground}}>move_down</span>
              </Fab>
            </GridCell>
          }
          {focusedArticle?.layout?.multiPageEnabled &&
            <GridCell center={true}>
              <Fab
                size='small'
                onClick={() =>{
                  const layoutProcessed = focusedArticle?.layout || {pages: []};
                  const newIndex = focusedArticle?.layout?.pages?.length;
                  const page = {
                    title: `page ${newIndex}`,
                    sections: [],
                  }
                  layoutProcessed.pages.push(page);
                  handleChangeFocusedArticle('layout', layoutProcessed);
                  setPage(newIndex);
                }}
              >
                <span className="material-icons md-24" style={{color: paletteData.primary.standard.foreground}}>note_add</span>
              </Fab>
            </GridCell>
          }
          <GridCell center={true}>
            <Fab
              size='small'
              onClick={() =>{
                const layoutProcessed = focusedArticle.layout;
                layoutProcessed.multiPageEnabled = !(layoutProcessed.multiPageEnabled || false);
                handleChangeFocusedArticle('layout', layoutProcessed);
                if(layoutProcessed.multiPageEnabled === false) {
                  setPage(0);
                }
              }}
            >
              {focusedArticle?.layout?.multiPageEnabled && <span className="material-icons md-24" style={{color: paletteData.primary.standard.foreground}}>layers</span>}
              {!focusedArticle?.layout?.multiPageEnabled && <span className="material-icons md-24" style={{color: paletteData.primary.standard.foreground}}>layers_clear</span>}
            </Fab>
          </GridCell>
        </GridContainer>
      </div>

      <LayoutEdit
        imageLibrary={imageLibrary}
        videoLibrary={focusedArticle?.videoLibrary || []}
        sections={(focusedArticle?.layout?.pages && focusedArticle?.layout?.pages[page]?.sections) || []}
        changeSection={(type, index, path, value) => changePage(page, type, index, path, value)}
        changeParent={(type, path, value) => changeParent(type, path, value)}
      />

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'editHeader' &&
            <div style={{padding: 10}}>
              <HeaderForm
                header={dialogData?.value || {}}
                handleChange={(name, value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...dialogData.value,
                      [name]: value,
                    },
                  });
                }}
                processing={processing}
              />
            </div>
          }
          {dialogData?.type === 'movePage' &&
            <div style={{padding: 10}}>
              <Input
                type='number'
                label='New Index'
                value={dialogData?.value?.index}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      index: value - 1,
                      humanIndex: value,
                    }
                  });
                }}
              />
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'editHeader' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                   onClick={() => {
                     const tempLayout = {
                       ...focusedArticle.layout,
                       header: dialogData?.value,
                     };
                     handleChangeFocusedArticle('layout', tempLayout);
                     setDialogData(undefined);
                     setDialogOpen(false);
                  }}
                >
                  Edit Header
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'messageWithCallback' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                   onClick={() => {
                     dialogData?.data?.callback();
                     setDialogData(undefined);
                     setDialogOpen(false);
                  }}
                >
                  Submit
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditArticleLayoutSubPanel.propTypes = {
  me: PropTypes.shape({}),
  focusedArticle: PropTypes.shape({}),
  handleChangeFocusedArticle: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
};

export default EditArticleLayoutSubPanel;
