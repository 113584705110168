import {SET_PROFILE} from "./actionTypes";

const initialState = {
  profile: undefined,
}

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      }
    }
    default:
      return state
  }
}
