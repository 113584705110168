import Button from './blocks/Button';
import Fab from './blocks/Fab';
import LinkButton from './blocks/LinkButton';
import Input from './blocks/Input';
import DatePicker from './blocks/DatePicker';
import Select from './blocks/Select';
import TextArea from './blocks/TextArea';
import TextAreaRich from './blocks/TextAreaRich';
import ProgressBar from './blocks/ProgressBar';
import MenuSide from './blocks/MenuSide';
import MenuDropdown from './blocks/MenuDropdown';
import Dialog from './blocks/Dialog';
import SearchBar from './blocks/SearchBar';
import Typography from './blocks/Typography';
import CheckBox from './blocks/CheckBox';
import Slider from './blocks/Slider';

const textData = {
  heading: {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
    fontSize: '3em',
    letterSpacing: undefined,
    lineHeight:  undefined,
  },
  subHeading: {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
    fontSize: '2em',
    letterSpacing: undefined,
    lineHeight:  undefined,
  },
  title: {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
    fontSize: '1.3em',
    letterSpacing: undefined,
    lineHeight:  undefined,
  },
  text: {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
    fontSize: '1em',
    letterSpacing: undefined,
    lineHeight:  1.3,
  },
  subText: {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
    fontSize: '.8em',
    letterSpacing: undefined,
    lineHeight:  1.4,
  },
  button: {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'",
    fontSize: '1em',
    letterSpacing: undefined,
    lineHeight:  undefined,
  }
}

const paletteData = {
  blank: {
    standard: {
      foreground: '#000000',
      background: 'rgba(0, 0, 0, 0.0)',
    },
    highlight: {
      foreground: '#000000',
      background: 'rgba(0, 0, 0, 0.0)',
    },
  },
  primary: {
    standard: {
      foreground: '#ffffff',
      background: '#530000',
    },
    highlight: {
      foreground: '#ffffff',
      background: '#9a0000',
    },
  },
  secondary: {
    standard: {
      foreground: '#530000',
      background: '#ffffff',
    },
    highlight: {
      foreground: '#9a0000',
      background: '#ffffff',
    },
  },
  soft: {
    standard: {
      foreground: '#000000',
      background: '#e8acac',
   },
   highlight: {
     foreground: '#000000',
     background: '#e8acac',
   },
 }
}

export {Button, Fab, LinkButton, Input, DatePicker, TextArea, TextAreaRich, ProgressBar, MenuSide, MenuDropdown, Select, Dialog, SearchBar, Typography, CheckBox, Slider, paletteData, textData};
