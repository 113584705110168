import React from 'react';
import PropTypes from 'prop-types';

function GridCell({children, style, weight, center, centerWeights, className, onClick}) {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        flex: (weight || undefined),
        display: center ? 'flex' : undefined,
        flexDirection: center ? 'column' : undefined,
        ...style,
      }}
    >
      {center && <div style={{flex: centerWeights?.top || 1}} />}
      {children}
      {center && <div style={{flex: centerWeights?.bottom || 1}} />}
    </div>
  );
}

GridCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape({}),
  weight: PropTypes.number,
  center: PropTypes.bool,
  centerWeights: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
  }),
  onClick: PropTypes.func,
};

export default GridCell;
