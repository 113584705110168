import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import jwt from 'jsonwebtoken';
import {Helmet} from "react-helmet";

import {setApiToken} from '../../../store/reducers/auth/actions.js';
import {setProfile} from '../../../store/reducers/user/actions.js';
import {GridContainer, GridCell} from '../../../components/grid';
import {paletteData, Button, Input, Typography, Dialog, ProgressBar} from '../../../components/styles';

import apiRequest from '../../../tools/apiRequest';
import handleErrorMessage from '../../../tools/handleErrorMessage';

function Login({auth, setApiToken, setProfile, setMe}) {
  const [processing, setProcessing] = useState(false);
  const [login, setLogin] = useState({name: '', password: ''});
  const [redirect, setRedirect] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleChangeLogin = (name, value) => {
    const recordProcessed = {
      ...login,
      [name]: value,
    };
    setLogin(recordProcessed);
  };

  const handleLogin = () => {
    setProcessing(true);
    apiRequest({
      type: 'post',
      action: 'auth/login',
      data: {
        email: login.email,
        password: login.password,
      },
    }).then((loginResult) => {
      const user = (jwt.decode(loginResult.data.auth?.access_token)).data;
      setApiToken(loginResult.data.auth);
      apiRequest({type: 'get', action: `users/${user._id}/profile`}).then((profileResult) => {
        setProfile(profileResult.data || undefined);
        setMe({
          ...(jwt.decode(loginResult.data.auth?.access_token))?.data,
          profile: profileResult.data,
        });
        setProcessing(false);
        setRedirect(true);
      }).catch((error) => {
        setDialogData({
          type: 'message',
          title: 'Login Request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
        setProcessing(false);
      });
    }).catch((error) => {
      setDialogData({
        type: 'message',
        title: 'Login Request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
      setProcessing(false);
    });
  }

  if(redirect) {
    return (<Redirect to="/console" />);
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundBlendMode: 'lighten',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      backgroundAttachment: 'fixed',
    }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - login</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      <div style={{flex: 1}}/>
      <div style={{textAlign: 'center'}}>
        <Typography size='heading' style={{color: paletteData.primary.standard.foreground, textShadow: '2px 2px black'}}>
          Rerengatahi
        </Typography>
        <Typography size='subHeading' style={{color: paletteData.primary.standard.foreground, textShadow: '2px 2px black'}}>
          Systems
        </Typography>
        <br/>
        <Typography size='subHeading' style={{color: paletteData.primary.standard.foreground, textShadow: '2px 2px black'}}>
          Working together to support our community
        </Typography>
      </div>
      <br/><br/>
      <GridContainer>
        <GridCell weight={1} />
        <GridCell style={{width: 300}}>
          <div style={{background: 'white', padding: 10, borderRadius: 4}}>
            <div style={{textAlign: 'center', fontSize: '2em'}}>
              Log In
            </div>
            <br/>
            <form>
              <Input
                name="email"
                palette='secondary'
                label="Email"
                type="text"
                autoComplete='username'
                forceShrink={true}
                value={login.email}
                onChange={(value) => handleChangeLogin('email', value)}
              />
              <br/>
              <Input
                name="password"
                palette='secondary'
                label="Password"
                type="password"
                autoComplete='off'
                forceShrink={true}
                value={login.password}
                onChange={(value) => handleChangeLogin('password', value)}
              />
              <br/>
              <div style={{textAlign: 'right'}}>
                <Button palette="primary" onClick={() => handleLogin()} disabled={processing}>
                  LOG IN
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
                <Typography
                  size='subText'
                  style={{textDecoration: 'underline', cursor: 'pointer'}}
                  onClick={() => {
                    setDialogData({
                      type: 'passwordReset',
                      title: 'Reset Password',
                      message: 'Enter the email for password reset.'
                    });
                    setDialogOpen(true);
                  }}
                >
                  reset password
                </Typography>
              </div>
            </form>
          </div>
        </GridCell>
        <GridCell weight={1} />
      </GridContainer>
      <div style={{flex: 2}}/>

      {/*popouts and popups*/}
      <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
        <div style={{padding: 10, background: paletteData.primary.standard.background}}>
          <Typography size="title" style={{color: paletteData.primary.standard.foreground}}>
            {dialogData?.title}
          </Typography>
        </div>

        {dialogData?.message &&
          <div style={{padding: 10}}>
            <Typography>
              {dialogData?.message}
            </Typography>
          </div>
        }
        {dialogData?.type === 'passwordReset' &&
          <div style={{padding: 5}}>
            <Input
              name="email"
              palette='secondary'
              label="Email"
              type="text"
              forceShrink={true}
              value={login.email}
              onChange={(value) => handleChangeLogin('email', value)}
            />
          </div>
        }
        <GridContainer>
          <GridCell weight={1}/>
          <GridCell style={{padding: 10}}>
            <Button
              disabled={processing}
              palette='primary'
              onClick={() => {
                setDialogOpen(false);
                setDialogData(undefined);
              }}
            >
              {dialogData?.type === 'message' ? 'OK' : 'cancel'}
            </Button>
          </GridCell>
          {dialogData?.type === 'passwordReset' &&
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setProcessing(true);
                  const updateKey = {
                    type: 'passwordreset',
                    container: 'systems',
                    value: login.email,
                  };
                  apiRequest({type: 'post', action: `public_keys/create`, data: {updateKey}})
                  .then((result) => {
                    setProcessing(false);
                    setDialogData({
                      type: 'message',
                      title: 'Password Reset Sent',
                      message: 'Please check your emails and follow the instructions to reset your password',
                    });
                    setDialogOpen(true);
                  }).catch((error) => {
                    setProcessing(false);
                    setDialogData({
                      type: 'message',
                      title: 'Password Reset Sent',
                      message: 'Please check your emails and follow the instructions to reset your password',
                    });
                    setDialogOpen(true);
                  });
                }}
              >
                Send Password Reset Email
              </Button>
            </GridCell>
          }
        </GridContainer>
      </Dialog>
    </div>
  );
}

Login.propTypes = {
  auth: PropTypes.shape({}),
  setApiToken: PropTypes.func,
  setProfile: PropTypes.func,
}

export default connect(undefined, {setApiToken, setProfile})(Login);
