import React from 'react';
import PropTypes from 'prop-types';

import {paletteData, Typography, Input, Button} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';
import ContactAddressForm from '../../../../../components/forms/contactAddress';
import PositionForm from '../../../../../components/forms/position';

function OfficesSubPanel({
  me,
  setFocusedFormSection,
  processing,
  setProcessing,
  search,
  setSearch,
  queryLimit,

  focusedOrganisation,
  handleChangeFocusedOrganisation,

  areaReferences,
}) {
    return (
      <div>
        {(!focusedOrganisation?.offices || focusedOrganisation?.offices?.length === 0) &&
          <div>
            <div style={{background: paletteData.primary.standard.background, padding: 10}}>
              <Typography style={{color: paletteData.primary.standard.foreground}}>
                Offices Details
              </Typography>
            </div>
            <div style={{padding: 10}}>
              <Typography>
                No offices registered
              </Typography>
            </div>
          </div>
        }
        {focusedOrganisation?.offices?.length > 0 && focusedOrganisation.offices.map((o, oIndex) => (
          <div key={oIndex}>
            <div style={{background: paletteData.primary.standard.background, padding: 10}}>
              {o.name &&
                <Typography style={{color: paletteData.primary.standard.foreground}}>
                  {o.name} details
                </Typography>
              }
              {!o.name &&
                <Typography style={{color: paletteData.primary.standard.foreground}}>
                  {areaReferences[o.area] ? areaReferences[o.area].name : ''} office {!areaReferences[o.area] ? oIndex : ''} details
                </Typography>
              }
            </div>
            <Input
              name="name"
              palette='secondary'
              label="Office Name"
              type="text"
              value={o.name || ''}
              onChange={(value) => {
                const officesTemp = focusedOrganisation.offices;
                officesTemp[oIndex].name = value;
                handleChangeFocusedOrganisation('offices', officesTemp);
              }}
            />
            <Input
              name="email"
              palette='secondary'
              label="Office Email"
              type="text"
              value={o?.email || ''}
              onChange={(value) => {
                const officesTemp = focusedOrganisation.offices;
                officesTemp[oIndex].email = value;
                handleChangeFocusedOrganisation('offices', officesTemp);
              }}
            />
            <Input
              name="phone"
              palette='secondary'
              label="Office Phone"
              type="text"
              value={o?.phone || ''}
              onChange={(value) => {
                const officesTemp = focusedOrganisation.offices;
                officesTemp[oIndex].phone = value;
                handleChangeFocusedOrganisation('offices', officesTemp);
              }}
            />
            <br/>
            <Typography style={{paddingLeft: 5, fontWeight: 500}}>
              Office Address
            </Typography>
            <ContactAddressForm address={o.address} handleChange={(name, value) => {
              const offices = focusedOrganisation.offices;
              if(!offices[oIndex].address) {
                offices[oIndex].address = {};
              }
              offices[oIndex].address[name] = value;
              handleChangeFocusedOrganisation('offices', offices);
            }}/>
            <GridContainer>
              <GridCell weight={1} center style={{paddingLeft: 5}}>
                {(!o.position?.lat || !o.position?.lng) &&
                  <Typography>
                    Your address is NOT visible to the public.  If you want people to be able to pick up orders from you your address must be public.
                  </Typography>
                }
                {o.position?.lat && o.position?.lng &&
                  <Typography>
                    This address is visible to the public.
                  </Typography>
                }
              </GridCell>
              <GridCell>
                {(!o.position?.lat || !o.position?.lng) &&
                  <PositionForm
                    variant='hiddenLatLng'
                    buttonText='Make Address Public'
                    position={o.position}
                    address={`${o.address?.extra ? `${o.address?.extra} ` : ''}${o.address?.number ? `${o.address?.number} ` : ''}${o.address?.street ? `${o.address?.street} ` : ''}${o.address?.suburb ? `${o.address?.suburb} ` : ''}${o.address?.city ? `${o.address?.city} ` : ''}${o.address?.country ? `${o.address?.country} ` : ''}`}
                    handleChange={(name, value) => {
                      if(name === 'position') {
                        const offices = focusedOrganisation.offices;
                        offices[oIndex].position = value;
                        handleChangeFocusedOrganisation('offices', offices);
                      } else {
                        const offices = focusedOrganisation.offices;
                        if(!offices[oIndex].position) {
                          offices[oIndex].position = {};
                        }
                        offices[oIndex].position[name] = value;
                        handleChangeFocusedOrganisation('offices', offices);
                      }
                    }}
                  />
                }
                {o.position?.lat && o.position?.lng &&
                  <Button palette='primary' onClick={() => {
                    const offices = focusedOrganisation.offices;
                    offices[oIndex].position = {};
                    handleChangeFocusedOrganisation('offices', offices);
                  }}>
                    Make address private
                  </Button>
                }
              </GridCell>
            </GridContainer>
            <br/>
          </div>
        ))}
        <GridContainer>
          <GridCell weight={1}/>
          <GridCell>
            <Button palette='primary' style={{padding: 30}} onClick={() => {
              window.scrollTo(0, 0);
              setFocusedFormSection('staff');
            }}>
              Next Step
            </Button>
          </GridCell>
          <GridCell weight={1}/>
        </GridContainer>
      </div>
  );
};

OfficesSubPanel.propTypes = {
  me: PropTypes.shape({}),
  setFocusedFormSection: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,

  focusedOrganisation: PropTypes.shape({}),
  handleChangeFocusedOrganisation: PropTypes.func,
};

export default OfficesSubPanel;
