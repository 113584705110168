import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import SearchStore from '../../../../components/search/store';
import {Typography, Button, Dialog, ProgressBar, paletteData} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

import FormInformationSubPanel from './FormInformation';
import AboutOrganisationSubPanel from './AboutOrganisation';

import OfficesSubPanel from './Offices';
import StaffSubPanel from './Staff';
import BuyingAndSellingSubPanel from './BuyingAndSelling';
import ListingSubPanel from './Listing';
import ImagesSubPanel from './Images';
import SummarySubPanel from './Summary';

import apiRequest from '../../../../tools/apiRequest';
import {setApiToken} from '../../../../store/reducers/auth/actions.js';
import {
  setSearch,

  addOrganisation,
  replaceOrganisation,
  removeOrganisation,
} from '../../../../store/reducers/lists/actions.js';
import {
  setOrganisationReference,
  clearOrganisationReference,
  setUserReference,
  setAreaReference,
  setAreaReferences,
  setCategoryReference,
} from '../../../../store/reducers/references/actions.js';

function WorkflowOrgSetup({
  me,
  auth,
  setApiToken,
  setSearch,
  search,
  queryLimit,

  categories,
  categoryReferences,
  setCategoryReference,

  users,
  userReferences,
  setUserReference,

  areas,
  areaReferences,
  setAreaReference,
  setAreaReferences,

  organisations,
  organisationReferences,
  setOrganisations,
  addOrganisations,
  addOrganisation,
  replaceOrganisation,
  removeOrganisation,
  setOrganisationReference,
  clearOrganisationReference,
}) {
  // page state
  const [focusedOrganisation, setFocusedOrganisation] = useState(undefined);
  const [focusedOrganisationOriginalOwner, setFocusedOrganisationOriginalOwner] = useState(undefined);
  const [focusedFormSection, setFocusedFormSection] = useState(undefined);
  const [layoutGeneration, setLayoutGeneration] = useState({});
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleChangeFocusedOrganisation = (name, value) => {
    const organisationProcessed = {
      ...focusedOrganisation,
      [name]: value,
    };
    setFocusedOrganisation(organisationProcessed);
  };

  const handleChangeLayoutGeneration = (name, value) => {
    const layoutGenerationProcessed = {
      ...layoutGeneration,
      [name]: value,
    }
    setLayoutGeneration(layoutGenerationProcessed);
  }

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({ type: 'post', action: 'upload/image', data: { file: reader.result } }).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({ imageId: result.data.public_id, alt }, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Setup an organisation
            </Typography>
          </GridCell>
          {focusedOrganisation &&
            <GridCell>
              <Button palette='secondary' onClick={() => {
                if(focusedFormSection) {
                  if(focusedFormSection === 'aboutOrganisation') {
                    setFocusedFormSection(undefined);
                  } else if(focusedFormSection === 'offices') {
                    setFocusedFormSection('aboutOrganisation');
                  } else if(focusedFormSection === 'staff') {
                    setFocusedFormSection('offices');
                  } else if(focusedFormSection === 'buyingAndSelling') {
                    setFocusedFormSection('staff');
                  } else if(focusedFormSection === 'listing') {
                    setFocusedFormSection('buyingAndSelling')
                  } else if(focusedFormSection === 'images') {
                    setFocusedFormSection('listing')
                  } else if(focusedFormSection === 'summary') {
                    setFocusedFormSection('images')
                  }
                } else {
                  setFocusedFormSection(undefined);
                  setFocusedOrganisation(undefined);
                }
              }}>
                Back
              </Button>
            </GridCell>
          }
        </GridContainer>
      </div>
      {!focusedOrganisation &&
        <GridContainer style={{flex: 1}}>
          <GridCell weight={1} center={true} centerWeights={{top: 1, bottom: 3}} style={{textAlign: 'center'}}>
            <Typography size='title'>
              No organisation selected
            </Typography>
            <br/>
            <GridContainer>
              <GridCell weight={1}/>
              {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
                <GridCell style={{padding: 10}}>
                  <Button palette='primary' onClick={() => {
                    setFocusedOrganisation({_id: 'new', owner: me._id, gallery: [], videoLibrary: [], offices: []});
                    setFocusedFormSection(undefined);
                  }}>
                    Create new organisation
                  </Button>
                </GridCell>
              }
              <GridCell style={{padding: 10}}>
                <Button palette='primary' onClick={() => {
                  setDialogData({
                    type: 'changeOrganisation',
                    title: 'Change Organisation',
                    value: {},
                  });
                  setDialogOpen(true);
                }}>
                  Choose an organisation
                </Button>
              </GridCell>
              <GridCell weight={1}/>
            </GridContainer>
          </GridCell>
        </GridContainer>
      }
      {focusedOrganisation &&
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <br/>
          {(!focusedFormSection || focusedFormSection === 'formInformation') &&
            <FormInformationSubPanel me={me} setFocusedFormSection={setFocusedFormSection}/>
          }
          {focusedFormSection === 'aboutOrganisation' &&
            <AboutOrganisationSubPanel
              me={me}
              setFocusedFormSection={setFocusedFormSection}
              processing={processing}
              setProcessing={setProcessing}
              search={search}
              setSearch={setSearch}
              queryLimit={queryLimit}

              focusedOrganisation={focusedOrganisation}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}

              users={users}
              userReferences={userReferences}
              setUserReference={setUserReference}

              areas={areas}
              areaReferences={areaReferences}
              setAreaReference={setAreaReference}
            />
          }
          {focusedFormSection === 'offices' &&
            <OfficesSubPanel
              me={me}
              setFocusedFormSection={setFocusedFormSection}
              processing={processing}
              setProcessing={setProcessing}
              search={search}
              setSearch={setSearch}
              queryLimit={queryLimit}

              focusedOrganisation={focusedOrganisation}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}

              areaReferences={areaReferences}
            />
          }
          {focusedFormSection === 'staff' &&
            <StaffSubPanel
              me={me}
              setFocusedFormSection={setFocusedFormSection}
              processing={processing}
              setProcessing={setProcessing}
              search={search}
              setSearch={setSearch}
              queryLimit={queryLimit}
              handleUploadImage={handleUploadImage}

              focusedOrganisation={focusedOrganisation}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}

              areaReferences={areaReferences}
            />
          }
          {focusedFormSection === 'buyingAndSelling' &&
            <BuyingAndSellingSubPanel
              me={me}
              setFocusedFormSection={setFocusedFormSection}
              processing={processing}
              setProcessing={setProcessing}
              search={search}
              setSearch={setSearch}
              queryLimit={queryLimit}

              focusedOrganisation={focusedOrganisation}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}

              categories={categories}
            />
          }
          {focusedFormSection === 'listing' &&
            <ListingSubPanel
              me={me}
              setFocusedFormSection={setFocusedFormSection}
              processing={processing}
              setProcessing={setProcessing}
              search={search}
              setSearch={setSearch}
              queryLimit={queryLimit}
              handleUploadImage={handleUploadImage}

              focusedOrganisation={focusedOrganisation}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
            />
          }
          {focusedFormSection === 'images' &&
            <ImagesSubPanel
              me={me}
              setFocusedFormSection={setFocusedFormSection}
              processing={processing}
              setProcessing={setProcessing}
              search={search}
              setSearch={setSearch}
              queryLimit={queryLimit}
              handleUploadImage={handleUploadImage}

              focusedOrganisation={focusedOrganisation}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
            />
          }
          {focusedFormSection === 'summary' &&
            <SummarySubPanel
              me={me}
              setFocusedFormSection={setFocusedFormSection}
              processing={processing}
              setProcessing={setProcessing}

              focusedOrganisation={focusedOrganisation}
              handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}

              layoutGeneration={layoutGeneration}
              handleChangeLayoutGeneration={handleChangeLayoutGeneration}

              setApiToken={setApiToken}
              userReferences={userReferences}
              setUserReference={setUserReference}
              addOrganisation={addOrganisation}
              setOrganisationReference={setOrganisationReference}
              replaceOrganisation={replaceOrganisation}

              focusedOrganisationOriginalOwner={focusedOrganisationOriginalOwner}
              setFocusedOrganisation={setFocusedOrganisation}
              setFocusedOrganisationOriginalOwner={setFocusedOrganisationOriginalOwner}
            />
          }
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'changeOrganisation' &&
            <div style={{padding: 10}}>
              <SearchStore
                databaseArea='organisations'
                processing={processing}
                setProcessing={setProcessing}
                expandSearch={dialogData.value.expandSearch}
                setExpandSearch={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...dialogData.value,
                      expandSearch: value,
                    }
                  });
                }}
              />
              {(!organisations || processing) &&
                <ProgressBar palette='secondary'/>
              }
              {organisations && organisations.map((o, oIndex) => (
                <div
                  key={oIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!organisationReferences || !organisationReferences[o._id]) {
                      setOrganisationReference(o);
                    }
                    if(!userReferences[o.owner]) {
                      let loaded = false;
                      if(users) {
                        for(let i = 0; i < users.length; i += 1) {
                          if(users[i]._id === o.owner) {
                            loaded = true;
                            setUserReference(users[i]);
                            break;
                          }
                        }
                      }
                      if(!loaded) {
                        apiRequest({type: 'get', action: `users/${o.owner}/user`}).then((userResult) => {
                          setUserReference(userResult.data.user);
                        }).catch((error) => {
                          handleChangeFocusedOrganisation('owner', undefined);
                        });
                      }
                    }

                    const officesToLoad = [];
                    for(let i = 0; i < (o.offices || []).length; i += 1) {
                      if(o.offices[i].area && ! areaReferences[o.offices[i].area]) {
                        officesToLoad.push(o.offices[i].area);
                      }
                    }
                    if(officesToLoad.length > 0) {
                      const query = {_id: {$in: officesToLoad}};
                      apiRequest({type: 'get', action: 'areas', data: {query, sort: {name: 1}, skip: 0}})
                      .then((result) => {
                        setAreaReferences(result.data.areas);
                      }).catch((error) => {
                        console.log(error);
                      });
                    }

                    setFocusedFormSection(undefined);
                    setFocusedOrganisation(o);
                    setFocusedOrganisationOriginalOwner(o.owner);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {o.name}
                </div>
              ))}
              {processing && organisations?.length > 15 &&
                <div>
                  <Typography>
                    Loading...
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {organisations?.length >= search?.organisations?.queryDepth * queryLimit &&
                <div style={{padding: 5}}>
                  <Button
                    palette='primary'
                    disabled={processing}
                    onClick={() => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...dialogData.value,
                          expandSearch: true,
                        }
                      });
                    }}
                  >
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

WorkflowOrgSetup.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
  setApiToken: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,

    organisations: state.lists.organisations,
    organisationReferences: state.references.organisations || {},

    users: state.lists.users,
    userReferences: state.references.users || {},

    areas: state.lists.areas,
    areaReferences: state.references.areas || {},

    categories: state.lists.categories,
    categoryReferences: state.references.categories || {},
  };
};

export default connect(mapStateToProps, {
  addOrganisation,
  replaceOrganisation,
  removeOrganisation,
  setOrganisationReference,
  clearOrganisationReference,
  setUserReference,
  setAreaReference,
  setAreaReferences,
  setSearch,
  setApiToken,
  setCategoryReference,
})(WorkflowOrgSetup);
