import React from 'react';
import PropTypes from 'prop-types';

import {Input, Button} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function ContactOfficeForm({contact, handleChange, owner, verification, handleVerifyEmail, processing}) {
  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            name="email"
            palette='secondary'
            label="Enquiry Email"
            type="text"
            value={contact?.email || ''}
            onChange={(value) => handleChange('email', value)}
          />
        </GridCell>
        {owner && verification && !verification[contact?.email] &&
          <GridCell style={{marginTop: 7}}>
            <Button palette='primary' disabled={processing} onClick={() => handleVerifyEmail(contact?.email)}>
              Verify Email
            </Button>
          </GridCell>
        }
      </GridContainer>
      <Input
        name="phone"
        palette='secondary'
        label="Enquiry Phone"
        type="text"
        value={contact?.phone || ''}
        onChange={(value) => handleChange('phone', value)}
      />
    </div>
  );
}

ContactOfficeForm.propTypes = {
  contact: PropTypes.shape({}),
}

export default ContactOfficeForm;
