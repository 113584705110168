import React from 'react';
import PropTypes from 'prop-types';

import {Input} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function ContactUserForm({phone, handleChange}) {
  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            name="cell"
            palette='secondary'
            label="Cell Phone"
            type="text"
            value={phone?.cell || ''}
            onChange={(value) => handleChange('cell', value)}
          />
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="home"
            palette='secondary'
            label="Home Phone"
            type="text"
            value={phone?.home || ''}
            onChange={(value) => handleChange('home', value)}
          />
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="work"
            palette='secondary'
            label="Work Phone"
            type="text"
            value={phone?.work || ''}
            onChange={(value) => handleChange('work', value)}
          />
        </GridCell>
      </GridContainer>
    </div>
  );
}

ContactUserForm.propTypes = {
  phone: PropTypes.shape({}),
}

export default ContactUserForm;
