import React from 'react';
import PropTypes from 'prop-types';

function MenuSide({children, style, open, side, id, handleClose}) {
  return (
    <div
      id={id || 'sideMenu'}
      style={{
        height: '100%',
        width: open ? '' : 0,
        position: 'fixed',
        zIndex: 1,
        top: 0,
        left: side === 'right' ? undefined : 0,
        right: side === 'right' ? 0 : undefined,
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: 1,
        overflowX: 'hidden',
        transition: '0.5s',
        ...style,
      }}
      onMouseLeave={() => handleClose()}
    >
      {children}
    </div>
  );
}

MenuSide.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.shape({}),
  handleClose: PropTypes.func,
  side: PropTypes.string,
  id: PropTypes.string,
};

export default MenuSide;
