import React, {useState} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../../components/search/store';
import {Button, ProgressBar, Typography, Dialog, paletteData} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

import apiRequest from '../../../../../tools/apiRequest';
import handleErrorMessage from '../../../../../tools/handleErrorMessage';

function SearchBookingCalendarsSubPanel({
    bookingCalendars,
    setBookingCalendars,
    addBookingCalendars,
    search,
    setSearch,
    queryLimit,
    processing,
    setProcessing,
    setFocusedBookingCalendar,
    handleChangeFocusedBookingCalendar,
    setFocusedBookingCalendarOriginalOwner,
    setBookingCalendarReferences,

    focusedOrganisation,
    organisations,
    setOrganisations,
    addOrganisations,
    organisationReferences,
    setOrganisationReference,
    setFocusedOrganisation,
}) {
  const [expandSearch, setExpandSearch] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <SearchStore
        databaseArea='bookingCalendars' 
        processing={processing}
        setProcessing={setProcessing}
        expandSearch={expandSearch}
        setExpandSearch={setExpandSearch}
      />
      {bookingCalendars?.length > 0 &&
        <div>
          {bookingCalendars.map((ps, psIndex) => (
            <div
              key={psIndex}
              style={{margin: 5, cursor: 'pointer', padding: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: 5}}
              onClick={() => {
                const catchError = {
                  organisation: false,
                };
                if(ps.organisation && ! organisationReferences[ps.organisation]) {
                  let loaded = false;
                  if(organisations) {
                    for(let i = 0; i < organisations.length; i += 1) {
                      if(organisations[i]._id === ps.organisation) {
                        loaded = true;
                        setOrganisationReference(organisations[i]);
                        break
                      }
                    }
                  }
                  if(!loaded) {
                    apiRequest({type: 'get', action: `organisations/${ps.organisation}`}).then((organisationResult) => {
                      setOrganisationReference(organisationResult.data.organisation);
                    }).catch((error) => {
                      catchError.organisation = true;
                    });
                  }
                }
                if(catchError.organisation) {
                  const pst = Object.assign({}, ps);
                  setFocusedBookingCalendar({
                    ...pst,
                    organisation: catchError.organisation ? undefined : pst.organisation,
                  });
                } else {
                  setFocusedBookingCalendar(Object.assign({}, ps));
                }
              }}
            >
              <GridContainer>
                <GridCell weight={1}>
                  <Typography>
                    {ps.name}
                  </Typography>
                </GridCell>
              </GridContainer>
            </div>
          ))}

          {processing && bookingCalendars?.length > 9 &&
            <div>
              <Typography>
                Loading...
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {bookingCalendars.length >= search?.bookingCalendars?.queryDepth * queryLimit &&
            <div style={{padding: 5}}>
              <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                Load More
              </Button>
            </div>
          }
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'changeProvider' &&
            <div style={{padding: 10}}>
              <SearchStore
                databaseArea='organisations'
                processing={processing}
                setProcessing={setProcessing}
                expandSearch={dialogData.value.expandSearch}
                setExpandSearch={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...dialogData.value,
                      expandSearch: value,
                    }
                  });
                }}
              />
              {(!organisations || processing) &&
                <ProgressBar palette='secondary'/>
              }
              {organisations && organisations.map((o, oIndex) => (
                <div
                  key={oIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    setProcessing(true);
                    if(!organisationReferences[o._id]) {
                      setOrganisationReference(o);
                    }
                    setFocusedOrganisation(o);
                    const query = {organisationId: o._id};
                    setSearch({name: 'bookingCalendars', value: {text: '', query, data: {usesQueryLimit: true}, queryDepth: 1}});
                    apiRequest({type: 'get', action: 'bookingCalendars', data: {query, sort: {name: 1}, skip: 0, limit: queryLimit}})
                    .then((result) => {
                      setProcessing(false);
                      setBookingCalendars(result.data.bookingCalendars || []);
                      setBookingCalendarReferences(result.data.bookingCalendars || []);
                    }).catch((error) => {
                      setProcessing(false);
                      setBookingCalendars([]);
                      setDialogData({
                        type: 'message',
                        title: `View booking calendar request denied`,
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {o.name}
                </div>
              ))}
              {processing && organisations?.length > 15 &&
                <div>
                  <Typography>
                    Loading...
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {organisations?.length >= search?.organisations?.queryDepth * queryLimit &&
                <div style={{padding: 5}}>
                  <Button
                    palette='primary'
                    disabled={processing}
                    onClick={() => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...dialogData.value,
                          expandSearch: true,
                        }
                      });
                    }}
                  >
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

SearchBookingCalendarsSubPanel.propTypes = {
  bookingCalendars: PropTypes.arrayOf(PropTypes.shape({})),
  addBookingCalendars: PropTypes.func,
  search: PropTypes.shape({}),
  setSearch: PropTypes.func,
  queryLimit: PropTypes.number,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  setFocusedBookingCalendar: PropTypes.func,
  setFocusedBookingCalendarIndex: PropTypes.func,
};

export default SearchBookingCalendarsSubPanel;
