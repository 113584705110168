import React from 'react';
import PropTypes from 'prop-types';

import {Input, Button} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function OfficeForm({office, areaReferences, handleChange}) {
  return (
    <div>
      <Input
        name="name"
        palette='secondary'
        label="Office Name"
        type="text"
        value={office?.name || ''}
        onChange={(value) => handleChange('name', value)}
      />

      <GridContainer>
        <GridCell weight={1}>
          <Input
            disabled={true}
            name="area"
            palette='secondary'
            label="Operational Area"
            type="text"
            value={office?.area ? areaReferences[office?.area]?.name || '...processing' : 'No area selected'}
          />
        </GridCell>
        <GridCell style={{marginTop: 7}}>
          <Button palette='primary' onClick={() => handleChange('area', undefined)}>
            Change Area
          </Button>
        </GridCell>
      </GridContainer>
    </div>
  );
}

OfficeForm.propTypes = {
  office: PropTypes.shape({}),
  areaReferences: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default OfficeForm;
