import React from 'react';
import PropTypes from 'prop-types';

import {Typography, Fab} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

function ParralaxImageBlockEdit({
  block,
  removeBlock,
  blockIndex,
  maxIndex,
  changeSection,
  changeParent,
  changeBlockIndex,
  stripImageURL,

  dialogData,
  dialogOpen,
  setDialogData,
  setDialogOpen
}) {
  return (
    <div style={{position: 'relative'}}>
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          minWidth: 20,
          padding: 5,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 5,
          background: 'white',
        }}
      >
        <GridContainer>
          <GridCell center={true}>
            <Fab
              size='small'
              onClick={() => {
                const {imageId, imageOptions} = stripImageURL(block.children[0].options.style.backgroundImage);
                const alt = block.children[0].options.alt || undefined;
                setDialogData({
                  type: 'selectImage',
                  title: 'Add Image',
                  data: {
                    callback: (value) => {
                      const valueProcessed = value || {};
                      if(!value?.imageId) {
                        valueProcessed.imageId = 'media-assets/default_e2qiho.jpg';
                        valueProcessed.imageOptions = 'q_auto:good,c_fill,w_2048';
                      }
                      const imageURL = `url("https://res.cloudinary.com/taitokerau-tatou/image/upload/${valueProcessed.imageOptions}/${valueProcessed.imageId}")`;
                      const styleProcessed = {
                        ...(block.children[0].options.style || {}),
                        backgroundImage: imageURL,
                      }
                      changeSection('update', blockIndex, 'children.0.options.style', styleProcessed);
                    }
                  },
                  value: {
                    search: '',
                    imageId,
                    imageOptions,
                    alt,
                  },
                });
                setDialogOpen(true);
              }}
            >
              <span className="material-icons md-24">photo</span>
            </Fab>
          </GridCell>
          <GridCell center={true}>
            <Fab
              size='small'
              style={{background: '#c6c6c6', cursor: 'pointer'}}
              onClick={() => {
                setDialogData({
                  type: 'moveSection',
                  title: 'Move Section',
                  data: {
                    maxIndex,
                    currentIndex: blockIndex + 1,
                    callbackMove: (shift) => changeBlockIndex(shift),
                    callbackRemove: () => removeBlock(blockIndex),
                  },
                  value: {
                    index: blockIndex + 1,
                  },
                });
                setDialogOpen(true);
              }}
            >
              <Typography style={{textAlign: 'center'}}>
                {blockIndex + 1}
              </Typography>
            </Fab>
          </GridCell>
        </GridContainer>
      </div>
      <div style={{position: 'relative', zIndex: -1}}>
        <div style={block.options?.style}>
          <div style={block.children[0]?.options?.style}/>
        </div>
      </div>
    </div>
  );
}

ParralaxImageBlockEdit.propTypes = {
  block: PropTypes.shape({}),
  changeSection: PropTypes.func,
  changeParent: PropTypes.func,
};

export default ParralaxImageBlockEdit;
