import React from 'react';
import PropTypes from 'prop-types';

function GridContainer({children, style, onClick}) {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        ...style,
      }}
    >
      {children}
    </div>
  );
}

GridContainer.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

export default GridContainer;
