import React from 'react';
import PropTypes from 'prop-types';
import {convertToRaw} from 'draft-js';
import {draftToMarkdown} from 'markdown-draft-js';

import {Input, TextAreaRich, Select} from '../../styles';

function productDetailsForm({focusedProduct, handleChange, handleChangeMultiple, processing, organisationReferences, productGroupReferences}) {
  return (
    <div>
      <Select
        name="state"
        palette='secondary'
        label="State"
        value={focusedProduct?.state || ''}
        onChange={(value) => handleChange('state', value)}
      >
        <option value="active">Visible</option>
        <option value="inactive">Hidden</option>
      </Select>
      <Input
        name="name"
        palette='secondary'
        label="Name"
        type="text"
        value={focusedProduct?.name || ''}
        onChange={(value) => handleChange('name', value)}
      />
      <TextAreaRich
        editorState={focusedProduct?.markdownState || undefined}
        markdown={focusedProduct?.description || ''}
        title='Product Description'
        palette='soft'
        onChange={(value) => {
          handleChangeMultiple({
            markdownState: value,
            description: draftToMarkdown(convertToRaw(value.getCurrentContent())),
          });
        }}
      />
    </div>
  );
}

productDetailsForm.propTypes = {
  focusedProduct: PropTypes.shape({}),
  handleChange: PropTypes.func,
  processing: PropTypes.bool,
}

export default productDetailsForm;
