import React from 'react';

import {paletteData, LinkButton} from '../../../components/styles';

function NavigationError({staticContext = {}}) {
  staticContext.status = 404;
  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{flex: 1}}/>
      <div style={{textAlign: 'center'}}>
        <div style={{color: paletteData.secondary.standard.foreground, fontSize: '2em'}}>
          404 Page not found
        </div>
        <br/>
        <div style={{color: paletteData.secondary.standard.foreground, fontSize: '1em'}}>
          You have navigated to a page that does not exist
        </div>
        <br/>
        <LinkButton to="/" palette="primary">Home</LinkButton>
      </div>
      <div style={{flex: 2}}/>
    </div>
  );
}

export default NavigationError;
