import React from 'react';
import PropTypes from 'prop-types';

import {Input, Button, Typography} from '../../styles';
import {GridContainer, GridCell} from '../../grid';
import {checkPasswordStrength, validateEmail} from '../../../tools/validation';

function UserForm({me, user, handleChange, handleVerifyEmail, processing, verification, privilageEscalation}) {

  return (
    <div>
      <Input
        disabled={privilageEscalation}
        name="name"
        palette='secondary'
        label="Username"
        type="text"
        value={user?.name || ''}
        onChange={(value) => handleChange('name', value)}
      />
      <GridContainer>
        <GridCell weight={1}>
          <Input
            disabled={privilageEscalation}
            name="email"
            palette='secondary'
            label="Login"
            type="text"
            value={user?.email || ''}
            onChange={(value) => handleChange('email', value)}
          />
          {privilageEscalation &&
            <Typography size='subText' style={{color: '#c6c6c6', marginLeft: 5, marginRigth: 5}}>
              * Cant change login details of someone of higher permissions
            </Typography>
          }
          {!validateEmail(user?.email) &&
            <Typography size='subText' style={{color: '#c6c6c6', marginLeft: 5, marginRigth: 5}}>
              please enter a valid email
            </Typography>
          }
        </GridCell>
        {verification && !verification[user?.email] &&
          <GridCell style={{marginTop: 7}}>
            <Button palette='primary' disabled={processing} onClick={() => handleVerifyEmail(user?.email)}>
              Verify Email
            </Button>
          </GridCell>
        }
      </GridContainer>
      {user._id === 'new' &&
        <div>
          <Input
            name="password"
            palette='secondary'
            label="Password"
            type="password"
            autoComplete="new-password"
            value={user?.password || ''}
            onChange={(value) => handleChange('password', value)}
          />
          {!checkPasswordStrength(user?.password) &&
            <Typography size='subText' style={{color: '#c6c6c6', marginLeft: 5, marginRigth: 5}}>
              Password must contain an uppercase and lowercase letter, one or more numbers, and a special character.
            </Typography>
          }
        </div>
      }
    </div>
  );
}

UserForm.propTypes = {
  user: PropTypes.shape({}),
  handleChange: PropTypes.func,
  handleVerifyEmail: PropTypes.func,
  processing: PropTypes.bool,
  verification: PropTypes.shape({}),
}

export default UserForm;
