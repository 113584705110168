import React, {useState} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../../components/search/store';
import {Button, ProgressBar, Typography} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';
import Hidden from '../../../../../components/hidden';

function SearchAreasSubPanel({
    areas,
    setAreas,
    addAreas,
    search,
    setSearch,
    queryLimit,
    processing,
    setProcessing,
    setFocusedArea,
    userReferences,
    setUserReferences,
}) {
  const [expandSearch, setExpandSearch] = useState(false);

  return (
    <div>
      <SearchStore databaseArea='areas' processing={processing} setProcessing={setProcessing} expandSearch={expandSearch} setExpandSearch={setExpandSearch}/>
      {areas?.length > 0 &&
        <div>
          {areas.map((a, aIndex) => (
            <GridContainer
              key={aIndex}
              style={{margin: 5, cursor: 'pointer'}}
              onClick={() => setFocusedArea(Object.assign({}, a))}
            >
              <Hidden breakpoint='hiddenlessthan1024'>
                <GridCell center={true} style={{height: 200}}>
                  <img
                    src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${a.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                    alt={a?.thumbnail?.alt}
                    style={{width: 200}}
                  />
                </GridCell>
              </Hidden>
              <GridCell weight={1} center style={{textAlign: 'left', padding: 10, height: 180}}>
                <Typography size='title'>
                  {a.nameProper || a.name}
                </Typography>
                <Typography style={{color: '#333333'}}>
                  {a.tagline}
                </Typography>
                <Typography style={{marginTop: 5}}>
                  {a.blurb?.length > 350 ? `${a.blurb.substring(0, 350)}...` : a.blurb}
                </Typography>
              </GridCell>
            </GridContainer>
          ))}
          {processing && areas?.length > 9 &&
            <div style={{padding: 5}}>
              <Typography>
                Loading...
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {!processing && search?.areas?.hasMore &&
            <div style={{padding: 5}}>
              <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                Load More
              </Button>
            </div>
          }
        </div>
      }
    </div>
  );
};

SearchAreasSubPanel.propTypes = {
  areas: PropTypes.arrayOf(PropTypes.shape({})),
  addAreas: PropTypes.func,
  search: PropTypes.shape({}),
  setSearch: PropTypes.func,
  queryLimit: PropTypes.number,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  setFocusedArea: PropTypes.func,
};

export default SearchAreasSubPanel;
