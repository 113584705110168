import React, {useState} from 'react';
import PropTypes from 'prop-types';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import {Input, Button} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function PositionForm({
  position,
  myPosition,
  address,
  handleChange,
  variant,
  buttonText,
}) {
  const [processing, setProcessing] = useState(false);
  const {ready} = usePlacesAutocomplete({
    requestOptions : {
      location: {lat: () => myPosition?.lat || -35.7274938, lng: () => myPosition?.lng || 174.3165604},
      radius: 300 * 1000,
    }
  });

  async function getPosition() {
    setProcessing(true);
    const results = await getGeocode({address});
    const {lat, lng} = await getLatLng(results[0]);
    handleChange('position', {lat, lng});
    setProcessing(false);
  }

  if(variant === 'hiddenLatLng') {
    return (
      <Button palette='primary' onClick={() => getPosition()} disabled={!ready || processing}>
        {buttonText || 'Generate Latitude and Longitude'}
      </Button>
    );
  }

  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            name="lat"
            palette='secondary'
            label="Latitude"
            type="number"
            value={position?.lat || ''}
            onChange={(value) => handleChange('lat', value)}
          />
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="lng"
            palette='secondary'
            label="Longitude"
            type="number"
            value={position?.lng  || ''}
            onChange={(value) => handleChange('lng', value)}
          />
        </GridCell>
        {(address && address !== '') &&
          <GridCell style={{marginTop: 7}}>
            <Button palette='primary' onClick={() => getPosition()} disabled={!ready || processing}>
              {buttonText || 'Generate Latitude and Longitude'}
            </Button>
          </GridCell>
        }
      </GridContainer>
    </div>
  );
}

PositionForm.propTypes = {
  position: PropTypes.shape({}),
  handleChange: PropTypes.func,
  handleGenerate: PropTypes.func,
}

export default PositionForm;
