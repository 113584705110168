import React from 'react';
import PropTypes from 'prop-types';

import {paletteData, textData} from '../../../styles';

function Typography({children, style, size, onClick, palette}) {
  return (
    <div
      onClick={onClick}
      style={{
        color: paletteData[palette || 'blank'].standard.foreground,
        lineHeight: (size === 'heading' ? textData.heading.lineHeight :
                     size === 'subHeading' ? textData.subHeading.lineHeight :
                     size === 'title' ? textData.title.lineHeight :
                     size === 'text' ? textData.text.lineHeight :
                     size === 'subText' ? textData.subText.lineHeight :
                     1.3),
        letterSpacing:  (size === 'heading' ? textData.heading.letterSpacing :
                         size === 'subHeading' ? textData.subHeading.letterSpacing :
                         size === 'title' ? textData.title.letterSpacing :
                         size === 'text' ? textData.text.letterSpacing :
                         size === 'subText' ? textData.subText.letterSpacing :
                         undefined),
        fontFamily: (size === 'heading' ? textData.heading.fontFamily :
                     size === 'subHeading' ? textData.subHeading.fontFamily :
                     size === 'title' ? textData.title.fontFamily :
                     size === 'text' ? textData.text.fontFamily :
                     size === 'subText' ? textData.subText.fontFamily :
                     textData.text.fontFamily),
        fontSize: (size === 'heading' ? textData.heading.fontSize :
                   size === 'subHeading' ? textData.subHeading.fontSize :
                   size === 'title' ? textData.title.fontSize :
                   size === 'text' ? textData.text.fontSize :
                   size === 'subText' ? textData.subText.fontSize :
                   '1em'),
        ...style,
      }}
    >
      {children}
    </div>
  );
}

Typography.propTypes = {
  palette: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape({}),
  size: PropTypes.string,
  onClick: PropTypes.func,
};

export default Typography;
