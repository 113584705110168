import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {paletteData, Input, Button, Fab, Typography, Dialog, ProgressBar, Select, CheckBox} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

import apiRequest from '../../../tools/apiRequest';
import handleErrorMessage from '../../../tools/handleErrorMessage';

import {
  setSearch,

  setUsers,
  addUsers,
  addUser,
  replaceUser,
  removeUser,

  setAreas,
  addAreas,
  addArea,
  replaceArea,
  removeArea,

  setOrganisations,
  addOrganisations,
  addOrganisation,
  replaceOrganisation,
  removeOrganisation,

  setCategories,
  addCategories,
  addCategory,
  replaceCategory,
  removeCategory,

  setEvents,
  addEvents,
  addEvent,
  replaceEvent,
  removeEvent,

  setProducts,
  addProducts,
  addProduct,
  replaceProduct,
  removeProduct,

  setReviews,
  addReviews,
  addReview,
  replaceReview,
  removeReview,

  setOrders,
  addOrders,
  addOrder,
  replaceOrder,
  removeOrder,

  setArticles,
  addArticles,
  addArticle,
  replaceArticle,
  removeArticle,

  setCommunities,
  addCommunities,
  addCommunity,
  replaceCommunity,
  removeCommunity,

  setPotentialCommunities,
  addPotentialCommunities,
  addPotentialCommunity,
  replacePotentialCommunity,
  removePotentialCommunity,
} from '../../../store/reducers/lists/actions.js';
import {
  setUserReferences,
  setUserReference,
  clearUserReference,

  setAreaReference,
  setAreaReferences,
  clearAreaReference,

  setOrganisationReference,
  setOrganisationReferences,
  clearOrganisationReference,

  setCategoryReference,
  setCategoryReferences,
  clearCategoryReference,

  setEventReference,
  setEventReferences,
  clearEventReference,

  setProductReference,
  setProductReferences,
  clearProductReference,

  setReviewReference,
  setReviewReferences,
  clearReviewReference,

  setOrderReference,
  setOrderReferences,
  clearOrderReference,

  setArticleReference,
  setArticleReferences,
  clearArticleReference,

  setCommunityReference,
  setCommunityReferences,
  clearCommunityReference,

  setPotentialCommunityReference,
  setPotentialCommunityReferences,
  clearPotentialCommunityReference,
} from '../../../store/reducers/references/actions.js';

function SearchStore({
  me,
  databaseArea,
  processing,
  setProcessing,
  expandSearch,
  setExpandSearch,
  overRideSearch,
  setOverRideSearch,
  refreshSearch,
  setRefreshSearch,
  queryLimit,
  setInitalLoadComplete,

  search,
  setSearch,
  lists,
  references,
  filter,

  setUsers,
  addUsers,
  addUser,
  replaceUser,
  removeUser,
  setUserReferences,
  setUserReference,
  clearUserReference,

  setAreas,
  addAreas,
  addArea,
  replaceArea,
  removeArea,
  setAreaReference,
  setAreaReferences,
  clearAreaReference,

  setOrganisations,
  addOrganisations,
  addOrganisation,
  replaceOrganisation,
  removeOrganisation,
  setOrganisationReference,
  setOrganisationReferences,
  clearOrganisationReference,

  setCategories,
  addCategories,
  addCategory,
  replaceCategory,
  removeCategory,
  setCategoryReference,
  setCategoryReferences,
  clearCategoryReference,

  setEvents,
  addEvents,
  addEvent,
  replaceEvent,
  removeEvent,
  setEventReference,
  setEventReferences,
  clearEventReference,

  setProducts,
  addProducts,
  addProduct,
  replaceProduct,
  removeProduct,
  setProductReference,
  setProductReferences,
  clearProductReference,

  setReviews,
  addReviews,
  addReview,
  replaceReview,
  removeReview,
  setReviewReference,
  setReviewReferences,
  clearReviewReference,

  setOrders,
  addOrders,
  addOrder,
  replaceOrder,
  removeOrder,
  setOrderReference,
  setOrderReferences,
  clearOrderReference,

  setArticles,
  addArticles,
  addArticle,
  replaceArticle,
  removeArticle,
  setArticleReference,
  setArticleReferences,
  clearArticleReference,

  setCommunities,
  addCommunities,
  addCommunity,
  replaceCommunity,
  removeCommunity,
  setCommunityReference,
  setCommunityReferences,
  clearCommunityReference,

  setPotentialCommunities,
  addPotentialCommunities,
  addPotentialCommunity,
  replacePotentialCommunity,
  removePotentialCommunity,
  setPotentialCommunityReference,
  setPotentialCommunityReferences,
  clearPotentialCommunityReference,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [searchValue, setSearchValue] = useState(search[databaseArea]?.text || '');

  const buildQuery = useCallback(
    (s, f, da) => {
      if(da === 'users') {
        return {
          ...(f || {}),
          ...(search[da]?.query || {}),
          $or: [
            {name: {$regex: s, $options: 'i'}},
            {email: {$regex: s, $options: 'i'}},
          ],

        };
      } else if (da === 'orders') {
        return  {
          ...(f || {}),
          ...(search[da]?.query || {}),
        };
      } else if(da === 'organisations') {
        return {
          ...(f || {}),
          ...(search[da]?.query || {}),
          $or: [
            {name: {$regex: s, $options: 'i'}},
            {categories: {$regex: s, $options: 'i'}},
            {website: {$regex: s, $options: 'i'}},
          ],
        };
      } else {
        return {
          ...(f || {}),
          ...(search[da]?.query || {}),
          $or: [
            {name: {$regex: s, $options: 'i'}},
          ],
        };
      }
    },
    [search],
  )

  const buildSort = useCallback(
    (da) => {
      if(da === 'orders') {
        return {createdAt: -1};
      } else {
        return {name: 1};
      }
    },
    [],
  );

  const addToStore = useCallback(
    (list, da) => {
      if (da === 'users') {
        addUsers(list);
        setUserReferences(list);
      } if (da === 'areas') {
        addAreas(list);
        setAreaReferences(list);
      } else if(da === 'organisations') {
        addOrganisations(list);
        setOrganisationReferences(list);
      } else if(da === 'categories') {
        addCategories(list);
        setCategoryReferences(list);
      } else if(da === 'events') {
        addEvents(list);
        setEventReferences(list);
      } else if(da === 'products') {
        addProducts(list);
        setProductReferences(list);
      } else if(da === 'reviews') {
        addReviews(list);
        setReviewReferences(list);
      } else if(da === 'orders') {
        addOrders(list);
        setOrderReferences(list);
      } else if(da === 'articles') {
        addArticles(list);
        setArticleReferences(list);
      } else if (da === 'communities') {
        addCommunities(list);
        setCommunityReferences(list)
      } else if (da === 'potentialCommunities') {
        addPotentialCommunities(list);
        setPotentialCommunityReferences(list);
      }
    },
    [
      addAreas, setAreaReferences,
      addOrganisations, setOrganisationReferences,
      addCategories, setCategoryReferences,
      addEvents, setEventReferences,
      addProducts, setProductReferences,
      addArticles, setArticleReferences,
      addOrders, setOrderReferences,
      addReviews, setReviewReferences,
      addUsers, setUserReferences,
      addCommunities, setCommunityReferences,
      addPotentialCommunities, setPotentialCommunityReferences,
    ],
  )

  const replaceStore = useCallback(
    (list, da) => {
      if(da === 'users') {
        setUsers(list);
        setUserReferences(list);
      } else if(da === 'areas') {
        setAreas(list);
        setAreaReferences(list);
      } else if(da === 'organisations') {
        setOrganisations(list);
        setOrganisationReferences(list);
      } else if(da === 'categories') {
        setCategories(list);
        setCategoryReferences(list);
      } else if(da === 'events') {
        setEvents(list);
        setEventReferences(list);
      } else if(da === 'products') {
        setProducts(list);
        setProductReferences(list);
      } else if(da === 'products') {
        setProducts(list);
        setProductReferences(list);
      } else if(da === 'reviews') {
        setReviews(list);
        setReviewReferences(list);
      } else if(da === 'orders') {
        setOrders(list);
        setOrderReferences(list);
      } else if(da === 'articles') {
        setArticles(list);
        setArticleReferences(list);
      } else if (da === 'communities') {
        setCommunities(list);
        setCommunityReferences(list)
      } else if (da === 'potentialCommunities') {
        setPotentialCommunities(list);
        setPotentialCommunityReferences(list);
      }
    },
    [
      setAreas, setAreaReferences,
      setOrganisations, setOrganisationReferences,
      setCategories, setCategoryReferences,
      setEvents, setEventReferences,
      setProducts, setProductReferences,
      setArticles, setArticleReferences,
      setOrders, setOrderReferences,
      setReviews, setReviewReferences,
      setUsers, setUserReferences,
      setCommunities, setCommunityReferences,
      setPotentialCommunities, setPotentialCommunityReferences,
    ],
  )

  const handleSearch = useCallback(
    (s, f, da) => {
      setProcessing(true);
      const query = buildQuery(s, f, da);
      const sort = buildSort(da);
      const skip = 0;
      let action = da;
      if(action === 'potentialCommunities') {
        action = 'communities/potential';
      }
      apiRequest({type: 'get', action, data: {query, sort, skip, limit: queryLimit}})
      .then((result) => {
        replaceStore(result.data[da], da);
        setSearch({name: da, value: {text: s, query, data: {usesQueryLimit: true},  queryDepth: 1, hasMore: result.data.hasMore}});
        setProcessing(false);
      }).catch((error) => {
        replaceStore([], da);
        setSearch({name: da, value: {text: s, query, data: {usesQueryLimit: true},  queryDepth: 1, hasMore: false}});
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: `Fetch ${da} request denied`,
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    },
    [buildQuery, buildSort, replaceStore, setSearch, queryLimit, setProcessing],
  );

  const handleExpandSearch = useCallback(
    (f, da) => {
      setProcessing(true);
      const s = search[da].text;

      const query = buildQuery(s, f, da);
      const sort = buildSort(da);
      const skip = (search[da]?.queryDepth || 0) * queryLimit;

      let action = da;
      if(action === 'potentialCommunities') {
        action = 'communities/potential';
      }
      apiRequest({type: 'get', action, data: {query, sort, skip, limit: queryLimit}})
      .then((result) => {
        addToStore(result.data[da], da);
        setSearch({name: da, value: {text: s, query, data: {usesQueryLimit: true},  queryDepth: search[da].queryDepth + 1, hasMore: result.data.hasMore}});
        setProcessing(false);
      }).catch((error) => {
        setSearch({name: da, value: {text: s, query, data: {usesQueryLimit: true},  queryDepth: search[da].queryDepth + 1, hasMore: false}});
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: `Fetch ${da} request denied`,
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    },
    [buildQuery, buildSort, addToStore, search, setSearch, queryLimit, setProcessing],
  )

  const [searchLoaded, setSearchLoaded] = useState(false);
  useEffect(() => {
    // inital load of search
    if(!searchLoaded) {
      setSearchLoaded(true);
      let searchValid = true;
      let searchMatch = true;
      if(databaseArea === 'events') {
        if(!filter?.host) searchValid = false;
        if(search?.events?.query?.host !== filter?.host) searchMatch = false;
      } else if(databaseArea === 'products') {
        if(!filter?.organisationId) searchValid = false;
        if(search?.products?.query?.organisationId !== filter?.organisationId) {
          searchMatch = false;
        }
      } else if(databaseArea === 'reviews') {
        if(!filter?.organisationId) searchValid = false;
        if(search?.reviews?.query?.organisationId !== filter?.organisationId) searchMatch = false;
      } else if(databaseArea === 'orders') {
        if(!filter?.provider) searchValid = false;
        if(search?.orders?.query?.provider !== filter?.provider) searchMatch = false;
      }

      if(searchValid && (!lists[databaseArea] || !searchMatch)) {
        handleSearch('', filter, databaseArea);
        if(setInitalLoadComplete !== undefined) {
          setInitalLoadComplete(true);
        }
      } else {
        if(setInitalLoadComplete !== undefined) {
          setInitalLoadComplete(true);
        }
      }
    }
    // expand search
    if(expandSearch) {
      setExpandSearch(false);
      handleExpandSearch(filter, databaseArea);
    }

    if(overRideSearch) {
      setOverRideSearch(false);
      setSearchValue('');
    }

    if(refreshSearch) {
      setRefreshSearch(false);
      handleSearch(search[databaseArea].text, filter, databaseArea);
    }
  }, [
    lists,
    databaseArea,
    overRideSearch,
    setOverRideSearch,
    handleExpandSearch,
    handleSearch,
    setInitalLoadComplete,
    refreshSearch,
    setRefreshSearch,

    filter,
    search,
    queryLimit,
    setSearch,
    searchLoaded,
    expandSearch,
    setExpandSearch,
    setProcessing,
  ]);

  return (
    <div>
      {/*-------------------- search bar ----------------------*/}
      <GridContainer>
        {databaseArea === 'products' &&
          <GridCell>
            <Select
              style={{minWidth: 100}}
              forceShrink={true}
              label='state'
              value={search.products?.query?.state || 'all'}
              onChange={(value) => {
                if(value === 'all') {
                  const searchProcessed = search.products;
                  delete searchProcessed.query.state;
                  setSearch({name: 'products', value: searchProcessed});
                } else {
                  const searchProcessed = search.products;
                  searchProcessed.query.state = value;
                  setSearch({name: 'products', value: searchProcessed});
                }
              }}
            >
              <option value='all'>All</option>
              <option value='active'>Visible</option>
              <option value='inactive'>Hidden</option>
            </Select>
          </GridCell>
        }
        <GridCell weight={1}>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSearch(searchValue, filter, databaseArea);
          }}>
            <Input
              name="searchValue"
              palette='secondary'
              label="Search"
              type="text"
              autoComplete="off"
              value={searchValue || ''}
              onChange={(value) => setSearchValue(value)}
            />
          </form>
        </GridCell>
        {search[databaseArea]?.text !== '' &&
          <GridCell style={{ marginTop: 11, marginRight: 5}}>
            <Fab
              size='small'
              disabled={processing}
              palette='primary'
              onClick={() => {
                setSearchValue('');
                handleSearch('', filter, databaseArea);
              }}
            >
              <span className="material-icons md-24" style={{fontSize: 20}}>close</span>
            </Fab>
          </GridCell>
        }
        <GridCell style={{marginTop: 11}}>
          <Fab
            size='small'
            disabled={processing}
            palette='primary'
            onClick={() => handleSearch(searchValue, filter, databaseArea)}
          >
            <span className="material-icons md-24" style={{fontSize: 20}}>search</span>
          </Fab>
        </GridCell>
      </GridContainer>
      {processing &&
        <div style={{marginLeft: 5, marginRight: 5, marginBottom: 5}}>
          <ProgressBar palette='secondary'/>
        </div>
      }
      {databaseArea === 'organisations' &&
        <GridContainer style={{marginLeft: 5, marginRight: 5}}>
          <GridCell center={true}> 
            <Typography style={{marginRight: 10}}>
              Filters: 
            </Typography>
          </GridCell>
          <GridCell>
            <Fab size="small" disabled={processing} palette='primary' onClick={() => {
              if(!lists.areas?.length) {
                handleSearch('', {}, 'areas');
              }
              setDialogData({
                type: 'changeArea',
                title: 'Change Area',
                value: {
                  search: search.areas?.text || '',
                }
              });
              setDialogOpen(true);
            }}>
              <span className="material-icons md-24" style={{fontSize: 20}}>location_on</span>
            </Fab>
          </GridCell>
          <GridCell style={{marginLeft: 5}}>
            <Fab size="small" disabled={processing} palette='primary' onClick={() => {
              setDialogData({
                type: 'addCategory',
                title: 'Add Group',
                value: {
                  search: '',
                }
              });
              setDialogOpen(true);
            }}>
              <span className="material-icons md-24" style={{fontSize: 20}}>group</span>
            </Fab>
          </GridCell>
          <GridCell style={{marginLeft: 10}}>
            <CheckBox 
              label="Created By Me"
              value={search?.organisations?.query?.createdBy === me?._id}
              onChange={() => {
                if(me && search?.organisations?.query?.createdBy !== me?._id) {
                  const queryProcessed = {
                    ...(search?.organisations?.query || {}),
                    createdBy: me._id,
                  }
                  setSearch({name: 'organisations', value: {text: '', queryDepth: 1, query: queryProcessed}});
                  handleSearch('', filter, 'organisations');
                } else {
                  const queryProcessed = search?.organisations?.query || {};
                  delete queryProcessed.createdBy;
                  setSearch({name: 'organisations', value: {text: '', queryDepth: 1, query: queryProcessed}});
                  handleSearch('', filter, 'organisations');
                }
              }}
            />
          </GridCell>
          {search.organisations?.query && search?.organisations?.query['offices.area'] &&
            <GridCell
              center={true}
              style={{margin: 5, padding: 5, background: '#c6c6c6', borderRadius: 5, cursor: 'pointer'}}
              onClick={() => {
                setProcessing(true);
                const queryProcessed = search.organisations.query;
                delete queryProcessed['offices.area'];
                setSearch({name: 'organisations', value: {text: '',  query: queryProcessed, data: {usesQueryLimit: true}, queryDepth: 1}});
                handleSearch(searchValue, filter, databaseArea);
              }}
            >
              <Typography size='subText'>
                {references?.areas && references.areas[search?.organisations?.query['offices.area']] ? references.areas[search?.organisations?.query['offices.area']].name : 'unnamed area'} X
              </Typography>
            </GridCell>
          }
          {search?.organisations?.query?.categories?.$in?.length > 0 && search.organisations.query.categories.$in.map((c, cIndex) => (
            <GridCell key={cIndex} style={{margin: 5, padding: 5, background: '#c6c6c6', borderRadius: 5, cursor: 'pointer'}} onClick={() => {
              setProcessing(true);
              const queryProcessed = search.organisations.query;
              queryProcessed.categories.$in.splice(cIndex, 1);
              if(queryProcessed.categories.$in.length === 0) {
                delete queryProcessed.categories;
              }
              setSearch({name: 'organisations', value: {text: search[databaseArea].text,  query: queryProcessed, data: {usesQueryLimit: true}, queryDepth: 1}});
              handleSearch(search[databaseArea].text, filter, databaseArea);
            }}>
              <Typography size='subText'>
                {c} X
              </Typography>
            </GridCell>
          ))}
        </GridContainer>
      }
      {(databaseArea === 'products' || databaseArea === 'events') &&
        <GridContainer style={{marginLeft: 5, marginRight: 5}}>
          <GridCell>
            <Fab
              disabled={processing}
              size='small'
              palette='primary'
              onClick={() => {
                if(!lists.organisations?.length) {
                  handleSearch('', {}, 'organisations');
                }
                setDialogData({
                  type: 'changeOrg',
                  title: 'Change Organisation',
                  value: {
                    search: search.organisations?.text || '',
                  }
                });
                setDialogOpen(true);
              }}
            >
              <span className="material-icons md-24" style={{fontSize: 20}}>storefront</span>
            </Fab>
          </GridCell>
          {search[databaseArea]?.query?.organisationId &&
            <div
              style={{margin: 5, padding: 5, background: '#c6c6c6', borderRadius: 5, cursor: 'pointer'}}
              onClick={() => {
                if(databaseArea === 'products') {
                  setSearch({name: 'products', value: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true}});
                  setProducts([]);
                } else if(databaseArea === 'events') {
                  setSearch({name: 'events', value: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true}});
                  setEvents([]);
                }
              }}
            >
              <Typography size='subText'>
                {references.organisations[search[databaseArea]?.query?.organisationId]?.name || 'unnamed organisation'} X
              </Typography>
            </div>
          }
        </GridContainer>
      }
      {/*----------------------- popouts and popups -----------------------*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => {
          setDialogOpen(false);
          setDialogData(undefined);
        }}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData?.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'addCategory' &&
            <div style={{padding: 10}}>
              <Input
                name="searchCategory"
                palette='secondary'
                label="Category"
                type="text"
                autoComplete="off"
                value={dialogData.value.search || ''}
                onChange={(value) => setDialogData({
                  ...dialogData,
                  value: {
                    ...(dialogData.value),
                    search: value,
                  }
                })}
              />
              {processing &&
                <div style={{marginLeft: 5, marginRight: 5}}>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {lists?.categories?.length > 0 && lists?.categories.filter((c, cIndex) => {
                return c.name.toLowerCase().includes(dialogData.value.search.toLowerCase());
              }).map((c, cIndex) => (
                <div key={cIndex} style={{padding: 10, margin: 5, borderStyle: 'solid', borderRadius: 5, borderWidth: 1, cursor: 'pointer'}} onClick={() => {
                  setProcessing(true);
                  const queryProcessed = search.organisations.query;
                  if(queryProcessed?.categories?.$in?.length > 0) {
                    const duplicateTest = queryProcessed.categories.$in.findIndex((t, tIndex) => {
                      return c.name === t;
                    })
                    if(duplicateTest === -1) {
                      queryProcessed.categories.$in.push(c.name);
                    }
                  } else {
                    queryProcessed.categories = {$in: [c.name]}
                  }
                  setSearch({name: 'organisations', value: {text: search[databaseArea].text,  query: queryProcessed, data: {usesQueryLimit: true}, queryDepth: 1}});
                  handleSearch(search[databaseArea].text, filter, databaseArea);
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}>
                  <Typography>{c.name}</Typography>
                </div>
              ))}
            </div>
          }
          {dialogData?.type === 'addTag' &&
            <div style={{padding: 10}}>
              <Input
                name="searchTag"
                palette='secondary'
                label="Tag"
                type="text"
                autoComplete="off"
                value={dialogData.value.search || ''}
                onChange={(value) => setDialogData({
                  ...dialogData,
                  value: {
                    ...(dialogData.value),
                    search: value,
                  }
                })}
              />
              {processing &&
                <div style={{marginLeft: 5, marginRight: 5}}>
                  <ProgressBar palette='secondary'/>
                </div>
              }
            </div>
          }
          {dialogData?.type === 'changeArea' &&
            <div style={{padding: 10}}>
              <form onSubmit={(e) => {
                e.preventDefault();
                handleSearch(dialogData.value.search, {}, 'areas');
              }}>
                <GridContainer>
                  <GridCell weight={1}>
                    <Input
                      name="searchArea"
                      palette='secondary'
                      label="Area"
                      type="text"
                      autoComplete="off"
                      value={dialogData.value.search || ''}
                      onChange={(value) => setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData.value),
                          search: value,
                        }
                      })}
                    />
                  </GridCell>
                  {dialogData.value.search &&
                    <GridCell style={{marginTop: 11, marginRight: 5}}>
                      <Fab
                        disabled={processing}
                        palette='primary'
                        size='small'
                        onClick={() => {
                          setDialogData({
                           ...dialogData,
                           value: {
                             ...(dialogData.value),
                             search: '',
                           }
                         })
                          handleSearch('', {}, 'areas');
                        }}
                      >
                        <span className="material-icons md-24" style={{fontSize: 20}}>close</span>
                      </Fab>
                    </GridCell>
                  }
                  <GridCell style={{marginTop: 11}}>
                    <Fab disabled={processing} palette='primary' onClick={() => {
                      handleSearch(dialogData.value.search, {}, 'areas');
                    }}>
                      <span className="material-icons md-24" style={{fontSize: 20}}>search</span>
                    </Fab>
                  </GridCell>
                </GridContainer>
              </form>
              {processing &&
                <div style={{padding: 5}}>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {lists?.areas?.length > 0 && lists.areas.map((a, aIndex) => (
                <div
                  key={aIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderRadius: 5, borderWidth: 1, cursor: 'pointer'}}
                  onClick={() => {
                    if(!processing) {
                      setProcessing(true);
                      const queryProcessed = search.organisations.query;
                      queryProcessed['offices.area'] = a._id;
                      setSearch({name: 'organisations', value: {text: '',  query: queryProcessed, data: {usesQueryLimit: true}, queryDepth: 1}});
                      handleSearch('', filter, databaseArea);
                      setDialogOpen(false);
                      setDialogData(undefined);
                    }
                  }}
                >
                  {a.name}
                </div>
              ))}
              {processing && lists.areas?.length > 9 &&
                <div style={{padding: 5}}>
                  <Typography>
                    Loading...
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {!processing && search?.areas?.hasMore &&
                <div style={{padding: 5}}>
                  <Button
                    palette='primary'
                    onClick={() => handleExpandSearch({}, 'areas')}
                  >
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
          {dialogData?.type === 'changeOrg' &&
            <div style={{padding: 10}}>
              <form onSubmit={(e) => {
                e.preventDefault();
                handleSearch(dialogData.value.search, {}, 'organisations');
              }}>
                <GridContainer>
                  <GridCell weight={1}>
                    <Input
                      name="searchOrg"
                      palette='secondary'
                      label="Organisation"
                      type="text"
                      autoComplete="off"
                      value={dialogData.value.search || ''}
                      onChange={(value) => setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData.value),
                          search: value,
                        }
                      })}
                    />
                  </GridCell>
                  {dialogData.value.search &&
                    <GridCell style={{marginTop: 11, marginRight: 5}}>
                      <Fab
                        disabled={processing}
                        palette='primary'
                        size='small'
                        onClick={() => {
                          setDialogData({
                           ...dialogData,
                           value: {
                             ...(dialogData.value),
                             search: '',
                           }
                         })
                          handleSearch('', {}, 'organisations');
                        }}
                      >
                        <span className="material-icons md-24" style={{fontSize: 20}}>close</span>
                      </Fab>
                    </GridCell>
                  }
                  <GridCell style={{marginTop: 11}}>
                    <Fab
                      disabled={processing}
                      palette='primary'
                      size='small'
                      onClick={() => {
                        handleSearch(dialogData.value.search, {}, 'organisations');
                      }}
                    >
                      <span className="material-icons md-24" style={{fontSize: 20}}>search</span>
                    </Fab>
                  </GridCell>
                </GridContainer>
              </form>
              {processing &&
                <div style={{marginLeft: 5, marginRight: 5}}>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {lists.organisations?.length > 0 && lists.organisations.map((o, oIndex) => (
                <div
                  key={oIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    const queryProcessed = {organisationId: o._id}

                    if(databaseArea === 'products') {
                      setSearch({name: 'products', value: {text: '', queryDepth: 1, query: queryProcessed}});
                      handleSearch('', filter, 'products');
                    } else if (databaseArea === 'events') {
                      setSearch({name: 'events', value: {text: '', queryDepth: 1, query: queryProcessed}});
                      handleSearch('', filter, 'events');
                    }

                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                {o.name}
                </div>
              ))}
              {processing && lists.organisations?.length > 9 &&
                <div style={{padding: 5}}>
                  <Typography>
                    Loading...
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {!processing && search?.organisations?.hasMore &&
                <div style={{padding: 5}}>
                  <Button palette='primary' onClick={() => handleExpandSearch({}, 'organisations')}>
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

SearchStore.propTypes = {
  databaseArea: PropTypes.string,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  expandSearch: PropTypes.bool,
  setExpandSearch: PropTypes.func,
  filter: PropTypes.shape({}),
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
    lists: state.lists,
    references: state.references,
  };
};

export default connect(mapStateToProps, {
  setSearch,

  setUsers,
  addUsers,
  addUser,
  replaceUser,
  removeUser,
  setUserReferences,
  setUserReference,
  clearUserReference,

  setAreas,
  addAreas,
  addArea,
  replaceArea,
  removeArea,
  setAreaReference,
  setAreaReferences,
  clearAreaReference,

  setOrganisations,
  addOrganisations,
  addOrganisation,
  replaceOrganisation,
  removeOrganisation,
  setOrganisationReference,
  setOrganisationReferences,
  clearOrganisationReference,

  setCategories,
  addCategories,
  addCategory,
  replaceCategory,
  removeCategory,
  setCategoryReference,
  setCategoryReferences,
  clearCategoryReference,

  setEvents,
  addEvents,
  addEvent,
  replaceEvent,
  removeEvent,
  setEventReference,
  setEventReferences,
  clearEventReference,

  setProducts,
  addProducts,
  addProduct,
  replaceProduct,
  removeProduct,
  setProductReference,
  setProductReferences,
  clearProductReference,

  setReviews,
  addReviews,
  addReview,
  replaceReview,
  removeReview,
  setReviewReference,
  setReviewReferences,
  clearReviewReference,

  setOrders,
  addOrders,
  addOrder,
  replaceOrder,
  removeOrder,
  setOrderReference,
  setOrderReferences,
  clearOrderReference,

  setArticles,
  addArticles,
  addArticle,
  replaceArticle,
  removeArticle,
  setArticleReference,
  setArticleReferences,
  clearArticleReference,

  setCommunities,
  addCommunities,
  addCommunity,
  replaceCommunity,
  removeCommunity,
  setCommunityReference,
  setCommunityReferences,
  clearCommunityReference,

  setPotentialCommunities,
  addPotentialCommunities,
  addPotentialCommunity,
  replacePotentialCommunity,
  removePotentialCommunity,
  setPotentialCommunityReference,
  setPotentialCommunityReferences,
  clearPotentialCommunityReference,
})(SearchStore);
