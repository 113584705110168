import React from 'react';
import PropTypes from 'prop-types';

import {Input, Typography, Fab} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

function NavigationElementEdit({
  block,
  removeBlock,
  blockIndex,
  maxIndex,
  changeSection,
  changeParent,
  changeBlockIndex,

  dialogData,
  dialogOpen,
  setDialogData,
  setDialogOpen
}) {
  return (
    <div style={{padding: 10, margin: 10, borderStyle: 'solid', borderRadius: 5}}>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            label='Menu Item Identifier'
            value={block.options.id}
            onChange={(value) => {
              changeSection('update', blockIndex, 'options.id', value);
            }}
          />
          <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
            This is how the system figures out where to scroll to.  Make sure each of your menu item identifiers are diferent.
          </Typography>
          <Input
            label='Menu Item Text'
            value={block.text}
            onChange={(value) => {
              changeSection('update', blockIndex, 'text', value);
            }}
          />
          <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
            This tells the system what the menu item should be called
          </Typography>
        </GridCell>
        <GridCell>
          <GridContainer
            style={{
              padding: 5,
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 5,
              background: 'white',
            }}
          >
            <GridCell center={true}>
              <Fab
                size='small'
                style={{background: '#c6c6c6', cursor: 'pointer'}}
                onClick={() => {
                  setDialogData({
                    type: 'moveSection',
                    title: 'Move Section',
                    data: {
                      maxIndex,
                      currentIndex: blockIndex + 1,
                      callbackMove: (shift) => changeBlockIndex(shift),
                      callbackRemove: () => removeBlock(blockIndex),
                    },
                    value: {
                      index: blockIndex + 1,
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                <Typography style={{textAlign: 'center'}}>
                  {blockIndex + 1}
                </Typography>
              </Fab>
            </GridCell>
          </GridContainer>
        </GridCell>
      </GridContainer>
    </div>
  );
}

NavigationElementEdit.propTypes = {
  block: PropTypes.shape({}),
  changeSection: PropTypes.func,
  changeParent: PropTypes.func,
};

export default NavigationElementEdit;
