import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

function Slider({min, max, value, onChange}) {
  const [inputValue, setInputValue] = useState(value || 0);

  useEffect(() => {
    if(value !== inputValue) {
      setInputValue(value);
    }
	}, [value, inputValue]);

  return (
    <input
      type='range'
      max={max}
      min={min}
      value={inputValue}
      onChange={(e) => {
        setInputValue(parseFloat(e.target.value));
        if(onChange) {
          onChange(parseFloat(e.target.value));
        }
      }}
    />
  );
}

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
};

export default Slider;
