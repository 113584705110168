import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Input, CheckBox, Typography, Button, Dialog, paletteData} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function ProductDeliveryForm({delivery, availability, handleChange, productType, focusedOrganisation, postSchemeReferences, bookingCalendars, bookingCalendarReferences}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <CheckBox
        name='inStore'
        label={`${productType === 'good' ? 'Pickup' : 'In Store Service'} Available`}
        value={delivery?.inStore}
        onChange={(value) => handleChange('inStore', value)}
      />
      {productType === 'good' &&
        <GridContainer>
          <GridCell style={{marginTop: 16}} weight={1}>
            <CheckBox
              name='national'
              label='NZ Delivery Available'
              value={delivery?.national}
              onChange={(value) => handleChange('national', value)}
            />
          </GridCell>
          {delivery?.national && delivery?.nationalPostSchemeId && postSchemeReferences[delivery?.nationalPostSchemeId] &&
            <GridCell style={{marginTop: 27, marginRight: 10}}>
              <Typography>
                ${(focusedOrganisation?.gst ? postSchemeReferences[delivery?.nationalPostSchemeId].price*1.15 : postSchemeReferences[delivery?.nationalPostSchemeId].price).toFixed(2)} per {postSchemeReferences[delivery?.nationalPostSchemeId].bundle} units
              </Typography>
            </GridCell>
          }
          {delivery?.national &&
            <GridCell>
              <Input
                disabled={true}
                name="nationalPostScheme"
                palette='secondary'
                label="National Post"
                type="text"
                value={delivery?.nationalPostSchemeId ? (postSchemeReferences[delivery?.nationalPostSchemeId]?.name || '...processing') : 'No post price selected'}
              />
            </GridCell>
          }
          {delivery?.national &&
            <GridCell style={{marginTop: 8}}>
              <Button palette='primary' onClick={() => handleChange('nationalPostSchemeId', undefined)}>
                Change Post Price
              </Button>
            </GridCell>
          }
        </GridContainer>
      }


      {productType === 'good' &&
        <GridContainer>
          <GridCell style={{marginTop: 16}} weight={1}>
            <CheckBox
              name='international'
              label='International Delivery Available'
              value={delivery?.international}
              onChange={(value) => handleChange('international', value)}
            />
          </GridCell>
          {delivery?.international && delivery?.internationalPostSchemeId && postSchemeReferences[delivery?.internationalPostSchemeId] &&
            <GridCell style={{marginTop: 27, marginRight: 10}}>
              <Typography>
                ${(focusedOrganisation?.gst ? postSchemeReferences[delivery?.internationalPostSchemeId].price*1.15 : postSchemeReferences[delivery?.internationalPostSchemeId].price).toFixed(2)} per {postSchemeReferences[delivery?.internationalPostSchemeId].bundle} units
              </Typography>
            </GridCell>
          }
          {delivery?.international &&
            <GridCell>
              <Input
                disabled={true}
                name="internationalPostScheme"
                palette='secondary'
                label="International Post"
                type="text"
                value={(delivery?.internationalPostSchemeId) ? (postSchemeReferences[delivery?.internationalPostSchemeId]?.name || '...processing') : 'No post price selected'}
              />
            </GridCell>
          }
          {delivery?.international &&
            <GridCell style={{marginTop: 8}}>
              <Button palette='primary' onClick={() => handleChange('internationalPostSchemeId', undefined)}>
                Change Post Price
              </Button>
            </GridCell>
          }
        </GridContainer>
      }
      {productType === 'service' &&
        <GridContainer>
          <GridCell style={{marginTop: 16}} weight={1}>
            <CheckBox
              name='callout'
              label='Service callout available'
              value={delivery?.callout}
              onChange={(value) => handleChange('callout', value)}
            />
          </GridCell>
          {delivery?.callout &&
            <GridCell style={{marginTop: 21}}>
              <Typography>
                $
              </Typography>
            </GridCell>
          }
          {delivery?.callout &&
            <GridCell>
              <Input
                name="calloutRate"
                palette='secondary'
                label="Callout Rate"
                type="number"
                value={delivery?.calloutRate || ''}
                onChange={(value) => handleChange('calloutRate', value)}
              />
            </GridCell>
          }
          {delivery?.callout && focusedOrganisation?.gst &&
            <GridCell style={{marginTop: 21}}>
              <Typography>
                + GST
              </Typography>
            </GridCell>
          }
          {delivery?.callout && focusedOrganisation?.gst &&
            <GridCell style={{marginTop: 12, marginRight: 5, textAlign: 'right'}}>
              <Button onClick={() => {
                setDialogData({
                  type: 'gstInclusive',
                  title: 'Set GST inclusive price',
                  value: {},
                });
                setDialogOpen(true);
              }}>
                (${(delivery?.calloutRate * 1.15).toFixed(2)} including GST)
              </Button>
            </GridCell>
          }
        </GridContainer>
      }
      {productType === 'service' &&
        <GridContainer>
          <GridCell style={{marginTop: 16}} weight={1}>
            <CheckBox
              name='callout'
              label='Online Booking Available'
              value={delivery?.onlineBooking}
              onChange={(value) => handleChange('onlineBooking', value)}
            />
          </GridCell>
          {delivery?.onlineBooking &&
            <GridCell>
              <Input
                disabled={true}
                name="onlineBookingCalendar"
                palette='secondary'
                label="Booking Calendar"
                type="text"
                value={(delivery?.onlineBookingCalendar) ? (bookingCalendarReferences[delivery?.onlineBookingCalendar]?.name || '...processing') : 'No Calendar Selected'}
              />
            </GridCell>
          }
          {delivery?.onlineBooking &&
            <GridCell style={{marginTop: 8}}>
              <Button palette='primary' onClick={() => handleChange('onlineBookingCalendar', undefined)}>
                Change Booking Calendar
              </Button>
            </GridCell>
          }
        </GridContainer>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <Typography>
              {dialogData.message}
            </Typography>
          }
          {dialogData?.type === 'gstInclusive' &&
            <div style={{padding: 10}}>
              <Input
                name="gstInclusivePrice"
                palette='secondary'
                label="GST inclusive price in NZD"
                type="number"
                value={dialogData?.value?.gstInclusivePrice || ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      gstInclusivePrice: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'gstInclusive' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                   onClick={() => {
                     const priceTemp = +((dialogData?.value?.gstInclusivePrice || 0) * 20 / 23).toFixed(2);
                     handleChange('calloutRate', priceTemp);
                     setDialogOpen(false);
                     setDialogData(undefined);
                  }}
                >
                  Update Price
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ProductDeliveryForm.propTypes = {
  delivery: PropTypes.shape({}),
  availability: PropTypes.shape({}),
  handleChange: PropTypes.func,
  productType: PropTypes.string,
  organisation: PropTypes.shape({}),
}

export default ProductDeliveryForm;
