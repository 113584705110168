import React from 'react';
import PropTypes from 'prop-types';

import {Input, Button} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function ContactUserForm({email, handleChange, handleVerifyEmail, processing, verification}) {
  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            name="home"
            palette='secondary'
            label="Home Email"
            type="text"
            value={email?.home || ''}
            onChange={(value) => handleChange('home', value)}
          />
        </GridCell>
        {verification && !verification[email?.home] &&
          <GridCell style={{marginTop: 7}}>
            <Button palette='primary' disabled={processing} onClick={() => handleVerifyEmail(email?.home)}>
              Verify Email
            </Button>
          </GridCell>
        }
      </GridContainer>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            name="work"
            palette='secondary'
            label="Work Email"
            type="text"
            value={email?.work || ''}
            onChange={(value) => handleChange('work', value)}
          />
        </GridCell>
        {verification && !verification[email?.work] &&
          <GridCell style={{marginTop: 7}} disabled={processing} onClick={() => handleVerifyEmail(email?.work)}>
            <Button palette='primary'>
              Verify Email
            </Button>
          </GridCell>
        }
      </GridContainer>
    </div>
  );
}

ContactUserForm.propTypes = {
  email: PropTypes.shape({}),
  handleChange: PropTypes.func,
  handleVerifyEmail: PropTypes.func,
  processing: PropTypes.bool,
  verification: PropTypes.shape({}),
}

export default ContactUserForm;
