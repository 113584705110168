const generateTitleBlock = ({text, styleContainer, styleText}) => {
  const section = {
    section: 'titleBlock',
    type: 'div',
    options: {
      style: {
        padding: 10,
        ...styleContainer,
      },
    },
    children: [
      {
        type: 'Typography',
        options: {
          size: 'title',
          style: {
            ...styleText,
          },
        },
        text,
      },
    ],
  };
  return section;
};

export default generateTitleBlock;
