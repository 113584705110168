const generateBannerBlock = ({backgroundId, title, tagline}) => {
  const section = {
    section: 'bannerBlock',
    type: 'GridContainer',
    options: {
      style: {
        backgroundImage: `url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,w_2048,q_auto:good/${backgroundId}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        height: 200,
      },
    },
    children: [
      {
        type: 'GridCell',
        options: {
          style: {
            width: '100%',
          },
          weight: 1,
          center: true,
          centerWeights: {top: 1, bottom: 2},
        },
        children: [
          {
            type: 'Typography',
            options: {
              style: {
                color: 'white',
                textShadow: '2px 2px black',
                textAlign: 'center',
              },
              size: 'heading',
            },
            text: title,
          },
          {
            type: 'Typography',
            options: {
              style: {
                color: 'white',
                textShadow: '2px 2px black',
                textAlign: 'center',
              },
              size: 'subHeading',
            },
            text: tagline,
          }
        ],
      }
    ],
  };
  return section;
};

export default generateBannerBlock;
