import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import EditProductPreview from './EditProductPreview';
import EditProductGallery from './EditProductGallery';
import EditProductInfo from './EditProductInfo';
import SearchProducts from './SearchProducts';

import apiRequest from '../../../../tools/apiRequest';
import {GridContainer, GridCell} from '../../../../components/grid';
import {paletteData, Button, ProgressBar, Dialog, Typography} from '../../../../components/styles';
import {
  setProducts,
  addProducts,
  addProduct,
  replaceProduct,
  removeProduct,

  setOrganisations,
  addOrganisations,
  replaceOrganisation,

  setProductGroups,
  addProductGroups,
  addProductGroup,
  replaceProductGroup,

  setPostSchemes,
  addPostSchemes,
  addPostScheme,

  setBookingCalendars,

  setSearch,
} from '../../../../store/reducers/lists/actions.js';
import {
  setProductReference,
  clearProductReference,
  setOrganisationReference,
  setProductGroupReference,
  setPostSchemeReference,
  setBookingCalendarReferences,
  setProductReferences,
  setProductGroupReferences,
} from '../../../../store/reducers/references/actions.js';
import Hidden from '../../../../components/hidden';
import handleErrorMessage from '../../../../tools/handleErrorMessage';

function ManageProductsPanel({
  me,
  auth,

  setSearch,
  search,
  queryLimit,

  products,
  setProductReference,
  clearProductReference,
  setProducts,
  addProducts,
  addProduct,
  replaceProduct,
  removeProduct,
  setProductReferences,

  productGroups,
  productGroupReferences,
  setProductGroups,
  addProductGroups,
  addProductGroup,
  replaceProductGroup,
  setProductGroupReference,
  setProductGroupReferences,

  organisations,
  organisationReferences,
  setOrganisationReference,
  setOrganisations,
  addOrganisations,
  replaceOrganisation,

  postSchemes,
  postSchemeReferences,
  setPostSchemes,
  addPostSchemes,
  addPostScheme,
  setPostSchemeReference,

  bookingCalendars,
  bookingCalendarReferences,
  setBookingCalendars,
  setBookingCalendarReferences,
}) {

  // page state
  const [focusedProduct, setFocusedProduct] = useState(undefined);
  const [focusedProductIndex, setFocusedProductIndex] = useState(undefined);
  const [focusedSubSection, setFocusedSubSection] = useState('preview');
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleChangesFocusedProduct = (changes) => {
    setFocusedProduct({
      ...focusedProduct,
      ...changes,
    });
  }

  const handleChangeFocusedProduct = (name, value) => {
    setFocusedProduct({
      ...focusedProduct,
      [name]: value,
    });
  };

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({type: 'post', action: 'upload/image', data: {file: reader.result}}).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({imageId: result.data.public_id, alt}, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  const handleUpdateProduct = () => {
    setProcessing(true);
    const productId = focusedProduct._id;
    const updateProduct = {
      state: focusedProduct?.state,
      name: focusedProduct?.name,
      tagline: focusedProduct?.tagline,
      blurb: focusedProduct?.blurb,
      thumbnail: focusedProduct?.thumbnail,
      gallery: focusedProduct?.gallery,
      description: focusedProduct?.description,
      type: focusedProduct?.type,
      tags: focusedProduct?.tags,
      organisationId: focusedProduct?.organisationId,
      productGroupId: focusedProduct?.productGroupId,
      payment: focusedProduct?.payment,
      delivery: focusedProduct?.delivery,
      availability: focusedProduct?.availability,
    }
    if(productId === 'new') {
      apiRequest({type: 'post', action: `products/create`, data: {updateProduct}})
      .then((result) => {
        console.log(result);
        addProduct(result.data.product);
        setProductReference(result.data.product);
        setProcessing(false);
        setFocusedProduct(undefined);
        setFocusedProductIndex(undefined);
        setFocusedSubSection('preview');
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Create product request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    } else {
      apiRequest({type: 'patch', action: `products/update/${productId}`, data: {updateProduct}})
      .then((result) => {
        replaceProduct({index: focusedProductIndex, product: {_id: productId, ...updateProduct}});
        setProductReference({_id: productId, ...updateProduct});
        setProcessing(false);
        setFocusedProduct(undefined);
        setFocusedProductIndex(undefined);
        setFocusedSubSection('preview');
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Update product request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    }
  }

  const handleUpdateOrganisationGallery = (newGallery, callback) => {
    setProcessing(true);
    const organisationId = organisationReferences[focusedProduct?.organisationId]._id;
    const updateOrganisation = {
      gallery: newGallery,
    }
    apiRequest({type: 'patch', action: `organisations/update/${organisationId}`, data: {updateOrganisation}})
    .then((result) => {
      replaceOrganisation({organisation: {_id: organisationId, ...organisationReferences[focusedProduct?.organisationId], ...updateOrganisation}});
      setOrganisationReference({_id: organisationId, ...organisationReferences[focusedProduct?.organisationId], ...updateOrganisation});
      setProcessing(false);
      if(callback) {
        callback();
      }
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update organisation request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }


  const handleDeleteProduct = () => {
    setProcessing(true);
    const productId = focusedProduct._id || 'new';
    apiRequest({type: 'delete', action: `products/delete/${productId}`})
    .then((result) => {
      setDialogOpen(false);
      setDialogData(undefined);
      setProcessing(false);
      removeProduct({product: focusedProduct});
      clearProductReference(productId);
      setFocusedProduct(undefined);
      setFocusedProductIndex(undefined);
      setFocusedSubSection('preview');
    }).catch((error) => {
      setDialogOpen(false);
      setDialogData(undefined);
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Delete product request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  };

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <div style={{color: paletteData.primary.standard.foreground, fontSize: '1.5em'}}>
              {focusedProduct ? ` ${focusedProduct.name || 'unnamed product'}` : 'Products'}
            </div>
          </GridCell>
          {/*large buttons*/}
          {!focusedProduct && search?.products?.query?.organisationId &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{height: 45}}>
                <Button
                  disabled={processing}
                  palette="secondary"
                  onClick={() =>{
                    setFocusedProduct({
                      _id: 'new',
                      organisationId: search?.products?.query?.organisationId,
                      productGroupId: search?.products?.query?.productGroupId,
                      gallery: []}
                    );
                  }}
                >
                  Add
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedProduct &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{height: 45}}>
                <Button
                  disabled={processing}
                  palette="secondary"
                  onClick={() =>{
                    if(focusedProduct) {
                      setFocusedProduct(undefined);
                      setFocusedProductIndex(undefined);
                      setFocusedSubSection('preview');
                    }
                  }}
                >
                  Cancel
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedProduct && focusedProduct?._id !== 'new' &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{marginLeft: 5, height: 45}}>
                <Button palette='secondary' onClick={() => {
                  setDialogOpen(true);
                  setDialogData({
                    type: 'deleteProduct',
                    title: 'Delete Product',
                    message: 'Are you sure you wish to delete this product?  This is irreversible',
                  });
                }} disabled={processing}>
                  Delete Product
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedProduct &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{marginLeft: 5, height: 45}}>
                <Button
                  palette='secondary'
                  onClick={() => handleUpdateProduct()}
                  disabled={processing || !focusedProduct?.name || !focusedProduct?.blurb || !focusedProduct?.thumbnail?.imageId || !focusedProduct?.organisationId || !focusedProduct?.type}
                >
                  {focusedProduct?._id === 'new' ? 'Create' : 'Update'} Product
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            </Hidden>
          }

          {/*small buttons*/}
          {!focusedProduct &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{height: 45}}>
                <Button
                  size='small'
                  disabled={processing}
                  palette="secondary"
                  onClick={() =>{
                    setFocusedProduct({
                      _id: 'new',
                      organisationId: search?.products?.query?.organisationId,
                      productGroupId: search?.products?.query?.productGroupId,
                      gallery: []}
                    );
                  }}
                >
                  Add
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedProduct &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{height: 45}}>
                <Button
                  size='small'
                  disabled={processing}
                  palette="secondary"
                  onClick={() =>{
                    if(focusedProduct) {
                      setFocusedProduct(undefined);
                      setFocusedProductIndex(undefined);
                      setFocusedSubSection('preview');
                    }
                  }}
                >
                  Cancel
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedProduct && focusedProduct?._id !== 'new' &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{marginLeft: 5, height: 45}}>
                <Button
                  size='small'
                  palette='secondary'
                  disabled={processing}
                  onClick={() => {
                    setDialogOpen(true);
                    setDialogData({
                      type: 'deleteProduct',
                      title: 'Delete Product',
                      message: 'Are you sure you wish to delete this product?  This is irreversible',
                    });
                  }}
                >
                  Delete
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedProduct &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{marginLeft: 5, height: 45}}>
                <Button
                  size='small'
                  palette='secondary'
                  onClick={() => handleUpdateProduct()}
                  disabled={processing || !focusedProduct?.name || !focusedProduct?.blurb || !focusedProduct?.thumbnail?.imageId || !focusedProduct?.organisationId || !focusedProduct?.type}
                >
                  {focusedProduct?._id === 'new' ? 'Create' : 'Update'}
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            </Hidden>
          }
        </GridContainer>
      </div>

      {/* search form */}
      {!focusedProduct &&
        <SearchProducts
          me={me}
          search={search}
          setSearch={setSearch}
          queryLimit={queryLimit}
          processing={processing}
          setProcessing={setProcessing}

          setFocusedProduct={setFocusedProduct}
          setFocusedProductIndex={setFocusedProductIndex}
          handleChangeFocusedProduct={handleChangeFocusedProduct}
          products={products}
          setProducts={setProducts}
          addProducts={addProducts}
          removeProduct={removeProduct}
          replaceProduct={replaceProduct}
          setProductReference={setProductReference}
          setProductReferences={setProductReferences}

          productGroups={productGroups}
          productGroupReferences={productGroupReferences}
          setProductGroups={setProductGroups}
          addProductGroups={addProductGroups}
          setProductGroupReference={setProductGroupReference}
          setProductGroupReferences={setProductGroupReferences}

          organisations={organisations}
          organisationReferences={organisationReferences}
          setOrganisations={setOrganisations}
          addOrganisations={addOrganisations}
          setOrganisationReference={setOrganisationReference}

          postSchemes={postSchemes}
          postSchemeReferences={postSchemeReferences}
          addPostSchemes={addPostSchemes}
          setPostSchemeReference={setPostSchemeReference}

          bookingCalendars={bookingCalendars}
          setBookingCalendars={setBookingCalendars}
          setBookingCalendarReferences={setBookingCalendarReferences}
        />
      }

      {focusedProduct &&
        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
          {/* product missing info */}
          {(!focusedProduct?.name || !focusedProduct?.blurb || !focusedProduct?.thumbnail?.imageId || !focusedProduct?.organisationId || !focusedProduct?.type) &&
            <div style={{padding: 10, margin: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'red'}}>
              <Typography size='title' style={{color: 'red'}}>
                Required information is missing:
              </Typography>
              {!focusedProduct?.name &&
                <Typography style={{color: 'red'}}>
                  Preview - Name
                </Typography>
              }
              {!focusedProduct?.blurb &&
                <Typography style={{color: 'red'}}>
                  Preview - Blurb
                </Typography>
              }
              {!focusedProduct?.thumbnail?.imageId &&
                <Typography style={{color: 'red'}}>
                  Preview - Thumbnail
                </Typography>
              }
              {!focusedProduct?.organisationId &&
                <Typography style={{color: 'red'}}>
                  Information - Provider
                </Typography>
              }
              {!focusedProduct?.type &&
                <Typography style={{color: 'red'}}>
                  Information - Product Type
                </Typography>
              }
            </div>
          }

          {/* product info submenu */}
          <GridContainer style={{borderStyle: 'none none solid none', marginBottom: 10, marginTop: 10, flexWrap: 'nowrap'}}>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'preview' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('preview')}>
              Preview
            </GridCell>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'info' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('info')}>
              Information
            </GridCell>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'gallery' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('gallery')}>
              Gallery
            </GridCell>
          </GridContainer>

          {/* Product subpanels */}
          {focusedSubSection === 'preview' &&
            <EditProductPreview
              focusedProduct={focusedProduct}
              focusedOrganisation={organisationReferences[focusedProduct?.organisationId]}
              handleUpdateOrganisationGallery={handleUpdateOrganisationGallery}
              handleChangeFocusedProduct={handleChangeFocusedProduct}
              handleChangesFocusedProduct={handleChangesFocusedProduct}
              processing={processing}
              setProcessing={setProcessing}
              handleUploadImage={handleUploadImage}
            />
          }
          {focusedSubSection === 'gallery' &&
            <EditProductGallery
              focusedProduct={focusedProduct}
              focusedOrganisation={organisationReferences[focusedProduct?.organisationId]}
              handleUpdateOrganisationGallery={handleUpdateOrganisationGallery}
              handleChangeFocusedProduct={handleChangeFocusedProduct}
              processing={processing}
              setProcessing={setProcessing}
              handleUploadImage={handleUploadImage}
            />
          }
          {focusedSubSection === 'info' &&
            <EditProductInfo
              focusedProduct={focusedProduct}
              focusedOrganisation={organisationReferences[focusedProduct?.organisationId]}
              handleChangeFocusedProduct={handleChangeFocusedProduct}
              handleChangesFocusedProduct={handleChangesFocusedProduct}
              processing={processing}
              setProcessing={setProcessing}
              handleUploadImage={handleUploadImage}

              search={search}
              setSearch={setSearch}
              queryLimit={queryLimit}

              organisations={organisations}
              setOrganisations={setOrganisations}
              organisationReferences={organisationReferences}
              setOrganisationReference={setOrganisationReference}

              productGroups={productGroups}
              productGroupReferences={productGroupReferences}
              setProductGroups={setProductGroups}
              addProductGroups={addProductGroups}
              addProductGroup={addProductGroup}
              replaceProductGroup={replaceProductGroup}
              setProductGroupReference={setProductGroupReference}

              postSchemes={postSchemes}
              postSchemeReferences={postSchemeReferences}
              addPostSchemes={addPostSchemes}
              addPostScheme={addPostScheme}
              setPostSchemeReference={setPostSchemeReference}

              bookingCalendars={bookingCalendars}
              bookingCalendarReferences={bookingCalendarReferences}
            />
          }
        </div>
      }

      {/* notification dialog */}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'deleteProduct' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => handleDeleteProduct()}
                >
                  Delete Product
                </Button>
                {processing &&
                  <ProgressBar />
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ManageProductsPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  search: PropTypes.shape({}),
}

const mapStateToProps = (state) => {
  return {
    products: state.lists.products,

    productGroups: state.lists.productGroups,
    productGroupReferences: state.references.productGroups || {},

    organisations: state.lists.organisations,
    organisationReferences: state.references.organisations || {},

    postSchemes: state.lists.postSchemes,
    postSchemeReferences: state.references.postSchemes || {},

    bookingCalendars: state.lists.bookingCalendars,
    bookingCalendarReferences: state.references.bookingCalendars || {},

    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
  };
};

export default connect(mapStateToProps, {
  setProducts,
  addProducts,
  addProduct,
  replaceProduct,
  removeProduct,
  setProductReference,
  clearProductReference,
  setProductReferences,

  setOrganisations,
  addOrganisations,
  setOrganisationReference,
  replaceOrganisation,

  setProductGroups,
  addProductGroups,
  addProductGroup,
  replaceProductGroup,
  setProductGroupReference,
  setProductGroupReferences,

  setPostSchemes,
  addPostSchemes,
  addPostScheme,
  setPostSchemeReference,

  setBookingCalendars,
  setBookingCalendarReferences,

  setSearch,
})(ManageProductsPanel);
