import React from 'react';
import PropTypes from 'prop-types';

function MenuDropdown({children, position, style, open, id, handleClose}) {
  return (
    <div
      id={id || 'dropdownMenu'}
      style={{
        width: open ? '' : 0,
        position: 'absolute',
        zIndex: 1,
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: 1,
        overflowX: 'hidden',
        transition: '0.5s',
        ...position,
        ...style,
      }}
      onMouseLeave={() => handleClose()}
    >
      {children}
    </div>
  );
}

MenuDropdown.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  position: PropTypes.shape({}),
  style: PropTypes.shape({}),
  handleClose: PropTypes.func,
  side: PropTypes.string,
  id: PropTypes.string,
};

export default MenuDropdown;
