import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../../components/search/store';
import OrganisationForm from '../../../../../components/forms/organisation';
import OrganisationSocialForm from '../../../../../components/forms/organisationSocial';

import {paletteData, Typography, Button, ProgressBar, Dialog, Input, TextArea, CheckBox} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function EditOrganisationInfoSubPanel({
    me,
    processing,
    setProcessing,
    search,
    setSearch,

    handleUploadImage,
    focusedOrganisation,
    handleChangeFocusedOrganisation,

    users,
    userReferences,
    setUserReference,

    categories,
  }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(undefined);
    const [imagePreview, setImagePreview] = useState(undefined);

    const imageUploadRef = useRef(null);

    const handleGeneratePreview = (file) => {
      setProcessing(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setProcessing(false);
        setImagePreview(reader.result)
      }
    }

    const handleUpdateLogoImage = (image) => {
      const item = {
        imageId: image?.imageId,
        tags: image?.tags,
        alt: image?.alt,
        description: image?.description,
      };
      handleChangeFocusedOrganisation('logo', item);
      setDialogOpen(false);
      setDialogData(undefined);
      setImagePreview(undefined);
    }

    return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center={true}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Organisation Details
            </Typography>
          </GridCell>
          <GridCell center={true}>
            <CheckBox
              labelStyle={{color: paletteData.primary.standard.foreground}}
              name='hidden'
              label='Hidden'
              value={focusedOrganisation.hidden || false}
              onChange={(value) => handleChangeFocusedOrganisation('hidden', value)}
            />
          </GridCell>
        </GridContainer>

      </div>
      <OrganisationForm
        me={me}
        processing={processing}
        organisation={focusedOrganisation}
        setUserReference={setUserReference}
        userReferences={userReferences}
        handleChange={(name, value) => {
          if(name === 'owner') {
            setDialogOpen(true);
            setDialogData({
              type: 'chooseUser',
              title: 'Choose Listing Manager',
            });
          } else if(name === 'logo') {
            setImagePreview(undefined);
            setDialogData({
              type: 'editLogoImage',
              title: 'Change Logo',
              preview: undefined,
              value: {
                file: undefined,
                imageId: value?.imageId || '',
                tags: value?.tags || '',
                alt: value?.alt || '',
                description: value?.description || '',
              }
            });
            setDialogOpen(true);
          } else if(name === 'thumbnail') {
            setDialogOpen(true);
            setImagePreview(undefined);
            setDialogData({
              type: 'editThumbnailImage',
              title: 'Change Thumbnail',
              preview: undefined,
              value: {
                file: undefined,
                imageId: value?.imageId || '',
                tags: value?.tags || '',
                alt: value?.alt || '',
                description: value?.description || '',
              }
            });
          } else {
            handleChangeFocusedOrganisation(name, value);
          }
        }}
      />

      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography style={{color: paletteData.primary.standard.foreground}}>
          Social Media
        </Typography>
      </div>
      <OrganisationSocialForm
        social={focusedOrganisation?.social}
        handleChange={(name, value) => {
          const socialProcessed = focusedOrganisation.social || {};
          socialProcessed[name] = value;
          handleChangeFocusedOrganisation('social', socialProcessed);
        }}
      />

      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Organisation Categories
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              palette="secondary" style={{padding: 5, fontSize: '.8em'}}
              onClick={() => {
                setDialogOpen(true);
                setImagePreview(undefined);
                setDialogData({
                  type: 'addCategory',
                  title: 'Add a category',
                });
              }}
            >
              Add category
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {(!focusedOrganisation.categories || focusedOrganisation.categories.length === 0) &&
        <div style={{margin: 5}}>
          <Typography>
            No Categories
          </Typography>
        </div>
      }
      {focusedOrganisation.categories?.length > 0 &&
        <div style={{margin: 5}}>
          {focusedOrganisation.categories.map((c, cIndex) => (
            <Typography
              key={cIndex}
              style={{display: 'inline', cursor: 'pointer'}}
              onClick={() => {
                const tempCategories = focusedOrganisation.categories;
                tempCategories.splice(cIndex, 1);
                handleChangeFocusedOrganisation('categories', tempCategories);
              }}
            >
              {c}{cIndex !== (focusedOrganisation.categories.length - 1) ? ', ' : ''}
            </Typography>
          ))}
        </div>
      }
      <br/>

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {(dialogData?.type === 'editLogoImage') &&
            <div style={{padding: 10}}>
              <GridContainer>
                <GridCell style={{width: 200, textAlign: 'center'}}>
                  <input
                    ref={imageUploadRef}
                    type="file"
                    style={{display: 'none'}}
                    onChange={(event) => {
                      handleGeneratePreview((event?.target?.files?.length) > 0 ? event.target.files[0] : undefined);
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          file: (event?.target?.files?.length) > 0 ? event.target.files[0] : undefined,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                  <Button palette='primary' onClick={() => imageUploadRef.current.click()} disabled={processing}>
                    Change Image
                  </Button>
                  {!imagePreview && !dialogData.value.imageId &&
                    <GridContainer style={{height: 200, width: 200, marginTop: 10}}>
                      <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5}}>
                        No Image
                      </GridCell>
                    </GridContainer>
                  }
                  {(imagePreview || dialogData.value.imageId) &&
                    <GridContainer style={{marginTop: 10}}>
                      <GridCell center={true} weight={1}>
                        <img src={imagePreview || `https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${dialogData.value.imageId}`} alt={dialogData?.value?.alt} style={{width: 200}}/>
                      </GridCell>
                    </GridContainer>
                  }
                  {dialogData?.value?.file && dialogData?.value?.file?.size > 1000000 &&
                    <div style={{padding: 10}}>
                      <Typography style={{color: 'red'}}>
                        File too large, must be under 1 MB
                      </Typography>
                    </div>
                  }
                  {(processing) &&
                    <ProgressBar palette='secondary'/>
                  }
                </GridCell>
                <GridCell weight={1}>
                  <Input
                    name="alt"
                    palette='secondary'
                    label="Alt Text"
                    type="text"
                    value={dialogData?.value?.alt || ''}
                    onChange={(value) => {
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          alt: value,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                  <Input
                    name="tags"
                    palette='secondary'
                    label="Tags"
                    type="text"
                    value={dialogData?.value?.tags || ''}
                    onChange={(value) => {
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          tags: value,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                  <TextArea
                    name="description"
                    palette='secondary'
                    label="Description"
                    type="text"
                    value={dialogData?.value?.description || ''}
                    onChange={(value) => {
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          description: value,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                </GridCell>
              </GridContainer>
            </div>
          }
          {dialogData?.type === 'chooseUser' &&
            <div style={{padding: 10}}>
              <SearchStore databaseArea='users' processing={processing} setProcessing={setProcessing} expandSearch={false} setExpandSearch={() => {}}/>
              {!processing && users && users.map((u, uIndex) => (
                <div
                  key={uIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!userReferences[u._id]) {
                      setUserReference(u);
                    }
                    handleChangeFocusedOrganisation('owner', u._id);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {u.name}
                  <div style={{color: '#c6c6c6'}}>
                    {u.email}
                  </div>
                </div>
              ))}
            </div>
          }
          {dialogData?.type === 'addCategory' &&
            <div style={{padding: 10}}>
              <Input
                label="search"
                value={dialogData?.value?.search || ''}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  })
                }}
              />
              {!processing && categories && categories.filter((c, cIndex) => {
                return c.name.toLowerCase().includes((dialogData?.value?.search || '').toLowerCase());
              }).map((c, cIndex) => (
                <div
                  key={cIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    const tempCategories = focusedOrganisation.categories || [];
                    let testCategories = true;
                    for(let i = 0; i < tempCategories.length; i += 1) {
                      if(tempCategories[i] === c.name) {
                        testCategories = false;
                      }
                    }
                    if(testCategories) {
                      tempCategories.push(c.name);
                      tempCategories.sort();
                      handleChangeFocusedOrganisation('categories', tempCategories);
                    }
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {c.name}
                </div>
              ))}
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                  setImagePreview(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'editLogoImage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || dialogData?.value?.file?.size > 1000000}
                  palette='primary'
                  onClick={() => {
                    if(dialogData?.value?.file) {
                      handleUploadImage(dialogData?.value, handleUpdateLogoImage);
                    } else {
                      handleUpdateLogoImage(dialogData?.value);
                    }
                  }}
                >
                  Edit Logo data
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditOrganisationInfoSubPanel.propTypes = {
  focusedOrganisation: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedOrganisation: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleUploadImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditOrganisationInfoSubPanel;
