import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../../components/search/store';
import {paletteData, Typography, Button, Input, Dialog, ProgressBar} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

import apiRequest from '../../../../../tools/apiRequest';

function AboutOrganisationSubPanel({
  me,
  setFocusedFormSection,
  processing,
  setProcessing,
  search,
  setSearch,
  queryLimit,

  focusedOrganisation,
  handleChangeFocusedOrganisation,

  users,
  userReferences,
  setUserReference,

  areas,
  areaReferences,
  setAreaReference,
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(undefined);

    const [initialLoad, setInitialLoad] = useState({
      owner: false,
      areas: false,
    });
    const [ownerLoaded, setOwnerLoaded] = useState(false);
    const [allLoaded, setAllLoaded] = useState(false);

    useEffect(() => {
      if(!allLoaded && ownerLoaded) {
        setAllLoaded(true);
      }

      if(!initialLoad.owner && focusedOrganisation?.owner && userReferences[focusedOrganisation.owner]) {
        setInitialLoad({
          ...initialLoad,
          owner: true,
        });
        setOwnerLoaded(true);
      } else if (!initialLoad.owner && !focusedOrganisation?.owner) {
        setInitialLoad({
          ...initialLoad,
          owner: true,
        });
        setOwnerLoaded(true);
      } else if(!initialLoad.owner) {
        setInitialLoad({
          ...initialLoad,
          owner: true,
        });
        apiRequest({type: 'get', action: `users/${focusedOrganisation?.owner}/user`})
        .then((userResult) => {
          setUserReference(userResult.data.user);
          setOwnerLoaded(true);
        }).catch((error) => {
          handleChangeFocusedOrganisation('owner', undefined);
          setOwnerLoaded(true);
        });
      }

    }, [
      initialLoad,
      allLoaded,
      ownerLoaded,
      focusedOrganisation,
      handleChangeFocusedOrganisation,
      userReferences,
      setUserReference,
    ]);

    return (
      <div>
        <div style={{background: paletteData.primary.standard.background, padding: 10}}>
          <Typography style={{color: paletteData.primary.standard.foreground}}>
            Who is the organisation?
          </Typography>
        </div>
        <Input
          name="name"
          palette='secondary'
          label="Organisation Name"
          type="text"
          value={focusedOrganisation?.name || ''}
          onChange={(value) => handleChangeFocusedOrganisation('name', value)}
        />
        {!focusedOrganisation.name &&
          <Typography size='subText' style={{color: 'red', paddingLeft: 5}}>
            Organisation name is required
          </Typography>
        }
        {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
          <GridContainer>
            <GridCell weight={1} style={{minWidth: 200}}>
              <Input
                disabled={true}
                forceFocus={true}
                name="owner"
                palette='secondary'
                label="Organisation owner"
                type="text"
                value={focusedOrganisation?.owner ? userReferences[focusedOrganisation?.owner]?.name || '...processing' : 'No organisation owner'}
              />
            </GridCell>
            <GridCell style={{marginTop: 7, marginLeft: 5}}>
              <Button palette='primary' onClick={() => {
                setDialogData({
                  type: 'chooseUser',
                  title: 'Choose Owner',
                });
                setDialogOpen(true);
              }}>
                Change Owner
              </Button>
            </GridCell>
          </GridContainer>
        }
        <br/>
        <div style={{background: paletteData.primary.standard.background, padding: 10}}>
          <GridContainer>
            <GridCell weight={1} center>
              <Typography style={{color: paletteData.primary.standard.foreground}}>
                Where does the organisation operate?
              </Typography>
            </GridCell>
            <GridCell>
              <Button palette='secondary' size='small' onClick={() => {
                setDialogData({
                  type: 'chooseArea',
                  title: 'Add Area',
                });
                setDialogOpen(true);
              }}>
                Add area
              </Button>
            </GridCell>
          </GridContainer>
        </div>

        <div style={{paddingTop: 10}}>
          {(!focusedOrganisation?.offices || focusedOrganisation?.offices?.length === 0) &&
            <Typography style={{color: 'red', paddingLeft: 5}}>
              Organisations must operate in a minimum of one area
            </Typography>
          }
          {focusedOrganisation?.offices?.length > 0 &&
            <GridContainer>
              {focusedOrganisation.offices.map((o, oIndex) => (
                <GridCell key={oIndex} style={{background: '#c6c6c6', padding: 5, marginRight: 5, marginBottom: 5, borderRadius: 5}}>
                  {areaReferences[o.area] ? areaReferences[o.area].name : '...loading'} <span className="material-icons md-24" style={{fontSize: 10, cursor: 'pointer'}} onClick={() => {
                    const tempOffices = focusedOrganisation.offices || [];
                    tempOffices.splice(oIndex, 1);
                    handleChangeFocusedOrganisation('offices', tempOffices);
                  }}>close</span>
                </GridCell>
              ))}
            </GridContainer>
          }

        </div>
        <br/>
        <div style={{background: paletteData.primary.standard.background, padding: 10}}>
          <Typography style={{color: paletteData.primary.standard.foreground}}>
            How do you get in contact with this organisation ?
          </Typography>
        </div>
        <Input
          name="website"
          palette='secondary'
          label="Website"
          type="text"
          value={focusedOrganisation?.website || ''}
          onChange={(value) => handleChangeFocusedOrganisation('website', value)}
        />
        <Input
          name="facebook"
          palette='secondary'
          label="Facebook"
          type="text"
          value={focusedOrganisation?.social?.facebook || ''}
          onChange={(value) => {
            const socialProcessed = focusedOrganisation?.social || {};
            socialProcessed.facebook = value;
            handleChangeFocusedOrganisation('social', socialProcessed);
          }}
        />
        <Input
          name="instagram"
          palette='secondary'
          label="Instagram"
          type="text"
          value={focusedOrganisation?.social?.instagram || ''}
          onChange={(value) => {
            const socialProcessed = focusedOrganisation?.social || {};
            socialProcessed.instagram = value;
            handleChangeFocusedOrganisation('social', socialProcessed);
          }}
        />
        <Input
          name="email"
          palette='secondary'
          label="Primary Email"
          type="text"
          value={focusedOrganisation?.email || ''}
          onChange={(value) => handleChangeFocusedOrganisation('email', value)}
        />
        {!focusedOrganisation?.email &&
          <Typography size='subText' style={{color: 'red', paddingLeft: 5}}>
            primary email is required if you wish to sell on the platform
          </Typography>
        }
        <Input
          name="phone"
          palette='secondary'
          label="Primary Phone"
          type="text"
          value={focusedOrganisation?.phone || ''}
          onChange={(value) => handleChangeFocusedOrganisation('phone', value)}
        />
        <br/>
        <GridContainer>
          <GridCell weight={1}/>
          <GridCell>
            <Button
              disabled={!focusedOrganisation.name || !focusedOrganisation.offices || focusedOrganisation.offices?.length === 0}
              palette='primary'
              style={{padding: 30}}
              onClick={() => {
                window.scrollTo(0, 0);
                setFocusedFormSection('offices');
              }}
            >
              Next Step
            </Button>
          </GridCell>
          <GridCell weight={1}/>
        </GridContainer>

        {/*popouts and popups*/}
        {dialogOpen &&
          <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
            <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
              <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                {dialogData?.title}
              </Typography>
            </div>
            {(dialogData?.message) &&
              <div style={{padding: 10}}>
                <Typography>
                  {dialogData.message}
                </Typography>
              </div>
            }
            {dialogData?.type === 'chooseUser' &&
              <div style={{padding: 10}}>
                <SearchStore databaseArea='users' processing={processing} setProcessing={setProcessing} expandSearch={false} setExpandSearch={() => {}}/>
                {(!users || processing) &&
                  <ProgressBar palette='secondary'/>
                }
                {!processing && users && users.map((u, uIndex) => (
                  <div
                    key={uIndex}
                    style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!userReferences[u._id]) {
                        setUserReference(u);
                      }
                      handleChangeFocusedOrganisation('owner', u._id);
                      setDialogOpen(false);
                      setDialogData(undefined);
                    }}
                  >
                    {u.name}
                    <div style={{color: '#c6c6c6'}}>
                      {u.email}
                    </div>
                  </div>
                ))}
              </div>
            }
            {dialogData?.type === 'chooseArea' &&
              <div style={{padding: 10}}>
                <SearchStore databaseArea='areas' processing={processing} setProcessing={setProcessing} expandSearch={false} setExpandSearch={() => {}}/>
                {(!areas || processing) &&
                  <ProgressBar palette='secondary'/>
                }
                {!processing && areas && areas.map((a, aIndex) => (
                  <div
                    key={aIndex}
                    style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!areaReferences[a._id]) {
                        setAreaReference(a);
                      }
                      const offices = focusedOrganisation.offices || [];
                      offices.push({area: a._id});
                      handleChangeFocusedOrganisation('offices', offices);
                      setDialogOpen(false);
                      setDialogData(undefined);
                    }}
                  >
                    {a.name}
                  </div>
                ))}
              </div>
            }
            <GridContainer>
              <GridCell weight={1}/>
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                  onClick={() => {
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
                </Button>
              </GridCell>
            </GridContainer>
          </Dialog>
        }
      </div>
  );
};

AboutOrganisationSubPanel.propTypes = {
  me: PropTypes.shape({}),
  setFocusedFormSection: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,

  focusedOrganisation: PropTypes.shape({}),
  handleChangeFocusedOrganisation: PropTypes.func,
};

export default AboutOrganisationSubPanel;
