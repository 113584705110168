import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from './GridContainer';
import GridCell from './GridCell';

function Grid({style, cells}) {
  return (
    <GridContainer style={style}>
      {cells.map((c, cIndex) => (
        <GridCell key={cIndex} style={c.style}>
          {c.content}
        </GridCell>
      ))}
    </GridContainer>
  );
}

Grid.propTypes = {
  style: PropTypes.shape({}),
  cells: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Grid;
