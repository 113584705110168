import React from 'react';
import PropTypes from 'prop-types';

import {CheckBox} from '../../styles';

function productOnlinePaymentForm({payment, availability, handleChange, productType}) {
  return (
    <div>
      <CheckBox
        name='pop'
        label={productType === 'good' ? 'Order online and pay on pickup available' : 'Order online and pay in store'}
        value={payment.pop}
        onChange={(value) => handleChange('pop', value)}
      />
      <CheckBox
        name='pop'
        label='Pay online using invoice'
        value={payment.poi}
        onChange={(value) => handleChange('poi', value)}
      />
      <CheckBox
        name='pop'
        label='Pay online using credit card'
        value={payment.po}
        onChange={(value) => handleChange('po', value)}
      />
    </div>
  );
}

productOnlinePaymentForm.propTypes = {
  payment: PropTypes.shape({}),
  availability: PropTypes.shape({}),
  handleChange: PropTypes.func,
  productType: PropTypes.string,
  organisation: PropTypes.shape({}),
}

export default productOnlinePaymentForm;
