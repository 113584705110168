import {generateImageBlock} from '../../../';

const generateRichTextBlock = ({markdown, styleContainer, styleText, image}) => {
  const section = {
    section: 'richTextBlock',
    type: 'div',
    options: {
      style: {
        padding: 10,
        overflow: 'hidden',
        ...styleContainer,
      },
    },
    children: [
      {
        type: 'RichText',
        options: {
          style: {
            whiteSpace: 'pre-wrap',
            ...styleText,
          },
        },
        markdown: markdown || '',
      },
    ],
  };
  if(image) {
    image = generateImageBlock({
      src: `https://res.cloudinary.com/taitokerau-tatou/image/upload/q_auto:good,c_fill,w_2048/${image.imageId}`,
      alt: image.alt,
      description: image.description,
      styleContainer: {
        float: 'right',
        width: '60%',
        paddingLeft: 10,
        paddingBottom: 10,
        marginTop: '1em',
        zIndex: 10,
      },
    });
    section.children.unshift(image);
  }
  return section;
};

export default generateRichTextBlock;
