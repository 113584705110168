import React from 'react';
import PropTypes from 'prop-types';

import {Input} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function AreaDetailsForm({focusedArea, handleChange}) {
  return (
    <GridContainer>
      <GridCell weight={1}>
        <Input
          name="name"
          palette='secondary'
          label="Name - Safe Search"
          type="text"
          value={focusedArea?.name || ''}
          onChange={(value) => handleChange('name', value)}
        />
      </GridCell>
      <GridCell weight={1}>
        <Input
          name="nameProper"
          palette='secondary'
          label="Name - Proper"
          type="text"
          value={focusedArea?.nameProper || ''}
          onChange={(value) => handleChange('nameProper', value)}
        />
      </GridCell>
    </GridContainer>
  );
}

AreaDetailsForm.propTypes = {
  focusedArea: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default AreaDetailsForm;
