import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import {paletteData, Typography, Button, Dialog, Input, TextArea, ProgressBar} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';
import Hidden from '../../../../../components/hidden';

import PreviewForm from '../../../../../components/forms/preview';

function ListingSubPanel({
  me,
  setFocusedFormSection,
  processing,
  setProcessing,
  search,
  setSearch,
  queryLimit,
  handleUploadImage,

  focusedOrganisation,
  handleChangeFocusedOrganisation,
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(undefined);
    const [imagePreview, setImagePreview] = useState(undefined);

    const imageUploadRef = useRef(null);

    const handleGeneratePreview = (file) => {
      setProcessing(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setProcessing(false);
        setImagePreview(reader.result)
      }
    }

    const handleUpdateThumbnailImage = (image, error) => {
      if(!error) {
        const item = {
          imageId: image?.imageId,
          tags: image?.tags,
          alt: image?.alt,
          description: image?.description,
        };
        handleChangeFocusedOrganisation('thumbnail', item);
        setDialogData(undefined);
        setImagePreview(undefined);
        setDialogOpen(false);
      }
    }

    return (
      <div>
        <div style={{background: paletteData.primary.standard.background, padding: 10}}>
          <GridContainer>
            <GridCell weight={1} center>
              <Typography style={{color: paletteData.primary.standard.foreground}}>
                Listing Details
              </Typography>
            </GridCell>
            <GridCell>
              <Button size='small' palette='secondary' onClick={() => {
                setDialogData({
                  type: 'viewListing',
                  title: 'View Listing',
                  value: {}
                });
                setDialogOpen(true);
              }}>
                View what your listing looks like
              </Button>
            </GridCell>
          </GridContainer>
        </div>
        <br/>
        <PreviewForm
          preview={focusedOrganisation}
          handleChange={(name, value) => {
            if(name === 'thumbnail') {
              setImagePreview(undefined);
              setDialogData({
                type: 'editThumbnailImage',
                title: 'Change Thumbnail',
                preview: undefined,
                value: {
                  file: undefined,
                  imageId: value?.imageId || '',
                  tags: value?.tags || '',
                  alt: value?.alt || '',
                  description: value?.description || '',
                }
              });
              setDialogOpen(true);
            } else {
              handleChangeFocusedOrganisation(name, value);
            }
          }}
        />

        <br/>
        <GridContainer>
          <GridCell weight={1}/>
          <GridCell>
            <Button palette='primary' style={{padding: 30}} onClick={() => {
              window.scrollTo(0, 0);
              setFocusedFormSection('images');
            }}>
              Next Step
            </Button>
          </GridCell>
          <GridCell weight={1}/>
        </GridContainer>


        {/*popouts and popups*/}
        {dialogOpen &&
          <Dialog open={dialogOpen} handleClose={() => {
           setDialogData(undefined);
           setImagePreview(undefined);
           setDialogOpen(false);
         }}>
           <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
             <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
               {dialogData?.title}
             </Typography>
           </div>
            {(dialogData?.message) &&
              <div style={{padding: 10}}>
                <Typography>
                  {dialogData.message}
                </Typography>
              </div>
            }
            {dialogData?.type === 'editThumbnailImage' &&
              <div style={{padding: 10}}>
                <GridContainer>
                  <GridCell style={{width: 200}}>
                    <input
                      ref={imageUploadRef}
                      type="file"
                      style={{display: 'none'}}
                      onChange={(event) => {
                        handleGeneratePreview((event?.target?.files?.length) > 0 ? event.target.files[0] : undefined);
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            file: (event?.target?.files?.length) > 0 ? event.target.files[0] : undefined,
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                    <Button palette='primary' onClick={() => imageUploadRef.current.click()} disabled={processing}>
                      Change Image
                    </Button>
                    {!imagePreview && !dialogData.value.imageId &&
                      <GridContainer style={{height: 200, width: 200, marginTop: 10}}>
                        <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5}}>
                          No Image
                        </GridCell>
                      </GridContainer>
                    }
                    {(imagePreview || dialogData.value.imageId) &&
                      <GridContainer style={{marginTop: 10}}>
                        <GridCell center={true} weight={1}>
                          <img src={imagePreview || `https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${dialogData.value.imageId}`} alt={dialogData?.value?.alt} style={{width: 200}}/>
                        </GridCell>
                      </GridContainer>
                    }
                    {dialogData?.value?.file && dialogData?.value?.file?.size > 1000000 &&
                      <div style={{padding: 10}}>
                        <Typography style={{color: 'red'}}>
                          File too large, must be under 1 MB
                        </Typography>
                      </div>
                    }
                    {(processing) &&
                      <ProgressBar palette='secondary'/>
                    }
                  </GridCell>
                  <GridCell weight={1}>
                    <Input
                      name="alt"
                      palette='secondary'
                      label="Alt Text"
                      type="text"
                      value={dialogData?.value?.alt || ''}
                      onChange={(value) => {
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            alt: value,
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                    <Input
                      name="tags"
                      palette='secondary'
                      label="Tags"
                      type="text"
                      value={dialogData?.value?.tags || ''}
                      onChange={(value) => {
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            tags: value,
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                    <TextArea
                      name="description"
                      palette='secondary'
                      label="Description"
                      type="text"
                      value={dialogData?.value?.description || ''}
                      onChange={(value) => {
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            description: value,
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                  </GridCell>
                </GridContainer>
              </div>
            }
            {dialogData?.type === 'viewListing' &&
              <div style={{padding: 10}}>
              <GridContainer>
                <Hidden breakpoint='hiddenlessthan1024'>
                  <GridCell center={true} style={{height: 200}}>
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${focusedOrganisation.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                      alt={focusedOrganisation?.thumbnail?.alt}
                      style={{width: 200}}
                    />
                  </GridCell>
                </Hidden>
                <GridCell weight={1} center style={{textAlign: 'left', padding: 10, height: 180}}>
                  <Typography size='title'>
                    {focusedOrganisation.name}
                  </Typography>
                  <Typography style={{color: '#333333'}}>
                    {focusedOrganisation.tagline}
                  </Typography>
                  <Typography style={{marginTop: 5}}>
                    {focusedOrganisation.blurb?.length > 350 ? `${focusedOrganisation.blurb.substring(0, 350)}...` : focusedOrganisation.blurb}
                  </Typography>
                </GridCell>
              </GridContainer>

              </div>
            }
            <GridContainer>
              <GridCell weight={1}/>
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                   onClick={() => {
                    setDialogData(undefined);
                    setImagePreview(undefined);
                    setDialogOpen(false);
                  }}
                >
                  {(dialogData?.type === 'message' || dialogData?.type === 'viewListing') ? 'OK' : 'Cancel'}
                </Button>
              </GridCell>
              {dialogData?.type === 'editThumbnailImage' &&
                <GridCell style={{padding: 10}}>
                  <Button
                    disabled={processing || dialogData?.value?.file?.size > 1000000}
                    palette='primary'
                    onClick={() => {
                      if(dialogData?.value?.file) {
                        handleUploadImage(dialogData?.value?.file, handleUpdateThumbnailImage);
                      } else {
                        handleUpdateThumbnailImage(dialogData?.value);
                      }
                    }}
                  >
                    Edit Thumbnail data
                  </Button>
                  {processing &&
                    <ProgressBar palette='secondary'/>
                  }
                </GridCell>
              }
            </GridContainer>
          </Dialog>
        }
      </div>
  );
};

ListingSubPanel.propTypes = {
  me: PropTypes.shape({}),
  setFocusedFormSection: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,

  focusedOrganisation: PropTypes.shape({}),
  handleChangeFocusedOrganisation: PropTypes.func,
};

export default ListingSubPanel;
