import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import PreviewForm from '../../../../../components/forms/preview';

import {paletteData, Button, Typography, Dialog, ProgressBar, Input} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function EditEventPreviewSubPanel({
    focusedEvent,
    handleChangeFocusedEvent,
    handleChangesFocusedEvent,
    handleUploadImage,
    processing,
    setProcessing,
  }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const imageUploadRef = useRef(null);

  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography style={{color: paletteData.primary.standard.foreground}}>
          Preview
        </Typography>
      </div>
      <div style={{margin: 5}}>
        <PreviewForm
          preview={focusedEvent}
          handleChange={(name, value) => {
            if(name === 'thumbnail') {
              setDialogData({
                type: 'selectImage',
                title: 'Add Image',
                data: {
                  callback: (value) => {
                    const thumbnailProcessed = value;
                    const galleryProcessed = focusedEvent?.gallery || [];
                    if(galleryProcessed.findIndex((i, iIndex) => {
                      return i.imageId === value.imageId;
                    }) === -1) {
                      galleryProcessed.push(value);
                    }
                    const chages = {
                      thumbnail: thumbnailProcessed,
                      gallery: galleryProcessed,
                    }
                    handleChangesFocusedEvent(chages);
                  }
                },
                value: {
                  search: '',
                  imageId: value?.imageId || '',
                  alt: value?.alt || '',
                },
              });
              setDialogOpen(true);
            } else {
              handleChangeFocusedEvent(name, value);
            }
          }}
        />
      </div>

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => {
         setDialogData(undefined);
         setDialogOpen(false);
       }}>
         <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
           <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
             {dialogData?.title}
           </Typography>
         </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'selectImage' &&
            <div style={{padding: 10}}>
              <Input
                label='Search Images'
                value={dialogData?.value?.search}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  })
                }}
              />
              {processing && <ProgressBar palette='secondary'/>}
              <GridContainer>
                <GridCell
                  style={{width: 100, margin: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!processing) {
                      imageUploadRef.current.click();
                    }
                  }}
                >
                  <input
                    ref={imageUploadRef}
                    type="file"
                    style={{display: 'none'}}
                    onChange={(event) => {
                      const file = event?.target?.files?.length > 0 ? event.target.files[0] : undefined;
                      handleUploadImage(file, (response, error) => {
                        if(!error) {
                          const alt = (file.name.split('.') || ['thumbnail'])[0];
                          dialogData?.data.callback({
                            imageId: response.imageId,
                            alt,
                            tags: '',
                            description: '',
                          });
                          setDialogData(undefined);
                          setDialogOpen(false);
                        }
                      });
                    }}
                  />
                  <div style={{width: '100%', height: 100, textAlign: 'center', background: '#c6c6c6'}}>
                    <span className="material-icons md-24" style={{marginTop: 38}}>image</span>
                    <span className="material-icons md-24" style={{marginTop: 38}}>add</span>
                  </div>
                  <Typography style={{textAlign: 'center', marginTop: 4}}>
                    New
                  </Typography>
                </GridCell>
                {focusedEvent?.gallery?.length > 0 && focusedEvent.gallery.filter((i, iIndex) => {
                  return (i.alt || '').toLowerCase().includes(dialogData?.value?.search?.toLowerCase());
                }).map((i, iIndex) => (
                  <GridCell
                    key={iIndex}
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        dialogData?.data.callback({
                          imageId: i.imageId,
                          alt: i.alt,
                          tags: i.tags,
                          description: i.description,
                        });
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_100,h_100/${i.imageId}`}
                      alt={i.alt}
                      style={{width: '100%'}}
                    />
                    <Typography style={{textAlign: 'center'}}>
                      {i.alt}
                    </Typography>
                  </GridCell>
                ))}
              </GridContainer>

            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditEventPreviewSubPanel.propTypes = {
  focusedEvent: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedEvent: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleUploadImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditEventPreviewSubPanel;
