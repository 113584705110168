import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {textData, Typography, Fab, MenuDropdown, Button} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

function TitleBlockEdit({
  block,
  removeBlock,
  blockIndex,
  maxIndex,
  changeSection,
  changeParent,
  changeBlockIndex,

  dialogData,
  dialogOpen,
  setDialogData,
  setDialogOpen
}) {
  const [colorPickerMenuOpen, setColorPickerMenuOpen] = useState(false);
  return (
    <div style={{...block.options.style}}>
      <GridContainer>
        <GridCell center={true} weight={1}>
          <input
            style={{
              ...block.children[0].options.style,
              background: 'rgba(0,0,0,0)',
              border: 'none',
              outline: 'none',
              fontSize: (block.children[0].options.size === 'heading' ? textData.heading.fontSize :
                         block.children[0].options.size === 'subHeading' ? textData.subHeading.fontSize :
                         block.children[0].options.size === 'title' ? textData.title.fontSize :
                         block.children[0].options.size === 'text' ? textData.text.fontSize :
                         block.children[0].options.size === 'subText' ? textData.subText.fontSize :
                         '1em'),
            }}
            value={block.children[0].text}
            onChange={(e) => {
              const value = e.target.value;
              changeSection('update', blockIndex, 'children.0.text', value);
            }}
          />
        </GridCell>
        <GridCell>
          <div
            style={{
              minWidth: 20,
              padding: 5,
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 5,
              background: 'white',
              position: 'relative',
            }}
          >
            <GridContainer>
              <GridCell>
                <Fab
                  size='small'
                  onClick={() => setColorPickerMenuOpen(true)}
                >
                  <span className="material-icons md-24">palette</span>
                </Fab>
                {colorPickerMenuOpen &&
                  <MenuDropdown
                    open={colorPickerMenuOpen}
                    handleClose={() => setColorPickerMenuOpen(false)}
                    position={{right: 10}}
                    style={{padding: 10, marginTop: 10, borderRadius: 5, zIndex: 100}}
                  >
                    <Button
                      onClick={() => {
                        console.log(block);
                        setDialogData({
                          type: 'colorPicker',
                          title: 'Change Title Color',
                          data: {
                            callback: (value) => changeSection('update', blockIndex, 'children.0.options.style.color', value),
                          },
                          value: {
                            color: block.children[0].options?.style?.color || '#000000',
                          },
                        });
                        setDialogOpen(true);
                      }}
                    >
                      Title
                    </Button>
                    <Button
                      onClick={() => {
                        setDialogData({
                          type: 'colorPicker',
                          title: 'Change Background Color',
                          data: {
                            callback: (value) => changeSection('update', blockIndex, 'options.style.background', value),
                          },
                          value: {
                            color: block.options.style.background,
                          },
                        });
                        setDialogOpen(true);
                      }}
                    >
                      Background
                    </Button>
                  </MenuDropdown>
                }
              </GridCell>
              <GridCell center={true}>
                <Fab
                  size='small'
                  style={{background: '#c6c6c6', cursor: 'pointer'}}
                  onClick={() => {
                    setDialogData({
                      type: 'moveSection',
                      title: 'Move Section',
                      data: {
                        maxIndex,
                        currentIndex: blockIndex + 1,
                        callbackMove: (shift) => changeBlockIndex(shift),
                        callbackRemove: () => removeBlock(blockIndex),
                      },
                      value: {
                        index: blockIndex + 1,
                      },
                    });
                    setDialogOpen(true);
                  }}
                >
                  <Typography style={{textAlign: 'center'}}>
                    {blockIndex + 1}
                  </Typography>
                </Fab>
              </GridCell>
            </GridContainer>
          </div>
        </GridCell>
      </GridContainer>
    </div>
  );
}

TitleBlockEdit.propTypes = {
  block: PropTypes.shape({}),
  changeSection: PropTypes.func,
  changeParent: PropTypes.func,
};

export default TitleBlockEdit;
