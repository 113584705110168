import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import UserForm from '../../../../components/forms/user';
import DemographicsForm from '../../../../components/forms/demographics';
import ContactPrimaryForm from '../../../../components/forms/contactPrimary';
import ContactEmailForm from '../../../../components/forms/contactEmail';
import ContactPhoneForm from '../../../../components/forms/contactPhone';
import ContactAddressForm from '../../../../components/forms/contactAddress';

import {setApiToken} from '../../../../store/reducers/auth/actions.js';
import {setProfile} from '../../../../store/reducers/user/actions.js';
import {setUserReference, setProfileReference} from '../../../../store/reducers/references/actions.js';
import {paletteData, Button, ProgressBar, Dialog, Typography, Input} from '../../../../components/styles';
import {GridContainer, GridCell, Grid} from '../../../../components/grid';
import apiRequest from '../../../../tools/apiRequest';
import handleErrorMessage from '../../../../tools/handleErrorMessage';
import {checkPasswordStrength} from '../../../../tools/validation';

function MyProfilePanel({me, auth, setApiToken, setProfile, setUserReference, setProfileReference, handleLogout}) {
  const [focusedUser, setFocusedUser] = useState(undefined);
  const [focusedProfile, setFocusedProfile] = useState(undefined);
  const [processing, setProcessing] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleChangeFocusedUser = (name, value) => {
    const userProcessed = {
      ...focusedUser,
      [name]: value,
    };
    setFocusedUser(userProcessed);
  };

  const handleChangeFocusedProfile = (name, value) => {
    const profileProcessed = {
      ...focusedProfile,
      [name]: value,
    };
    setFocusedProfile(profileProcessed);
  };

  const handleUpdateUser = () => {
    setProcessing(true);
    const userId = focusedUser._id;
    const updateUser = {
      name: focusedUser.name,
      email: focusedUser.email,
      whitelist: focusedUser.whitelist,
      blacklist: focusedUser.blacklist,
    };
    const updateProfile = {
      _id: focusedProfile._id || 'new',
      userId,
      nameFirst: focusedProfile.nameFirst,
      nameMiddle: focusedProfile.nameMiddle,
      nameLast: focusedProfile.nameLast,
      dob: focusedProfile.dob,
      email: focusedProfile.email,
      phone: focusedProfile.phone,
      address: focusedProfile.address,
      primaryContact: focusedProfile.primaryContact,
    }
    apiRequest({type: 'patch', action: `users/update/${userId}`, data: {updateUser, updateProfile}})
    .then((result) => {
      if(result.data.auth) {
        setApiToken(result.data.auth);
      }
      setProfile(updateProfile);
      setUserReference({
        _id: userId,
        verification: focusedUser.verification,
        roles: focusedUser.roles,
         ...updateUser
       });
      setProfileReference(updateProfile._id === 'new' ? result.data.profile : updateProfile);
      setFocusedUser(undefined);
      setFocusedProfile(undefined);
      setProcessing(false);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update user request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleVerifyEmail = (email) => {
    setProcessing(true);
    const updateKey = {
      name: me?.name,
      userId: me?._id,
      type: 'emailverification',
      container: 'systems',
      value: email,
    }
    apiRequest({type: 'post', action: `keys/create`, data: {updateKey}})
    .then((result) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Email Verification Sent',
        message: 'Please check your emails and follow the instructions to verify this email address',
      });
      setDialogOpen(true);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Verify email request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  };

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              My Profile
            </Typography>
          </GridCell>
          <GridCell center={true} style={{marginLeft: 5}}>
            <Button
              size='small'
              disabled={processing}
              palette="secondary"
              onClick={() =>{
                if(focusedUser) {
                  setFocusedProfile(undefined);
                  setFocusedUser(undefined);
                } else {
                  setDialogData({
                    type: 'updateUserReAuthenticate',
                    title: `Please re-enter your password`,
                    value: {
                      password: '',
                    }
                  });
                  setDialogOpen(true);
                }
              }}
            >
              {focusedUser ? 'Cancel' : 'Update Details'}
            </Button>
          </GridCell>
          {!focusedUser &&
            <GridCell center={true} style={{marginLeft: 5}}>
              <Button disabled={processing} size='small' palette='secondary' onClick={() => {
                setDialogData({
                  type: 'resetPasswordReAuthenticate',
                  title: `Please re-enter your password`,
                  value: {
                    password: '',
                  }
                });
                setDialogOpen(true);
              }}>
                Reset Password
              </Button>
            </GridCell>
          }
          {!focusedUser &&
            <GridCell center={true} style={{marginLeft: 5}}>
              <Button disabled={processing} size='small' palette='secondary' onClick={() => {
                setDialogData({
                  type: 'logOut',
                  title: `Log Out`,
                  message: 'Do you wish to log out?'
                });
                setDialogOpen(true);
              }}>
                Log Out
              </Button>
            </GridCell>
          }
          {focusedUser &&
            <GridCell center={true} style={{marginLeft: 5}}>
              <Button size='small' palette='secondary' onClick={() => handleUpdateUser()} disabled={processing}>
                Submit
              </Button>
            </GridCell>
          }

        </GridContainer>
      </div>
      {processing &&
        <ProgressBar palette='secondary'/>
      }
      <div style={{padding: 10, display: focusedUser ? 'none' : 'block'}}>
        <Grid cells={[{style: {width: 150}, content: 'Username:'}, {content: me.name}]}/>
        <Grid cells={[{style: {width: 150}, content: 'Login:'}, {content: me.email}]}/>
        <br/>
        {(me?.profile) &&
          <div>
            <div style={{background: paletteData.primary.standard.background, color: paletteData.primary.standard.foreground, fontSize: '1em', padding: 10, marginBottom :10}}>
              Demographics
            </div>
            <Grid cells={[{style: {width: 150}, content: 'Name:'}, {content: `${(me?.profile?.nameFirst) || ''} ${me?.profile?.nameMiddle || ''} ${me?.profile?.nameLast || ''}`}]}/>
            <Grid cells={[{style: {width: 150}, content: 'Date of Birth:'}, {content: (me?.profile?.dob)}]}/>
            <br/>
            <div style={{background: paletteData.primary.standard.background, color: paletteData.primary.standard.foreground, fontSize: '1em', padding: 10, marginBottom :10}}>
              Contact Details
            </div>
            <Grid cells={[{style: {width: 150}, content: 'Email:'}, {content: ((me?.profile?.primaryContact?.primaryEmail) === 'me' || !me?.profile?.email) ? (me?.email) : (me?.profile?.email[(me?.profile?.primaryContact?.primaryEmail)])}]}/>
            <Grid cells={[{style: {width: 150}, content: 'Phone:'}, {content: (me?.profile?.phone) ? (me?.profile?.phone[me?.profile?.primaryContact?.primaryPhone]) : ''}]}/>
            <Grid cells={[{style: {width: 150}, content: 'Address:'}, {content: `${(me?.profile?.address?.extra) || ''} ${(me?.profile?.address?.number) || ''} ${(me?.profile?.address?.street) || ''} ${(me?.profile?.address?.suburb) || ''} ${(me?.profile?.address?.city) || ''} ${(me?.profile?.address?.country) || ''}`}]}/>
          </div>
        }
      </div>
      <div style={{padding: 10, display: focusedUser ? 'block' : 'none'}}>
        {focusedUser &&
          <div>
            <UserForm user={focusedUser} handleChange={handleChangeFocusedUser} handleVerifyEmail={handleVerifyEmail} processing={processing} verification={me?.verification?.contact || {}}/>
            <br/>
            <div style={{background: paletteData.primary.standard.background, color: paletteData.primary.standard.foreground, fontSize: '1em', padding: 10}}>
              Demographics
            </div>
            <DemographicsForm demographics={focusedProfile} handleChange={handleChangeFocusedProfile}/>
            <br/>
            <div style={{background: paletteData.primary.standard.background, color: paletteData.primary.standard.foreground, fontSize: '1em', padding: 10}}>
              Contact Details
            </div>
            <ContactEmailForm email={focusedProfile?.email} processing={processing} verification={me?.verification?.contact || {}} handleVerifyEmail={handleVerifyEmail} handleChange={(name, value) => {
              const c = focusedProfile.email || {};
              c[name] = value;
              handleChangeFocusedProfile('email', c);
            }}/>
            <ContactPhoneForm phone={focusedProfile?.phone} handleChange={(name, value) => {
              const c = focusedProfile.phone || {};
              c[name] = value;
              handleChangeFocusedProfile('phone', c);
            }}/>
            <br/>
            <div style={{background: paletteData.primary.standard.background, color: paletteData.primary.standard.foreground, fontSize: '1em', padding: 10}}>
              Primary Contact
            </div>
            <ContactPrimaryForm primaryContact={focusedProfile?.primaryContact} handleChange={(name, value) => {
              const p = focusedProfile.primaryContact || {};
              p[name] = value;
              handleChangeFocusedProfile('primaryContact', p);
            }}/>
            <br/>
            <div style={{background: paletteData.primary.standard.background, color: paletteData.primary.standard.foreground, fontSize: '1em', padding: 10}}>
              Address
            </div>
            <ContactAddressForm address={(focusedProfile?.address)} handleChange={(name, value) => {
              const a = focusedProfile.address || {};
              a[name] = value;
              handleChangeFocusedProfile('address', a);
            }}/>
            <br/>
          </div>
        }
      </div>

      {/*popouts and popups*/}
      <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
        <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
          <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
            {dialogData?.title}
          </Typography>
        </div>
        {(dialogData?.message) &&
          <div style={{padding: 15}}>
            <Typography>
              {dialogData?.message}
            </Typography>
          </div>
        }
        {((dialogData?.type) === 'resetPasswordReAuthenticate' || (dialogData?.type) === 'updateUserReAuthenticate') &&
          <div style={{padding: 10}}>
            <Input
              name="Password"
              palette='secondary'
              label="Password"
              type="password"
              autoComplete="new-password"
              value={dialogData?.value?.password || ''}
              onChange={(value) => {
                const dialogDataProcessed = {
                  ...dialogData,
                  value: {
                    ...(dialogData?.value || {}),
                    password: value,
                  }
                };
                setDialogData(dialogDataProcessed);
              }}
            />
          </div>
        }
        {(dialogData?.type) === 'resetPassword' &&
          <div style={{padding: 10}}>
            <Input
              name="newPassword"
              palette='secondary'
              label="New Password"
              type="password"
              value={dialogData?.value?.newPassword || ''}
              onChange={(value) => {
                const dialogDataProcessed = {
                  ...dialogData,
                  value: {
                    ...(dialogData?.value || {}),
                    newPassword: value,
                  }
                };
                setDialogData(dialogDataProcessed);
              }}
            />
            {!checkPasswordStrength(dialogData?.value?.newPassword) &&
              <Typography size="subText" style={{marginLeft: 5, marginRight: 5}}>Password must contain an uppercase and lowercase letter, one or more numbers, and a special character.</Typography>
            }
          </div>
        }
        <GridContainer>
          <GridCell weight={1}/>
          <GridCell style={{padding: 10}}>
            <Button
              disabled={processing}
              palette='primary'
              onClick={() => {
                setDialogOpen(false);
                setDialogData(undefined);
              }}
            >
              {dialogData?.type === 'message' ? 'OK' : 'cancel'}
            </Button>
          </GridCell>
          {(dialogData?.type) === 'resetPassword' &&
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing || !dialogData?.value?.newPassword || dialogData?.value?.newPassword === '' || !checkPasswordStrength(dialogData?.value?.newPassword)}
                palette='primary'
                onClick={() => {
                  setProcessing(true);
                  apiRequest({type: 'patch', action: 'users/user-reset-password', data: {password: dialogData?.value?.newPassword}})
                  .then((result) => {
                    setProcessing(false);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }).catch((error) => {
                    setProcessing(false);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  });
                }}
              >
                Reset Password
              </Button>
              {processing && <ProgressBar palette='secondary'/>}
            </GridCell>
          }
          {(dialogData?.type) === 'resetPasswordReAuthenticate' &&
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing || !dialogData?.value?.password || dialogData?.value?.password === ''}
                palette='primary'
                onClick={() => {
                  setProcessing(true);
                  apiRequest({type: 'post', action: 'validate/reAuthentication', data: {password: dialogData?.value?.password}})
                  .then((result) => {
                    setProcessing(false);
                    setDialogData({
                      type: 'resetPassword',
                      title: `Enter New Password`,
                      value: {
                        newPassword: '',
                      }
                    });
                  }).catch((error) => {
                    setProcessing(false);
                    setDialogData({
                      type: 'resetPasswordReAuthenticate',
                      title: `Please re-enter your password`,
                      message: 'Password not correct',
                      value: {
                        password: '',
                      }
                    });
                  });
                }}
              >
                Validate
              </Button>
            </GridCell>
          }
          {(dialogData?.type) === 'updateUserReAuthenticate' &&
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing || !dialogData?.value?.password || dialogData?.value?.password === ''}
                palette='primary'
                onClick={() => {
                  setProcessing(true);
                  apiRequest({type: 'post', action: 'validate/reAuthentication', data: {password: dialogData?.value?.password}})
                  .then((result) => {
                    setProcessing(false);
                    setDialogOpen(false);
                    setFocusedProfile(me.profile || {email: {}, phone: {}, address: {addressCountry: 'New Zealand'}, primaryContact: {}});
                    setFocusedUser(me);
                  }).catch((error) => {
                    setProcessing(false);
                    setDialogData({
                      type: 'resetPasswordReAuthenticate',
                      title: `Please re-enter your password`,
                      message: 'Password not correct',
                      value: {
                        password: '',
                      }
                    });
                  });
                }}
              >
                Validate
              </Button>
            </GridCell>
          }
          {dialogData?.type === 'logOut' &&
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setProcessing(true);
                  handleLogout(() => {
                    setProcessing(false);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  });
                }}
              >
                Logout
              </Button>
              {processing && <ProgressBar palette='secondary'/>}
            </GridCell>
          }
        </GridContainer>
      </Dialog>
    </div>
  );
}

MyProfilePanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
  setApiToken: PropTypes.func,
  setProfile: PropTypes.func,
}

export default connect(undefined, {setApiToken, setProfile, setUserReference, setProfileReference})(MyProfilePanel);
