const generateGridContainer = ({options, children}) => {
  const section = {
    section: 'gridBlock',
    type: 'GridContainer',
    options: {
      style: {
        ...options?.style || {},
      },
    },
    children: children || [],
  };
  return section;
};

export default generateGridContainer;
