import React from 'react';
import PropTypes from 'prop-types';

import {Input, Select, Typography, TextArea} from '../../styles';

function VideoForm({video, handleChange}) {
  return (
    <div>
      <Select
        name="organisation"
        palette='secondary'
        label="Platform"
        type="text"
        value={video.platform || ''}
        onChange={(value) => handleChange('platform', value)}
      >
        <option value="youtube">Youtube</option>
        <option value="vimeo">Vimeo</option>
        <option value="facebook">Facebook</option>
      </Select>
      <Input
        name="link"
        palette='secondary'
        label="Link"
        type="text"
        value={video.link || ''}
        onChange={(value) => handleChange('link', value)}
      />
      {!video.link &&
        <Typography size='subText' style={{color: 'red', marginLeft: 5}}>
          Video link is required
        </Typography>
      }
      <br/><br/>
      <Input
        name="title"
        palette='secondary'
        label="Title"
        type="text"
        value={video.title || ''}
        onChange={(value) => handleChange('title', value)}
      />
      {!video.title &&
        <Typography size='subText' style={{color: 'red', marginLeft: 5}}>
          Title is required
        </Typography>
      }
      <Input
        name="credit"
        palette='secondary'
        label="Created By"
        type="text"
        value={video.credit || ''}
        onChange={(value) => handleChange('credit', value)}
      />
      {video.platform === 'facebook' && !video.credit &&
        <Typography size='subText' style={{color: 'red', marginLeft: 5}}>
          Name of creator is required by facebook.
        </Typography>
      }
      <Input
        name="citation"
        palette='secondary'
        label="Citation"
        type="text"
        value={video.citation || ''}
        onChange={(value) => handleChange('citation', value)}
      />
      {video.platform === 'facebook' && !video.citation &&
        <Typography size='subText' style={{color: 'red', marginLeft: 5}}>
          Citation link is required by facebook.  A citation link is a link to the facebook page of the creator.
        </Typography>
      }
      <Input
        name="tags"
        palette='secondary'
        label="Tags"
        type="text"
        value={video.tags || ''}
        onChange={(value) => handleChange('tags', value)}
      />
      <TextArea
        name="description"
        palette='secondary'
        label="Description"
        type="text"
        value={video.description || ''}
        onChange={(value) => handleChange('description', value)}
      />
    </div>
  );
}

VideoForm.propTypes = {
  category: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default VideoForm;
