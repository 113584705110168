import React from 'react';
import PropTypes from 'prop-types';

import {paletteData, Typography, Button} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function FormInformationSubPanel({
  me,
  setFocusedFormSection,
}) {
    return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography style={{color: paletteData.primary.standard.foreground}}>
          Required Information
        </Typography>
      </div>
      <div style={{padding: 10}}>
        <Typography>
          Setting up an organisation
        </Typography>
        <ul>
          <li><Typography><strong>Organisation name</strong></Typography></li>
          {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
            <li><Typography><strong>Username of the organisation owner</strong></Typography></li>
          }
          <li><Typography><strong>Area(s) that the organisation operates in</strong></Typography></li>
        </ul>
        <Typography>
          Selling on the platform
        </Typography>
        <ul>
          <li><Typography><strong>Email Address</strong> - Used to notify when orders are available for processing and to give to customers for enquires</Typography></li>
          <li><Typography><strong>Bank Account</strong> - Used to generate invoices</Typography></li>
        </ul>
      </div>
      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography style={{color: paletteData.primary.standard.foreground}}>
          Optional Information
        </Typography>
      </div>
      <div style={{padding: 10}}>
        <Typography>
          Letting people get in contact
        </Typography>
        <ul>
          <li><Typography><strong>Phone number</strong> - Used to let people contact you</Typography></li>
          <li><Typography><strong>Physical Address</strong> - Used to let people know where you are and enables pickup orders</Typography></li>
          <li><Typography><strong>Website</strong> - Adds a link to a website on the listing</Typography></li>
        </ul>
        <Typography>
          Selling on the platform
        </Typography>
        <ul>
          <li><Typography><strong>GST Number</strong> - If the organisation makes more than $60,000 a year they are required to have a GST number.  This allows the generation of GST invoices and GST reciepts </Typography></li>
          <li><Typography><strong>Stripe ID</strong> - Allows the organisation to accept online payments.  This needs to be entered by an administrator.</Typography></li>
        </ul>
        <Typography>
          Making the listing better
        </Typography>
        <ul>
          <li><Typography><strong>Tagline</strong> - Short sentence giving an impression of the organisation</Typography></li>
          <li><Typography><strong>Blurb</strong> - A paragraph describing the organisation in detail</Typography></li>
          <li><Typography><strong>Thumbnail</strong> - A picture used when searching organisations</Typography></li>
          <li><Typography><strong>Logo</strong> - Allows for branding on the listing, invoices, and reciepts</Typography></li>
          <li><Typography><strong>Splash Image</strong> - A picture that is used when displying your listing</Typography></li>
          <li><Typography><strong>Organisation Information</strong> - Letting people know what you do</Typography></li>
          <li><Typography><strong>Details of staff</strong> - Used to letting people know who is involved in the organisation</Typography></li>
        </ul>
      </div>
      <br/>
      <GridContainer>
        <GridCell weight={1}/>
        <GridCell>
          <Button palette='primary' style={{padding: 30}} onClick={() => {
            window.scrollTo(0, 0);
            setFocusedFormSection('aboutOrganisation');
          }}>
            Start Setup
          </Button>
        </GridCell>
        <GridCell weight={1}/>
      </GridContainer>

    </div>
  );
};

FormInformationSubPanel.propTypes = {
  me: PropTypes.shape({}),
  setFocusedFormSection: PropTypes.func,
};

export default FormInformationSubPanel;
