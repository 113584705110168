import React, {useState} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../../components/search/store';
import {Button, ProgressBar, Typography, Dialog, paletteData} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';
import Hidden from '../../../../../components/hidden';

import apiRequest from '../../../../../tools/apiRequest';
import handleErrorMessage from '../../../../../tools/handleErrorMessage';

function SearchOrganisationsSubPanel({
    me,
    organisations,
    setOrganisations,
    addOrganisations,
    setOrganisationReferences,
    search,
    setSearch,
    queryLimit,
    processing,
    setProcessing,
    setFocusedOrganisation,
    handleChangeFocusedOrganisation,
    setFocusedOrganisationOriginalOwner,
    users,
    areas,
    userReferences,
    areaReferences,
    setUserReference,
    setAreaReferences,
    focusedArea,
    setFocusedArea,
    setAreaReference,
}) {
  const [expandSearch, setExpandSearch] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [filter, setFilter] = useState(undefined);
  const [overRideSearch, setOverRideSearch] = useState(false);

  return (
    <div>
      <SearchStore
        me={me}
        databaseArea='organisations'
        processing={processing}
        setProcessing={setProcessing}
        expandSearch={expandSearch}
        setExpandSearch={setExpandSearch}
        overRideSearch={overRideSearch}
        setOverRideSearch={setOverRideSearch}
      />
      {organisations?.length > 0 &&
        <div>
          {organisations.map((o, oIndex) => (
            <GridContainer
              key={oIndex}
              style={{margin: 5, cursor: 'pointer'}}
              onClick={() => {
                if(o.owner && !userReferences[o.owner]) {
                  let loaded = false;
                  if(users) {
                    for(let i = 0; i < users.length; i += 1) {
                      if(users[i]._id === o.owner) {
                        loaded = true;
                        setUserReference(users[i]);
                        break;
                      }
                    }
                  }
                  if(!loaded) {
                    apiRequest({type: 'get', action: `users/${o.owner}/user`}).then((userResult) => {
                      setUserReference(userResult.data.user);
                    }).catch((error) => {});
                  }
                }

                const officesToLoad = [];
                for(let i = 0; i < (o.offices || []).length; i += 1) {
                  if(o.offices[i].area && ! areaReferences[o.offices[i].area]) {
                    officesToLoad.push(o.offices[i].area);
                  }
                }
                if(officesToLoad.length > 0) {
                  const query = {_id: {$in: officesToLoad}};
                  apiRequest({type: 'get', action: 'areas', data: {query, sort: {name: 1}, skip: 0}})
                  .then((result) => {
                    setAreaReferences(result.data.areas);
                  }).catch((error) => {
                    console.log(error);
                  });
                }

                setFocusedOrganisation(Object.assign({}, o));
                setFocusedOrganisationOriginalOwner(o.owner);
              }}
            >
              <Hidden breakpoint='hiddenlessthan1024'>
                <GridCell center={true} style={{height: 200}}>
                  <img
                    src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${o.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                    alt={o?.thumbnail?.alt}
                    style={{width: 200}}
                  />
                </GridCell>
              </Hidden>
              <GridCell weight={1} center style={{textAlign: 'left', padding: 10, height: 180}}>
                <Typography size='title'>
                  {o.nameProper || o.name}
                </Typography>
                <Typography style={{color: '#333333'}}>
                  {o.tagline}
                </Typography>
                <Typography style={{marginTop: 5}}>
                  {o.blurb?.length > 350 ? `${o.blurb.substring(0, 350)}...` : o.blurb}
                </Typography>
              </GridCell>
            </GridContainer>
          ))}
          {processing && organisations?.length > 9 &&
            <div style={{padding: 5}}>
              <Typography>
                Loading...
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {organisations.length >= search?.organisations?.queryDepth * queryLimit &&
            <div style={{padding: 5}}>
              <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                Load More
              </Button>
            </div>
          }
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'changeArea' &&
            <div style={{padding: 10}}>
              <SearchStore databaseArea='areas' processing={processing} setProcessing={setProcessing} expandSearch={false} setExpandSearch={() => {}} me={me}/>
              {(!areas || processing) &&
                <ProgressBar palette='secondary'/>
              }
              <div
                style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                onClick={() => {
                  setProcessing(true);
                  setFocusedArea(undefined);
                  const query = filter || {};
                  delete query['offices.area'];
                  setFilter(query);
                  setOverRideSearch(true);
                  setSearch({name: 'organisations', value: {text: '', queryDepth: 1, query}});
                  apiRequest({type: 'get', action: 'organisations', data: {query, sort: {name: 1}, skip: 0, limit: queryLimit}})
                  .then((result) => {
                    setOrganisations(result.data.organisations || []);
                    setOrganisationReferences(result.data.organisations || []);
                    setProcessing(false);
                  }).catch((error) => {
                    setOrganisations([]);
                    setDialogData({
                      type: 'message',
                      title: `View organisations request denied`,
                      message: handleErrorMessage(error),
                    });
                    setDialogOpen(true);
                    setProcessing(false);
                  });
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                All Areas
              </div>
              {!processing && areas && areas.map((a, oIndex) => (
                <div
                  key={oIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    setProcessing(true);
                    if(!areaReferences[a._id]) {
                      setAreaReference(a);
                    }
                    setFocusedArea(a);
                    const query = filter || {};
                    query['offices.area'] = a._id;
                    setFilter(query);
                    setOverRideSearch(true);
                    setSearch({name: 'organisations', value: {text: '', queryDepth: 1, query}});
                    apiRequest({type: 'get', action: 'organisations', data: {query, sort: {name: 1}, skip: 0, limit: queryLimit}})
                    .then((result) => {
                      setOrganisations(result.data.organisations || []);
                      setOrganisationReferences(result.data.organisations || []);
                      setProcessing(false);
                    }).catch((error) => {
                      setOrganisations([]);
                      setDialogData({
                        type: 'message',
                        title: `View products request denied`,
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                      setProcessing(false);
                    });
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {a.name}
                </div>
              ))}
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

SearchOrganisationsSubPanel.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.shape({})),
  addOrganisations: PropTypes.func,
  search: PropTypes.shape({}),
  setSearch: PropTypes.func,
  queryLimit: PropTypes.number,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  setFocusedOrganisation: PropTypes.func,
  setOrganisationReferences: PropTypes.func,
  focusedArea: PropTypes.shape({}),
  setFocusedArea: PropTypes.func,
};

export default SearchOrganisationsSubPanel;
