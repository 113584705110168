import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

function Dialog({children, style, open, handleClose, centerWeights, id}) {
  const handleClickOutside = (event) => {
    if(open) {
      const contained = document.getElementById(id || 'dialog').contains(event.target);
      if(!contained) {
        handleClose();
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside);;
    }
  });

  return (
    <div
      style={{
        display: open ? 'flex' : 'none',
        flexDirection: 'column',
        position: 'fixed',
        zIndex: 500,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.8)',
        transition: '0.5s',
      }}
    >
      <div style={{flex: centerWeights?.top || 1}}/>
      <div
        id={id || 'dialog'}
        style={{
          background: 'white',
          borderStyle: 'solid',
          maxWidth: 1024,
          width: '90%',
          maxHeight: '90vh',
          margin: 'auto',
          borderWidth: 1,
          borderRadius: 5,
          overflowY: 'scroll',
          ...style,
        }}
      >
        {children}
      </div>
      <div style={{flex: centerWeights?.bottom || 2}}/>
    </div>
  );
}

Dialog.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.shape({}),
  handleClose: PropTypes.func,
  centerWeights: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
  }),
};

export default Dialog;
