import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

function TextAreaRich({ value, onChange }) {
    return (
        <MDEditor
            value={value}
            onChange={onChange}
            previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
            }}
        />
    );
}

export default TextAreaRich;
