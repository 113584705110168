import React from 'react';
import PropTypes from 'prop-types';

import {Input} from '../../styles';

function OrganisationForm({social, handleChange}) {
  return (
    <div>
      <Input
        name="facebook"
        palette='secondary'
        label="Facebook"
        type="text"
        value={social?.facebook || ''}
        onChange={(value) => handleChange('facebook', value)}
      />
      <Input
        name="instagram"
        palette='secondary'
        label="Instagram"
        type="text"
        value={social?.instagram || ''}
        onChange={(value) => handleChange('instagram', value)}
      />
    </div>
  );
}

OrganisationForm.propTypes = {
  social: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default OrganisationForm;
