import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Typography, ProgressBar, paletteData} from '../styles';
import {GridContainer, GridCell} from '../grid';
import Hidden from '../hidden';
import {times, bookingTimes, processBookings, convertDateToText, convertDateToDay} from '../../tools/calendarTimes';
import apiRequest from '../../tools/apiRequest';
import handleErrorMessage from '../../tools/handleErrorMessage';
import {setBookings, setSearch} from '../../store/reducers/lists/actions.js';
import {setBookingReferences} from '../../store/reducers/references/actions.js';

function Calendar({
  startDate,
  endDate,
  processing,
  setProcessing,
  focusedOrganisation,
  selectedCalendar,
  onClickBooking,
  onClickDay,

  search,
  setSearch,

  bookings,
  setBookings,
  bookingReferences,
  setBookingReferences,

  bookingCalendars,
  bookingCalendarReferences,
  refreshCalendar,
  setRefreshCalendar,
}) {
  const [dateRangeLoaded, setDateRangeLoaded] = useState(undefined);
  const [blocks, setBlocks] = useState(undefined);
  const [weeks, setWeeks] = useState(undefined);

  useEffect(() => {
    if(refreshCalendar) {
      setRefreshCalendar(false);
      setDateRangeLoaded(undefined);
      setBlocks(undefined);
      setWeeks(undefined);
    }
    if(!dateRangeLoaded) {
      setDateRangeLoaded({startDate, endDate});
      const daysToPush = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1; // days betwen + starting day
      const blocksTemp = [];
      const weeksTemp = [];

      if(daysToPush !== 1) {
        // pre-load blank days
        const preLoadDays = startDate.getDay();
        if(preLoadDays > 0) {
          for(let i = 0; i < preLoadDays; i += 1) {
            const d = new Date(startDate);
            d.setDate(d.getDate() - (preLoadDays - i));
            blocksTemp.push({
              state: 'disabled',
              date: d,
              bookings: [],
            });
          }
        }
      }

      if(daysToPush > 0) {
        const bookingsProcessed = {};
        for(let i = 0; i < daysToPush; i += 1) {
          const d = new Date(startDate);
          d.setDate(d.getDate() + i);
          if(
            search?.bookings?.query?.organisationId !== focusedOrganisation._id ||
            !bookings ||
            !bookings[`${d.getFullYear()}-${d.getMonth() + 1 < 10 ? '0' : ''}${d.getMonth() + 1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}`] ||
            bookings[`${d.getFullYear()}-${d.getMonth() + 1 < 10 ? '0' : ''}${d.getMonth() + 1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}`].loaded !== true
          ) {
            bookingsProcessed[`${d.getFullYear()}-${d.getMonth() + 1 < 10 ? '0' : ''}${d.getMonth() + 1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}`] = {
              loaded: true,
              list: [],
            }
          }
        }

        if(Object.keys(bookingsProcessed).length > 0) {
          setProcessing(true);
          const query = {
            organisationId: focusedOrganisation._id,
            'times.date': {$in: Object.keys(bookingsProcessed)},
          };
          setSearch({name: 'bookings', value: {text: '', queryDepth: 1, query}});
          apiRequest({type: 'get', action: 'bookings', data: {query, sort: {name: 1}, skip: 0}})
          .then((result) => {
            const bookingsTemp = processBookings(result.data || []);
            const bookingsKeys = Object.keys(bookingsTemp);
            for(let i = 0; i < bookingsKeys.length; i += 1) {
              if(bookingsProcessed[bookingsKeys[i]]) {
                bookingsProcessed[bookingsKeys[i]].list = bookingsTemp[bookingsKeys[i]].list;
              }
            }
            setBookingReferences(result.data);
            setBookings(bookingsProcessed);
            setDateRangeLoaded(undefined);
            setProcessing(false);
          }).catch((error) => {
            console.log(handleErrorMessage(error));
          });
        } else {
          for(let i = 0; i < daysToPush; i += 1) {
            const d = new Date(startDate);
            d.setDate(d.getDate() + i);
            const block = {
              state: 'enabled',
              date: d,
              bookings: undefined,
            }
            // filter out and apply bookings
            if(bookings) {
              block.bookings = bookings[`${d.getFullYear()}-${d.getMonth() + 1 < 10 ? '0' : ''}${d.getMonth() + 1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}`];
            }
            blocksTemp.push(block);
          }
        }
      }

      if(daysToPush !== 1) {
        // post-load blank days
        const postLoadDays = 6 - endDate.getDay();
        if(postLoadDays > 0) {
          for(let i = 0; i < postLoadDays; i += 1) {
            const d = new Date(endDate);
            d.setDate(d.getDate() + (i + 1));
            blocksTemp.push({
              state: 'disabled',
              date: d,
              bookings: [],
            });
          }
        }
      }
      setBlocks(blocksTemp);
      for(let i = 0; i < blocksTemp.length / 7; i += 1) {
        weeksTemp.push({index: (i + 1)});
      }
      setWeeks(weeksTemp);
    }
  }, [startDate, endDate, dateRangeLoaded, blocks, bookings, refreshCalendar, setRefreshCalendar, focusedOrganisation, setBookings, setProcessing, setBookingReferences, setSearch, search]);
  return (
    <div>
      {blocks?.length > 1 &&
        <div>
          <Hidden breakpoint='hiddenlessthan1024'>
            <div>
              <GridContainer>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{textAlign: 'center', padding: 10, color: paletteData.primary.standard.foreground}}>
                    Sunday
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{textAlign: 'center', padding: 10, color: paletteData.primary.standard.foreground}}>
                    Monday
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{textAlign: 'center', padding: 10, color: paletteData.primary.standard.foreground}}>
                    Tuesday
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{textAlign: 'center', padding: 10, color: paletteData.primary.standard.foreground}}>
                    Wednesday
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{textAlign: 'center', padding: 10, color: paletteData.primary.standard.foreground}}>
                    Thursday
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{textAlign: 'center', padding: 10, color: paletteData.primary.standard.foreground}}>
                    Friday
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{textAlign: 'center', padding: 10, color: paletteData.primary.standard.foreground}}>
                    Saturday
                  </Typography>
                </GridCell>
              </GridContainer>
            </div>
          </Hidden>
          <Hidden breakpoint='hiddengreaterthan1024'>
            <div>
              <GridContainer>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{padding: 10, color: paletteData.primary.standard.foreground}}>
                    Sun
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{padding: 10, color: paletteData.primary.standard.foreground}}>
                    Mon
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{padding: 10, color: paletteData.primary.standard.foreground}}>
                    Tue
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{padding: 10, color: paletteData.primary.standard.foreground}}>
                    Wed
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{padding: 10, color: paletteData.primary.standard.foreground}}>
                    Thur
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{padding: 10, color: paletteData.primary.standard.foreground}}>
                    Fri
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{background: paletteData.primary.standard.background}}>
                  <Typography size='subText' style={{padding: 10, color: paletteData.primary.standard.foreground}}>
                    Sat
                  </Typography>
                </GridCell>
              </GridContainer>
            </div>
          </Hidden>
        </div>
      }
      {blocks?.length === 1 &&
        <div style={{background: paletteData.primary.standard.background}}>
          <Typography size='subText' style={{padding: 10, color: paletteData.primary.standard.foreground}}>
            {convertDateToDay(blocks[0].date)} {blocks[0].date.getDate()}/{blocks[0].date.getMonth() + 1}/{blocks[0].date.getFullYear()}
          </Typography>
        </div>
      }
      {processing &&
        <div>
          <ProgressBar palette='secondary'/>
        </div>
      }
      {blocks?.length > 1 && weeks?.length > 0 && weeks.map((w, wIndex) => (
        <GridContainer key={wIndex} style={{flexWrap: 'nowrap'}}>
          {blocks?.length > 0 && blocks.filter((b, bIndex) => {
            return (bIndex >= wIndex * 7) &&  (bIndex < (wIndex + 1) * 7);
          }).map((b, bIndex) => (
            <GridCell key={bIndex} weight={1} style={{padding: 5, background: b.state === 'disabled' ? '#c6c6c6' : 'white', minHeight: 40}}>
              <Hidden breakpoint='hiddenlessthan1024'>
                <div>
                  {b.state !== 'disabled' &&
                    <div style={{cursor: onClickDay !== undefined ? 'pointer' : ''}} onClick={() => {
                      if(onClickDay !== undefined) {
                        onClickDay(convertDateToText(b.date));
                      }
                    }}>
                      <Typography size='subText' style={{marginBottom: 5}}>
                        {b.date.getDate()}/{b.date.getMonth() + 1}/{b.date.getFullYear()}
                      </Typography>
                    </div>
                  }
                  {b.bookings?.list?.length > 0 && b.bookings.list.filter((a, aIndex) => {
                    if(selectedCalendar === 'all') {
                      return true;
                    } else if(a.bookingCalendarId === selectedCalendar) {
                      return true;
                    }
                    return false;
                  }).map((a, aIndex) => (
                    <div key={aIndex} style={{padding: 5, marginBottom: 5, borderRadius: 2, background: (bookingCalendarReferences[a.bookingCalendarId]?.background || '#bfeb98'), cursor: onClickBooking !== undefined ? 'pointer' : ''}} onClick={() => {
                      if(onClickBooking !== undefined) {
                        onClickBooking(a);
                      }
                    }}>
                      <Typography size='subText' style={{fontWeight: 500, color: (bookingCalendarReferences[a.bookingCalendarId]?.invertText ? 'white' : 'black')}}>
                        {bookingCalendarReferences[a.bookingCalendarId]?.name}
                      </Typography>
                      <Typography  size='subText' style={{color: (bookingCalendarReferences[a.bookingCalendarId]?.invertText ? 'white' : 'black')}}>
                        {times[a.time.start].blockStartHuman} - {bookingTimes[bookingTimes.findIndex((bt, btIndex) => {
                          return parseInt(bt.value) === parseInt(a.time.blocks);
                        })].text}
                      </Typography>
                      <Typography size='subText' style={{color: (bookingCalendarReferences[a.bookingCalendarId]?.invertText ? 'white' : 'black')}}>
                        {a.notes}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Hidden>
              <Hidden breakpoint='hiddengreaterthan1024'>
                <div style={{cursor: onClickDay !== undefined ? 'pointer' : ''}} onClick={() => {
                  if(onClickDay !== undefined) {
                    onClickDay(convertDateToText(b.date));
                  }
                }}>
                  {b.state !== 'disabled' &&
                    <Typography size='subText' style={{marginBottom: 5}}>
                      {b.date.getDate()}/{b.date.getMonth() + 1}
                    </Typography>
                  }
                  {b.bookings?.list?.filter((a, aIndex) => {
                    if(selectedCalendar === 'all') {
                      return true;
                    } else if(a.bookingCalendarId === selectedCalendar) {
                      return true;
                    }
                    return false;
                  })?.length > 0 &&
                    <Typography size='subText' style={{padding: 5, borderRadius: 2, background: '#bfeb98'}}>
                      {b.bookings?.list?.filter((a, aIndex) => {
                        if(selectedCalendar === 'all') {
                          return true;
                        } else if(a.bookingCalendarId === selectedCalendar) {
                          return true;
                        }
                        return false;
                      })?.length} task{b.bookings?.list?.filter((a, aIndex) => {
                        if(selectedCalendar === 'all') {
                          return true;
                        } else if(a.bookingCalendarId === selectedCalendar) {
                          return true;
                        }
                        return false;
                      })?.length > 1 ? 's' : ''}
                    </Typography>
                  }
                </div>
              </Hidden>
            </GridCell>
          ))}
        </GridContainer>
      ))}

      {blocks?.length === 1 && blocks[0]?.bookings?.list?.length > 0 && blocks[0]?.bookings.list.filter((a, aIndex) => {
        if(selectedCalendar === 'all') {
          return true;
        } else if(a.bookingCalendarId === selectedCalendar) {
          return true;
        }
        return false;
      }).map((a, aIndex) => (
        <div key={aIndex} style={{padding: 5, marginTop: 5, borderRadius: 2, background: (bookingCalendarReferences[a.bookingCalendarId]?.background || '#bfeb98'), cursor: onClickBooking !== undefined ? 'pointer' : ''}} onClick={() => {
          if(onClickBooking !== undefined) {
            onClickBooking(a);
          }
        }}>
          <Typography size='subText' style={{fontWeight: 500, color: (bookingCalendarReferences[a.bookingCalendarId]?.invertText ? 'white' : 'black')}}>
            {bookingCalendarReferences[a.bookingCalendarId]?.name}
          </Typography>
          <Typography  size='subText' style={{color: (bookingCalendarReferences[a.bookingCalendarId]?.invertText ? 'white' : 'black')}}>
            {times[a.time.start].blockStartHuman} - {bookingTimes[bookingTimes.findIndex((bt, btIndex) => {
              return parseInt(bt.value) === parseInt(a.time.blocks);
            })].text}
          </Typography>
          <Typography size='subText' style={{color: (bookingCalendarReferences[a.bookingCalendarId]?.invertText ? 'white' : 'black')}}>
            {a.notes}
          </Typography>
        </div>
      ))}
    </div>
  );
}

Calendar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  focusedOrganisation: PropTypes.shape({}),
  selectedCalendar: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,

    bookings: state.lists.bookings,
    bookingReferences: state.references.bookings || {},

    bookingCalendars: state.lists.bookingCalendars,
    bookingCalendarReferences: state.references.bookingCalendars || {},
  };
};

export default connect(mapStateToProps, {
  setBookings,
  setBookingReferences,
  setSearch,
})(Calendar);
