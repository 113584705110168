import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import {connect} from 'react-redux';
import {useLoadScript} from '@react-google-maps/api';
import {Switch, Route, Link} from 'react-router-dom';

import {paletteData, LinkButton, Button, Dialog, Typography, Fab, MenuDropdown, ProgressBar} from '../components/styles';
import {GridContainer, GridCell} from '../components/grid';
import Hidden from '../components/hidden';
import apiRequest from '../tools/apiRequest';
import {setApiToken} from '../store/reducers/auth/actions.js';
import {setProfile} from '../store/reducers/user/actions.js';
import {resetLists, setCategories, setSearch} from '../store/reducers/lists/actions.js';
import {resetReferences, setCategoryReferences} from '../store/reducers/references/actions.js';

import Home from '../pages/public/home';
import Login from '../pages/public/login';
import EmailVerification  from '../pages/public/emailVerification';
import PasswordReset  from '../pages/public/passwordReset';
import NavigationError from '../pages/public/navigationError';

import Console from '../pages/private/console';

const googleMapsLibraries = ['places'];

function Routes({auth, user, setApiToken, setProfile, resetLists, resetReferences, setCategories, setSearch, lists, setCategoryReferences}) {
  const [dialogData, setDialogData] = useState(undefined);
  const [dialogOpen, setDialogOpen] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(false);
  const [me, setMe] = useState({});

  const [initialLoad, setInitialLoad] = useState({
    refresh: false,
    categories: false,
  });
  const [refreshCredentialsLoaded, setRefreshCredentialsLoaded] = useState(false);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  useEffect(() => {
    if(!allLoaded && refreshCredentialsLoaded && categoriesLoaded) {
      setAllLoaded(true);
    }

    if(!initialLoad.categories && lists.categories) {
      setInitialLoad({
        ...initialLoad,
        categories: true,
      });
      setCategoriesLoaded(true);
    } else if(!initialLoad.categories) {
      setInitialLoad({
        ...initialLoad,
        categories: true,
      });

      apiRequest({type: 'get', action: 'public_categories', data: {}})
      .then((result) => {
        setCategoriesLoaded(true);
        setCategories(result?.data?.categories || []);
        setCategoryReferences(result?.data?.categories || []);
        setSearch({name: 'categories', value: {text: '', queryDepth: 1, filter: undefined, hasMore: result.data.hasMore}});
      }).catch((error) => {
        setCategoriesLoaded(true);
        setCategories([]);
        setSearch({name: 'categories', value: {text: '', queryDepth: 1, filter: undefined, hasMore: false}});
      });
    }

    if(!initialLoad.refreshCredentials && auth?.api?.access_token) {
      setInitialLoad({
        ...initialLoad,
        refreshCredentials: true,
      });
      setRefreshCredentialsLoaded(true);
      setMe({
        ...(jwt.decode(auth?.api?.access_token))?.data,
        profile: user.profile,
      });
    } else if(!initialLoad.refreshCredentials) {
      setInitialLoad({
        ...initialLoad,
        refreshCredentials: true,
      });
      apiRequest({type: 'post', action: 'auth/refresh'}).then((refreshResult) => {
        setApiToken(refreshResult.data.auth);
        if(refreshResult.data.auth) {
          const user = (jwt.decode(refreshResult.data.auth?.access_token)).data;
          apiRequest({type: 'get', action: `users/${user._id}/profile`}).then((profileResult) => {
            setRefreshCredentialsLoaded(true);
            setProfile(profileResult.data?.profile || undefined);
            setMe({
              ...(jwt.decode(refreshResult.data.auth?.access_token))?.data,
              profile: profileResult.data?.profile,
            });
          }).catch((error) => {
            setRefreshCredentialsLoaded(true);
            setMe({});
            setApiToken(undefined);
            setProfile(undefined);
          });
        } else {
          setRefreshCredentialsLoaded(true);
          setMe({});
          setApiToken(undefined);
          setProfile(undefined);
        }
      }).catch((error) => {
        setRefreshCredentialsLoaded(true);
        setMe({});
        setApiToken(undefined);
        setProfile(undefined);
      });
    }
  }, [auth, user, setApiToken, setProfile, allLoaded, refreshCredentialsLoaded, initialLoad, lists, setCategories, setSearch, categoriesLoaded, setCategoryReferences]);

  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: googleMapsLibraries,
    nonce: document.querySelector('meta[name="CSP-NONCE"]')?.getAttribute('content'),
  });

  if(loadError) return (<div>Error Loading Page</div>);
  if(!isLoaded) return (<div>Loading</div>);

  const handleLogout = (callback) => {
    apiRequest({type: 'post', action: 'validate/logout'}).then((result) => {
      setApiToken(undefined);
      setProfile(undefined);
      resetLists();
      resetReferences();
      setInitialLoad({
        refresh: false,
      });
      setRefreshCredentialsLoaded(false);
      setAllLoaded(false);
      callback();
    }).catch((error) => {
      callback();
    });
  }

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
        <GridContainer style={{
          height: 63,
          background: paletteData.primary.standard.background,
        }}>
          <GridCell center={true} style={{margin: 5}}>
            <Link to='/'>
              <img
                style={{maxWidth: 45}}
                src='https://res.cloudinary.com/taitokerau-tatou/image/upload/c_scale,q_auto:good,w_300/v1617227279/media-assets/Reretahi-_Logo-V3_-_no_words_koehgh.png'
                alt='reretahi logo'
              />
            </Link>
          </GridCell>
          <GridCell center={true}>
            <Link to='/' style={{textDecoration: 'none'}}>
              <Typography size='title' style={{color: paletteData.primary.standard.foreground, marginLeft: 10, letterSpacing: 2.3}}>
                RERENGĀTAHI
              </Typography>
              <Typography size='subText' style={{color: paletteData.primary.standard.foreground, marginLeft: 10, letterSpacing: 2}}>
                NORTHLAND TOGETHER
              </Typography>
            </Link>
          </GridCell>
          <GridCell weight={1}/>
          <Hidden breakpoint='hiddenlessthan800'>
            <GridCell center={true}>
              <LinkButton to="/" palette='primary'>Home</LinkButton>
            </GridCell>
          </Hidden>

          {!me._id &&
            <Hidden breakpoint='hiddenlessthan800'>
              <GridCell center={true}>
                <LinkButton to="/login" palette='primary'>
                  Login
                  {!refreshCredentialsLoaded && <ProgressBar palette='primary'/>}
                </LinkButton>

              </GridCell>
            </Hidden>
          }
          {me._id &&
            <Hidden breakpoint='hiddenlessthan800'>
              <GridCell center={true}>
                <LinkButton to="/console" palette='primary'>{me?.name || 'Console'}</LinkButton>
              </GridCell>
            </Hidden>
          }
          <Hidden breakpoint='hiddengreaterthan800'>
            <GridCell center={true} style={{padding: 10}}>
              <Fab palette='secondary' size='small' onClick={() => {
                setMenuOpen(!menuOpen);
              }}>
                <span className="material-icons md-24">menu</span>
              </Fab>
              {menuOpen &&
                <MenuDropdown
                  open={menuOpen}
                  handleClose={() => setMenuOpen(false)}
                  position={{right: 10, top: 50}}
                  style={{padding: 10, marginTop: 5, borderRadius: 5}}
                >
                  <div style={{minWidth: 100}}>
                    <LinkButton to="/" onClick={() => setMenuOpen(false)}>Home</LinkButton>
                    {!me._id &&
                      <div>
                        <LinkButton to="/login" onClick={() => setMenuOpen(false)}>Login</LinkButton>
                      </div>
                    }
                    {me._id &&
                      <div>
                        <LinkButton to="/console" onClick={() => setMenuOpen(false)}>Console</LinkButton>
                      </div>
                    }
                    {me.name &&
                      <div>
                        <LinkButton to="/login" onClick={() => {
                          handleLogout();
                          setMenuOpen(false);
                        }}>
                          Log Out
                        </LinkButton>
                      </div>
                    }
                  </div>
                </MenuDropdown>
              }
            </GridCell>
          </Hidden>
        </GridContainer>
        <Switch>
          {/*Public Routes*/}
          <Route exact path="/">
            <Home/>
          </Route>
          <Route exact path="/emailverification/:id">
            <EmailVerification me={me} auth={auth} setMe={setMe}/>
          </Route>
          <Route exact path="/passwordreset/:id">
            <PasswordReset />
          </Route>
          <Route exact path="/login">
            <Login setMe={setMe}/>
          </Route>
          {/*Private Routes*/}
          <Route exact path="/console">
            <Console me={me} auth={auth} handleLogout={handleLogout} setMe={setMe}/>
          </Route>
          {/*Fallback Route*/}
          <Route path="*">
            <NavigationError />
          </Route>
        </Switch>
      </div>
      <div style={{background: paletteData.primary.standard.background, padding: 5, textAlign: 'right'}}>
        <a
          style={{textDecoration: 'none'}}
          href='https://heiwi.co.nz/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
            Copyright He Iwi Kotahi Tatau Trust 2021
          </Typography>
        </a>
        <a
          style={{textDecoration: 'none'}}
          href='mailto:info@rerengatahi.nz'
        >
          <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
            Contact: info@rerengatahi.nz
          </Typography>
        </a>
        <a
          style={{textDecoration: 'none'}}
          href='http://redincmedia.co.nz/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
            Created by Red Inc Transmedia
          </Typography>
        </a>
      </div>


      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog id='logout-dialog' open={dialogOpen} handleClose={() => {
          setDialogOpen(false);
          setDialogData(undefined);
        }}>
          <div style={{padding: 10, background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground, textAlign: 'center'}}>
              {dialogData?.title}
            </Typography>
          </div>
          <div style={{padding: 10}}>
            <Typography>
              {dialogData?.message}
            </Typography>
          </div>
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                Cancel
              </Button>
            </GridCell>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                  handleLogout();
                }}
              >
                Logout
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

Routes.propTypes = {
  auth: PropTypes.shape({}),
  user: PropTypes.shape({}),
  setApiToken: PropTypes.func,
  setProfile: PropTypes.func,
  resetLists: PropTypes.func,
  resetReferences: PropTypes.func,
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {setApiToken, setProfile, resetLists, resetReferences, setCategories, setSearch, setCategoryReferences})(Routes);
