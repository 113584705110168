import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import BookingCalendarForm from '../../../../components/forms/bookingCalendar';
import SearchBookingCalendars from './SearchBookingCalendars';

import {GridContainer, GridCell} from '../../../../components/grid';
import {paletteData, Button, ProgressBar, Dialog, Typography} from '../../../../components/styles';
import {setBookingCalendars, addBookingCalendars, addBookingCalendar, replaceBookingCalendar, removeBookingCalendar, setOrganisations, addOrganisations, setSearch} from '../../../../store/reducers/lists/actions.js';
import {setBookingCalendarReference, clearBookingCalendarReference, setOrganisationReference, setBookingCalendarReferences} from '../../../../store/reducers/references/actions.js';
import apiRequest from '../../../../tools/apiRequest';
import handleErrorMessage from '../../../../tools/handleErrorMessage';

function ManageBookingCalendarsPanel({
  me,
  auth,
  bookingCalendars,
  search,
  setSearch,
  queryLimit,

  setBookingCalendars,
  addBookingCalendars,
  addBookingCalendar,
  replaceBookingCalendar,
  removeBookingCalendar,
  setBookingCalendarReference,
  clearBookingCalendarReference,
  setBookingCalendarReferences,

  organisations,
  organisationReferences,
  setOrganisations,
  setOrganisationReference,
}) {
  // page state
  const [focusedBookingCalendar, setFocusedBookingCalendar] = useState(undefined);
  const [focusedOrganisation, setFocusedOrganisation] = useState(search?.bookingCalendars?.query?.organisationId && organisationReferences[search?.bookingCalendars?.query?.organisationId] ? organisationReferences[search?.bookingCalendars?.query?.organisationId] : undefined);
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleChangesFocusedBookingCalendar = (changes) => {
    const bookingCalendarProcessed = {
      ...focusedBookingCalendar,
      ...changes,
    };
    setFocusedBookingCalendar(bookingCalendarProcessed);
  }

  const handleChangeFocusedBookingCalendar = (name, value) => {
    const bookingCalendarProcessed = {
      ...focusedBookingCalendar,
      [name]: value,
    };
    if(name === 'multipleBookingsPerOrder') {
      bookingCalendarProcessed.multipleBookingsAreConsecutive = false;
    }
    setFocusedBookingCalendar(bookingCalendarProcessed);
  };

  const handleUpdateBookingCalendar = () => {
    setProcessing(true);
    const bookingCalendarId = focusedBookingCalendar._id;
    const updateBookingCalendar = {
      name: focusedBookingCalendar?.name,
      organisationId: focusedBookingCalendar?.organisationId,
      timezone: focusedBookingCalendar?.timezone,
      minimumStartTime: focusedBookingCalendar?.minimumStartTime !== undefined ? parseInt(focusedBookingCalendar.minimumStartTime) : undefined,
      maximumEndTime: focusedBookingCalendar?.maximumEndTime !== undefined ? parseInt(focusedBookingCalendar.maximumEndTime) : undefined,
      minimumBookingTime: focusedBookingCalendar?.minimumBookingTime !== undefined ? parseInt(focusedBookingCalendar.minimumBookingTime) : undefined,
      maximumBookingTime: focusedBookingCalendar?.maximumBookingTime !== undefined ? parseInt(focusedBookingCalendar.maximumBookingTime) : undefined,
      bookingLeadIn: focusedBookingCalendar?.bookingLeadIn !== undefined ? parseInt(focusedBookingCalendar.bookingLeadIn) : undefined,
      bookingLeadOut: focusedBookingCalendar?.bookingLeadOut !== undefined ? parseInt(focusedBookingCalendar.bookingLeadOut) : undefined,
      similtaniousBookings: focusedBookingCalendar?.similtaniousBookings !== undefined ? parseInt(focusedBookingCalendar.similtaniousBookings) : undefined,
      multipleBookingsPerOrder: focusedBookingCalendar?.multipleBookingsPerOrder,
      multipleBookingsAreConsecutive: focusedBookingCalendar?.multipleBookingsAreConsecutive,
      availableDays: focusedBookingCalendar?.availableDays,
      background: focusedBookingCalendar?.background,
      invertText: focusedBookingCalendar?.invertText,
    }
    if(bookingCalendarId === 'new') {
      apiRequest({type: 'post', action: `bookingCalendars/create`, data: {updateBookingCalendar}})
      .then((result) => {
        addBookingCalendar(result.data.bookingCalendar);
        setBookingCalendarReference(result.data.bookingCalendar);

        setProcessing(false);
        setFocusedBookingCalendar(undefined);
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Create booking calendar request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    } else {
      apiRequest({type: 'patch', action: `bookingCalendars/update/${bookingCalendarId}`, data: {updateBookingCalendar}})
      .then((result) => {
        replaceBookingCalendar({bookingCalendar: {_id: bookingCalendarId, ...updateBookingCalendar}});
        setBookingCalendarReference({_id: bookingCalendarId, ...updateBookingCalendar});
        setProcessing(false);
        setFocusedBookingCalendar(undefined);
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Update booking calendar request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    }
  }

  const handleDeleteBookingCalendar = () => {
    setProcessing(true);
    const bookingCalendarId = focusedBookingCalendar._id || 'new';
    apiRequest({type: 'delete', action: `bookingCalendars/delete/${bookingCalendarId}`})
    .then((result) => {
      setProcessing(false);
      removeBookingCalendar({bookingCalendar: focusedBookingCalendar});
      setFocusedBookingCalendar(undefined);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Delete post scheme request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <div style={{color: paletteData.primary.standard.foreground, fontSize: '1.5em'}}>
              Manage Booking Calendars
            </div>
          </GridCell>
          <GridCell center style={{height: 45, display: 'block'}}>
            <Button
              disabled={processing || !focusedOrganisation}
              palette="secondary"
              onClick={() =>{
                if(focusedBookingCalendar) {
                  setFocusedBookingCalendar(undefined);
                } else {
                  setFocusedBookingCalendar({_id: 'new', timezone: 'Pacific/Auckland', similtaniousBookings: 1, bookingLeadIn: 7, organisationId: focusedOrganisation?._id});
                }
              }}
            >
              {focusedBookingCalendar ? 'Cancel' : 'Add'}
            </Button>
          </GridCell>
          <GridCell center style={{marginLeft: 5, height: 45, display: focusedBookingCalendar && focusedBookingCalendar._id !== 'new' ? 'block' : 'none'}}>
            <Button palette='secondary' onClick={() => handleDeleteBookingCalendar()} disabled={processing}>
              Delete Calendar
            </Button>
          </GridCell>
          <GridCell center style={{marginLeft: 5, height: 45, display: focusedBookingCalendar ? 'block' : 'none'}}>
            <Button
              disabled={
                processing ||
                !focusedBookingCalendar?.name ||
                !focusedBookingCalendar?.organisationId ||
                (parseInt(focusedBookingCalendar?.minimumStartTime) > parseInt(focusedBookingCalendar?.maximumEndTime)) ||
                (parseInt(focusedBookingCalendar?.minimumBookingTime) > parseInt(focusedBookingCalendar?.maximumBookingTime))
              }
              palette='secondary'
              onClick={() => handleUpdateBookingCalendar()}
            >
              {focusedBookingCalendar?._id === 'new' ? 'Create' : 'Update'} Calendar
            </Button>
            {processing &&
              <ProgressBar palette='secondary'/>
            }
          </GridCell>
        </GridContainer>
      </div>
      {!focusedBookingCalendar &&
        <SearchBookingCalendars
          search={search}
          setSearch={setSearch}
          queryLimit={queryLimit}
          processing={processing}
          setProcessing={setProcessing}

          bookingCalendars={bookingCalendars}
          addBookingCalendars={addBookingCalendars}
          setBookingCalendars={setBookingCalendars}
          setFocusedBookingCalendar={setFocusedBookingCalendar}
          setBookingCalendarReferences={setBookingCalendarReferences}

          focusedOrganisation={focusedOrganisation}
          organisations={organisations}
          setOrganisations={setOrganisations}
          addOrganisations={addOrganisations}
          organisationReferences={organisationReferences}
          setOrganisationReference={setOrganisationReference}
          setFocusedOrganisation={setFocusedOrganisation}
        />
      }
      {focusedBookingCalendar &&
        <div>
          {processing &&
            <div style={{marginLeft: 5, marginRight: 5}}>
              <ProgressBar palette='secondary'/>
            </div>
          }
          <div>
            <BookingCalendarForm
              focusedOrganisation={focusedOrganisation}
              bookingCalendar={focusedBookingCalendar}
              handleChange={(name, value) => handleChangeFocusedBookingCalendar(name, value)}
              handleChanges={(changes) => handleChangesFocusedBookingCalendar(changes)}
            />
          </div>
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <Typography>
              {dialogData.message}
            </Typography>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ManageBookingCalendarsPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
  bookingCalendars: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = (state) => {
  return {
    bookingCalendars: state.lists.bookingCalendars,

    organisations: state.lists.organisations,
    organisationReferences: state.references.organisations || {},

    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
  };
};

export default connect(mapStateToProps, {setBookingCalendars, addBookingCalendars, addBookingCalendar, replaceBookingCalendar, removeBookingCalendar, setSearch, setOrganisations, setBookingCalendarReference, clearBookingCalendarReference, setOrganisationReference, setBookingCalendarReferences})(ManageBookingCalendarsPanel);
