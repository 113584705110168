import {
  SET_USERS,
  ADD_USERS,
  ADD_USER,
  REPLACE_USER,
  REMOVE_USER,

  SET_AREAS,
  ADD_AREAS,
  ADD_AREA,
  REPLACE_AREA,
  REMOVE_AREA,

  SET_ORGANISATIONS,
  ADD_ORGANISATIONS,
  ADD_ORGANISATION,
  REPLACE_ORGANISATION,
  REMOVE_ORGANISATION,

  SET_CATEGORIES,
  ADD_CATEGORIES,
  ADD_CATEGORY,
  REPLACE_CATEGORY,
  REMOVE_CATEGORY,

  SET_ARTICLES,
  ADD_ARTICLES,
  ADD_ARTICLE,
  REPLACE_ARTICLE,
  REMOVE_ARTICLE,

  SET_EVENTS,
  ADD_EVENTS,
  ADD_EVENT,
  REPLACE_EVENT,
  REMOVE_EVENT,

  SET_PRODUCTS,
  ADD_PRODUCTS,
  ADD_PRODUCT,
  REPLACE_PRODUCT,
  REMOVE_PRODUCT,

  SET_PRODUCTGROUPS,
  ADD_PRODUCTGROUPS,
  ADD_PRODUCTGROUP,
  REPLACE_PRODUCTGROUP,
  REMOVE_PRODUCTGROUP,

  SET_ORDERS,
  ADD_ORDERS,
  ADD_ORDER,
  REPLACE_ORDER,
  REMOVE_ORDER,
  PRUNE_BOOKING_FROM_ORDER,

  SET_REVIEWS,
  ADD_REVIEWS,
  ADD_REVIEW,
  REPLACE_REVIEW,
  REMOVE_REVIEW,

  SET_POST_SCHEMES,
  ADD_POST_SCHEMES,
  ADD_POST_SCHEME,
  REPLACE_POST_SCHEME,
  REMOVE_POST_SCHEME,

  SET_BOOKING_CALENDARS,
  ADD_BOOKING_CALENDARS,
  ADD_BOOKING_CALENDAR,
  REPLACE_BOOKING_CALENDAR,
  REMOVE_BOOKING_CALENDAR,

  SET_BOOKINGS,
  ADD_BOOKING,
  REPLACE_BOOKING,
  REMOVE_BOOKING,
  REMOVE_BOOKINGS,

  SET_COMMUNITIES,
  ADD_COMMUNITY,
  ADD_COMMUNITIES,
  REPLACE_COMMUNITY,
  REMOVE_COMMUNITY,

  SET_POTENTIAL_COMMUNITIES,
  ADD_POTENTIAL_COMMUNITY,
  ADD_POTENTIAL_COMMUNITIES,
  REPLACE_POTENTIAL_COMMUNITY,
  REMOVE_POTENTIAL_COMMUNITY,

  SET_SEARCH,
  RESET_LISTS,
} from "./actionTypes";


//--------------------- users --------------------

export const setUsers = (content) => ({
  type: SET_USERS,
  payload: content
});

export const addUser = (content) => ({
  type: ADD_USER,
  payload: content
});

export const addUsers = (content) => ({
  type: ADD_USERS,
  payload: content
});

export const removeUser = (content) => ({
  type: REMOVE_USER,
  payload: content
});

export const replaceUser = (content) => ({
  type: REPLACE_USER,
  payload: content
});

//--------------------- areas --------------------


export const setAreas = (content) => ({
  type: SET_AREAS,
  payload: content
});

export const addArea = (content) => ({
  type: ADD_AREA,
  payload: content
});

export const addAreas = (content) => ({
  type: ADD_AREAS,
  payload: content
});

//--------------------- organisations --------------------

export const setOrganisations = (content) => ({
  type: SET_ORGANISATIONS,
  payload: content
});

export const addOrganisation = (content) => ({
  type: ADD_ORGANISATION,
  payload: content
});

export const addOrganisations = (content) => ({
  type: ADD_ORGANISATIONS,
  payload: content
});

export const removeOrganisation = (content) => ({
  type: REMOVE_ORGANISATION,
  payload: content
});

export const replaceOrganisation = (content) => ({
  type: REPLACE_ORGANISATION,
  payload: content
});



export const removeArea = (content) => ({
  type: REMOVE_AREA,
  payload: content
});

export const replaceArea = (content) => ({
  type: REPLACE_AREA,
  payload: content
});

//--------------------- categories --------------------

export const setCategories = (content) => ({
  type: SET_CATEGORIES,
  payload: content
});

export const addCategory = (content) => ({
  type: ADD_CATEGORY,
  payload: content
});

export const addCategories = (content) => ({
  type: ADD_CATEGORIES,
  payload: content
});

export const removeCategory = (content) => ({
  type: REMOVE_CATEGORY,
  payload: content
});

export const replaceCategory = (content) => ({
  type: REPLACE_CATEGORY,
  payload: content
});


//--------------------- articles --------------------

export const setArticles = (content) => ({
  type: SET_ARTICLES,
  payload: content
});

export const addArticle = (content) => ({
  type: ADD_ARTICLE,
  payload: content
});

export const addArticles = (content) => ({
  type: ADD_ARTICLES,
  payload: content
});

export const removeArticle = (content) => ({
  type: REMOVE_ARTICLE,
  payload: content
});

export const replaceArticle = (content) => ({
  type: REPLACE_ARTICLE,
  payload: content
});



export const setEvents = (content) => ({
  type: SET_EVENTS,
  payload: content
});

export const addEvent = (content) => ({
  type: ADD_EVENT,
  payload: content
});

export const addEvents = (content) => ({
  type: ADD_EVENTS,
  payload: content
});

export const removeEvent = (content) => ({
  type: REMOVE_EVENT,
  payload: content
});

export const replaceEvent = (content) => ({
  type: REPLACE_EVENT,
  payload: content
});




export const setProducts = (content) => ({
  type: SET_PRODUCTS,
  payload: content
});

export const addProduct = (content) => ({
  type: ADD_PRODUCT,
  payload: content
});

export const addProducts = (content) => ({
  type: ADD_PRODUCTS,
  payload: content
});

export const removeProduct = (content) => ({
  type: REMOVE_PRODUCT,
  payload: content
});

export const replaceProduct = (content) => ({
  type: REPLACE_PRODUCT,
  payload: content
});



export const setProductGroups = (content) => ({
  type: SET_PRODUCTGROUPS,
  payload: content
});

export const addProductGroup = (content) => ({
  type: ADD_PRODUCTGROUP,
  payload: content
});

export const addProductGroups = (content) => ({
  type: ADD_PRODUCTGROUPS,
  payload: content
});

export const removeProductGroup = (content) => ({
  type: REMOVE_PRODUCTGROUP,
  payload: content
});

export const replaceProductGroup = (content) => ({
  type: REPLACE_PRODUCTGROUP,
  payload: content
});



export const setOrders = (content) => ({
  type: SET_ORDERS,
  payload: content
});

export const addOrder = (content) => ({
  type: ADD_ORDER,
  payload: content
});

export const addOrders = (content) => ({
  type: ADD_ORDERS,
  payload: content
});

export const removeOrder = (content) => ({
  type: REMOVE_ORDER,
  payload: content
});

export const replaceOrder = (content) => ({
  type: REPLACE_ORDER,
  payload: content
});

export const pruneBookingFromOrder = (content) => ({
  type: PRUNE_BOOKING_FROM_ORDER,
  payload: content
});

export const setReviews = (content) => ({
  type: SET_REVIEWS,
  payload: content
});

export const addReview = (content) => ({
  type: ADD_REVIEW,
  payload: content
});

export const addReviews = (content) => ({
  type: ADD_REVIEWS,
  payload: content
});

export const removeReview = (content) => ({
  type: REMOVE_REVIEW,
  payload: content
});

export const replaceReview = (content) => ({
  type: REPLACE_REVIEW,
  payload: content
});



export const setPostSchemes = (content) => ({
  type: SET_POST_SCHEMES,
  payload: content
});

export const addPostScheme = (content) => ({
  type: ADD_POST_SCHEME,
  payload: content
});

export const addPostSchemes = (content) => ({
  type: ADD_POST_SCHEMES,
  payload: content
});

export const removePostScheme = (content) => ({
  type: REMOVE_POST_SCHEME,
  payload: content
});

export const replacePostScheme = (content) => ({
  type: REPLACE_POST_SCHEME,
  payload: content
});



export const setBookingCalendars = (content) => ({
  type: SET_BOOKING_CALENDARS,
  payload: content
});

export const addBookingCalendar = (content) => ({
  type: ADD_BOOKING_CALENDAR,
  payload: content
});

export const addBookingCalendars = (content) => ({
  type: ADD_BOOKING_CALENDARS,
  payload: content
});

export const removeBookingCalendar = (content) => ({
  type: REMOVE_BOOKING_CALENDAR,
  payload: content
});

export const replaceBookingCalendar = (content) => ({
  type: REPLACE_BOOKING_CALENDAR,
  payload: content
});



export const setBookings = (content) => ({
  type: SET_BOOKINGS,
  payload: content
});

export const addBooking = (content) => ({
  type: ADD_BOOKING,
  payload: content
});

export const removeBooking = (content) => ({
  type: REMOVE_BOOKING,
  payload: content
});

export const removeBookings = (content) => ({
  type: REMOVE_BOOKINGS,
  payload: content
});

export const replaceBooking = (content) => ({
  type: REPLACE_BOOKING,
  payload: content
});

//--------------------- communities --------------------

export const setCommunities = (content) => ({
  type: SET_COMMUNITIES,
  payload: content
});

export const addCommunity = (content) => ({
  type: ADD_COMMUNITY,
  payload: content
});

export const addCommunities = (content) => ({
  type: ADD_COMMUNITIES,
  payload: content
});

export const removeCommunity = (content) => ({
  type: REMOVE_COMMUNITY,
  payload: content
});

export const replaceCommunity = (content) => ({
  type: REPLACE_COMMUNITY,
  payload: content
});

//--------------------- potential communities --------------------

export const setPotentialCommunities = (content) => ({
  type: SET_POTENTIAL_COMMUNITIES,
  payload: content
});

export const addPotentialCommunity = (content) => ({
  type: ADD_POTENTIAL_COMMUNITY,
  payload: content
});

export const addPotentialCommunities = (content) => ({
  type: ADD_POTENTIAL_COMMUNITIES,
  payload: content
});

export const removePotentialCommunity = (content) => ({
  type: REMOVE_POTENTIAL_COMMUNITY,
  payload: content
});

export const replacePotentialCommunity = (content) => ({
  type: REPLACE_POTENTIAL_COMMUNITY,
  payload: content
});


export const setSearch = (content) => ({
  type: SET_SEARCH,
  payload: content,
});
export const resetLists = () => ({
  type: RESET_LISTS,
});
