import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { TextAreaRich, Typography, Fab, Slider } from '../../../styles';
import { GridContainer, GridCell } from '../../../grid';

function RichTextBlockEdit({
  block,
  removeBlock,
  blockIndex,
  maxIndex,
  changeBlockIndex,
  changeSection,
  stripImageURL,
  controls,

  setDialogData,
  setDialogOpen
}) {
  const [imageWidth, setImageWidth] = useState(block.children.length === 2 ? parseInt(block.children[0].options.style.width.replace('%', '')) : undefined)
  
  return (
    <div style={{ position: 'relative' }}>
      <GridContainer style={{ margin: 10 }}>
        <GridCell weight={1} center={true}>
          <Typography size='title'>
            Text Block Editor
          </Typography>
        </GridCell>
        <GridCell
          style={{
            minWidth: 20,
            padding: 5,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 5,
            background: 'white',
          }}
          center={true}
        >
          <GridContainer>
            <GridCell>
              <Fab
                size='small'
                href='https://en.wikipedia.org/wiki/Markdown'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className="material-icons md-24">info</span>
              </Fab>
            </GridCell>
            {controls !== 'grid' &&
              <GridCell center={true}>
                <Fab
                  size='small'
                  onClick={() => {
                    const { imageId, imageOptions } = stripImageURL(block.children.length === 2 ? block.children[0].children[0].options.src : undefined);
                    const alt = block.children.length === 2 ? block.children[0].children[0].options.alt : undefined;
                    setDialogData({
                      type: 'selectImage',
                      title: 'Add Image',
                      data: {
                        callback: (value) => {
                          if (value.imageId) {
                            setImageWidth(60);
                            const childrenProcessed = block.children;
                            const child = {
                              section: 'imageBlock',
                              type: 'div',
                              options: {
                                style: {
                                  float: 'right',
                                  width: '60%',
                                  paddingLeft: 10,
                                  paddingBottom: 10,
                                  marginTop: '1em',
                                  zIndex: 10,
                                },
                                description: '',
                              },
                              children: [
                                {
                                  type: 'Image',
                                  options: {
                                    src: `https://res.cloudinary.com/taitokerau-tatou/image/upload/${value.imageOptions || 'q_auto:good,c_fill,w_1024'}/${value.imageId}`,
                                    alt: value.alt,
                                    style: {
                                      width: '100%',
                                    },
                                  },
                                },
                              ],
                            }
                            if (childrenProcessed.length === 1) {
                              childrenProcessed.splice(0, 0, child);
                            } else if (childrenProcessed.length === 2) {
                              childrenProcessed.splice(0, 1, child);
                            }
                            changeSection('update', blockIndex, 'children', childrenProcessed);
                          } else if (block.children.length === 2) {
                            const childrenProcessed = block.children;
                            childrenProcessed.splice(0, 1);
                            changeSection('update', blockIndex, 'children', childrenProcessed);
                          }
                        }
                      },
                      value: {
                        search: '',
                        imageId,
                        imageOptions,
                        alt,
                      },
                    });
                    setDialogOpen(true);
                  }}
                >
                  <span className="material-icons md-24">photo</span>
                </Fab>
              </GridCell>
            }
            <GridCell>
              <Fab
                size='small'
                style={{ background: '#c6c6c6' }}
                onClick={() => {
                  setDialogData({
                    type: 'moveSection',
                    title: 'Move Section',
                    data: {
                      maxIndex,
                      currentIndex: blockIndex + 1,
                      callbackMove: (shift) => changeBlockIndex(shift),
                      callbackRemove: () => removeBlock(blockIndex),
                    },
                    value: {
                      index: blockIndex + 1,
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                <Typography style={{ textAlign: 'center' }}>
                  {blockIndex + 1}
                </Typography>
              </Fab>
            </GridCell>
          </GridContainer>
        </GridCell>
      </GridContainer>
      {block.children.length === 2 &&
        <div style={{
          ...block.children[0].options.style,
          position: 'relative',
          marginTop: 0,
        }}>
          <div style={{
            position: 'absolute',
            bottom: 30,
            right: 10,
            minWidth: 20,
            padding: 5,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 5,
            background: 'white',
          }}>
            <Slider
              max={80}
              min={20}
              value={imageWidth || 0}
              onChange={(value) => {
                setImageWidth(value);
                changeSection('update', blockIndex, 'children.0.options.style.width', `${value}%`);
              }}
            />
          </div>
          <img
            src={block.children[0].children[0].options.src}
            alt={block.children[0].children[0].options.alt}
            style={{
              ...block.children[0].children[0].options.style,
            }}
          />
        </div>
      }
      <TextAreaRich
        value={block.children[block.children.length - 1].markdown || ''}
        onChange={(value) => {
          changeSection('update', blockIndex, `children.${block.children.length - 1}.markdown`, value);
        }}
      />
      <div style={{clear: 'both'}}/>
    </div>
  );
}

RichTextBlockEdit.propTypes = {
  block: PropTypes.shape({}),
  changeSection: PropTypes.func,
  changeParent: PropTypes.func,
};

export default RichTextBlockEdit;