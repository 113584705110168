import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Typography, Fab, ProgressBar} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

function ImageBlockEdit({
  block,
  removeBlock,
  blockIndex,
  maxIndex,
  changeSection,
  changeParent,
  changeBlockIndex,
  stripImageURL,
  stripImageOptions,

  dialogData,
  dialogOpen,
  setDialogData,
  setDialogOpen
}) {
  const [processing, setProcessing] = useState(false);
  return (
    <div style={{position: 'relative'}}>
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          minWidth: 20,
          padding: 5,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 5,
          background: 'white',
          zIndex: 1,
        }}
      >
        <GridContainer>
          <GridCell center={true}>
            <Fab
              size='small'
              onClick={() => {
                const {imageId, imageOptions} = stripImageURL(block.children[0].options.src);
                const alt = block.children[0].options.alt || undefined;
                setDialogData({
                  type: 'selectImage',
                  title: 'Add Image',
                  data: {
                    callback: (value) => {
                      const valueProcessed = value || {};
                      if(!value?.imageId) {
                        valueProcessed.imageId = 'media-assets/default_e2qiho.jpg';
                        valueProcessed.imageOptions = 'q_auto:good,c_fill,w_2048,h_1200';
                      }
                      const imageURL = `https://res.cloudinary.com/taitokerau-tatou/image/upload/${valueProcessed.imageOptions}/${valueProcessed.imageId}`;
                      const optionsProcessed = {
                        ...(block.children[0].options || {}),
                        src: imageURL,
                        alt,
                      }
                      changeSection('update', blockIndex, 'children.0.options', optionsProcessed);
                      setProcessing(true);
                    }
                  },
                  value: {
                    search: '',
                    imageId,
                    imageOptions,
                    alt,
                  },
                });
                setDialogOpen(true);
              }}
            >
              <span className="material-icons md-24">photo</span>
            </Fab>
          </GridCell>
          <GridCell center={true}>
            <Fab
              size='small'
              onClick={() => {
                const {imageId, imageOptions} = stripImageURL(block.children[0].options.src);
                const {width, height} = stripImageOptions(imageOptions);
                const alt = block.children[0].options.alt || undefined;
                setDialogData({
                  type: 'resizeImage',
                  title: 'Resize Image',
                  data: {
                    callback: (value) => {
                      const imageOptionsProcessed = `q_auto:good,c_fill,w_${value.width},h_${value.height}`;
                      const imageURL = `https://res.cloudinary.com/taitokerau-tatou/image/upload/${imageOptionsProcessed}/${value.imageId}`;
                      const optionsProcessed = {
                        ...(block.children[0].options || {}),
                        src: imageURL,
                        alt,
                      }
                      changeSection('update', blockIndex, 'children.0.options', optionsProcessed);
                      setProcessing(true);
                    }
                  },
                  value: {
                    search: '',
                    width,
                    height,
                    imageId,
                    imageOptions,
                    alt,
                  },
                });
                setDialogOpen(true);
              }}
            >
              <span className="material-icons md-24">aspect_ratio</span>
            </Fab>
          </GridCell>
          <GridCell center={true}>
            <Fab
              size='small'
              style={{background: '#c6c6c6', cursor: 'pointer'}}
              onClick={() => {
                setDialogData({
                  type: 'moveSection',
                  title: 'Move Section',
                  data: {
                    maxIndex,
                    currentIndex: blockIndex + 1,
                    callbackMove: (shift) => changeBlockIndex(shift),
                    callbackRemove: () => removeBlock(blockIndex),
                  },
                  value: {
                    index: blockIndex + 1,
                  },
                });
                setDialogOpen(true);
              }}
            >
              <Typography style={{textAlign: 'center'}}>
                {blockIndex + 1}
              </Typography>
            </Fab>
          </GridCell>
        </GridContainer>
      </div>
      <div style={{position: 'relative'}}>
        {processing &&
          <div style={{position: 'absolute', width: '100%', height: '100%'}}>
            <GridContainer style={{height: '100%', width: '100%'}}>
              <GridCell weight={1}/>
              <GridCell center={true}>
                <div
                  style={{
                    textAlign: 'center',
                    background: 'white',
                    padding: 20,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 5,
                  }}
                >
                  <Typography size='title'>
                    ...Loading
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              </GridCell>
              <GridCell weight={1}/>
            </GridContainer>
          </div>
        }
        <div style={block.options?.style}>
          <img
            id={`${blockIndex}.children.0.options.src`}
            src={block.children[0]?.options?.src}
            alt={block.children[0]?.options?.alt}
            style={block.children[0]?.options?.style}
            onLoad={() => {
              setProcessing(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

ImageBlockEdit.propTypes = {
  block: PropTypes.shape({}),
  changeSection: PropTypes.func,
  changeParent: PropTypes.func,
};

export default ImageBlockEdit;
