import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {GridContainer, GridCell} from '../../../grid';
import {Typography} from '../../../styles';

function CheckBox({type, label, name, value, onChange, labelStyle, boxStyle, disabled}) {
  const [checkBoxValue, setCheckBoxValue] = useState(value);

  return (
    <GridContainer>
      <GridCell center>
        <input
          type="checkbox"
          style={{
            padding: 5,
            ...boxStyle,
          }}
          name={name}
          disabled={disabled || false}
          checked={checkBoxValue}
          onChange={(e) => {
            setCheckBoxValue(e.target.checked);
            if(onChange) {
              onChange(e.target.checked);
            }
          }}
        />
      </GridCell>
      <GridCell>
        <Typography style={{
          padding: 5,
          ...labelStyle,
        }}>
          {label}
        </Typography>
      </GridCell>
    </GridContainer>
  );
}

CheckBox.propTypes = {
  style: PropTypes.shape({}),
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckBox;
