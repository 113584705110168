import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import apiRequest from '../../../tools/apiRequest';
import { Input, Button, ProgressBar, CheckBox } from '../../styles';
import { GridContainer, GridCell } from '../../grid';

function OrganisationForm({ organisation, userReferences, handleChange, processing, me, setUserReference }) {
  const [ownerLoaded, setOwnerLoaded] = useState(false);
  const [ownerLoadComplete, setOwnerLoadComplete] = useState(false);
  useEffect(() => {
    if (!ownerLoaded && organisation?.owner && userReferences[organisation?.owner]) {
      setOwnerLoaded(true);
      setOwnerLoadComplete(true);
    } else if (!ownerLoaded) {
      setOwnerLoaded(true);
      apiRequest({ type: 'get', action: `users/${organisation?.owner}` })
        .then((result) => {
          setUserReference(result.data.user);
          setOwnerLoadComplete(true);
        }).catch((error) => {
          setOwnerLoadComplete(true);
        });
    }
  }, [organisation, ownerLoaded, setUserReference, userReferences]);

  return (
    <div>
      <GridContainer>
        <GridCell style={{ margin: 5, width: 180, textAlign: 'center' }}>
          <div>
            <div style={{ marginBottom: 10, textAlign: 'center' }}>
              <Button palette='primary' onClick={() => handleChange('logo', organisation?.logo)} disabled={processing}>
                Change Logo
              </Button>
            </div>
            {!organisation.logo?.imageId &&
              <GridContainer style={{ height: 180, width: 180 }}>
                <GridCell center={true} weight={1} style={{ background: '#c6c6c6', textAlign: 'center', borderRadius: 5 }}>
                  No Image
                </GridCell>
              </GridContainer>
            }
            {organisation.logo?.imageId &&
              <GridContainer>
                <GridCell center={true} weight={1} style={{ minHeight: 180 }}>
                  <img
                    src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fit,q_auto:good,w_400,h_400/${organisation.logo?.imageId}`}
                    alt={organisation?.logo?.altText}
                    style={{ width: 180 }}
                  />
                </GridCell>
              </GridContainer>
            }
          </div>
          {(processing) &&
            <ProgressBar palette='secondary' />
          }
        </GridCell>
        <GridCell weight={1} style={{ minWidth: 400 }}>
          {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
            <GridContainer>
              <GridCell weight={1} style={{ minWidth: 200 }}>
                <Input
                  disabled={true}
                  forceFocus={true}
                  name="owner"
                  palette='secondary'
                  label="Organisation Listing Manager"
                  type="text"
                  value={!ownerLoadComplete ? '...loading' : userReferences[organisation?.owner]?.name || 'No listing manager'}
                />
              </GridCell>
              <GridCell style={{ marginTop: 7, marginLeft: 5 }}>
                <Button palette='primary' onClick={() => handleChange('owner', undefined)}>
                  Change Listing Manager
                </Button>
              </GridCell>
            </GridContainer>
          }
          <GridContainer>
            <GridCell weight={1}>
              <Input
                name="name"
                palette='secondary'
                label="Name - safe search"
                type="text"
                value={organisation?.name || ''}
                onChange={(value) => handleChange('name', value)}
              />
            </GridCell>
            <GridCell weight={1}>
              <Input
                name="name"
                palette='secondary'
                label="Name - proper"
                type="text"
                value={organisation?.nameProper || ''}
                onChange={(value) => handleChange('nameProper', value)}
              />
            </GridCell>
          </GridContainer>

          <Input
            name="website"
            palette='secondary'
            label="Website"
            type="text"
            value={organisation?.website || ''}
            onChange={(value) => handleChange('website', value)}
          />
          <Input
            name="email"
            palette='secondary'
            label="Primary Email"
            type="text"
            value={organisation?.email || ''}
            onChange={(value) => handleChange('email', value)}
          />
          <Input
            name="phone"
            palette='secondary'
            label="Primary Phone"
            type="text"
            value={organisation?.phone || ''}
            onChange={(value) => handleChange('phone', value)}
          />
          <Input
            name="gst"
            palette='secondary'
            label="GST Number"
            type="text"
            value={organisation?.gst || ''}
            onChange={(value) => handleChange('gst', value)}
          />
          <GridContainer>
            <GridCell weight={1}>
              <Input
                disabled={(organisation?.bank && organisation?.bank.substring(0, 11) === 'hidden-****')}
                name="bank"
                palette='secondary'
                label="Bank Account"
                type="text"
                value={(organisation?.bank && organisation?.bank.substring(0, 11) === 'hidden-****') ? (organisation?.bank.substring(7)) : (organisation?.bank || '')}
                onChange={(value) => handleChange('bank', value)}
              />
            </GridCell>
            {(organisation?.bank && organisation?.bank.substring(0, 11) === 'hidden-****') &&
              <GridCell style={{ marginTop: 7 }}>
                <Button palette='primary' onClick={() => handleChange('bank', '')}>
                  Update
                </Button>
              </GridCell>
            }
          </GridContainer>
          {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
            <GridContainer>
              <GridCell weight={1}>
                <Input
                  disabled={(organisation?.stripe && organisation?.stripe.substring(0, 11) === 'hidden-****')}
                  name="stripe"
                  palette='secondary'
                  label="Stripe ID"
                  type="text"
                  value={(organisation?.stripe && organisation?.stripe.substring(0, 11) === 'hidden-****') ? (organisation?.stripe.substring(7)) : (organisation?.stripe || '')}
                  onChange={(value) => handleChange('stripe', value)}
                />
              </GridCell>
              {(organisation?.stripe && organisation?.stripe.substring(0, 11) === 'hidden-****') &&
                <GridCell style={{ marginTop: 7 }}>
                  <Button palette='primary' onClick={() => handleChange('stripe', '')}>
                    Update
                  </Button>
                </GridCell>
              }
            </GridContainer>
          }
          {organisation?.stripe && organisation?.bank &&
            <CheckBox
              label='Enable Shop'
              value={organisation?.shopEnabled}
              onChange={(value) => {
                handleChange('shopEnabled', value);
              }}
            />
          }
        </GridCell>
      </GridContainer>
    </div>
  );
}

OrganisationForm.propTypes = {
  organisation: PropTypes.shape({}),
  userReferences: PropTypes.shape({}),
  handleChange: PropTypes.func,
  processing: PropTypes.bool,
  me: PropTypes.shape({}),
}

export default OrganisationForm;
