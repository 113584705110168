const times = [
  {value: 0, blockStart: '00:00', blockEnd: '00:15', blockStartHuman: 'Midnight', blockEndHuman: '12:15 am'},
  {value: 1, blockStart: '00:15', blockEnd: '00:30', blockStartHuman: '12:15 am', blockEndHuman: '12:30 am'},
  {value: 2, blockStart: '00:30', blockEnd: '00:45', blockStartHuman: '12:30 am', blockEndHuman: '12:45 am'},
  {value: 3, blockStart: '00:45', blockEnd: '01:00', blockStartHuman: '12:45 am', blockEndHuman: '01:00 am'},

  {value: 4, blockStart: '01:00', blockEnd: '01:15', blockStartHuman: '1:00 am', blockEndHuman: '1:15 am'},
  {value: 5, blockStart: '01:15', blockEnd: '01:30', blockStartHuman: '1:15 am', blockEndHuman: '1:30 am'},
  {value: 6, blockStart: '01:30', blockEnd: '01:45', blockStartHuman: '1:30 am', blockEndHuman: '1:45 am'},
  {value: 7, blockStart: '01:45', blockEnd: '02:00', blockStartHuman: '1:45 am', blockEndHuman: '2:00 am'},

  {value: 8, blockStart: '02:00', blockEnd: '02:15', blockStartHuman: '2:00 am', blockEndHuman: '2:15 am'},
  {value: 9, blockStart: '02:15', blockEnd: '02:30', blockStartHuman: '2:15 am', blockEndHuman: '2:30 am'},
  {value: 10, blockStart: '02:30', blockEnd: '02:45', blockStartHuman: '2:30 am', blockEndHuman: '2:45 am'},
  {value: 11, blockStart: '02:45', blockEnd: '03:00', blockStartHuman: '2:45 am', blockEndHuman: '3:00 am'},

  {value: 12, blockStart: '03:00', blockEnd: '03:15', blockStartHuman: '3:00 am', blockEndHuman: '3:15 am'},
  {value: 13, blockStart: '03:15', blockEnd: '03:30', blockStartHuman: '3:15 am', blockEndHuman: '3:30 am'},
  {value: 14, blockStart: '03:30', blockEnd: '03:45', blockStartHuman: '3:30 am', blockEndHuman: '3:45 am'},
  {value: 15, blockStart: '03:45', blockEnd: '04:00', blockStartHuman: '3:45 am', blockEndHuman: '4:00 am'},

  {value: 16, blockStart: '04:00', blockEnd: '04:15', blockStartHuman: '4:00 am', blockEndHuman: '4:15 am'},
  {value: 17, blockStart: '04:15', blockEnd: '04:30', blockStartHuman: '4:15 am', blockEndHuman: '4:30 am'},
  {value: 18, blockStart: '04:30', blockEnd: '04:45', blockStartHuman: '4:30 am', blockEndHuman: '4:45 am'},
  {value: 19, blockStart: '04:45', blockEnd: '05:00', blockStartHuman: '4:45 am', blockEndHuman: '5:00 am'},

  {value: 20, blockStart: '05:00', blockEnd: '05:15', blockStartHuman: '5:00 am', blockEndHuman: '5:15 am'},
  {value: 21, blockStart: '05:15', blockEnd: '05:30', blockStartHuman: '5:15 am', blockEndHuman: '5:30 am'},
  {value: 22, blockStart: '05:30', blockEnd: '05:45', blockStartHuman: '5:30 am', blockEndHuman: '5:45 am'},
  {value: 23, blockStart: '05:45', blockEnd: '06:00', blockStartHuman: '5:45 am', blockEndHuman: '6:00 am'},

  {value: 24, blockStart: '06:00', blockEnd: '06:15', blockStartHuman: '6:00 am', blockEndHuman: '6:15 am'},
  {value: 25, blockStart: '06:15', blockEnd: '06:30', blockStartHuman: '6:15 am', blockEndHuman: '06:30 am'},
  {value: 26, blockStart: '06:30', blockEnd: '06:45', blockStartHuman: '6:30 am', blockEndHuman: '6:45 am'},
  {value: 27, blockStart: '06:45', blockEnd: '07:00', blockStartHuman: '6:45 am', blockEndHuman: '7:00 am'},

  {value: 28, blockStart: '07:00', blockEnd: '07:15', blockStartHuman: '7:00 am', blockEndHuman: '7:15 am'},
  {value: 29, blockStart: '07:15', blockEnd: '07:30', blockStartHuman: '7:15 am', blockEndHuman: '7:30 am'},
  {value: 30, blockStart: '07:30', blockEnd: '07:45', blockStartHuman: '7:30 am', blockEndHuman: '7:45 am'},
  {value: 31, blockStart: '07:45', blockEnd: '08:00', blockStartHuman: '7:45 am', blockEndHuman: '8:00 am'},

  {value: 32, blockStart: '08:00', blockEnd: '08:15', blockStartHuman: '8:00 am', blockEndHuman: '8:15 am'},
  {value: 33, blockStart: '08:15', blockEnd: '08:30', blockStartHuman: '8:15 am', blockEndHuman: '8:30 am'},
  {value: 34, blockStart: '08:30', blockEnd: '08:45', blockStartHuman: '8:30 am', blockEndHuman: '8:45 am'},
  {value: 35, blockStart: '08:45', blockEnd: '09:00', blockStartHuman: '8:45 am', blockEndHuman: '9:00 am'},

  {value: 36, blockStart: '09:00', blockEnd: '09:15', blockStartHuman: '9:00 am', blockEndHuman: '9:15 am'},
  {value: 37, blockStart: '09:15', blockEnd: '09:30', blockStartHuman: '9:15 am', blockEndHuman: '9:30 am'},
  {value: 38, blockStart: '09:30', blockEnd: '09:45', blockStartHuman: '9:30 am', blockEndHuman: '9:45 am'},
  {value: 39, blockStart: '09:45', blockEnd: '10:00', blockStartHuman: '9:45 am', blockEndHuman: '10:00 am'},

  {value: 40, blockStart: '10:00', blockEnd: '10:15', blockStartHuman: '10:00 am', blockEndHuman: '10:15 am'},
  {value: 41, blockStart: '10:15', blockEnd: '10:30', blockStartHuman: '10:15 am', blockEndHuman: '10:30 am'},
  {value: 42, blockStart: '10:30', blockEnd: '10:45', blockStartHuman: '10:30 am', blockEndHuman: '10:45 am'},
  {value: 43, blockStart: '10:45', blockEnd: '11:00', blockStartHuman: '10:45 am', blockEndHuman: '11:00 am'},

  {value: 44, blockStart: '11:00', blockEnd: '11:15', blockStartHuman: '11:00 am', blockEndHuman: '11:15 am'},
  {value: 45, blockStart: '11:15', blockEnd: '11:30', blockStartHuman: '11:15 am', blockEndHuman: '11:30 am'},
  {value: 46, blockStart: '11:30', blockEnd: '11:45', blockStartHuman: '11:30 am', blockEndHuman: '11:45 am'},
  {value: 47, blockStart: '11:45', blockEnd: '12:00', blockStartHuman: '11:45 am', blockEndHuman: '12:00 am'},

  {value: 48, blockStart: '12:00', blockEnd: '12:15', blockStartHuman: 'Midday', blockEndHuman: '12:15 pm'},
  {value: 49, blockStart: '12:15', blockEnd: '12:30', blockStartHuman: '12:15 pm', blockEndHuman: '12:30 pm'},
  {value: 50, blockStart: '12:30', blockEnd: '12:45', blockStartHuman: '12:30 pm', blockEndHuman: '12:45 pm'},
  {value: 51, blockStart: '12:45', blockEnd: '13:00', blockStartHuman: '12:45 pm', blockEndHuman: '1:00 pm'},

  {value: 52, blockStart: '13:00', blockEnd: '13:15', blockStartHuman: '1:00 pm', blockEndHuman: '1:15 pm'},
  {value: 53, blockStart: '13:15', blockEnd: '13:30', blockStartHuman: '1:15 pm', blockEndHuman: '1:30 pm'},
  {value: 54, blockStart: '13:30', blockEnd: '13:45', blockStartHuman: '1:30 pm', blockEndHuman: '1:45 pm'},
  {value: 55, blockStart: '13:45', blockEnd: '14:00', blockStartHuman: '1:45 pm', blockEndHuman: '2:00 pm'},

  {value: 56, blockStart: '14:00', blockEnd: '14:15', blockStartHuman: '2:00 pm', blockEndHuman: '2:15 pm'},
  {value: 57, blockStart: '14:15', blockEnd: '14:30', blockStartHuman: '2:15 pm', blockEndHuman: '2:30 pm'},
  {value: 58, blockStart: '14:30', blockEnd: '14:45', blockStartHuman: '2:30 pm', blockEndHuman: '2:45 pm'},
  {value: 59, blockStart: '14:45', blockEnd: '15:00', blockStartHuman: '2:45 pm', blockEndHuman: '3:00 pm'},

  {value: 60, blockStart: '15:00', blockEnd: '15:15', blockStartHuman: '3:00 pm', blockEndHuman: '3:15 pm'},
  {value: 61, blockStart: '15:15', blockEnd: '15:30', blockStartHuman: '3:15 pm', blockEndHuman: '3:30 pm'},
  {value: 62, blockStart: '15:30', blockEnd: '15:45', blockStartHuman: '3:30 pm', blockEndHuman: '3:45 pm'},
  {value: 63, blockStart: '15:45', blockEnd: '16:00', blockStartHuman: '3:45 pm', blockEndHuman: '4:00 pm'},

  {value: 64, blockStart: '16:00', blockEnd: '16:15', blockStartHuman: '4:00 pm', blockEndHuman: '4:15 pm'},
  {value: 65, blockStart: '16:15', blockEnd: '16:30', blockStartHuman: '4:15 pm', blockEndHuman: '4:30 pm'},
  {value: 66, blockStart: '16:30', blockEnd: '16:45', blockStartHuman: '4:30 pm', blockEndHuman: '4:45 pm'},
  {value: 67, blockStart: '16:45', blockEnd: '17:00', blockStartHuman: '4:45 pm', blockEndHuman: '5:00 pm'},

  {value: 68, blockStart: '17:00', blockEnd: '17:15', blockStartHuman: '5:00 pm', blockEndHuman: '5:15 pm'},
  {value: 69, blockStart: '17:15', blockEnd: '17:30', blockStartHuman: '5:15 pm', blockEndHuman: '5:30 pm'},
  {value: 70, blockStart: '17:30', blockEnd: '17:45', blockStartHuman: '5:30 pm', blockEndHuman: '5:45 pm'},
  {value: 71, blockStart: '17:45', blockEnd: '18:00', blockStartHuman: '5:45 pm', blockEndHuman: '6:00 pm'},

  {value: 72, blockStart: '18:00', blockEnd: '18:15', blockStartHuman: '6:00 pm', blockEndHuman: '6:15 pm'},
  {value: 73, blockStart: '18:15', blockEnd: '18:30', blockStartHuman: '6:15 pm', blockEndHuman: '6:30 pm'},
  {value: 74, blockStart: '18:30', blockEnd: '18:45', blockStartHuman: '6:30 pm', blockEndHuman: '6:45 pm'},
  {value: 75, blockStart: '18:45', blockEnd: '19:00', blockStartHuman: '6:45 pm', blockEndHuman: '7:00 pm'},

  {value: 76, blockStart: '19:00', blockEnd: '19:15', blockStartHuman: '7:00 pm', blockEndHuman: '7:15 pm'},
  {value: 77, blockStart: '19:15', blockEnd: '19:30', blockStartHuman: '7:15 pm', blockEndHuman: '7:30 pm'},
  {value: 78, blockStart: '19:30', blockEnd: '19:45', blockStartHuman: '7:30 pm', blockEndHuman: '7:45 pm'},
  {value: 79, blockStart: '19:45', blockEnd: '20:00', blockStartHuman: '7:45 pm', blockEndHuman: '8:00 pm'},

  {value: 80, blockStart: '20:00', blockEnd: '20:15', blockStartHuman: '8:00 pm', blockEndHuman: '8:15 pm'},
  {value: 81, blockStart: '20:15', blockEnd: '20:30', blockStartHuman: '8:15 pm', blockEndHuman: '8:30 pm'},
  {value: 82, blockStart: '20:30', blockEnd: '20:45', blockStartHuman: '8:30 pm', blockEndHuman: '8:45 pm'},
  {value: 83, blockStart: '20:45', blockEnd: '21:00', blockStartHuman: '8:45 pm', blockEndHuman: '9:00 pm'},

  {value: 84, blockStart: '21:00', blockEnd: '21:15', blockStartHuman: '9:00 pm', blockEndHuman: '9:15 pm'},
  {value: 85, blockStart: '21:15', blockEnd: '21:30', blockStartHuman: '9:15 pm', blockEndHuman: '9:30 pm'},
  {value: 86, blockStart: '21:30', blockEnd: '21:45', blockStartHuman: '9:30 pm', blockEndHuman: '9:45 pm'},
  {value: 87, blockStart: '21:45', blockEnd: '22:00', blockStartHuman: '9:45 pm', blockEndHuman: '10:00 pm'},

  {value: 88, blockStart: '22:00', blockEnd: '22:15', blockStartHuman: '10:00 pm', blockEndHuman: '10:15 pm'},
  {value: 89, blockStart: '22:15', blockEnd: '22:30', blockStartHuman: '10:15 pm', blockEndHuman: '10:30 pm'},
  {value: 90, blockStart: '22:30', blockEnd: '22:45', blockStartHuman: '10:30 pm', blockEndHuman: '10:45 pm'},
  {value: 91, blockStart: '22:45', blockEnd: '23:00', blockStartHuman: '10:45 pm', blockEndHuman: '11:00 pm'},

  {value: 92, blockStart: '23:00', blockEnd: '23:15', blockStartHuman: '11:00 pm', blockEndHuman: '11:15 pm'},
  {value: 93, blockStart: '23:15', blockEnd: '23:30', blockStartHuman: '11:15 pm', blockEndHuman: '11:30 pm'},
  {value: 94, blockStart: '23:30', blockEnd: '23:45', blockStartHuman: '11:30 pm', blockEndHuman: '11:45 pm'},
  {value: 95, blockStart: '23:45', blockEnd: '00:00', blockStartHuman: '11:45 pm', blockEndHuman: 'Midnight'},
];

const bookingTimes = [
  {value: 1, text: '15 Minutes'},
  {value: 2, text: '30 Minutes'},
  {value: 3, text: '45 Minutes'},
  {value: 4, text: '1 Hour'},
  {value: 5, text: '1 Hour 15 Minutes'},
  {value: 6, text: '1 Hour 30 Minutes'},
  {value: 7, text: '1 Hour 45 Minutes'},
  {value: 8, text: '2 Hours'},
  {value: 9, text: '2 Hours 15 Minutes'},
  {value: 10, text: '2 Hours 30 Minutes'},
  {value: 11, text: '2 Hours 45 Minutes'},
  {value: 12, text: '3 Hours'},
  {value: 13, text: '3 Hours 15 Minutes'},
  {value: 14, text: '3 Hours 30 Minutes'},
  {value: 15, text: '3 Hours 45 Minutes'},
  {value: 16, text: '4 Hours'},
  {value: 96, text: '1 Day', textAlt: '1 apointment per day'},
];

const timezones = [
  'Pacific/Auckland',
];

const createOffset = (calendar, date) => {
  const userDate = new Date(`${date} 0:00`);
  const calendarDate = new Date(new Date(`${date} 0:00`).toLocaleString('UTC', {timeZone: calendar.timezone}));
  return ((calendarDate.getTime() - userDate.getTime()) / 1000) / 60;
}

const getStartTime = (time) => {
  return times[time].blockStart;
}

const getEndTime = (time) => {
  return times[time].blockEnd;
}

const calculateBlockEnd = (start, duration) => {
  return times[start + duration].blockStart;
}

const getDates = (date, start, duration) => {
  const startDate = new Date(`${date} ${times[start].blockStart}`);
  const endDate = new Date(`${date} ${times[start + duration].blockStart}`);
  return ({startDate, endDate});
}

const processBookings = (bookings) => {
  const bookingsProcessed = {};
  for (let i = 0; i < bookings?.length; i += 1) {
    const b = bookings[i];
    for(let j = 0; j < b?.times?.length; j += 1) {
      const bookingProcessed = {
        _id: b._id,
        organisationId: b.organisationId,
        orderId: b.orderId,
        userId: b.userId,
        bookingCalendarId: b.bookingCalendarId,
        type: b.type,
        notes: b.notes,
        time: {
          date: b.times[j].date,
          start: b.times[j].start,
          blocks: b.times[j].blocks,
        },
      }

      if(!bookingsProcessed[bookingProcessed.time.date]) {
        bookingsProcessed[bookingProcessed.time.date] = {
          loaded: false,
          list: [],
        };
      }
      bookingsProcessed[bookingProcessed.time.date].list.push(bookingProcessed);
      bookingsProcessed[bookingProcessed.time.date].list.sort(function(a, b) {
        if(a.time.start > b.time.start) {
          return 1;
        } else if(a.time.start < b.time.start) {
          return -1;
        }
        return 0;
      });
    }
  }
  return bookingsProcessed;
}

const convertDateToDay = (d) => {
  const day = d.getDay();
  if(day === 0) {
    return 'Sunday';
  } else if (day === 1) {
    return 'Monday';
  } else if (day === 2) {
    return 'Tuesday';
  } else if (day === 3) {
    return 'Wednesday'
  } else if (day === 4) {
    return 'Thursday';
  } else if (day === 5) {
    return 'Friday';
  } else if (day === 6) {
    return 'Saturday';
  } else {
    return 'Error Fetching Day';
  }
}

const convertDateToText = (d) => {
  return `${d.getFullYear()}-${d.getMonth()+1 < 10 ? '0' : ''}${d.getMonth()+1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}`;
}

const processTimesAddOffeset = (calendar, t) => {
  if(t && t.length > 0) {
    for(let i = 0; i < (t).length; i += 1) {
      const offset = (t[i].offset ? t[i].offset : createOffset(calendar, t[i].date));
      if(!t[i].offset) {
        t[i].offset = createOffset(calendar, t[i].date);
      }

      // sort date
      const date = new Date(new Date(new Date(`${t[i].date} ${times[t[i].start].blockStart}`).toLocaleString('UTC', {timeZone: calendar.timezone})).getTime() + offset*60000);
      t[i].date = convertDateToText(date);

      // sort time
      let shift = Math.floor(offset / 15);
      while (shift > 95) {
        shift -= 96;
      }
      while (shift < -95) {
        shift += 96;
      }
      let start = parseInt(t[i].start) + shift;
      if(start > 95) {
        start -= 96;
      }
      if(start > 95) {
        start -= 96;
      }
      t[i].start = start;
    }
  }
  return t;
}

const processTimesRemoveOffeset = (calendar, t) => {
  if(t && t.length > 0) {
    for(let i = 0; i < (t).length; i += 1) {
      const offset = -(t[i].offset ? t[i].offset : createOffset(calendar, t[i].date));

      // sort date
      const date = new Date(new Date(new Date(`${t[i].date} ${times[t[i].start].blockStart}`).toLocaleString('UTC', {timeZone: calendar.timezone})).getTime() + offset*60000);
      t[i].date = convertDateToText(date);

      // sort time
      let shift = Math.floor(offset / 15);
      while (shift > 95) {
        shift -= 96;
      }
      while (shift < -95) {
        shift += 96;
      }
      let start = parseInt(t[i].start) + shift;
      if(start > 95) {
        start -= 96;
      }
      if(start > 95) {
        start -= 96;
      }
      t[i].start = start;
    }
  }
  return t;
}

const generateFirstOfMonth = (d) => {
  const tempDate = new Date(d);
  return new Date(tempDate.getFullYear(), tempDate.getMonth(), 1);
}

const generateLastOfMonth = (d) => {
  const tempDate = new Date(d);
  return new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0);
}

const generateStartOfWeek = (d) => {
  const tempDate = new Date(d);
  var day = tempDate.getDay();
  if(day !== 0) {
    tempDate.setHours(-24 * (day));
  }
  return tempDate;
}

const generateEndOfWeek = (d) => {
  const tempDate = generateStartOfWeek(d);
  const endOfWeek = tempDate.getDate() + 6;
  tempDate.setDate(endOfWeek);
  return tempDate;
}

export {
  times,
  bookingTimes,
  timezones,

  getDates,
  getStartTime,
  getEndTime,
  calculateBlockEnd,
  createOffset,
  processBookings,
  processTimesRemoveOffeset,
  processTimesAddOffeset,
  convertDateToText,
  convertDateToDay,
  generateFirstOfMonth,
  generateLastOfMonth,
  generateStartOfWeek,
  generateEndOfWeek,
};
