import React, {useState} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../../components/search/store';
import OfficeForm from '../../../../../components/forms/office';
import ContactOfficeForm from '../../../../../components/forms/contactOffice';
import ContactAddressForm from '../../../../../components/forms/contactAddress';
import PositionForm from '../../../../../components/forms/position';
import StaffForm from '../../../../../components/forms/staff';

import {paletteData, Button, Typography, Dialog, CheckBox, ProgressBar} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function EditOrganisationOfficesSubPanel({
    me,
    processing,
    setProcessing,
    handleVerifyEmail,
    handleUploadImage,

    focusedOrganisation,
    handleChangeFocusedOrganisation,

    areas,
    areaReferences,
    setAreaReference,
  }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Offices
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              palette="secondary"
              size='small'
              onClick={() => {
                const offices = focusedOrganisation.offices;
                offices.push({staff: [], address: {}});
                handleChangeFocusedOrganisation('offices', offices);
                setDialogData({
                  type: 'message',
                  title: 'Office Created',
                });
                setDialogOpen(true);
              }}
            >
              Add office
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {focusedOrganisation.offices?.length > 0 && focusedOrganisation.offices.map((o, oIndex) => (
        <div key={oIndex}>
          <div style={{marginTop: 10, padding: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: '5px 5px 0px 0px', background: paletteData.primary.standard.background}}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Office {oIndex + 1}
            </Typography>
          </div>
          <div style={{padding: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: '0px 0px 5px 5px', background: '#e3e3e3'}}>
            <OfficeForm office={o} areaReferences={areaReferences} handleChange={(name, value) => {
              if(name === 'area') {
                setDialogData({
                  type: 'chooseArea',
                  title: 'Change Area',
                  index: oIndex,
                });
                setDialogOpen(true);
              } else {
                const offices = focusedOrganisation.offices;
                offices[oIndex][name] = value;
                handleChangeFocusedOrganisation('offices', offices);
              }
            }}/>
            <br/>
            <div style={{background: paletteData.secondary.standard.background, borderStyle: 'solid', borderWidth: 1, padding: 10}}>
              <Typography style={{color: paletteData.secondary.standard.foreground}}>
                Office Contact
              </Typography>
            </div>
            <ContactOfficeForm
              contact={o}
              handleChange={(name, value) => {
                const offices = focusedOrganisation.offices || {};
                offices[oIndex][name] = value;
                handleChangeFocusedOrganisation('offices', offices);
              }}
              owner={me._id === focusedOrganisation.owner}
              verification={me?.verification?.contact || {}}
              handleVerifyEmail={handleVerifyEmail}
              processing={processing}
            />
            <br/>
            <div style={{background: paletteData.secondary.standard.background, borderStyle: 'solid', borderWidth: 1, padding: 10}}>
              <Typography style={{color: paletteData.secondary.standard.foreground}}>
                Office Physical Address
              </Typography>
            </div>
            <ContactAddressForm address={o.address} handleChange={(name, value) => {
              const offices = focusedOrganisation.offices;
              if(!offices[oIndex].address) {
                offices[oIndex].address = {};
              }
              offices[oIndex].address[name] = value;
              handleChangeFocusedOrganisation('offices', offices);
            }}/>
            <PositionForm
              position={o.position}
              address={`${o.address?.extra ? `${o.address?.extra} ` : ''}${o.address?.number ? `${o.address?.number} ` : ''}${o.address?.street ? `${o.address?.street} ` : ''}${o.address?.suburb ? `${o.address?.suburb} ` : ''}${o.address?.city ? `${o.address?.city} ` : ''}${o.address?.country ? `${o.address?.country} ` : ''}`}
              handleChange={(name, value) => {
                if(name === 'position') {
                  const offices = focusedOrganisation.offices;
                  offices[oIndex].position = value;
                  handleChangeFocusedOrganisation('offices', offices);
                } else {
                  const offices = focusedOrganisation.offices;
                  if(!offices[oIndex].position) {
                    offices[oIndex].position = {};
                  }
                  offices[oIndex].position[name] = value;
                  handleChangeFocusedOrganisation('offices', offices);
                }
              }}
            />
            <Typography style={{marginLeft: 5}} size='subText'>
              Having your position entered (latitude and longitude) will place a pin on the maps at your office location.
            </Typography>
            <br/>
            <div style={{background: paletteData.secondary.standard.background, borderStyle: 'solid', borderWidth: 1, padding: 10}}>
              <Typography style={{color: paletteData.secondary.standard.foreground}}>
                Office Postal Address
              </Typography>
            </div>
            <CheckBox name='postalClonesAddress' label='Same As Physical Address' value={o.postalClonesAddress} onChange={(value) => {
              const offices = focusedOrganisation.offices;
              offices[oIndex].postalClonesAddress = value;
              offices[oIndex].postal = {};
              handleChangeFocusedOrganisation('offices', offices);
            }}/>
            {!o.postalClonesAddress &&
              <ContactAddressForm
                address={o.postal}
                handleChange={(name, value) => {
                  const offices = focusedOrganisation.offices;
                  if(!offices[oIndex].postal) {
                    offices[oIndex].postal = {};
                  }
                  offices[oIndex].postal[name] = value;
                  handleChangeFocusedOrganisation('offices', offices);
                }}
              />
            }

            <br/>
            <div style={{background: paletteData.secondary.standard.background, borderStyle: 'solid', borderWidth: 1, padding: 10}}>
              <GridContainer>
                <GridCell weight={1} center={true}>
                  <Typography style={{color: paletteData.secondary.standard.foreground}}>
                    Office Staff
                  </Typography>
                </GridCell>
                <GridCell>
                  <Button
                    palette="primary" style={{padding: 5, fontSize: '.8em'}}
                    onClick={() => {
                      const officesTemp = focusedOrganisation.offices;
                      if(!officesTemp[oIndex].staff) {
                        officesTemp[oIndex].staff = [];
                      }
                      officesTemp[oIndex].staff.push({});
                      handleChangeFocusedOrganisation('offices', officesTemp);
                    }}
                  >
                    Add staff
                  </Button>
                </GridCell>
              </GridContainer>
            </div>
            {o?.staff?.length === 0 &&
              <div style={{padding: 5}}>
                No registered staff
              </div>
            }
            {o?.staff?.length > 0 && o.staff.map((s, sIndex) => (
              <div key={sIndex} style={{marginTop: 10}}>
                <StaffForm
                  staff={s}
                  processing={processing}
                  setProcessing={setProcessing}
                  handleChange={(name, value) => {
                    const offices = focusedOrganisation.offices;
                    offices[oIndex].staff[sIndex][name] = value;
                    handleChangeFocusedOrganisation('offices', offices);
                  }}
                  handleRemoveStaff={() => {
                    const offices = focusedOrganisation.offices;
                    offices[oIndex].staff.splice(sIndex, 1);
                    handleChangeFocusedOrganisation('offices', offices);
                  }}
                  handleUploadImage={handleUploadImage}
                  owner={me._id === focusedOrganisation.owner}
                  verification={me?.verification?.contact || {}}
                  handleVerifyEmail={handleVerifyEmail}
                />
              </div>
            ))}
            <br/>
            <GridContainer>
              <GridCell weight={1}/>
              <GridCell>
                <Button
                  palette='primary'
                onClick={() => {
                  setDialogOpen(true);
                  setDialogData({
                    type: 'removeOffice',
                    title: 'Remove Office',
                    message: 'Do you wish to remove this office?',
                    value: {
                      index: oIndex,
                    }
                  });
                }}
              >
                Remove Office
              </Button>
            </GridCell>
          </GridContainer>
        </div>
        </div>
      ))}

      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => {
          setDialogOpen(false);
          setDialogData(undefined);
        }}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.type === 'message' || dialogData?.type === 'removeOffice') &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'chooseArea' &&
            <div style={{padding: 10}}>
              <SearchStore databaseArea='areas' processing={processing} setProcessing={setProcessing} expandSearch={false} setExpandSearch={() => {}}/>
              {(!areas || processing) &&
                <ProgressBar palette='secondary'/>
              }
              {!processing && areas && areas.map((a, aIndex) => (
                <div
                  key={aIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!areaReferences[a._id]) {
                      setAreaReference(a);
                    }
                    const offices = focusedOrganisation.offices || [];
                    offices[dialogData.index].area = a._id;
                    handleChangeFocusedOrganisation('offices', offices);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {a.name}
                </div>
              ))}
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'removeOffice' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                  onClick={() => {
                    const offices = focusedOrganisation.offices;
                    offices.splice(dialogData.value.index, 1);
                    handleChangeFocusedOrganisation('offices', offices);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  Remove Office
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditOrganisationOfficesSubPanel.propTypes = {
  me: PropTypes.shape({}),
  focusedOrganisation: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedOrganisation: PropTypes.func,
  handleVerifyEmail: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleUploadImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditOrganisationOfficesSubPanel;
