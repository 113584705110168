import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {GridContainer, GridCell} from '../../../../components/grid';
import {paletteData, Typography, ProgressBar, Button, Dialog} from '../../../../components/styles';
import SearchStore from '../../../../components/search/store';

import apiRequest from '../../../../tools/apiRequest';
import handleErrorMessage from '../../../../tools/handleErrorMessage';
import {setUserReference, setUserReferences, setCommunityReference} from '../../../../store/reducers/references/actions.js';
import {addCommunity, replaceCommunity} from '../../../../store/reducers/lists/actions.js';

function ManageModeratorsPanel({
  me,
  auth,
  communities,
  userReferences,
  setUserReferences,
  potentialCommunities,
  search,
  users,
  setUserReference,
  addCommunity,
  replaceCommunity,
  setCommunityReference,
}) {
  const [processing, setProcessing] = useState(false);
  const [expandSearch, setExpandSearch] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const [checkUsers, setCheckUsers] = useState(true);
  const [usersLoaded, setUsersLoaded] = useState(false);
  useEffect(() => {
    if(checkUsers && communities?.length > 0) {
      setCheckUsers(false);
      const usersToFetch = [];
      for(let i = 0; i < communities.length; i += 1) {
        if(communities[i]?.moderators?.length > 0) {
          for(let j = 0; j < communities[i].moderators?.length; j += 1) {
            if(!userReferences[communities[i].moderators[j]]) {
              usersToFetch.push(communities[i].moderators[j]);
            }
          }
        }
      }
      if(usersToFetch.length > 0) {
        const query = {
          _id: {$in: usersToFetch}
        };
        const sort = {};
        apiRequest({type: 'get', action: 'users', data: {query, sort}})
        .then((result) => {
          setUserReferences(result.data.users);
          setUsersLoaded(true);
        }).catch((error) => {
          setUsersLoaded(true);
        });
      } else {
        setUsersLoaded(true);
      }
    }
  }, [communities, checkUsers, userReferences, setUserReferences]);

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Manage Moderators
            </Typography>
          </GridCell>
          <GridCell>
            <Button palette='secondary' onClick={() => {
              setDialogData({
                type: 'addModerator',
                title: 'Add Community Moderator',
                value: {
                  community: undefined,
                  user: undefined,
                },
                data: {
                  expandSearchPotentialCommunities: false,
                  expandSearchUsers: false,
                }
              });
              setDialogOpen(true);
            }}>
              Add
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      <SearchStore databaseArea='communities' processing={processing} setProcessing={setProcessing} expandSearch={expandSearch} setExpandSearch={setExpandSearch}/>
      {communities?.length > 0 && communities.map((c, cIndex) => (
        <div key={cIndex} style={{padding: 5}}>
          <div style={{background: paletteData.primary.standard.background, padding: 10}}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              {c.name}
            </Typography>
          </div>
          {!usersLoaded &&
            <div style={{padding: 10}}>
              <Typography>
                Loading Users
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {usersLoaded && c?.moderators?.length > 0 && c.moderators.map((m, mIndex) => (
            <GridContainer key={mIndex} style={{padding: 10}}>
              <GridCell weight={1} center={true}>
                <Typography>
                  {userReferences[m] ? userReferences[m].name : 'Un-named moderator'}
                </Typography>
              </GridCell>
              <GridCell>
                <Button disabled={processing} palette='primary' size='small' onClick={() => {
                  setDialogData({
                    type: 'removeModerator',
                    title: 'Remove Community Moderator',
                    message: `Do you want to remove ${userReferences[m] ? userReferences[m].name : 'Un-named moderator'} from moderating ${c.name}`,
                    data: {
                      moderatorId: m,
                      resourceId: c._id,
                      resourceType: c.type
                    }
                  });
                  setDialogOpen(true);
                }}>
                  Remove
                </Button>
              </GridCell>
            </GridContainer>
          ))}
          {c?.moderators?.length === 0 &&
            <div style={{padding: 10}}>
              <Typography>
                No Moderators
              </Typography>
            </div>
          }
        </div>
      ))}
      {search?.communities?.hasMore && search?.communities?.length > 0 &&
        <div style={{padding: 5}}>
          <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
            Load More
          </Button>
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => {
          setDialogOpen(false);
          setDialogData(undefined);
        }}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData.type === 'addModerator' &&
            <div style={{padding: 10}}>
              {dialogData?.value?.community === undefined &&
                <div>
                  <SearchStore
                    databaseArea='potentialCommunities'
                    processing={processing}
                    setProcessing={setProcessing}
                    expandSearch={dialogData.data.expandSearchPotentialCommunities}
                    setExpandSearch={(value) => {
                      setDialogData({
                        ...dialogData,
                        data: {
                          ...(dialogData.data || {}),
                          expandSearchPotentialCommunities: value,
                        }
                      });
                    }}
                  />
                  {potentialCommunities?.length > 0 && potentialCommunities.map((c, cIndex) => (
                    <div
                      key={cIndex}
                      style={{padding: 10, margin: 5, borderRadius: 5, borderStyle: 'solid', borderWidth: 1, cursor: 'pointer'}}
                      onClick={() => {
                        setDialogData({
                          ...dialogData,
                          value: {
                            ...(dialogData.value || {}),
                            community: c,
                          }
                        })
                      }}
                    >
                      <Typography>
                        {c.name}
                      </Typography>
                    </div>
                  ))}
                  {search?.potentialCommunities?.hasMore && potentialCommunities?.length > 0 &&
                    <div>
                      <Button palette='primary' onClick={() => {
                        setDialogData({
                          ...dialogData,
                          data: {
                            ...(dialogData.data || {}),
                            expandSearchPotentialCommunities: true,
                          }
                        });
                      }}>
                        Load More
                      </Button>
                      {processing &&
                        <ProgressBar palette='secondary'/>
                      }
                    </div>
                  }
                </div>
              }
              {dialogData?.value?.community !== undefined && dialogData?.value?.user === undefined &&
                <div>
                  <SearchStore
                    databaseArea='users'
                    processing={processing}
                    setProcessing={setProcessing}
                    expandSearch={dialogData.data.expandSearchUsers}
                    setExpandSearch={(value) => {
                      setDialogData({
                        ...dialogData,
                        data: {
                          ...(dialogData.data || {}),
                          expandSearchUsers: value,
                        }
                      });
                    }}
                  />
                  {users?.length > 0 && users.map((u, uIndex) => (
                    <div
                      key={uIndex}
                      style={{padding: 10, margin: 5, borderRadius: 5, borderStyle: 'solid', borderWidth: 1, cursor: 'pointer'}}
                      onClick={() => {
                        setDialogData({
                          ...dialogData,
                          value: {
                            ...(dialogData.value || {}),
                            user: u,
                          }
                        })
                      }}
                    >
                      <GridContainer>
                        <GridCell weight={1}>
                          <Typography>
                            {u.name}
                          </Typography>
                        </GridCell>
                        <GridCell>
                          <Typography>
                            {u.email}
                          </Typography>
                        </GridCell>
                      </GridContainer>

                    </div>
                  ))}
                  {search?.users?.hasMore && users?.length > 0 &&
                    <div>
                      <Button palette='primary' onClick={() => {
                        setDialogData({
                          ...dialogData,
                          data: {
                            ...(dialogData.data || {}),
                            expandSearchUsers: true,
                          }
                        });
                      }}>
                        Load More
                      </Button>
                      {processing &&
                        <ProgressBar palette='secondary'/>
                      }
                    </div>
                  }
                </div>
              }
              {dialogData?.value?.community !== undefined && dialogData?.value?.user !== undefined &&
                <div>
                  <GridContainer style={{paddingBottom: 5}}>
                    <GridCell center={true} style={{width: 100}}>
                      <Typography>
                        Community
                      </Typography>
                    </GridCell>
                    <GridCell center={true} weight={1}>
                      <Typography>
                        {dialogData?.value?.community?.name}
                      </Typography>
                    </GridCell>
                    <GridCell>
                      <Button palette='primary' size='small' onClick={() => {
                        setDialogData({
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            community: undefined,
                          }
                        })
                      }}>
                        Edit
                      </Button>
                    </GridCell>
                  </GridContainer>
                  <GridContainer>
                    <GridCell center={true} style={{width: 100}}>
                      <Typography>
                        Moderator
                      </Typography>
                    </GridCell>
                    <GridCell center={true} weight={1}>
                      <Typography>
                        {dialogData?.value?.user?.name}
                      </Typography>
                    </GridCell>
                    <GridCell>
                      <Button palette='primary' size='small' onClick={() => {
                        setDialogData({
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            user: undefined,
                          }
                        })
                      }}>
                        Edit
                      </Button>
                    </GridCell>
                  </GridContainer>
                </div>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'addModerator' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.community || !dialogData?.value?.user}
                  palette='primary'
                  onClick={() => {
                    const data = {
                      moderatorId: dialogData.value.user._id,
                      resourceId: dialogData.value.community._id,
                      resourceType: dialogData.value.community.type,
                    }
                    setProcessing(true);
                    apiRequest({type: 'patch', action: `communities/addModerator`, data})
                    .then((result) => {
                      if(userReferences[data.moderatorId]) {
                        const tempUser = {
                          ...userReferences[data.moderatorId],
                          roles: {
                            ...(userReferences[data.moderatorId]?.roles || {}),
                            community: {
                              ...(userReferences[data.moderatorId]?.roles?.community || {}),
                              [dialogData.value.community.type]: {
                                ...((userReferences[data.moderatorId]?.roles?.community) && (userReferences[data.moderatorId]?.roles?.community[data.resourceType]) ? (userReferences[data.moderatorId]?.roles?.community[data.resourceType]) : {}),
                                [data.resourceId]: {
                                  ...(
                                    userReferences[data.moderatorId]?.roles?.community && userReferences[data.moderatorId]?.roles?.community[data.resourceType] && userReferences[data.moderatorId]?.roles?.community[data.resourceType][data.resourceId] ?
                                    userReferences[data.moderatorId]?.roles?.community[data.resourceType][data.resourceId] : {}
                                  ),
                                  moderator: true,
                                }
                              }
                            }
                          }
                        };
                        setUserReference(tempUser);
                      }
                      if(communities.findIndex((c) => {
                        return c._id === data.resourceId && c.type === data.resourceType;
                      }) !== -1) {
                        const tempCommunity = dialogData.value.community;
                        if(!tempCommunity.moderators) tempCommunity.moderators = [];
                        tempCommunity.moderators.push(data.moderatorId);
                        replaceCommunity({community: tempCommunity});
                        setCommunityReference(tempCommunity);
                      } else {
                        const tempCommunity = dialogData.value.community;
                        if(!tempCommunity.moderators) tempCommunity.moderators = [];
                        tempCommunity.moderators.push(data.moderatorId);
                        addCommunity(tempCommunity);
                        setCommunityReference(tempCommunity);
                      }
                      setProcessing(false);
                      setDialogOpen(false);
                      setDialogData(undefined);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Add moderator request denied',
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Add
                </Button>
                {processing &&
                  <ProgressBar palette='secondary' />
                }
              </GridCell>
            }
            {dialogData?.type === 'removeModerator' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    apiRequest({type: 'patch', action: `communities/removeModerator`, data: dialogData.data})
                    .then((result) => {
                      if(userReferences[dialogData.data.moderatorId]) {
                        const tempUser = userReferences[dialogData.data.moderatorId];
                        if(tempUser.roles?.community && tempUser.roles?.community[dialogData.data.resourceType] && tempUser.roles?.community[dialogData.data.resourceType][dialogData.data.resourceId]) {
                          delete tempUser.roles?.community[dialogData.data.resourceType][dialogData.data.resourceId].moderator;
                          if(Object.keys(tempUser.roles?.community[dialogData.data.resourceType][dialogData.data.resourceId]).length === 0) {
                            delete tempUser.roles?.community[dialogData.data.resourceType][dialogData.data.resourceId];
                          }
                        }
                        setUserReference(tempUser);
                      }

                      const communityIndex = communities.findIndex((c) => {
                        return c._id === dialogData.data.resourceId && c.type === dialogData.data.resourceType;
                      });
                      if(communityIndex !== -1) {
                        const tempCommunity = communities[communityIndex];
                        if(!tempCommunity.moderators) tempCommunity.moderators = [];
                        const moderatorIndex = tempCommunity.moderators.findIndex((m) => {
                          return m === dialogData.data.moderatorId;
                        });
                        if(moderatorIndex !== -1) {
                          tempCommunity.moderators.splice(moderatorIndex, 1);
                          replaceCommunity({community: tempCommunity});
                          setCommunityReference(tempCommunity);
                        }
                      }
                      setProcessing(false);
                      setDialogOpen(false);
                      setDialogData(undefined);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Remove moderator request denied',
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Remove
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ManageModeratorsPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
}

const mapStateToProps = (state) => {
  return {
    communities: state.lists.communities,
    communityReferences: state.references.communities || {},
    potentialCommunities: state.lists.potentialCommunities,
    potentialCommunityReferences: state.references.potentialCommunities || {},
    users: state.lists.users,
    userReferences: state.references.users || {},
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
  };
};

export default connect(mapStateToProps, {
  setUserReference,
  addCommunity,
  replaceCommunity,
  setCommunityReference,
  setUserReferences,
})(ManageModeratorsPanel);
