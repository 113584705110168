import React, { useState, useEffect, createRef } from 'react';
import { BlockPicker } from 'react-color';
import PropTypes from 'prop-types';
import objectPath from 'object-path';

import { GridContainer, GridCell } from '../../../../../components/grid';
import { paletteData, textData, Button, Typography, Dialog, Fab, Input, Select, ProgressBar, } from '../../../../../components/styles';
import { LayoutEdit } from '../../../../../components/layout';
import apiRequest from '../../../../../tools/apiRequest';

import HeaderForm from '../../../../../components/forms/header';

function EditOrganisationLayoutSubPanel({
  me,
  focusedOrganisation,
  handleChangeFocusedOrganisation,
  processing,
  setProcessing
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [imageLibrary, setImages] = useState(undefined);
  const [page, setPage] = useState(0);
  const [imageUploadRef] = useState(createRef());

  useEffect(() => {
    if (!imageLibrary) {
      const gallery = focusedOrganisation.gallery;
      const logoArray = focusedOrganisation.logo ? [focusedOrganisation.logo] : [];
      const staffImagesProcessed = [];

      for (let i = 0; i < focusedOrganisation.offices.length; i += 1) {
        const staffByOffice = focusedOrganisation.offices[i].staff || [];
        for (let j = 0; j < staffByOffice.length; j += 1) {
          const test = staffImagesProcessed.find((s, stIndex) => staffByOffice[j].email === s.email);
          if (!test && staffByOffice[j].profileImage) {
            staffImagesProcessed.push({ imageId: staffByOffice[j].profileImage, alt: staffByOffice[j].name });
          }
        }
      }
      setImages(gallery.concat(logoArray).concat(staffImagesProcessed));
    }
  }, [focusedOrganisation, imageLibrary]);

  const changePage = (page, type, index, path, value) => {
    if (type === 'insert') {
      const tempLayout = focusedOrganisation.layout || {
        header: {},
        multiPageEnabled: false,
        pages: [{
          title: 'home',
          sections: [],
        }],
      };
      if (!tempLayout.pages) {
        tempLayout.pages = [{
          title: 'home',
          sections: [],
        }];
      };
      tempLayout.pages[page].sections.splice(index, 0, value);
      handleChangeFocusedOrganisation('layout', tempLayout);
    } else if (type === 'update') {
      const tempLayout = focusedOrganisation.layout;
      if (path === undefined) {
        tempLayout.pages[page].sections = value;
      } else {
        if (path === '') {
          objectPath.set(tempLayout.pages[page].sections, `${index}`, value);
        } else {
          objectPath.set(tempLayout.pages[page].sections, `${index}.${path}`, value);
        }
      }
      handleChangeFocusedOrganisation('layout', tempLayout);
    }
  }

  const changeParent = (type, path, value) => {
    if (type === 'insert') {
      const tempArray = objectPath.get(focusedOrganisation, path) || [];
      tempArray.push(value);
      objectPath.set(focusedOrganisation, path, tempArray);
    }
  }

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({ type: 'post', action: 'upload/image', data: { file: reader.result } }).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({ imageId: result.data.public_id, alt }, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <div style={{ background: paletteData.primary.standard.background, padding: 10 }}>
        <GridContainer>
          <GridCell center={true}>
            <a
              href={`https://rerengatahi.nz/organisation/${focusedOrganisation._id}`}
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel='noopener noreferrer'
            >
              <Typography style={{ color: paletteData.primary.standard.foreground }}>
                Layout
              </Typography>
            </a>
          </GridCell>
          <GridCell center={true}>
            <a
              href={`https://rerengatahi.nz/organisation/${focusedOrganisation._id}`}
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel='noopener noreferrer'
            >
              <span className="material-icons md-24" style={{ color: paletteData.primary.standard.foreground, marginLeft: 5 }}>visibility</span>
            </a>
          </GridCell>
          <GridCell weight={1} />

          {focusedOrganisation?.layout?.multiPageEnabled &&
            <GridCell center={true}>
              <Select
                style={{ minWidth: 100 }}
                palette='primary'
                label="Page Index"
                value={page}
                onChange={(value) => setPage(value)}
              >
                {focusedOrganisation?.layout?.pages?.length > 0 && focusedOrganisation.layout.pages.map((p, pIndex) => (
                  <option key={pIndex} value={pIndex} style={{ color: 'black' }}>{pIndex + 1}</option>
                ))}
              </Select>
            </GridCell>
          }
          {focusedOrganisation?.layout?.multiPageEnabled &&
            <GridCell center={true}>
              <Input
                palette='primary'
                label="Page Title"
                value={focusedOrganisation?.layout?.pages[page]?.title}
                onChange={(value) => {
                  const layoutProcessed = focusedOrganisation.layout;
                  layoutProcessed.pages[page].title = value;
                  handleChangeFocusedOrganisation('layout', layoutProcessed);
                }}
              />
            </GridCell>
          }
          {focusedOrganisation?.layout?.multiPageEnabled && page !== 0 &&
            <GridCell center={true}>
              <Fab
                size='small'
                onClick={() => {
                  setDialogData({
                    type: 'messageWithCallback',
                    title: 'Delete Page',
                    message: 'Deleting a page is perminant are you sure you wish to proceed?',
                    data: {
                      callback: () => {
                        const layoutProcessed = focusedOrganisation?.layout || { pages: [] };
                        layoutProcessed.pages.splice(page, 1);
                        handleChangeFocusedOrganisation('layout', layoutProcessed);
                        setPage(page - 1);
                      }
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                <span className="material-icons md-24" style={{ color: paletteData.primary.standard.foreground }}>delete_sweep</span>
              </Fab>
            </GridCell>
          }
          {focusedOrganisation?.layout?.multiPageEnabled &&
            <GridCell center={true}>
              <Fab
                size='small'
                onClick={() => {
                  setDialogData({
                    type: 'movePage',
                    title: 'Move Page',
                    data: {
                      currentIndex: page,
                    },
                    value: {
                      index: page,
                      humanIndex: (page + 1),
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                <span className="material-icons md-24" style={{ color: paletteData.primary.standard.foreground }}>move_down</span>
              </Fab>
            </GridCell>
          }
          {focusedOrganisation?.layout?.multiPageEnabled &&
            <GridCell center={true}>
              <Fab
                size='small'
                onClick={() => {
                  const layoutProcessed = focusedOrganisation?.layout || { pages: [] };
                  const newIndex = focusedOrganisation?.layout?.pages?.length;
                  const page = {
                    title: `page ${newIndex}`,
                    sections: [],
                  }
                  layoutProcessed.pages.push(page);
                  handleChangeFocusedOrganisation('layout', layoutProcessed);
                  setPage(newIndex);
                }}
              >
                <span className="material-icons md-24" style={{ color: paletteData.primary.standard.foreground }}>note_add</span>
              </Fab>
            </GridCell>
          }
          <GridCell center={true}>
            <Fab
              size='small'
              onClick={() => {
                const layoutProcessed = focusedOrganisation.layout;
                layoutProcessed.multiPageEnabled = !(layoutProcessed.multiPageEnabled || false);
                handleChangeFocusedOrganisation('layout', layoutProcessed);
                if (layoutProcessed.multiPageEnabled === false) {
                  setPage(0);
                }
              }}
            >
              {focusedOrganisation?.layout?.multiPageEnabled && <span className="material-icons md-24" style={{ color: paletteData.primary.standard.foreground }}>layers</span>}
              {!focusedOrganisation?.layout?.multiPageEnabled && <span className="material-icons md-24" style={{ color: paletteData.primary.standard.foreground }}>layers_clear</span>}
            </Fab>
          </GridCell>
        </GridContainer>
      </div>

      {page === 0 &&
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              minWidth: 20,
              padding: 5,
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 5,
              background: 'white',
            }}
          >
            <GridContainer>
              <GridCell>
                <Fab
                  size='small'
                  onClick={() => {
                    setDialogData({
                      type: 'addHeading',
                      title: 'Add Heading',
                      data: {
                        callback: (value) => {
                          handleChangeFocusedOrganisation('heading', value);
                        }
                      },
                      value: focusedOrganisation?.heading || {},
                    });
                    setDialogOpen(true);
                  }}
                >
                  <span className="material-icons md-24">title</span>
                </Fab>
              </GridCell>
              <GridCell>
                <Fab
                  size='small'
                  onClick={() => {
                    setDialogData({
                      type: 'colorPicker',
                      title: 'Heading Color',
                      data: {
                        callback: (value) => {
                          handleChangeFocusedOrganisation('heading', value);
                        }
                      },
                      value: focusedOrganisation?.heading || {},
                    });
                    setDialogOpen(true);
                  }}
                >
                  <span className="material-icons md-24">palette</span>
                </Fab>
              </GridCell>
              <GridCell>
                <Fab
                  size='small'
                  onClick={() => {
                    setDialogData({
                      type: 'selectImage',
                      title: 'Add Logo',
                      data: {
                        imageType: 'logo',
                        callback: (value) => {
                          if (value?.imageId) {
                            const image = {
                              imageId: value.imageId,
                              tags: undefined,
                              alt: `${focusedOrganisation.name} Logo`,
                              description: `${focusedOrganisation.name} Logo`,
                              width: value?.width || 80,
                            };
                            handleChangeFocusedOrganisation('logo', image);
                          } else {
                            handleChangeFocusedOrganisation('logo', null);
                          }
                        }
                      },
                      value: {
                        search: '',
                        imageId: focusedOrganisation?.logo?.imageId,
                        imageOptions: focusedOrganisation?.logo?.imageOptions,
                        alt: focusedOrganisation?.logo?.alt || 'Logo',
                        width: focusedOrganisation?.logo?.width,
                      },
                    });
                    setDialogOpen(true);
                  }}
                >
                  <span className="material-icons md-24">account_circle</span>
                </Fab>
              </GridCell>
              <GridCell>
                <Fab
                  size='small'
                  onClick={() => {
                    setDialogData({
                      type: 'selectImage',
                      title: 'Add Background Image',
                      data: {
                        imageType: 'background',
                        callback: (value) => {
                          if (value?.imageId) {
                            const image = {
                              imageId: value.imageId,
                              tags: undefined,
                              alt: `${focusedOrganisation.name} Splash Image`,
                              description: `${focusedOrganisation.name} Splash Image`,
                              fade: value?.fade || 80,
                            };

                            handleChangeFocusedOrganisation('splash', image);
                          } else {
                            handleChangeFocusedOrganisation('splash', null);
                          }
                        }
                      },
                      value: {
                        search: '',
                        imageId: focusedOrganisation?.splash?.imageId,
                        imageOptions: focusedOrganisation?.splash?.imageOptions,
                        alt: focusedOrganisation?.splash?.alt || 'Background Image',
                        fade: focusedOrganisation?.splash?.fade,
                      },
                    });
                    setDialogOpen(true);
                  }}
                >
                  <span className="material-icons md-24">panorama</span>
                </Fab>
              </GridCell>
            </GridContainer>
          </div>
          <GridContainer
            style={{
              width: '100%',
              height: '100%',
              background: `url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,w_1024,q_auto:good/${focusedOrganisation?.splash?.imageId || 'media-assets/default_e2qiho.jpg'}")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundBlendMode: focusedOrganisation?.splash?.fade ? focusedOrganisation?.splash?.fade < 0 ? 'darken' : 'lighten' : 'lighten',
              backgroundColor: focusedOrganisation?.splash?.fade ? focusedOrganisation?.splash?.fade < 0 ? `rgba(0, 0, 0, ${-(focusedOrganisation?.splash?.fade / 100)})` : `rgba(255, 255, 255, ${(focusedOrganisation?.splash?.fade / 100)})` : undefined,
            }}
          >
            <GridCell
              style={{ minWidth: '60%' }}
              weight={2} center={true} centerWeights={{ top: 1, bottom: 2 }}
            >
              <GridContainer>
                <GridCell weight={1} />
                <GridCell>
                  {focusedOrganisation?.logo &&
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,w_1024,q_auto:good/${focusedOrganisation?.logo?.imageId}`}
                      alt={`${focusedOrganisation?.name} Logo`}
                      style={{
                        maxWidth: 1024,
                        maxHeight: '70vh',
                        width: `${focusedOrganisation?.logo?.width || 80}%`,
                        paddingLeft: `${focusedOrganisation?.logo?.width ? (100 - focusedOrganisation.logo.width) / 2 : 10}%`,
                        paddingRight: `${focusedOrganisation?.logo?.width ? (100 - focusedOrganisation.logo.width) / 2 : 10}%`
                      }}
                    />
                  }
                  <div>
                    <input
                      style={{
                        width: '100%',
                        background: 'rgba(0,0,0,0)',
                        border: 'none',
                        outline: 'none',

                        fontSize: textData.heading.fontSize,
                        fontWeight: textData.heading.fontWeight,
                        textAlign: 'center',
                        color: focusedOrganisation?.heading?.colorCode,
                      }}
                      value={focusedOrganisation?.heading?.title}
                      onChange={(e) => {
                        const headingProcessed = {
                          ...(focusedOrganisation?.heading || {}),
                          title: e.target.value,
                        }
                        handleChangeFocusedOrganisation('heading', headingProcessed);
                      }}
                    />
                    <input
                      style={{
                        width: '100%',
                        background: 'rgba(0,0,0,0)',
                        border: 'none',
                        outline: 'none',

                        fontSize: textData.subHeading.fontSize,
                        fontWeight: textData.subHeading.fontWeight,
                        textAlign: 'center',
                        color: focusedOrganisation?.heading?.colorCode,
                      }}
                      value={focusedOrganisation?.heading?.subTitle}
                      onChange={(e) => {
                        const headingProcessed = {
                          ...(focusedOrganisation?.heading || {}),
                          subTitle: e.target.value,
                        }
                        handleChangeFocusedOrganisation('heading', headingProcessed);
                      }}
                    />
                    {/*
                                <Typography size='heading' style={{ textAlign: 'center', color: focusedOrganisation?.heading?.colorCode || undefined }}>
                                  {focusedOrganisation?.heading?.title}
                                </Typography>
                                <Typography size='subHeading' style={{ textAlign: 'center', color: focusedOrganisation?.heading?.colorCode || undefined }}>
                                  {focusedOrganisation?.heading?.subTitle}
                                </Typography>
                                */}
                  </div>
                </GridCell>
                <GridCell weight={1} />
              </GridContainer>
            </GridCell>
            <GridCell
              weight={1} center={true} centerWeights={{ top: 1, bottom: 2 }}
            >
              <div style={{
                margin: 40,
                padding: 20,
                background: 'rgba(0, 0, 0, 0.6)',
                maxWidth: 400
              }}>
                {focusedOrganisation?.phone &&
                  <div
                    style={{ borderStyle: 'solid', borderColor: 'white', borderWidth: 1, padding: 10, margin: 10 }}
                  >
                    <Typography style={{ textAlign: 'center', color: 'white' }}>
                      {focusedOrganisation?.phone}
                    </Typography>
                  </div>
                }
                {focusedOrganisation?.email &&
                  <div
                    style={{ borderStyle: 'solid', borderColor: 'white', borderWidth: 1, padding: 10, margin: 10, cursor: 'pointer' }}
                  >
                    <Typography style={{ textAlign: 'center', color: 'white' }}>
                      {focusedOrganisation?.email}
                    </Typography>
                  </div>
                }
                {focusedOrganisation?.website &&
                  <div
                    style={{ borderStyle: 'solid', borderColor: 'white', borderWidth: 1, padding: 10, margin: 10, cursor: 'pointer' }}
                  >
                    <Typography style={{ textAlign: 'center', color: 'white' }}>
                      Website
                    </Typography>
                  </div>
                }
                <div
                  style={{ borderStyle: 'solid', borderColor: 'white', borderWidth: 1, padding: 10, margin: 10, cursor: 'pointer' }}
                  onClick={() => { }}
                >
                  <Typography style={{ textAlign: 'center', color: 'white' }}>
                    Reviews
                  </Typography>
                </div>
                {
                  ((focusedOrganisation?.bank && focusedOrganisation?.stripe && focusedOrganisation?.shopEnabled) ||
                    (me?._id && (me?._id === focusedOrganisation?.owner || (me?.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))))) &&
                  <div
                    style={{ borderStyle: 'solid', borderColor: 'white', borderWidth: 1, padding: 10, margin: 10, cursor: 'pointer' }}
                    onClick={() => { }}
                  >
                    <Typography style={{ textAlign: 'center', color: 'white' }}>
                      Shop
                    </Typography>
                  </div>
                }
                <GridContainer>
                  <GridCell weight={1} />
                  {focusedOrganisation?.social?.facebook &&
                    <GridCell>
                      <div style={{ width: 20, height: 20, margin: 10, padding: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: 10, borderColor: 'white' }}>
                        <img src='https://res.cloudinary.com/taitokerau-tatou/image/upload/media-assets/facebook_gapsnz.png' alt='facebook logo' style={{ width: '100%' }} />
                      </div>
                    </GridCell>
                  }
                  {focusedOrganisation?.social?.instagram &&
                    <GridCell>
                      <div style={{ width: 20, height: 20, margin: 10, padding: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: 10, borderColor: 'white' }}>
                        <img src='https://res.cloudinary.com/taitokerau-tatou/image/upload/media-assets/instagram_gwld2d.png' alt='instagram logo' style={{ width: '100%' }} />
                      </div>
                    </GridCell>
                  }
                  {focusedOrganisation?.social?.twitter &&
                    <GridCell>
                      <div style={{ width: 20, height: 20, margin: 10, padding: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: 10, borderColor: 'white' }}>
                        <img src='https://res.cloudinary.com/taitokerau-tatou/image/upload/media-assets/twitter_qiny9m.png' alt='facebook logo' style={{ width: '100%' }} />
                      </div>
                    </GridCell>
                  }
                  <GridCell weight={1} />
                </GridContainer>
              </div>
            </GridCell>
          </GridContainer>
        </div>
      }

      <LayoutEdit
        imageLibrary={imageLibrary}
        videoLibrary={focusedOrganisation?.videoLibrary || []}
        sections={(focusedOrganisation?.layout?.pages && focusedOrganisation?.layout?.pages[page]?.sections) || []}
        changeSection={(type, index, path, value) => changePage(page, type, index, path, value)}
        changeParent={(type, path, value) => changeParent(type, path, value)}
      />

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
            setDialogData(undefined);
            setDialogOpen(false);
          }}
        >
          <div style={{ padding: 10, textAlign: 'center', background: paletteData.primary.standard.background }}>
            <Typography size='title' style={{ color: paletteData.primary.standard.foreground }}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{ padding: 10 }}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'editHeader' &&
            <div style={{ padding: 10 }}>
              <HeaderForm
                header={dialogData?.value || {}}
                handleChange={(name, value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...dialogData.value,
                      [name]: value,
                    },
                  });
                }}
                processing={processing}
              />
            </div>
          }
          {dialogData?.type === 'movePage' &&
            <div style={{ padding: 10 }}>
              <Input
                type='number'
                label='New Index'
                value={dialogData?.value?.index}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      index: value - 1,
                      humanIndex: value,
                    }
                  });
                }}
              />
            </div>
          }
          {dialogData?.type === 'selectImage' &&
            <div style={{ padding: 10 }}>
              {dialogData?.data?.imageType === 'background' &&
                <div>
                  <Input
                    label='Fade %'
                    value={dialogData?.value?.fade}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          fade: value,
                        }
                      })
                    }}
                  />
                </div>
              }
              {dialogData?.data?.imageType === 'logo' &&
                <div>
                  <Input
                    label='Logo width %'
                    value={dialogData?.value?.width}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          width: value,
                        }
                      })
                    }}
                  />
                </div>
              }
              <Input
                label='Search Images'
                value={dialogData?.value?.search}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  })
                }}
              />
              {processing && <ProgressBar palette='secondary' />}
              <GridContainer>
                {dialogData?.value?.imageId &&
                  <GridCell
                    style={{ width: 100, margin: 5, cursor: 'pointer' }}
                    onClick={() => {
                      if (!processing) {
                        dialogData?.data.callback({
                          imageId: dialogData?.value?.imageId,
                          imageOptions: dialogData?.value?.imageOptions,
                          alt: dialogData?.value?.alt,
                          width: dialogData?.value?.width,
                          fade: dialogData?.value?.fade,
                        });
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_100,h_100/${dialogData?.value?.imageId || 'media-assets/hudson-hintze-vpxeE7s-my4-unsplash_axrfv6_oemuhc.jpg'}`}
                      alt={dialogData?.value?.alt}
                      style={{ width: '100%' }}
                    />
                    <Typography style={{ textAlign: 'center' }}>
                      Current
                    </Typography>
                  </GridCell>
                }
                <GridCell
                  style={{ width: 100, margin: 5, cursor: 'pointer' }}
                  onClick={() => {
                    if (!processing) {
                      imageUploadRef.current.click();
                    }
                  }}
                >
                  <input
                    ref={imageUploadRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      const file = event?.target?.files?.length > 0 ? event.target.files[0] : undefined;
                      handleUploadImage(file, (response, error) => {
                        if (!error) {
                          dialogData?.data.callback({
                            imageId: response.imageId,
                            imageOptions: dialogData?.value?.imageOptions,
                            alt: response.alt,
                            width: dialogData?.value?.width,
                            fade: dialogData?.value?.fade,
                          });
                          const image = {
                            imageId: response.imageId,
                            tags: '',
                            alt: response.alt,
                            description: '',
                          };
                          changeParent('insert', 'gallery', image);
                          setDialogData(undefined);
                          setDialogOpen(false);
                        } else {
                          console.log(error);
                        }
                      });
                    }}
                  />
                  <div style={{ width: '100%', height: 100, textAlign: 'center', background: '#c6c6c6' }}>
                    <span className="material-icons md-24" style={{ marginTop: 35 }}>image</span>
                    <span className="material-icons md-24" style={{ marginTop: 35 }}>add</span>
                  </div>
                  <Typography style={{ textAlign: 'center' }}>
                    New
                  </Typography>
                </GridCell>
                {dialogData?.value?.imageId &&
                  <GridCell
                    style={{ width: 100, margin: 5, cursor: 'pointer' }}
                    onClick={() => {
                      if (!processing) {
                        dialogData?.data.callback({ imageId: undefined, imageOptions: undefined, alt: undefined, width: 80, fade: 80 });
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <div style={{ width: '100%', height: 100, textAlign: 'center', background: '#c6c6c6' }}>
                      <span className="material-icons md-24" style={{ marginTop: 35 }}>image</span>
                      <span className="material-icons md-24" style={{ marginTop: 35 }}>remove</span>
                    </div>
                    <Typography style={{ textAlign: 'center' }}>
                      Remove
                    </Typography>
                  </GridCell>
                }
                {focusedOrganisation?.gallery?.length > 0 && focusedOrganisation?.gallery.filter((i, iIndex) => {
                  return (i.alt || '').toLowerCase().includes(dialogData?.value?.search?.toLowerCase());
                }).map((i, iIndex) => (
                  <GridCell
                    key={iIndex}
                    style={{ width: 100, margin: 5, cursor: 'pointer' }}
                    onClick={() => {
                      if (!processing) {
                        dialogData?.data.callback({
                          imageId: i.imageId,
                          imageOptions: dialogData?.value?.imageOptions,
                          alt: i.alt,
                          width: dialogData?.value?.width,
                          fade: dialogData?.value?.fade,
                        });
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_100,h_100/${i.imageId}`}
                      alt={i.alt}
                      style={{ width: '100%' }}
                    />
                    <Typography style={{ textAlign: 'center' }}>
                      {i.alt}
                    </Typography>
                  </GridCell>
                ))}
              </GridContainer>
            </div>
          }
          {dialogData?.type === 'addHeading' &&
            <div style={{ padding: 10 }}>
              <Input
                label="Title"
                value={dialogData?.value?.title || ''}
                onChange={(value) => {
                  setDialogData({
                    ...(dialogData || {}),
                    value: {
                      ...(dialogData?.value || {}),
                      title: value,
                    }
                  });
                }}
              />
              <Input
                label="Sub Title"
                value={dialogData?.value?.subTitle || ''}
                onChange={(value) => {
                  setDialogData({
                    ...(dialogData || {}),
                    value: {
                      ...(dialogData?.value || {}),
                      subTitle: value,
                    }
                  });
                }}
              />
              <Input
                label="Color Code"
                value={dialogData?.value?.colorCode || ''}
                onChange={(value) => {
                  setDialogData({
                    ...(dialogData || {}),
                    value: {
                      ...(dialogData?.value || {}),
                      colorCode: value,
                    }
                  });
                }}
              />
            </div>
          }
          {dialogData?.type === 'colorPicker' &&
            <div style={{ padding: 10 }}>
              <BlockPicker
                triangle='hide'
                width='100%'
                colors={['#ffffff', '#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', '#000000']}
                color={dialogData?.value?.colorCode}
                onChange={(color) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      colorCode: color.hex,
                    }
                  });
                }}
              />
            </div>
          }
          <GridContainer>
            <GridCell weight={1} />
            <GridCell style={{ padding: 10 }}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'editHeader' &&
              <GridCell style={{ padding: 10 }}>
                <Button
                  palette='primary'
                  onClick={() => {
                    const tempLayout = {
                      ...focusedOrganisation.layout,
                      header: dialogData?.value,
                    };
                    handleChangeFocusedOrganisation('layout', tempLayout);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Edit Header
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'messageWithCallback' &&
              <GridCell style={{ padding: 10 }}>
                <Button
                  palette='primary'
                  onClick={() => {
                    dialogData?.data?.callback();
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Submit
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'addHeading' &&
              <GridCell style={{ padding: 10 }}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    if (dialogData?.data?.callback) {
                      dialogData.data.callback(dialogData.value);
                    }
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Submit
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'colorPicker' &&
              <GridCell style={{ padding: 10 }}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    if (dialogData?.data?.callback) {
                      dialogData.data.callback(dialogData.value);
                    }
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Submit
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'selectImage' && dialogData?.value?.imageId &&
              <GridCell style={{ padding: 10 }}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    if (dialogData?.data?.callback) {
                      dialogData?.data.callback({
                        imageId: dialogData?.value?.imageId,
                        imageOptions: dialogData?.value?.imageOptions,
                        alt: dialogData?.value?.alt,
                        width: dialogData?.value?.width,
                        fade: dialogData?.value?.fade,
                      });
                    }
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Submit
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditOrganisationLayoutSubPanel.propTypes = {
  me: PropTypes.shape({}),
  focusedOrganisation: PropTypes.shape({}),
  handleChangeFocusedOrganisation: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
};

export default EditOrganisationLayoutSubPanel;
