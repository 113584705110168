import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {paletteData} from '../../../styles';

function ProgressBar({progress, palette}) {
  const [tempProgress, setTempProgress] = useState(progress !== undefined ? progress : 0);

  useEffect(() => {
    var tp = tempProgress;
    const stp = setTempProgress;

    const timer = setInterval(() => {
      tp = (tp + 1)% 100;
      stp(tp);
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, [tempProgress]);

  return (
    <div style={{
      width: '100%',
      height: 7,
      background: palette ? paletteData[palette].highlight.background : paletteData['blank'].highlight.background,
    }}>
      <div style={{
        width: `${tempProgress}%`,
        marginTop: 1,
        marginBottom: 1,
        height: 5,
        background: palette ? paletteData[palette].highlight.foreground : paletteData['blank'].highlight.foreground,
      }}/>
    </div>
  );
}

ProgressBar.propTypes = {
  palette: PropTypes.string,
  progress: PropTypes.number,
};

export default ProgressBar;
