import React from 'react';
import PropTypes from 'prop-types';

import {generateGridCell} from '../../';
import RichTextBlockEdit from '../RichTextBlockEdit';
import TitleBlockEdit from '../TitleBlockEdit';
import ImageBlockEdit from '../ImageBlockEdit';

import {Typography, Fab} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

function GridBlockEdit({
  block,
  blockIndex,
  maxIndex,
  removeBlock,
  changeSection,
  changeParent,
  stripImageURL,
  stripImageOptions,
  changeBlockIndex,

  dialogData,
  dialogOpen,
  setDialogData,
  setDialogOpen
}) {
  return (
    <div style={{background: '#c6c6c6'}}>
      <GridContainer>
        <GridCell weight={1}/>
        <GridCell>
          <GridContainer
            style={{
              padding: 5,
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 5,
              background: 'white',
              margin: 10,
            }}
          >
            <GridCell center={true}>
              <Fab
                size='small'
                onClick={() => {
                  const childrenProcessed = block.children || [];
                  const cell = generateGridCell({
                    options: {
                      weight: 1,
                    },
                    children: [],
                  });
                  childrenProcessed.push(cell);
                  changeSection('update', blockIndex, 'children', childrenProcessed);
                }}
              >
                <span className="material-icons md-24">view_column</span>
              </Fab>
            </GridCell>
            <GridCell center={true}>
              <Fab
                size='small'
                style={{background: '#c6c6c6', cursor: 'pointer'}}
                onClick={() => {
                  setDialogData({
                    type: 'moveSection',
                    title: 'Move Section',
                    data: {
                      maxIndex,
                      currentIndex: blockIndex + 1,
                      callbackMove: (shift) => changeBlockIndex(shift),
                      callbackRemove: () => removeBlock(blockIndex),
                    },
                    value: {
                      index: blockIndex + 1,
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                <Typography style={{textAlign: 'center'}}>
                  {blockIndex + 1}
                </Typography>
              </Fab>
            </GridCell>
          </GridContainer>
        </GridCell>
      </GridContainer>
      <div style={{padding: 10}}>
        {!(block?.children?.length > 0) &&
          <div style={{background: 'white', padding: 10}}>
            <Typography style={{textAlign: 'center'}}>
              Grid has no cells
            </Typography>
          </div>
        }
        {block?.children?.length > 0 &&
          <GridContainer>
            {block.children.map((c, cIndex) => (
              <GridCell
                weight={c.options?.weight}
                key={cIndex}
                style={{padding: 10}}
              >
                <GridContainer>
                  <GridCell weight={1}/>
                  <GridCell>
                    <GridContainer
                      style={{
                        padding: 5,
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderRadius: 5,
                        background: 'white',
                        marginBottom: 10,
                      }}
                    >
                      <GridCell center={true}>
                        <Fab
                          size='small'
                          onClick={() => {
                            setDialogData({
                              type: 'messageWithCallback',
                              title: 'Remove Cell',
                              message: 'Do you wish to remove this cell?',
                              data: {
                                callback: (value) => {
                                  const childrenProcessed = block.children || [];
                                  childrenProcessed.splice(value.cellIndex, 1);
                                  changeSection('update', blockIndex, 'children', childrenProcessed);
                                },
                              },
                              value: {
                                cellIndex: cIndex,
                              },
                            });
                            setDialogOpen(true);
                          }}
                        >
                          <span className="material-icons md-24">remove</span>
                        </Fab>
                      </GridCell>
                      <GridCell center={true}>
                        <Fab
                          size='small'
                          onClick={() => {
                            if(cIndex > 0) {
                              const childTemp = block.children[cIndex];
                              const newIndex = cIndex - 1;
                              const childrenProcessed = block.children || [];
                              childrenProcessed.splice(cIndex, 1);
                              childrenProcessed.splice(newIndex, 0, childTemp);
                              changeSection('update', blockIndex, 'children', childrenProcessed);
                            }
                          }}
                        >
                          <span className="material-icons md-24">navigate_before</span>
                        </Fab>
                      </GridCell>
                      <GridCell center={true}>
                        <Fab
                          size='small'
                          onClick={() => {
                            if(cIndex < (block.children.length - 1)) {
                              const childTemp = block.children[cIndex];
                              const newIndex = cIndex + 1;
                              const childrenProcessed = block.children || [];
                              childrenProcessed.splice(cIndex, 1);
                              childrenProcessed.splice(newIndex, 0, childTemp);
                              changeSection('update', blockIndex, 'children', childrenProcessed);
                            }
                          }}
                        >
                          <span className="material-icons md-24">navigate_next</span>
                        </Fab>
                      </GridCell>
                      <GridCell center={true}>
                        <Fab
                          size='small'
                          onClick={() => {
                            setDialogData({
                              type: 'addSection',
                              title: 'Add Row',
                              data: {
                                limitSections: 'grid',
                                maxIndex: (c.children || []).length + 1,
                                callback: (index, section) => {
                                  const childrenProcessed = c.children || [];
                                  childrenProcessed.splice(index, 0, section);
                                  changeSection('update', blockIndex, `children.${cIndex}.children`, childrenProcessed);
                                },
                              },
                              value: {
                                index: (c.children || []).length + 1,
                              }
                            });
                            setDialogOpen(true);
                          }}
                        >
                          <span className="material-icons md-24">view_list</span>
                        </Fab>
                      </GridCell>
                    </GridContainer>
                  </GridCell>
                </GridContainer>
                <div style={{background: 'white', padding: 10}}>
                  {!(c.children?.length > 0) &&
                    <div>
                      <Typography>
                        Cell has no rows
                      </Typography>
                    </div>
                  }
                  {c.children?.length > 0 && c.children.map((r, rIndex) => (
                    <div key={rIndex}>
                      {r.section === 'titleBlock' &&
                        <TitleBlockEdit
                          controls='grid'
                          block={r}
                          removeBlock={(index) => {
                            const childrenProcessed = c.children;
                            childrenProcessed.splice(index, 1);
                            changeSection('update', blockIndex, `children.${cIndex}.children`, childrenProcessed);
                          }}
                          blockIndex={rIndex}
                          maxIndex={c.children.length}
                          changeBlockIndex={(shift) => {
                            if((rIndex + shift) >= 0 && (rIndex + shift) <= (c.children?.length - 1)) {
                              const currentIndex = rIndex;
                              const newIndex = rIndex + shift;
                              const tempBlock = c.children[currentIndex];
                              const childrenProcessed = c.children;
                              childrenProcessed.splice(currentIndex, 1);
                              childrenProcessed.splice(newIndex, 0, tempBlock);
                              changeSection('update', blockIndex, `children.${cIndex}.children`, childrenProcessed);
                            }
                          }}
                          changeSection={(type, index, path, value) => {
                            changeSection(type, blockIndex, `children.${cIndex}.children.${index}.${path}`, value);
                          }}
                          cangeParent={changeParent}
                          dialogOpen={dialogOpen}
                          setDialogOpen={setDialogOpen}
                          dialogData={dialogData}
                          setDialogData={setDialogData}
                        />
                      }
                      {r.section === 'richTextBlock' &&
                        <RichTextBlockEdit
                          controls='grid'
                          block={r}
                          removeBlock={(index) => {
                            const childrenProcessed = c.children;
                            childrenProcessed.splice(index, 1);
                            changeSection('update', blockIndex, `children.${cIndex}.children`, childrenProcessed);
                          }}
                          blockIndex={rIndex}
                          maxIndex={c.children.length}
                          changeBlockIndex={(shift) => {
                            if((rIndex + shift) >= 0 && (rIndex + shift) <= (c.children?.length - 1)) {
                              const currentIndex = rIndex;
                              const newIndex = rIndex + shift;
                              const tempBlock = c.children[currentIndex];
                              const childrenProcessed = c.children;
                              childrenProcessed.splice(currentIndex, 1);
                              childrenProcessed.splice(newIndex, 0, tempBlock);
                              changeSection('update', blockIndex, `children.${cIndex}.children`, childrenProcessed);
                            }
                          }}
                          changeSection={(type, index, path, value) => {
                            changeSection(type, blockIndex, `children.${cIndex}.children.${index}.${path}`, value);
                          }}

                          changeParent={changeParent}
                          stripImageURL={stripImageURL}
                          dialogOpen={dialogOpen}
                          setDialogOpen={setDialogOpen}
                          dialogData={dialogData}
                          setDialogData={setDialogData}
                        />
                      }
                      {r.section === 'imageBlock' &&
                        <ImageBlockEdit
                          block={r}
                          removeBlock={(index) => {
                            const childrenProcessed = c.children;
                            childrenProcessed.splice(index, 1);
                            changeSection('update', blockIndex, `children.${cIndex}.children`, childrenProcessed);
                          }}
                          blockIndex={rIndex}
                          maxIndex={c.children.length}
                          changeBlockIndex={(shift) => {
                            if((rIndex + shift) >= 0 && (rIndex + shift) <= (c.children?.length - 1)) {
                              const currentIndex = rIndex;
                              const newIndex = rIndex + shift;
                              const tempBlock = c.children[currentIndex];
                              const childrenProcessed = c.children;
                              childrenProcessed.splice(currentIndex, 1);
                              childrenProcessed.splice(newIndex, 0, tempBlock);
                              changeSection('update', blockIndex, `children.${cIndex}.children`, childrenProcessed);
                            }
                          }}
                          changeSection={(type, index, path, value) => {
                            changeSection(type, blockIndex, `children.${cIndex}.children.${index}.${path}`, value);
                          }}
                          changeParent={changeParent}
                          stripImageURL={stripImageURL}
                          stripImageOptions={stripImageOptions}
                          dialogOpen={dialogOpen}
                          setDialogOpen={setDialogOpen}
                          dialogData={dialogData}
                          setDialogData={setDialogData}
                        />
                      }
                    </div>
                  ))}
                </div>
              </GridCell>
            ))}
          </GridContainer>
        }
      </div>
    </div>
  );
}

GridBlockEdit.propTypes = {
  block: PropTypes.shape({}),
  changeSection: PropTypes.func,
  changeParent: PropTypes.func,
};

export default GridBlockEdit;
