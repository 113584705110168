import React from 'react';
import PropTypes from 'prop-types';

import {Typography, Fab} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

function GalleryBlockEdit({
  block,
  removeBlock,
  blockIndex,
  maxIndex,
  changeSection,
  changeParent,
  changeBlockIndex,

  dialogData,
  dialogOpen,
  setDialogData,
  setDialogOpen
}) {
  return (
    <div style={{
      position: 'relative',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          minWidth: 20,
          padding: 5,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 5,
          background: 'white',
        }}
      >
        <GridContainer>
          <GridCell center={true}>
            <Fab
              size='small'
              style={{background: '#c6c6c6', cursor: 'pointer'}}
              onClick={() => {
                setDialogData({
                  type: 'moveSection',
                  title: 'Move Section',
                  data: {
                    maxIndex,
                    currentIndex: blockIndex + 1,
                    callbackMove: (shift) => changeBlockIndex(shift),
                    callbackRemove: () => removeBlock(blockIndex),
                  },
                  value: {
                    index: blockIndex + 1,
                  },
                });
                setDialogOpen(true);
              }}
            >
              <Typography style={{textAlign: 'center'}}>
                {blockIndex + 1}
              </Typography>
            </Fab>
          </GridCell>
        </GridContainer>
      </div>
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        ...block.options.style,
      }}>
        <GridContainer>
          {block?.children[0]?.options?.images?.length > 0 && block?.children[0]?.options?.images.map((i, iIndex) => (
            <GridCell
              key={iIndex}
              style={{
                width: 140,
                padding: 10,
                position: 'relative',
              }}
            >
              <div style={{position: 'absolute', bottom: 20, right: 15, padding: 3, borderRadius: 5, background: 'white'}}>
                <span
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setDialogData({
                      type: 'messageWithCallback',
                      title: 'Remove Image',
                      message: 'Do you wish to remove this image?',
                      data: {
                        callback: (value) => {
                          if(value.iIndex !== undefined) {
                            const imagesProcessed = block?.children[0]?.options?.images || [];
                            imagesProcessed.splice(value.iIndex, 1);
                            changeSection('update', blockIndex, 'children.0.options.images', imagesProcessed);
                          }
                        }
                      },
                      value: {
                        iIndex,
                      },
                    });
                    setDialogOpen(true);
                  }}
                  className="material-icons md-24"
                >
                  remove
                </span>
                <span
                  className="material-icons md-24"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setDialogData({
                      type: 'selectImage',
                      title: 'Edit Image',
                      data: {
                        callback: (value) => {
                          if(value.imageId) {
                            const imagesProcessed = block?.children[0]?.options?.images || [];
                            imagesProcessed.splice(iIndex, 1, value);
                            changeSection('update', blockIndex, 'children.0.options.images', imagesProcessed);
                          }
                        }
                      },
                      value: {
                        search: '',
                      },
                    });
                    setDialogOpen(true);
                  }}
                >
                  edit
                </span>
              </div>
              <img src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${i.imageId}`} alt={i.alt} style={{width: '100%'}}/>
            </GridCell>
          ))}
          <GridCell
            center={true}
            style={{
              width: 140,
              height: 140,
              margin: 10,
              cursor: 'pointer',
              textAlign: 'center',
              background: '#c6c6c6'
            }}
            onClick={() => {
              setDialogData({
                type: 'selectImage',
                title: 'Add Image',
                data: {
                  callback: (value) => {
                    if(value.imageId) {
                      const imagesProcessed = block?.children[0]?.options?.images || [];
                      imagesProcessed.push(value);
                      changeSection('update', blockIndex, 'children.0.options.images', imagesProcessed);
                    }
                  }
                },
                value: {
                  search: '',
                },
              });
              setDialogOpen(true);
            }}
          >
            <span className="material-icons md-24">add</span>
          </GridCell>
        </GridContainer>
      </div>
    </div>
  );
}

GalleryBlockEdit.propTypes = {
  block: PropTypes.shape({}),
  changeSection: PropTypes.func,
  changeParent: PropTypes.func,
};

export default GalleryBlockEdit;
