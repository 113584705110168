import React from 'react';
import PropTypes from 'prop-types';

function VimeoEmbed({vimeoId, preview}) {
  console.log({vimeoId});
  return (
    <div style={{width: '100%', paddingBottom: '56%', position: 'relative', pointerEvents: preview ? 'none' : 'auto'}}>
      <iframe title={vimeoId} style={{width: '100%', height: '100%', position: 'absolute', top: 0}} src={`https://player.vimeo.com/video/${vimeoId}`} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
  );
}

VimeoEmbed.propTypes = {
  vimeoId: PropTypes.string,
  preview: PropTypes.bool,
};

export default VimeoEmbed;
