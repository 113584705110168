import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {paletteData, textData} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

function Input({onChange, onFocus, onBlur, palette, type, label, placeholder, name, value, style, containerStyle, inputStyle, labelStyle, labelSize, labelHeight, highlightStyle, indicatorStyle, autoComplete, disabled, forceShrink, postIcon, preIcon, required}) {
  const [inputValue, setInputValue] = useState(value || '');
  const [focused, setFocused] = useState(false);
  const [highlightPosition, setHighlightPosition] = useState((focused) ? 'translateX(0%)' : 'translateX(-105%)');
  const [labelColor, setLabelColor] = useState((focused) ? paletteData[palette || 'blank'].highlight.foreground: paletteData[palette || 'blank'].standard.foreground);

  const [labelHeightInternal, setLabelHeightInternal] = useState((forceShrink || value || value === 0 || inputValue || focused) ? 25 : (labelHeight || 5));
  const [labelSizeInternal, setLabelSizeInternal] = useState((forceShrink || value || value === 0 || inputValue || focused) ? '.8em' : (labelSize || '1em'));

  useEffect(() => {
    if(value !== inputValue) {
      setInputValue(value);
    }
    setLabelHeightInternal((forceShrink || value || value === 0 || inputValue || focused) ? 25 : (labelHeight || 5));
    setHighlightPosition((focused) ? 'translateX(0%)' : 'translateX(-105%)');
    setLabelColor((focused) ? paletteData[palette || 'blank'].highlight.foreground: paletteData[palette || 'blank'].standard.foreground);
    setLabelSizeInternal((forceShrink || value || value === 0 || inputValue || focused) ? '.8em' : (labelSize || '1em'));

	}, [value, palette, focused, forceShrink, inputValue, labelSize, labelHeight]);

  return (
    <div style={{padding: 5, marginTop: type === 'date' ? 0 : 2, ...style}}>
      <div style={{position: 'relative', width: '100%', overflow: 'hidden', minHeight: 45, ...containerStyle}}>
        <GridContainer>
          {preIcon !== undefined &&
            <GridCell>
              <div
                style={{cursor: (preIcon.callback !== undefined) ? 'pointer' : undefined}}
                onClick={() => {
                  if(preIcon.callback !== undefined) {
                    preIcon.callback();
                  }
                }}
              >
                {preIcon.display}
              </div>
            </GridCell>
          }
          <GridCell weight={1}>
            <input
              placeholder={placeholder}
              disabled={disabled || false}
              type={type}
              autoComplete={autoComplete}
              name={name}
              value={value || ''}
              style={{
                width: '100%',
                height: '100%',
                paddingTop: 13,
                border: 'none',
                outline: 'none',
                fontSize: '1em',
                fontFamily: textData.text.fontFamily,
                color: paletteData[palette || 'blank'].standard.foreground,
                background: 'rgba(0, 0, 0, 0.0)',
                ...inputStyle,
              }}
              onFocus={() => {
                // update look when input focused
                setFocused(true);
                setLabelHeightInternal(25);
                setHighlightPosition('translateX(0%)');
                setLabelColor(paletteData[palette || 'blank'].highlight.foreground);
                setLabelSizeInternal('.8em');
                if(onFocus) {
                  onFocus();
                }
              }}
              onBlur={() => {
                // update look when input unfocused
                setFocused(false);
                setLabelHeightInternal((value || value === 0 || forceShrink) ? 25 : (labelHeight || 5));
                setLabelSizeInternal((value || value === 0 || forceShrink)? '.8em' :'1em');
                setHighlightPosition('translateX(-105%)');
                setLabelColor(paletteData[palette || 'blank'].standard.foreground);
                if(onBlur) {
                  onBlur();
                }
              }}
              onChange={(e) => {
                setInputValue(e.target.value);
                if(onChange) {
                  if(type !== 'number' || e.target.value === '') {
                    onChange(e.target.value);
                  } else if(!isNaN(e.target.value)) {
                    onChange(parseFloat(e.target.value));
                  }
                }
              }}
            />
          </GridCell>
          {postIcon !== undefined &&
            <GridCell>
              <div
                style={{cursor: (postIcon.callback !== undefined) ? 'pointer' : undefined}}
                onClick={() => {
                  if(postIcon.callback !== undefined) {
                    postIcon.callback();
                  }
                }}
              >
                {postIcon.display}
              </div>
            </GridCell>
          }
        </GridContainer>

        <label htmlFor={name} style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          borderBottom: `1px solid ${paletteData[palette || 'blank'].standard.foreground}`,
          ...indicatorStyle,
        }}>
          <span
            style={{
              position: 'absolute',
              fontSize: labelSizeInternal,
              fontFamily: textData.text.fontFamily,
              color: labelColor,
              bottom: labelHeightInternal,
              left: 2,
              transition: '0.2s',
              ...labelStyle,
            }}
          >
            {label} {required ? '*' : ''}
          </span>
        </label>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            borderBottom: `2px solid ${paletteData[palette || 'blank'].highlight.foreground}`,
            transition: '0.2s',
            transform: highlightPosition,
            ...highlightStyle,
          }}
        />
      </div>
    </div>

  );
}

Input.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.any,
  forceShrink: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  palette: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.shape({}),
  inputStyle: PropTypes.shape({}),
  containerStyle: PropTypes.shape({}),
  labelStyle:  PropTypes.shape({}),
  labelSize: PropTypes.string,
  labelHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highlightStyle: PropTypes.shape({}),
  indicatorStyle: PropTypes.shape({}),
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  forceText:  PropTypes.bool,
  preIcon: PropTypes.shape({
    display: PropTypes.node,
    callback: PropTypes.func,
  }),
  postIcon: PropTypes.shape({
    display: PropTypes.node,
    callback: PropTypes.func,
  }),
  placeholder: PropTypes.string,
};

export default Input;
