import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {paletteData, textData} from '../../../styles';

function Button({children, onClick, palette, style, disabled, size, href, target, rel, highlight}) {
  const [styleData] = useState({
    backgroundColor: palette ? paletteData[palette][highlight ? 'highlight' : 'standard'].background : paletteData['blank'][highlight ? 'highlight' : 'standard'].background,
    color: palette ? paletteData[palette][highlight ? 'highlight' : 'standard'].foreground : paletteData['blank'][highlight ? 'highlight' : 'standard'].foreground,
    border: 'none',
    outline: 'none',
    textDecoration: 'none',
    display: 'block',
    cursor: 'pointer',
    transition: '0.3s',
    padding: size === 'small' ? 6 : 10,
    fontSize: size === 'small' ? '.8em' : '1em',
    textAlign: 'center',
    fontFamily: textData.button.fontFamily,
    ...style,
  });

  const disabledButton =
  <div
    onClick={() => {}}
    style={{
      backgroundColor: '#c6c6c6',
      color: '#242424',
      border: 'none',
      outline: 'none',
      textDecoration: 'none',
      display: 'block',
      padding: size === 'small' ? 6 : 10,
      fontSize: size === 'small' ? '.8em' : '1em',
      letterSpacing: '.1rem',
      textAlign: 'center',
      fontFamily: textData.button.fontFamily,
      ...style,
    }}
  >
    {children}
  </div>;

  const enabledButton =
  <div
    onClick={() => {
      if(onClick) {
        onClick();
      }
    }}
    style={styleData}
  >
    {children}
  </div>;

  return (
          disabled ? disabledButton :
          href ? <a href={href} target={target} rel={rel} style={{textDecoration: 'none'}}>{enabledButton}</a> : enabledButton
  );
}

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  palette: PropTypes.string,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
  size: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};

export default Button;
