import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Input, Typography, Button, Dialog, CheckBox, Select, paletteData} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function ProductPaymentForm({payment, availability, handleChange, productType, focusedOrganisation}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <GridContainer>
        <GridCell style={{marginTop: 22}}>
          <Typography>
            $
          </Typography>
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="price"
            palette='secondary'
            label="Price"
            type="number"
            value={payment?.price || ''}
            onChange={(value) => handleChange('price', value)}
          />
        </GridCell>
        {focusedOrganisation?.gst &&
          <GridCell style={{marginTop: 22}}>
            <Typography>
              + GST
            </Typography>
          </GridCell>
        }
        {focusedOrganisation?.gst &&
          <GridCell style={{marginTop: 12, marginRight: 5, textAlign: 'right'}}>
            <Button onClick={() => {
              setDialogData({
                type: 'gstInclusive',
                title: 'Set GST inclusive price',
                data: {
                  name: 'price'
                },
                value: {},
              });
              setDialogOpen(true);
            }}>
              (${((payment?.price || 0) * 1.15).toFixed(2)} inc GST)
            </Button>
          </GridCell>
        }
        {productType === 'service' &&
          <GridCell>
            <Select
              style={{minWidth: 150}}
              name="servicePaymentFor"
              palette='secondary'
              label="Payment"
              type="text"
              value={payment?.servicePaymentFor}
              onChange={(value) => handleChange('servicePaymentFor', value)}
            >
              <option value='perPerson'>Per Person</option>
              <option value='perHour'>Per Hour</option>
              <option value='perBooking'>Per Booking</option>
            </Select>
          </GridCell>
        }
      </GridContainer>

      <CheckBox
        name='bulkPriceAvailable'
        label='Bulk Price Available'
        value={payment.bulkPriceAvailable}
        onChange={(value) => handleChange('bulkPriceAvailable', value)}
      />
      {payment.bulkPriceAvailable &&
        <GridContainer>
          <GridCell weight={1}>
            <Input
              name="bulkThreshold"
              palette='secondary'
              label="Per Units"
              type="number"
              value={payment?.bulkThreshold || ''}
              onChange={(value) => handleChange('bulkThreshold', value)}
            />
          </GridCell>
          <GridCell style={{marginTop: 22}}>
            <Typography>
              $
            </Typography>
          </GridCell>
          <GridCell weight={1}>
            <Input
              name="bulkPrice"
              palette='secondary'
              label="Bulk Price"
              type="number"
              value={payment?.bulkPrice || ''}
              onChange={(value) => handleChange('bulkPrice', value)}
            />
          </GridCell>
          {focusedOrganisation?.gst &&
            <GridCell style={{marginTop: 22}}>
              <Typography>
                + GST
              </Typography>
            </GridCell>
          }
          {focusedOrganisation?.gst &&
            <GridCell style={{marginTop: 12, marginRight: 5, textAlign: 'right'}}>
              <Button onClick={() => {
                setDialogData({
                  type: 'gstInclusive',
                  title: 'Set GST inclusive price',
                  data: {
                    name: 'bulkPrice'
                  },
                  value: {},
                });
                setDialogOpen(true);
              }}>
                (${((payment?.bulkPrice || 0) * 1.15).toFixed(2)} inc GST)
              </Button>
            </GridCell>
          }
        </GridContainer>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <Typography>
              {dialogData.message}
            </Typography>
          }
          {dialogData?.type === 'gstInclusive' &&
            <div style={{padding: 10}}>
              <Input
                name="gstInclusivePrice"
                palette='secondary'
                label="GST inclusive price in NZD"
                type="number"
                value={dialogData?.value?.gstInclusivePrice || ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      gstInclusivePrice: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'gstInclusive' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                   onClick={() => {
                     const priceTemp = +((dialogData?.value?.gstInclusivePrice || 0) * 20 / 23).toFixed(2);
                     handleChange(dialogData?.data?.name, priceTemp);
                     setDialogOpen(false);
                     setDialogData(undefined);
                  }}
                >
                  Update Price
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ProductPaymentForm.propTypes = {
  payment: PropTypes.shape({}),
  availability: PropTypes.shape({}),
  handleChange: PropTypes.func,
  productType: PropTypes.string,
  organisation: PropTypes.shape({}),
}

export default ProductPaymentForm;
