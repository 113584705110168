import React from 'react';
import PropTypes from 'prop-types';

import {Input, TextArea} from '../../styles';

function HeaderForm({header, handleChange, processing}) {
  return (
    <div>
      <Input
        name="title"
        palette='secondary'
        label="Page Title"
        type="text"
        value={header?.title || ''}
        onChange={(value) => handleChange('title', value)}
      />
      <TextArea
        name="description"
        palette='secondary'
        label="Page Description"
        value={header?.description || ''}
        onChange={(value) => handleChange('description', value)}
      />
      <TextArea
        name="keywords"
        palette='secondary'
        label="Page Keywords"
        value={header?.keywords || ''}
        onChange={(value) => handleChange('keywords', value)}
      />
    </div>
  );
}

HeaderForm.propTypes = {
  header: PropTypes.shape({}),
  handleChange: PropTypes.func,
  processing: PropTypes.bool,
}

export default HeaderForm;
