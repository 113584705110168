import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import UserForm from '../../../../components/forms/user';
import RoleForm from '../../../../components/forms/role';
import DemographicsForm from '../../../../components/forms/demographics';
import ContactEmailForm from '../../../../components/forms/contactEmail';
import ContactPhoneForm from '../../../../components/forms/contactPhone';
import ContactPrimaryForm from '../../../../components/forms/contactPrimary';
import ContactAddressForm from '../../../../components/forms/contactAddress';
import SearchStore from '../../../../components/search/store';

import {GridContainer, GridCell} from '../../../../components/grid';
import {paletteData, Button, ProgressBar, Dialog, Typography, Input} from '../../../../components/styles';
import apiRequest from '../../../../tools/apiRequest';
import {setApiToken} from '../../../../store/reducers/auth/actions.js';
import {setProfile} from '../../../../store/reducers/user/actions.js';
import {setUsers, addUsers, addUser, replaceUser, removeUser, setSearch} from '../../../../store/reducers/lists/actions.js';
import {setUserReference, clearUserReference, setProfileReference, clearProfileReference, setOrganisationReference, setOrganisationReferences, setCategoryReferences} from '../../../../store/reducers/references/actions.js';
import handleErrorMessage from '../../../../tools/handleErrorMessage';
import {validateEmail, checkPasswordStrength} from '../../../../tools/validation';

function ManageUsersPanel({
  me,
  auth,
  users,
  organisations,
  userReferences,
  profileReferences,
  organisationRefences,
  search,
  setUsers,
  addUsers,
  addUser,
  setApiToken,
  setProfile,
  replaceUser,
  removeUser,
  setUserReference,
  clearUserReference,
  setProfileReference,
  clearProfileReference,
  setOrganisationReference,
  setOrganisationReferences,
  setSearch,
  queryLimit,
  areaReferences,
  categories,
  categoryReferences,
  setCategoryReferences,
}) {
  // page state
  const [focusedUser, setFocusedUser] = useState(undefined);
  const [focusedProfile, setFocusedProfile] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [expandSearch, setExpandSearch] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const [organisationsLoaded, setOrganisationsLoaded] = useState(false);
  useEffect(() => {
    if(focusedUser && !organisationsLoaded) {
      setOrganisationsLoaded(true);
      const organisationsToTest = Object.keys(focusedUser?.roles?.organisational || {});
      const organisationsToLoad = [];
      for(let i = 0; i < organisationsToTest.length; i += 1) {
        if(!organisationRefences[organisationsToTest[i]]) {
          organisationsToLoad.push(organisationsToTest[i]);
        }
      }
      if(organisationsToLoad.length > 0) {
        const query = {_id: {$in: organisationsToLoad}};
        const sort = {name: 1};
        apiRequest({type: 'get', action: 'organisations', data: {query, sort, skip: 0, limit: queryLimit}})
        .then((result) => {
          setOrganisationReferences(result.data.organisations);
          setProcessing(false);
        }).catch((error) => {
          setProcessing(false);
          setDialogData({
            type: 'message',
            title: `Fetch organisations request denied`,
            message: handleErrorMessage(error),
          });
          setDialogOpen(true);
        });
      }
    }
  }, [focusedUser, organisationRefences, setOrganisationReferences, organisationsLoaded, queryLimit]);

  const handleChangeFocusedUser = (name, value) => {
    const userProcessed = {
      ...focusedUser,
      [name]: value,
    };
    setFocusedUser(userProcessed);
  };

  const handleChangeFocusedProfile = (name, value) => {
    const profileProcessed = {
      ...focusedProfile,
      [name]: value,
    };
    setFocusedProfile(profileProcessed);
  };

  const handleUpdateUser = () => {
    setProcessing(true);
    const userId = focusedUser._id || 'new';
    const updateUser = {
      name: focusedUser.name,
      email: focusedUser.email,
      whitelist: focusedUser.whitelist,
      blacklist: focusedUser.blacklist,
    };
    const updateProfile = {
      _id: focusedProfile._id || 'new',
      userId,
      nameFirst: focusedProfile.nameFirst,
      nameMiddle: focusedProfile.nameMiddle,
      nameLast: focusedProfile.nameLast,
      dob: focusedProfile.dob,
      email: focusedProfile.email,
      phone: focusedProfile.phone,
      address: focusedProfile.address,
      primaryContact: focusedProfile.primaryContact,
    }
    const updateRole = {
      userId,
      global: focusedUser.roles?.global || {},
      organisational: focusedUser.roles?.organisational || {},
      community: focusedUser.roles?.community || {},
    }

    if(userId === 'new') {
      apiRequest({type: 'post', action: `users/create`, data: {updateUser, updateProfile, updateRole, password: focusedUser.password}})
      .then((result) => {
        if(userId === me._id) {
          if(result.data.auth) {
            setApiToken(result.data.auth);
          }
          setProfile(updateProfile);
        }
        addUser(result.data.user);
        setUserReference(result.data.user);
        setProfileReference(result.data.profile);
        setFocusedUser(undefined);
        setOrganisationsLoaded(false);
        setFocusedProfile(undefined);
        setProcessing(false);
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Create user request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    } else {
      apiRequest({type: 'patch', action: `users/update/${userId}`, data: {updateUser, updateProfile, updateRole}})
      .then((result) => {
        if(userId === me._id) {
          if(result.data.auth) {
            setApiToken(result.data.auth);
          }
          setProfile(updateProfile);
        }
        replaceUser({
          user: {
            _id: userId,
            verification: focusedUser.verification,
            roles: {
              global: updateRole.global,
              organisational: updateRole.organisational,
            },
            ...updateUser
          }
        });
        setUserReference({
          _id: userId,
          verification: focusedUser.verification,
          roles: {
            global: updateRole.global,
            organisational: updateRole.organisational,
          },
           ...updateUser
         });
        setProfileReference(updateProfile._id === 'new' ? result.data.profile : updateProfile);
        setFocusedUser(undefined);
        setOrganisationsLoaded(false);
        setFocusedProfile(undefined);
        setProcessing(false);
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Create user request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    }
  }

  const handleDeleteUser = () => {
    setProcessing(true);
    const userId = focusedUser._id || 'new';
    apiRequest({type: 'delete', action: `users/delete/${userId}`})
    .then((result) => {
      setProcessing(false);
      removeUser({user: focusedUser});
      clearUserReference(userId);
      clearProfileReference(userId);
      setFocusedUser(undefined);
      setOrganisationsLoaded(false);
      setFocusedProfile(undefined);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Create user request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const checkPrivilageEscalation = () => {
    if(!me?.roles?.global['worker'] && !me?.roles?.global['admin'] && !me?.roles?.global['super-admin'] &&
      (focusedUser?.roles?.global['worker'] || focusedUser?.roles?.global['admin'] || focusedUser?.roles?.global['super-admin'])) {
        return true;
    }
    if(me?.roles?.global['worker'] &&
      (focusedUser?.roles?.global['admin'] || focusedUser?.roles?.global['super-admin'])) {
        return true;
    }
    if(me?.roles?.global['admin'] &&
      (focusedUser?.roles?.global['super-admin'])) {
        return true;
    }
    return false;
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <div style={{color: paletteData.primary.standard.foreground, fontSize: '1.5em'}}>
              Manage Users
            </div>
          </GridCell>
          <GridCell center style={{height: 45, display: 'block'}}>
            <Button
              disabled={processing}
              palette="secondary"
              onClick={() =>{
                if(focusedUser) {
                  setFocusedProfile(undefined);
                  setFocusedUser(undefined);
                  setOrganisationsLoaded(false);
                } else {
                  setFocusedProfile({address: {country: 'New Zealand'}, phone: {}, email: {}, primaryContact: {}});
                  setFocusedUser({_id: 'new'});
                }
              }}
            >
              {focusedUser ? 'Cancel' : 'Add'}
            </Button>
          </GridCell>
          <GridCell center style={{marginLeft: 5, height: 45, display: focusedUser && focusedUser._id !== 'new' ? 'block' : 'none'}}>
            <Button palette="secondary" disabled={me._id === focusedUser?._id || checkPrivilageEscalation()} onClick={() => handleDeleteUser()}>
              Delete User
            </Button>
          </GridCell>
          <GridCell center style={{marginLeft: 5, height: 45, display: focusedUser ? 'block' : 'none'}}>
            <Button palette="secondary" onClick={() => handleUpdateUser()} disabled={processing || !focusedUser?.name || !validateEmail(focusedUser?.email)}>
              {focusedUser?._id === 'new' ? 'Create User' :  'Update details'}
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {!focusedUser &&
        <div>
          <SearchStore databaseArea='users' processing={processing} setProcessing={setProcessing} expandSearch={expandSearch} setExpandSearch={setExpandSearch}/>
          {users?.length > 0 &&
            <div>
              {users && users.map((u, uIndex) => (
                <div key={uIndex} style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5}}>
                  <GridContainer>
                    <GridCell weight={1}>
                    <Typography>
                      {u.name}
                    </Typography>
                    <Typography style={{color: '#c6c6c6'}}>
                      {u.email}
                    </Typography>
                    </GridCell>
                    {me.roles?.global && me.roles.global['super-admin'] &&
                      <GridCell style={{marginRight: 5}}>
                        <Button
                          disabled={processing}
                          palette='primary'
                          onClick={() => {
                            setDialogOpen(true);
                            setDialogData({
                              type: 'resetPassword',
                              title: `Reset password for ${u.name}`,
                              value: {
                                userId: u._id,
                                username: u.email,
                                newPassword: '',
                              }
                            });
                          }}
                        >
                          Reset Password
                        </Button>
                      </GridCell>
                    }
                    <GridCell>
                      <Button
                        disabled={processing}
                        palette='primary'
                        onClick={() => {
                          if(!userReferences[u._id] || !profileReferences[u._id]) {
                            setProcessing(true);
                            apiRequest({type: 'get', action: `users/${u._id}`}).then((result) => {
                              setProcessing(false);
                              setFocusedUser(result.data.user);
                              setUserReference(result.data.user);
                              setFocusedProfile(result.data.profile || {});
                              if(result.data.profile) {
                                setProfileReference(result.data.profile);
                              }
                            }).catch((error) => {
                              console.log(error);
                            });
                          } else {
                            setFocusedUser(userReferences[u._id]);
                            setFocusedProfile(profileReferences[u._id]);
                          }
                        }}
                      >
                        Edit
                      </Button>
                    </GridCell>
                  </GridContainer>

                </div>
              ))}
              {processing && users?.length > 9 &&
                <div>
                  <Typography>
                    Loading...
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {search?.users?.hasMore === true &&
                <div style={{padding: 5}}>
                  <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
        </div>
      }
      {focusedUser && focusedProfile &&
        <div>
          {processing &&
            <ProgressBar palette='secondary'/>
          }
          <UserForm me={me} user={focusedUser} handleChange={handleChangeFocusedUser} privilageEscalation={checkPrivilageEscalation()}/>
          <br/>
          {me?.roles?.global && me?.roles?.global['super-admin'] &&
            <div>
              <RoleForm me={me} user={focusedUser} handleChange={handleChangeFocusedUser}/>
            </div>
          }
          <div style={{background: paletteData.primary.standard.background, color: paletteData.primary.standard.foreground, fontSize: '1em', padding: 10}}>
            Demographics
          </div>
          <DemographicsForm demographics={focusedProfile} handleChange={handleChangeFocusedProfile}/>
          <br/>
          <div style={{background: paletteData.primary.standard.background, color: paletteData.primary.standard.foreground, fontSize: '1em', padding: 10}}>
            Contact Details
          </div>
          <ContactEmailForm email={focusedProfile?.email} handleChange={(name, value) => {
            const c = focusedProfile.email || {};
            c[name] = value;
            handleChangeFocusedProfile('email', c);
          }}/>

          <ContactPhoneForm phone={focusedProfile?.phone} handleChange={(name, value) => {
            const c = focusedProfile.phone || {};
            c[name] = value;
            handleChangeFocusedProfile('phone', c);
          }}/>
          <br/>
          <div style={{background: paletteData.primary.standard.background, color: paletteData.primary.standard.foreground, fontSize: '1em', padding: 10}}>
            Primary Contact
          </div>
          <ContactPrimaryForm primaryContact={focusedProfile?.primaryContact} handleChange={(name, value) => {
            const p = focusedProfile.primaryContact || {};
            p[name] = value;
            handleChangeFocusedProfile('primaryContact', p);
          }}/>
          <br/>
          <div style={{background: paletteData.primary.standard.background, color: paletteData.primary.standard.foreground, fontSize: '1em', padding: 10}}>
            Address
          </div>
          <ContactAddressForm address={focusedProfile?.address} handleChange={(name, value) => {
            const a = focusedProfile.address || {};
            a[name] = value;
            handleChangeFocusedProfile('address', a);
          }}/>
          <br/>
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData?.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'resetPassword' &&
            <div style={{padding: 10}}>
              <form>
                <Input
                  style={{display: 'none'}}
                  disabled={true}
                  name='username'
                  label='Username'
                  type='text'
                  autoComplete='username'
                  value={dialogData?.value?.username}
                  onChange={() => {}}
                />
                <Input
                  name="newPassword"
                  palette='secondary'
                  label="New Password"
                  type="password"
                  autoComplete="new-password"
                  value={dialogData?.value?.newPassword || ''}
                  onChange={(value) => {
                    const dialogDataProcessed = {
                      ...dialogData,
                      value: {
                        ...(dialogData?.value || {}),
                        newPassword: value,
                      }
                    };
                    setDialogData(dialogDataProcessed);
                  }}
                />
                {!checkPasswordStrength(dialogData?.value?.newPassword) &&
                  <Typography size='subText' style={{paddingLeft: 5, paddingRight: 5, color: '#c6c6c6'}}>
                    Password must contain an uppercase and lowercase letter, one or more numbers, and a special character.
                  </Typography>
                }
              </form>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'cancel'}
              </Button>
            </GridCell>
            <GridCell style={{padding: 10, display: dialogData?.type === 'resetPassword' ? 'block': 'none'}}>
              <Button
                disabled={processing || !dialogData?.value?.newPassword || dialogData?.value?.newPassword === '' || !checkPasswordStrength(dialogData?.value?.newPassword)}
                palette='primary'
                onClick={() => {
                  setProcessing(true);
                  apiRequest({type: 'patch', action: `users/admin-reset-password/${dialogData?.value?.userId}`, data: {password: dialogData?.value?.newPassword}})
                  .then((result) => {
                    console.log(result);
                    setProcessing(false);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }).catch((error) => {
                    console.log(error);
                    setProcessing(false);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  });
                }}
              >
                Reset Password
              </Button>
              {processing && <ProgressBar palette='secondary'/>}
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ManageUsersPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
  users: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = (state) => {
  return {
    users: state.lists.users,
    userReferences: state.references.users || {},
    profileReferences: state.references.profiles || {},

    organisations: state.lists.organisations,
    organisationRefences: state.references.organisations || {},

    categories: state.lists.categories,
    categoryReferences: state.references.categories || {},

    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
  };
};

export default connect(mapStateToProps, {setUsers, addUsers, addUser, replaceUser, removeUser, setApiToken, setProfile, setUserReference, clearUserReference, setProfileReference, clearProfileReference, setSearch, setOrganisationReference, setOrganisationReferences, setCategoryReferences})(ManageUsersPanel);
