import {
  SET_USER,
  SET_USERS,
  CLEAR_USER,

  SET_PROFILE,
  CLEAR_PROFILE,

  SET_AREA,
  SET_AREAS,
  CLEAR_AREA,

  SET_ORGANISATION,
  SET_ORGANISATIONS,
  CLEAR_ORGANISATION,

  SET_CATEGORY,
  SET_CATEGORIES,
  CLEAR_CATEGORY,

  SET_EVENT,
  SET_EVENTS,
  CLEAR_EVENT,

  SET_PRODUCT,
  SET_PRODUCTS,
  CLEAR_PRODUCT,

  SET_PRODUCTGROUP,
  SET_PRODUCTGROUPS,
  CLEAR_PRODUCTGROUP,

  SET_POST_SCHEME,
  SET_POST_SCHEMES,
  CLEAR_POST_SCHEME,

  SET_BOOKING_CALENDAR,
  SET_BOOKING_CALENDARS,
  CLEAR_BOOKING_CALENDAR,

  SET_REVIEW,
  SET_REVIEWS,
  CLEAR_REVIEW,

  SET_ORDER,
  SET_ORDERS,
  CLEAR_ORDER,
  PRUNE_BOOKING_FROM_ORDER,

  SET_ARTICLE,
  SET_ARTICLES,
  CLEAR_ARTICLE,

  SET_BOOKING,
  SET_BOOKINGS,
  CLEAR_BOOKING,

  SET_COMMUNITY,
  SET_COMMUNITIES,
  CLEAR_COMMUNITY,

  SET_POTENTIAL_COMMUNITY,
  SET_POTENTIAL_COMMUNITIES,
  CLEAR_POTENTIAL_COMMUNITY,

  RESET_REFERENCES,
} from "./actionTypes";

//--------------------- users --------------------

export const setUserReference = (content) => ({
  type: SET_USER,
  payload: content
});

export const setUserReferences = (content) => ({
  type: SET_USERS,
  payload: content
});

export const clearUserReference = (content) => ({
  type: CLEAR_USER,
  payload: content
});


export const setProfileReference = (content) => ({
  type: SET_PROFILE,
  payload: content
});

export const clearProfileReference = (content) => ({
  type: CLEAR_PROFILE,
  payload: content
});

//--------------------- areas --------------------

export const setAreaReference = (content) => ({
  type: SET_AREA,
  payload: content
});

export const setAreaReferences = (content) => ({
  type: SET_AREAS,
  payload: content
});

export const clearAreaReference = (content) => ({
  type: CLEAR_AREA,
  payload: content
});

//--------------------- organisations --------------------


export const setOrganisationReference = (content) => ({
  type: SET_ORGANISATION,
  payload: content
});

export const setOrganisationReferences = (content) => ({
  type: SET_ORGANISATIONS,
  payload: content
});

export const clearOrganisationReference = (content) => ({
  type: CLEAR_ORGANISATION,
  payload: content
});

//--------------------- categories --------------------

export const setCategoryReference = (content) => ({
  type: SET_CATEGORY,
  payload: content
});

export const setCategoryReferences = (content) => ({
  type: SET_CATEGORIES,
  payload: content
});

export const clearCategoryReference = (content) => ({
  type: CLEAR_CATEGORY,
  payload: content
});

//--------------------- events --------------------
export const setEventReference = (content) => ({
  type: SET_EVENT,
  payload: content
});

export const setEventReferences = (content) => ({
  type: SET_EVENTS,
  payload: content
});

export const clearEventReference = (content) => ({
  type: CLEAR_EVENT,
  payload: content
});

//--------------------- products --------------------
export const setProductReference = (content) => ({
  type: SET_PRODUCT,
  payload: content
});

export const setProductReferences = (content) => ({
  type: SET_PRODUCTS,
  payload: content
});

export const clearProductReference = (content) => ({
  type: CLEAR_PRODUCT,
  payload: content
});

//--------------------- product groups --------------------
export const setProductGroupReference = (content) => ({
  type: SET_PRODUCTGROUP,
  payload: content
});

export const setProductGroupReferences = (content) => ({
  type: SET_PRODUCTGROUPS,
  payload: content
});

export const clearProductGroupReference = (content) => ({
  type: CLEAR_PRODUCTGROUP,
  payload: content
});

//--------------------- post schemes --------------------
export const setPostSchemeReference = (content) => ({
  type: SET_POST_SCHEME,
  payload: content
});

export const setPostSchemeReferences = (content) => ({
  type: SET_POST_SCHEMES,
  payload: content
});

export const clearPostSchemeReference = (content) => ({
  type: CLEAR_POST_SCHEME,
  payload: content
});

//--------------------- booking calendars --------------------
export const setBookingCalendarReference = (content) => ({
  type: SET_BOOKING_CALENDAR,
  payload: content
});

export const setBookingCalendarReferences = (content) => ({
  type: SET_BOOKING_CALENDARS,
  payload: content
});

export const clearBookingCalendarReference = (content) => ({
  type: CLEAR_BOOKING_CALENDAR,
  payload: content
});

//--------------------- reviews --------------------
export const setReviewReference = (content) => ({
  type: SET_REVIEW,
  payload: content
});

export const setReviewReferences = (content) => ({
  type: SET_REVIEWS,
  payload: content
});

export const clearReviewReference = (content) => ({
  type: CLEAR_REVIEW,
  payload: content
});

//--------------------- orders --------------------
export const setOrderReference = (content) => ({
  type: SET_ORDER,
  payload: content
});

export const setOrderReferences = (content) => ({
  type: SET_ORDERS,
  payload: content
});

export const clearOrderReference = (content) => ({
  type: CLEAR_ORDER,
  payload: content
});

export const pruneBookingFromOrderReference = (content) => ({
  type: PRUNE_BOOKING_FROM_ORDER,
  payload: content
});

//--------------------- articles --------------------
export const setArticleReference = (content) => ({
  type: SET_ARTICLE,
  payload: content
});

export const setArticleReferences = (content) => ({
  type: SET_ARTICLES,
  payload: content
});

export const clearArticleReference = (content) => ({
  type: CLEAR_ARTICLE,
  payload: content
});

//--------------------- bookings --------------------
export const setBookingReference = (content) => ({
  type: SET_BOOKING,
  payload: content
});

export const setBookingReferences = (content) => ({
  type: SET_BOOKINGS,
  payload: content
});

export const clearBookingReference = (content) => ({
  type: CLEAR_BOOKING,
  payload: content
});

//--------------------- communities --------------------
export const setCommunityReference = (content) => ({
  type: SET_COMMUNITY,
  payload: content
});

export const setCommunityReferences = (content) => ({
  type: SET_COMMUNITIES,
  payload: content
});

export const clearCommunityReference = (content) => ({
  type: CLEAR_COMMUNITY,
  payload: content
});

//--------------------- potential communities --------------------
export const setPotentialCommunityReference = (content) => ({
  type: SET_POTENTIAL_COMMUNITY,
  payload: content
});

export const setPotentialCommunityReferences = (content) => ({
  type: SET_POTENTIAL_COMMUNITIES,
  payload: content
});

export const clearPotentialCommunityReference = (content) => ({
  type: CLEAR_POTENTIAL_COMMUNITY,
  payload: content
});

// --------------------- reset ------------------------
export const resetReferences = () => ({
  type: RESET_REFERENCES,
});
