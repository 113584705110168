import React from 'react';
import PropTypes from 'prop-types';

import {Input} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function HeaderForm({dateTime, handleChange}) {
  return (
    <GridContainer>
      <GridCell weight={1}>
        <Input
          name="date"
          palette='secondary'
          label="Date"
          forceShrink={true}
          type="date"
          value={dateTime?.date || ''}
          onChange={(value) => handleChange('date', value)}
        />
      </GridCell>
      <GridCell weight={1}>
        <Input
          name="start"
          palette='secondary'
          label="Start"
          forceShrink={true}
          type="time"
          value={dateTime?.start || ''}
          onChange={(value) => handleChange('start', value)}
        />
      </GridCell>
      <GridCell weight={1}>
        <Input
          name="end"
          palette='secondary'
          label="End"
          forceShrink={true}
          type="time"
          value={dateTime?.end || ''}
          onChange={(value) => handleChange('end', value)}
        />
      </GridCell>
    </GridContainer>
  );
}

HeaderForm.propTypes = {
  dateTime: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default HeaderForm;
