import React, { useState } from 'react';
import { paletteData, Typography, Button, ProgressBar, Select } from '../../../../components/styles';
import { GridCell, GridContainer } from '../../../../components/grid';

import apiRequest from '../../../../tools/apiRequest';

function ContactList() {
    const [processing, setProcessing] = useState(false);
    const [contactList, setContactList] = useState(undefined);

    const [hiddenFilter, setHiddenFilter] = useState('All Organisations');

    const createCSVBlob = (csv, title) => {
        const link = document.createElement('a');
        document.body.appendChild(link);

        const filename = (title || 'UserExport') + '.csv';
        const blob = new Blob([csv], { type: 'text/csv' });
        const csvUrl = window.webkitURL.createObjectURL(blob);

        link.href = csvUrl;
        link.download = filename;
        link.click();

        document.body.removeChild(link);
    };

    return (
        <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{ background: paletteData.primary.standard.background, padding: 10 }}>
                <GridContainer>
                    <GridCell weight={1}>
                        <Typography style={{ color: paletteData.primary.standard.foreground, fontSize: '1.5em' }}>
                            Contact List
                        </Typography>
                    </GridCell>
                    {contactList?.length > 0 &&
                        <GridCell style={{ marginRight: 10 }}>
                            <Button
                                size='small'
                                palette="secondary"
                                paletteData='darkOnLight'
                                onClick={() => {
                                    let csv = 'Organisation,phone,email,website,facebook,instagram\r\n';
                                    for (let i = 0; i < contactList?.length; i += 1) {
                                        csv += `${contactList[i]?.name || 'Unnamed'},${contactList[i]?.phone || ''},${contactList[i]?.email || ''},${contactList[i]?.website || ''},${contactList[i]?.social?.facebook || ''},${contactList[i]?.social?.instagram || ''}\r\n`;
                                    }

                                    createCSVBlob(csv, 'Contact List');
                                }}
                            >
                                Download
                            </Button>
                        </GridCell>
                    }
                    <GridCell>
                        <Button
                            size='small'
                            palette="secondary"
                            paletteData='darkOnLight'
                            onClick={() => {
                                setProcessing(true);
                                const filter = {};
                                if (hiddenFilter === 'Live Organisations') {
                                    if (!filter.$or) {
                                        filter.$or = [];
                                    }
                                    const liveCase1 = { hidden: false };
                                    const liveCase2 = { hidden: { $exists: false } };
                                    filter.$or.push(liveCase1);
                                    filter.$or.push(liveCase2);
                                } else if (hiddenFilter === 'Pending Organisations') {
                                    filter.hidden = true;
                                }

                                apiRequest({ type: 'get', action: 'organisations/contactList', data: { filter } })
                                    .then((result) => {
                                        setProcessing(false);
                                        setContactList(result.data);
                                        console.log(result);
                                    }).catch((error) => {
                                        setProcessing(false);
                                        console.log(error);
                                    });
                            }}
                        >
                            Generate
                        </Button>
                    </GridCell>
                </GridContainer>
            </div>
            <div style={{ borderStyle: 'solid', borderWidth: 1 }}>
                <div style={{ padding: 10 }}>
                    <Typography size='title'>
                        Filter
                    </Typography>
                </div>
                <div style={{ padding: 5 }}>
                    <Select
                        label='Organisation State'
                        value={hiddenFilter}
                        onChange={(value) => setHiddenFilter(value)}
                    >
                        <option>All Organisations</option>
                        <option>Live Organisations</option>
                        <option>Pending Organisations</option>
                    </Select>
                </div>
            </div>
            {processing && <ProgressBar />}

            {console.log(contactList)}

            {contactList?.length > 0 &&
                <div style={{ padding: 10 }}>
                    {contactList?.length > 0 && contactList?.map((o, oIndex) => (
                        <div key={oIndex}>
                            <GridContainer>
                                <GridCell weight={1}>
                                    {o.name}
                                </GridCell>
                                <GridCell style={{ width: 200 }}>
                                    {o.phone}
                                </GridCell>
                                <GridCell style={{ width: 300 }}>
                                    {o.email}
                                </GridCell>
                            </GridContainer>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}

export default ContactList;
