const generateGalleryBlock = ({images, styleContainer}) => {
  const section = {
    section: 'galleryBlock',
    type: 'div',
    options: {
      style: {
        margin: 'auto',
        ...styleContainer,
      },
    },
    children: [
      {
        type: 'Gallery',
        options: {
          images: images || [],
        },
      },
    ],
  };
  return section;
};

export default generateGalleryBlock;
