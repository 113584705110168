import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import SearchEvents from './SearchEvents';
import EditEventPreview from './EditEventPreview';
import EditEventGallery from './EditEventGallery';
import EditEventVideoLibrary from './EditEventVideoLibrary';
import EditEventLayout from './EditEventLayout';
import EditEventInfo from './EditEventInfo';

import {GridContainer, GridCell} from '../../../../components/grid';
import {paletteData, Button, ProgressBar, Dialog, Typography} from '../../../../components/styles';
import {setEvents, addEvents, addEvent, replaceEvent, removeEvent, setSearch, setOrganisations, addOrganisations, setAreas, addAreas} from '../../../../store/reducers/lists/actions.js';
import {setEventReference, clearEventReference, setOrganisationReference, setAreaReference, setEventReferences, setAreaReferences, setOrganisationReferences} from '../../../../store/reducers/references/actions.js';
import Hidden from '../../../../components/hidden';

import apiRequest from '../../../../tools/apiRequest';
import handleErrorMessage from '../../../../tools/handleErrorMessage';

function ManageEventsPanel({
  me,
  auth,
  search,
  setSearch,
  queryLimit,

  events,
  setEvents,
  addEvents,
  addEvent,
  replaceEvent,
  removeEvent,
  setEventReference,
  clearEventReference,

  organisations,
  organisationReferences,
  setOrganisations,
  addOrganisations,
  setOrganisationReference,
  setOrganisationReferences,

  areas,
  areaReferences,
  setAreas,
  addAreas,
  setAreaReference,
  setAreaReferences,
}) {
  // page state
  const [focusedOrganisation, setFocusedOrganisation] = useState(search?.events?.query?.host && organisationReferences[search?.events?.query?.host] ? organisationReferences[search?.events?.query?.host] : undefined);
  const [focusedEvent, setFocusedEvent] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [focusedSubSection, setFocusedSubSection] = useState('preview');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleChangesFocusedEvent = (changes) => {
    setFocusedEvent({
      ...focusedEvent,
      ...changes,
    });
  }

  const handleChangeFocusedEvent = (name, value) => {
    setFocusedEvent({
      ...focusedEvent,
      [name]: value,
    });
  };

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({type: 'post', action: 'upload/image', data: {file: reader.result}}).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({imageId: result.data.public_id, alt}, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  const handleUpdateEvent = () => {
    setProcessing(true);
    const eventId = focusedEvent._id;
    const updateEvent = {
      state: focusedEvent?.state,
      host: focusedEvent?.host || focusedOrganisation?._id,
      partners: focusedEvent?.partners,
      name: focusedEvent?.name,
      tagline: focusedEvent?.tagline,
      blurb: focusedEvent?.blurb,
      searchTerms: focusedEvent?.searchTerms,
      thumbnail: focusedEvent?.thumbnail,
      layout: focusedEvent?.layout,
      gallery: focusedEvent?.gallery,
      videoLibrary: focusedEvent?.videoLibrary,
      registry: focusedEvent?.registry,
      locations: focusedEvent?.locations,
    }
    if(eventId === 'new') {
      apiRequest({type: 'post', action: `events/create`, data: {updateEvent}})
      .then((result) => {
        addEvent(result.data.event);
        setEventReference(result.data.event);
        setProcessing(false);
        setFocusedEvent(undefined);
        setFocusedSubSection('preview');
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Create event request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    } else {
      apiRequest({type: 'patch', action: `events/update/${eventId}`, data: {updateEvent}})
      .then((result) => {
        replaceEvent({event: {_id: eventId, ...updateEvent}});
        setEventReference({_id: eventId, ...updateEvent});
        setProcessing(false);
        setFocusedEvent(undefined);
        setFocusedSubSection('preview');
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Update event request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    }
  }

  const handleDeleteEvent = () => {
    setProcessing(true);
    const eventId = focusedEvent._id || 'new';
    apiRequest({type: 'delete', action: `events/delete/${eventId}`})
    .then((result) => {
      setDialogOpen(false);
      setDialogData(undefined);
      setProcessing(false);
      removeEvent({event: focusedEvent});
      clearEventReference(eventId);
      setFocusedEvent(undefined);
    }).catch((error) => {
      console.log(error);
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Delete event request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Event{focusedEvent ? '' : 's'}
            </Typography>
          </GridCell>
          {/*large buttons*/}
          {!focusedEvent &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{height: 45}}>
                <Button
                  disabled={processing || !search?.events?.query?.organisationId}
                  palette="secondary"
                  onClick={() => setFocusedEvent({_id: 'new', host: search?.events?.query?.organisationId})}
                >
                  Add
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedEvent &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{height: 45}}>
                <Button
                  disabled={processing || !focusedOrganisation}
                  palette="secondary"
                  onClick={() =>{
                    setFocusedEvent(undefined);
                    setFocusedSubSection('preview');
                  }}
                >
                  Cancel
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedEvent && focusedEvent._id !== 'new' &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{marginLeft: 5, height: 45}}>
                <Button
                  disabled={processing}
                  palette='secondary'
                  onClick={() => {
                    setDialogOpen(true);
                    setDialogData({
                      type: 'deleteEvent',
                      title: 'Delete Event',
                      message: 'Are you sure you wish to delete this event?  This is irreversible',
                    });
                  }}
                >
                  Delete Event
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedEvent &&
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell center style={{marginLeft: 5, height: 45}}>
                <Button
                  palette='secondary'
                  onClick={() => handleUpdateEvent()}
                  disabled={processing || !focusedEvent?.host || !focusedEvent?.name}
                >
                  {focusedEvent?._id === 'new' ? 'Create' : 'Update'} Event
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            </Hidden>
          }

          {/*small buttons*/}
          {!focusedEvent &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{height: 45}}>
                <Button
                  size='small'
                  disabled={processing}
                  palette="secondary"
                  onClick={() => setFocusedEvent({_id: 'new', host: focusedOrganisation._id, partners: [focusedOrganisation._id], gallery: [], videoLibrary: []})}
                >
                  Add
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedEvent &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{height: 45}}>
                <Button
                  size='small'
                  disabled={processing}
                  palette="secondary"
                  onClick={() =>{
                    setFocusedEvent(undefined);
                    setFocusedSubSection('preview');
                  }}
                >
                  Cancel
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedEvent && focusedEvent._id !== 'new' &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{marginLeft: 5, height: 45}}>
                <Button
                  size='small'
                  disabled={processing}
                  palette='secondary'
                  onClick={() => {
                    setDialogOpen(true);
                    setDialogData({
                      type: 'deleteEvent',
                      title: 'Delete Event',
                      message: 'Are you sure you wish to delete this event?  This is irreversible',
                    });
                  }}
                >
                  Delete
                </Button>
              </GridCell>
            </Hidden>
          }
          {focusedEvent &&
            <Hidden breakpoint='hiddengreaterthan650'>
              <GridCell center style={{marginLeft: 5, height: 45}}>
                <Button
                  size='small'
                  palette='secondary'
                  onClick={() => handleUpdateEvent()}
                  disabled={processing || !focusedEvent?.host || !focusedEvent?.name}
                >
                  {focusedEvent?._id === 'new' ? 'Create' : 'Update'}
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            </Hidden>
          }
        </GridContainer>
      </div>
      {!focusedEvent &&
        <SearchEvents
          search={search}
          queryLimit={queryLimit}
          setSearch={setSearch}
          processing={processing}
          setProcessing={setProcessing}
          handleChangeFocusedEvent={handleChangeFocusedEvent}

          events={events}
          setEvents={setEvents}
          setFocusedEvent={setFocusedEvent}
          setEventReferences={setEventReferences}

          areaReferences={areaReferences}
          setAreaReferences={setAreaReferences}

          focusedOrganisation={focusedOrganisation}
          setFocusedOrganisation={setFocusedOrganisation}
          organisations={organisations}
          organisationReferences={organisationReferences}
          setOrganisationReferences={setOrganisationReferences}
          setOrganisationReference={setOrganisationReference}
        />
      }

      {focusedEvent &&
        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
          {(!focusedEvent?.host || !focusedEvent?.name) &&
            <div style={{padding: 10, margin: 10, borderStyle: 'solid', borderWidth: 1, borderColor: 'red'}}>
              <Typography size='title' style={{color: 'red'}}>
                Required information is missing:
              </Typography>
              {!focusedEvent?.name &&
                <Typography style={{color: 'red'}}>
                  Preview - Name
                </Typography>
              }
              {!focusedEvent?.host &&
                <Typography style={{color: 'red'}}>
                  Information - Host
                </Typography>
              }
            </div>
          }
          <GridContainer style={{borderStyle: 'none none solid none', marginBottom: 10, marginTop: 10, flexWrap: 'nowrap'}}>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'preview' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('preview')}>
              Preview
            </GridCell>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'layout' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('layout')}>
              Layout
            </GridCell>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'info' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('info')}>
              Information
            </GridCell>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'gallery' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('gallery')}>
              Gallery
            </GridCell>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'video' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('video')}>
              Videos
            </GridCell>
          </GridContainer>
          {focusedSubSection === 'preview' &&
            <EditEventPreview
              focusedEvent={focusedEvent}
              focusedOrganisation={focusedOrganisation}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedEvent={handleChangeFocusedEvent}
              handleChangesFocusedEvent={handleChangesFocusedEvent}
              handleUploadImage={handleUploadImage}
            />
          }
          {focusedSubSection === 'gallery' &&
            <EditEventGallery
              focusedEvent={focusedEvent}
              focusedOrganisation={focusedOrganisation}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedEvent={handleChangeFocusedEvent}
              handleUploadImage={handleUploadImage}
            />
          }

          {focusedSubSection === 'video' &&
            <EditEventVideoLibrary
              focusedEvent={focusedEvent}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedEvent={handleChangeFocusedEvent}
              handleUploadImage={handleUploadImage}
            />
          }

          {focusedSubSection === 'layout' &&
            <EditEventLayout
              me={me}
              focusedEvent={focusedEvent}
              handleChangeFocusedEvent={handleChangeFocusedEvent}
              processing={processing}
              setProcessing={setProcessing}
            />
          }

          {focusedSubSection === 'info' &&
            <EditEventInfo
              focusedEvent={focusedEvent}
              handleChangeFocusedEvent={handleChangeFocusedEvent}
              handleChangesFocusedEvent={handleChangesFocusedEvent}
              processing={processing}
              setProcessing={setProcessing}

              search={search}
              setSearch={setSearch}
              queryLimit={queryLimit}

              areas={areas}
              setAreas={setAreas}
              areaReferences={areaReferences}
              setAreaReference={setAreaReference}

              organisations={organisations}
              setOrganisations={setOrganisations}
              organisationReferences={organisationReferences}
              setOrganisationReference={setOrganisationReference}
            />
          }
        </div>
      }
      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'deleteEvent' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => handleDeleteEvent()}
                >
                  Delete Event
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ManageEventsPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,

    events: state.lists.events,

    organisations: state.lists.organisations,
    organisationReferences: state.references.organisations || {},

    areas: state.lists.areas,
    areaReferences: state.references.areas || {},
  };
};

export default connect(mapStateToProps, {
  setSearch,

  setEvents,
  addEvents,
  addEvent,
  replaceEvent,
  removeEvent,
  setEventReference,
  clearEventReference,
  setEventReferences,

  setOrganisations,
  addOrganisations,
  setOrganisationReference,
  setOrganisationReferences,

  setAreas,
  addAreas,
  setAreaReference,
  setAreaReferences,
})(ManageEventsPanel);
