import React, {useState} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../../components/search/store';
import {Button, ProgressBar, Typography, Dialog, paletteData} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

import apiRequest from '../../../../../tools/apiRequest';
import handleErrorMessage from '../../../../../tools/handleErrorMessage';

function SearchReviewsSubPanel({
    search,
    queryLimit,
    setSearch,
    processing,
    setProcessing,
    handleChangeFocusedReview,

    reviews,
    removeReview,
    setReviews,
    addReviews,
    setFocusedReview,
    setReviewReferences,

    organisations,
    setOrganisations,
    addOrganisations,
    replaceOrganisation,
    organisationReferences,
    setOrganisationReference,
    focusedOrganisation,
    setFocusedOrganisation,
}) {
  const [expandSearch, setExpandSearch] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <SearchStore databaseArea='events' processing={processing} setProcessing={setProcessing} expandSearch={expandSearch} setExpandSearch={setExpandSearch} filter={{organisationId: focusedOrganisation?._id}}/>
        </GridCell>
        <GridCell style={{paddingTop: 7, paddingLeft: 10}}>
          <Button
            disabled={processing}
            palette='primary' onClick={() => {
              setDialogData({
                type: 'changeOrganisation',
                title: 'Change Provider',
                value: {},
              });
              setDialogOpen(true);
            }}
          >
            {focusedOrganisation?.name || 'Choose Organisation'}
          </Button>
        </GridCell>
        {focusedOrganisation?._id &&
          <GridCell style={{paddingTop: 7, paddingLeft: 10}}>
            <Button disabled={processing} palette='primary' onClick={() => {
              setDialogData({
                type: 'recalculateScore',
                title: 'Recalculate Score',
                message: `Do you wish to manualy recalculate score of ${focusedOrganisation?.name}.  This should only be done if you have removed reviews and may take some time to process.`,
                data: {
                  organisationId: focusedOrganisation._id,
                }
              });
              setDialogOpen(true);
            }}>
              Recalculate Score ({+((focusedOrganisation?.review?.stars || 0)/(focusedOrganisation?.review?.total || 1)).toFixed(1)}:{focusedOrganisation?.review?.total || 0})
            </Button>
          </GridCell>
        }
      </GridContainer>
      {focusedOrganisation && reviews?.length > 0 &&
        <div>
          {reviews.map((r, rIndex) => (
            <GridContainer key={rIndex} style={{padding: 10, marginTop: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: 5}}>
                <GridCell weight={1}>
                  <span className="material-icons md-24" style={{fontSize: 20}}>{r.stars > 0 ? 'star' : 'star_border'}</span>
                  <span className="material-icons md-24" style={{fontSize: 20}}>{r.stars > 1 ? 'star' : 'star_border'}</span>
                  <span className="material-icons md-24" style={{fontSize: 20}}>{r.stars > 2 ? 'star' : 'star_border'}</span>
                  <span className="material-icons md-24" style={{fontSize: 20}}>{r.stars > 3 ? 'star' : 'star_border'}</span>
                  <span className="material-icons md-24" style={{fontSize: 20}}>{r.stars > 4 ? 'star' : 'star_border'}</span>
                  <br/>
                  <Typography>
                    {r.text}
                  </Typography>
                </GridCell>
                <GridCell>
                  <Button palette='primary' onClick={() => {
                    setDialogData({
                      type: 'deleteReview',
                      title: 'Delete Review',
                      message: 'Are you sure you wish to delete this review?  doing so is permanent.',
                      data: {
                        reviewId: r._id,
                        reviewIndex: rIndex,
                      }
                    });
                    setDialogOpen(true);
                  }}>
                    Delete Review
                  </Button>
                </GridCell>
            </GridContainer>
          ))}
          {processing && reviews?.length > 9 &&
            <div>
              <Typography>
                Loading...
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {reviews.length >= search?.reviews?.queryDepth * queryLimit &&
            <div style={{padding: 5}}>
              <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                Load More
              </Button>
            </div>
          }
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'changeOrganisation' &&
            <div style={{padding: 10}}>
              <SearchStore
                databaseArea='organisations'
                processing={processing}
                setProcessing={setProcessing}
                expandSearch={dialogData.value.expandSearch}
                setExpandSearch={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...dialogData.value,
                      expandSearch: value,
                    }
                  });
                }}
              />
              {(!organisations || processing) &&
                <ProgressBar palette='secondary'/>
              }
              {!processing && organisations && organisations.map((o, oIndex) => (
                <div
                  key={oIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    setProcessing(true);
                    if(!organisationReferences[o._id]) {
                      setOrganisationReference(o);
                    }
                    setFocusedOrganisation(o);
                    const query = {organisationId: o._id}
                    setSearch({name: 'reviews', value: {text: '', queryDepth: 1, query}});
                    apiRequest({type: 'get', action: 'reviews', data: {query: query, sort: {createdAt: -1}, skip: 0, limit: queryLimit}})
                    .then((result) => {
                      setProcessing(false);
                      setReviews(result.data.reviews || []);
                      setReviewReferences(result.data.reviews || []);
                      setDialogOpen(false);
                      setDialogData(undefined);
                    }).catch((error) => {
                      setProcessing(false);
                      setReviews([]);
                      setDialogData({
                        type: 'message',
                        title: `View reviews request denied`,
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  {o?.name}
                </div>
              ))}
              {processing && organisations?.length > 15 &&
                <div>
                  <Typography>
                    Loading...
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {organisations?.length >= search?.organisations?.queryDepth * queryLimit &&
                <div style={{padding: 5}}>
                  <Button
                    palette='primary'
                    disabled={processing}
                    onClick={() => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...dialogData.value,
                          expandSearch: true,
                        }
                      });
                    }}
                  >
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'deleteReview' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                     setProcessing(true);
                     apiRequest({type: 'delete', action: `reviews/delete/${dialogData?.data?.reviewId}`})
                     .then((result) => {
                       removeReview(dialogData?.data?.reviewIndex);
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     }).catch((error) => {
                       setProcessing(false);
                       setDialogData({
                         type: 'message',
                         title: 'Delete event request denied',
                         message: handleErrorMessage(error),
                       });
                       setDialogOpen(true);
                     });
                  }}
                >
                  Delete
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'recalculateScore' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                     setProcessing(true);
                     apiRequest({type: 'patch', action: `organisations/recalculateReviewScore/${dialogData?.data?.organisationId}`})
                     .then((result) => {
                       setProcessing(false);
                       if(organisations) {
                         const oIndex = organisations.findIndex((o) => o._id === dialogData?.data?.organisationId);
                         if(oIndex !== -1) {
                           replaceOrganisation({index: oIndex, organisation: result.data.organisation});
                         }
                       }
                       setOrganisationReference(result.data.organisation);
                       setFocusedOrganisation(result.data.organisation);

                       console.log(result.data.organisation);
                       setDialogData(undefined);
                       setDialogOpen(false);
                     }).catch((error) => {
                       setProcessing(false);
                       setDialogData({
                         type: 'message',
                         title: 'Recalculate score request denied',
                         message: handleErrorMessage(error),
                       });
                       setDialogOpen(true);
                     });
                  }}
                >
                  Recalculate
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

SearchReviewsSubPanel.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
  addReviews: PropTypes.func,
  search: PropTypes.shape({}),
  setSearch: PropTypes.func,
  queryLimit: PropTypes.number,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  setFocusedReview: PropTypes.func,
  setFocusedReviewIndex: PropTypes.func,
};

export default SearchReviewsSubPanel;
