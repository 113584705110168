import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {paletteData, Button, Typography, Dialog, Input} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

import apiRequest from '../../../../../tools/apiRequest';

function SearchCategoriesSubPanel({
    processing,
    setProcessing,
    categories,
    setFocusedCategory,
    userReferences,
    setUserReferences,
    setModeratorsLoaded,
  }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [search, setSearch] = useState('');

  return (
    <div>
      <form>
        <Input
          name="name"
          palette='secondary'
          label="Search"
          type="text"
          autoComplete='off'
          value={search || ''}
          onChange={(value) => setSearch(value)}
        />
      </form>
      {categories?.length > 0 && categories.filter((c, cIndex) => {
        return c.name.toLowerCase().includes(search.toLowerCase());
      }).map((c, cIndex) => (
        <div
          key={cIndex}
          style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
          onClick={() => {
            const usersToFetch = [];
            for(let i = 0; i < (c.moderators || []).length; i += 1) {
              if(!userReferences[c.moderators[i]]){
                usersToFetch.push(c.moderators[i])
              }
            }
            if(usersToFetch.length > 0) {
              const query = {_id: {$in: usersToFetch}};
              apiRequest({type: 'get', action: 'users', data: {query, sort: {name: 1}}})
              .then((result) => {
                setUserReferences(result.data.users);
                setModeratorsLoaded(true);
              }).catch((error) => {
                setModeratorsLoaded(true);
              });
            } else {
              setModeratorsLoaded(true);
            }
            setFocusedCategory(c);
          }}
        >
          {c.nameProper || c.name}
        </div>
      ))}

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
       >
         <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
           <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
             {dialogData?.title}
           </Typography>
         </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

SearchCategoriesSubPanel.propTypes = {
  focusedCategory: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedCategory: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleUploadImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default SearchCategoriesSubPanel;
