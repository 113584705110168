import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {paletteData, textData} from '../../../styles';

function Select({onChange, onFocus, onBlur, palette, label, name, value, style, inputStyle, containerStyle, indicatorStyle, labelStyle, highlightStyle, disabled, forceShrink, children}) {
  const [inputValue, setInputValue] = useState(value || '');
  const [focused, setFocused] = useState(false);
  const [labelHeight, setLabelHeight] = useState((forceShrink || value || value === 0 || inputValue || focused) ? 25 : 5);
  const [labelFontSize, setLabelFontSize] = useState((forceShrink || value || value === 0 || inputValue || focused) ? '.8em' :'1em');
  const [highlightPosition, setHighlightPosition] = useState((focused) ? 'translateX(0%)' : 'translateX(-105%)');
  const [labelColor, setLabelColor] = useState((focused) ? paletteData[palette || 'blank'].highlight.foreground: paletteData[palette || 'blank'].standard.foreground);

  useEffect(() => {
    if(value !== inputValue) {
      setInputValue(value);
    }
    setLabelHeight((forceShrink || value || value === 0 || inputValue || focused) ? 25 : 5);
    setLabelFontSize((forceShrink || value || value === 0 || inputValue || focused) ? '.8em' :'1em');
    setHighlightPosition((focused) ? 'translateX(0%)' : 'translateX(-105%)');
    setLabelColor((focused) ? paletteData[palette || 'blank'].highlight.foreground: paletteData[palette || 'blank'].standard.foreground);

	}, [value, palette, focused, forceShrink, inputValue]);

  return (
    <div style={{padding: 5, marginTop: 2, ...style}}>
      <div style={{ position: 'relative', width: '100%', overflow: 'hidden', minHeight: 45, ...containerStyle}}>
        <select
          disabled={disabled}
          name={name}
          value={value}
          style={{
            width: '100%',
            height: '100%',
            paddingTop: 20,
            border: 'none',
            outline: 'none',
            fontSize: '1em',
            fontFamily: textData.text.fontFamily,
            color: paletteData[palette || 'blank'].standard.foreground,
            background: 'rgba(0, 0, 0, 0.0)',
            ...inputStyle,
          }}
          onFocus={() => {
            // update look when input focused
            setFocused(true);
            setLabelHeight(25);
            setHighlightPosition('translateX(0%)');
            setLabelColor(paletteData[palette || 'blank'].highlight.foreground);
            setLabelFontSize('.8em');
            if(onFocus) {
              onFocus();
            }
          }}
          onBlur={() => {
            // update look when input unfocused
            setFocused(false);
            setLabelHeight((value || value === 0 || forceShrink) ? 25 : 5);
            setLabelFontSize((value || value === 0 || forceShrink)? '.8em' :'1em');
            setHighlightPosition('translateX(-105%)');
            setLabelColor(paletteData[palette || 'blank'].standard.foreground);
            if(onBlur) {
              onBlur();
            }
          }}
          onChange={(e) => {
            setInputValue(e.target.value);
            if(onChange) {
              onChange(e.target.value);
            }
          }}
        >
          <option value="" style={{display: 'none'}}></option>
          {children}
        </select>
        <label htmlFor={name} style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          borderBottom: `1px solid ${paletteData[palette || 'blank'].standard.foreground}`,
          ...indicatorStyle,
        }}>
          <span
            style={{
              position: 'absolute',
              fontSize: labelFontSize,
              fontFamily: textData.text.fontFamily,
              color: labelColor,
              bottom: labelHeight,
              left: 2,
              transition: '0.2s',
              ...labelStyle,
            }}
          >
            {label}
          </span>
        </label>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            borderBottom: `2px solid ${paletteData[palette || 'blank'].highlight.foreground}`,
            transition: '0.2s',
            transform: highlightPosition,
            ...highlightStyle,
          }}
        />
      </div>
    </div>
  );
}

Select.propTypes = {
  value: PropTypes.any,
  forceShrink: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  palette: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.shape({}),
  inputStyle: PropTypes.shape({}),
  containerStyle: PropTypes.shape({}),
  indicatorStyle: PropTypes.shape({}),
  labelStyle:  PropTypes.shape({}),
  highlightStyle: PropTypes.shape({}),
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  forceText:  PropTypes.bool,
  children: PropTypes.node,
};

export default Select;
