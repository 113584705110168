import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import ProductDetailsForm from '../../../../../components/forms/productDetails';
import ProductSortingForm from '../../../../../components/forms/productSorting';
import ProductPaymentForm from '../../../../../components/forms/productPayment';
import ProductOnlinePaymentForm from '../../../../../components/forms/productOnlinePayment';
import ProductAvailabilityForm from '../../../../../components/forms/productAvailability';
import ProductDeliveryForm from '../../../../../components/forms/productDelivery';
import PostSchemeForm from '../../../../../components/forms/postScheme';

import SearchStore from '../../../../../components/search/store';
import {paletteData, Typography, Dialog, Button, ProgressBar, Input, Fab} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';
import apiRequest from '../../../../../tools/apiRequest';
import handleErrorMessage from '../../../../../tools/handleErrorMessage';

function EditProductInfoSubPanel({
  focusedProduct,
  handleChangeFocusedProduct,
  handleChangesFocusedProduct,
  handleUploadImage,
  processing,
  setProcessing,

  search,
  setSearch,
  queryLimit,

  focusedOrganisation,
  organisations,
  setOrganisations,
  organisationReferences,
  setOrganisationReference,

  productGroups,
  productGroupReferences,
  setProductGroups,
  addProductGroups,
  addProductGroup,
  setProductGroupReference,
  replaceProductGroup,

  postSchemes,
  postSchemeReferences,
  addPostSchemes,
  addPostScheme,
  setPostSchemeReference,

  bookingCalendars,
  bookingCalendarReferences,
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(undefined);
    const [imagePreview, setImagePreview] = useState(undefined);

    const imageUploadRef = useRef(null);

    const handleGeneratePreview = (file) => {
      setProcessing(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setProcessing(false);
        setImagePreview(reader.result)
      }
    }

    return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography style={{color: paletteData.primary.standard.foreground}}>
          Product Details
        </Typography>
      </div>
      <ProductDetailsForm
        focusedProduct={focusedProduct}
        organisationReferences={organisationReferences}
        productGroupReferences={productGroupReferences}
        handleChange={(name, value) => {
          if(name === 'productGroup') {
            setDialogData({
              type: 'changeProductGroup',
              title: 'Change Product Group',
              data: {},
            });
            setDialogOpen(true);
          } else {
            handleChangeFocusedProduct(name, value);
          }
        }}
        handleChangeMultiple={(value) => handleChangesFocusedProduct(value)}
      />
      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography style={{color: paletteData.primary.standard.foreground}}>
          Sorting
        </Typography>
      </div>
      <ProductSortingForm
        focusedProduct={focusedProduct}
        handleChange={(name, value) => {
          if(name === 'productGroup') {
            setDialogData({
              type: 'changeProductGroup',
              title: 'Change Product Group',
              data: {},
            });
            setDialogOpen(true);
          } else {
            handleChangeFocusedProduct(name, value);
          }
        }}
        processing={processing}
        setProcessing={setProcessing}

        focusedOrganisation={focusedOrganisation}
        productGroups={productGroups}
        productGroupReferences={productGroupReferences}
        setProductGroupReference={setProductGroupReference}
        replaceProductGroup={replaceProductGroup}
      />

      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography style={{color: paletteData.primary.standard.foreground}}>
          Availability
        </Typography>
      </div>
      <div style={{padding: 10}}>
        <ProductAvailabilityForm
          productType={focusedProduct.type}
          availability={focusedProduct.availability || {}}
          handleChange={(name, value) => {
            const tempAvailability = focusedProduct.availability || {};
            tempAvailability[name] = value;
            handleChangeFocusedProduct('availability', tempAvailability);
          }}
        />
      </div>
      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography style={{color: paletteData.primary.standard.foreground}}>
          Payment
        </Typography>
      </div>
      <div style={{padding: 10}}>
        <ProductPaymentForm
          focusedOrganisation={focusedOrganisation}
          productType={focusedProduct?.type || ''}
          payment={focusedProduct.payment || {}}
          handleChange={(name, value) => {
            const tempPayment = focusedProduct.payment || {};
            tempPayment[name] = value;
            handleChangeFocusedProduct('payment', tempPayment);
          }}
        />
        {focusedProduct?.availability?.online &&
          <ProductOnlinePaymentForm
            payment={focusedProduct.payment || {}}
            handleChange={(name, value) => {
              const tempPayment = focusedProduct.payment || {};
              tempPayment[name] = value;
              handleChangeFocusedProduct('payment', tempPayment);
            }}
          />
        }
      </div>
      {focusedProduct?.availability?.online &&
        <div style={{background: paletteData.primary.standard.background, padding: 10}}>
          <Typography style={{color: paletteData.primary.standard.foreground}}>
            Online Delivery Details
          </Typography>
        </div>
      }
      {focusedProduct?.availability?.online &&
        <div style={{padding: 10}}>
          <ProductDeliveryForm
            postSchemeReferences={postSchemeReferences}
            bookingCalendars={bookingCalendars}
            bookingCalendarReferences={bookingCalendarReferences}
            focusedOrganisation={focusedOrganisation}
            delivery={focusedProduct.delivery || {}}
            availability={focusedProduct?.availability || {}}
            productType={focusedProduct?.type || ''}
            handleChange={(name, value) => {
              if(name === 'nationalPostSchemeId') {
                setDialogData({
                  type: `changeNationalPostSchemeId`,
                  title: 'Change National Post Price Scheme',
                  data: {},
                });
                setDialogOpen(true);
              } else if(name === 'internationalPostSchemeId') {
                setDialogData({
                  type: `changeInterationalPostSchemeId`,
                  title: 'Change International Post Price Scheme',
                  data: {},
                });
                setDialogOpen(true);
              } else if(name === 'onlineBookingCalendar') {
                setDialogData({
                  type: `changeOnlineBookingCalendar`,
                  title: 'Change Online Booking Calendar',
                  data: {},
                });
                setDialogOpen(true);
              } else {
                const tempDelivery = focusedProduct.delivery || {};
                tempDelivery[name] = value;
                handleChangeFocusedProduct('delivery', tempDelivery);
              }
            }}
          />
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
            setDialogData(undefined);
            setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'changeProductGroup' &&
            <div style={{padding: 10}}>
              <GridContainer>
                <GridCell weight={1}>
                  <SearchStore databaseArea='productGroups' processing={processing} setProcessing={setProcessing} expandSearch={false} setExpandSearch={() => {}} filter={{organisationId: focusedOrganisation?._id}}/>
                </GridCell>
                <GridCell style={{padding: 11}}>
                  <Fab
                    size='small'
                    palette='primary'
                    onClick={() => {
                      setDialogData({
                        type: 'createProductGroup',
                        title: `Create Category`,
                        value: {}
                      });
                    }}
                  >
                     <span className="material-icons md-24" style={{fontSize: 16}}>add</span>
                  </Fab>
                </GridCell>
              </GridContainer>
              {!processing && productGroups && productGroups.map((pg, aIndex) => (
                <div
                  key={aIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!productGroupReferences[pg._id]) {
                      setProductGroupReference(pg);
                    }

                    handleChangeFocusedProduct('productGroupId', pg._id);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {pg.name}
                </div>
              ))}
            </div>
          }
          {dialogData?.type === 'createProductGroup' &&
            <div style={{padding: 10}}>
              <GridContainer>
                <GridCell style={{width: 200, textAlign: 'center'}}>
                  {!imagePreview && !dialogData.value.imageId &&
                    <GridContainer style={{height: 200, width: 200, marginTop: 10}}>
                      <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5}}>
                        No Image
                      </GridCell>
                    </GridContainer>
                  }
                  {(imagePreview || dialogData.value.imageId) &&
                    <GridContainer style={{marginTop: 10}}>
                      <GridCell center={true} weight={1}>
                        <img src={imagePreview || `https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${dialogData.value.imageId}`} alt={dialogData?.value?.alt} style={{width: 200}}/>
                      </GridCell>
                    </GridContainer>
                  }
                  <input
                    ref={imageUploadRef}
                    type="file"
                    style={{display: 'none'}}
                    onChange={(event) => {
                      handleGeneratePreview((event?.target?.files?.length) > 0 ? event.target.files[0] : undefined);
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          file: (event?.target?.files?.length) > 0 ? event.target.files[0] : undefined,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                  <Button palette='primary' style={{marginTop: 10}} onClick={() => imageUploadRef.current.click()} disabled={processing}>
                    Change Image
                  </Button>
                  {dialogData?.value?.file && dialogData?.value?.file?.size > 1000000 &&
                    <div style={{padding: 10}}>
                      <Typography style={{color: 'red'}}>
                        File too large, must be under 1 MB
                      </Typography>
                    </div>
                  }
                  {(processing) &&
                    <ProgressBar palette='secondary'/>
                  }
                </GridCell>
                <GridCell weight={1}>
                  <Input
                    name="name"
                    palette='secondary'
                    label="Name"
                    type="text"
                    value={dialogData?.value?.name || ''}
                    onChange={(value) => {
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          name: value,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                </GridCell>
                <GridCell style={{marginTop: 11}}>
                  <Fab
                    size='small'
                    palette='primary'
                    onClick={() => {
                      setDialogData({
                        type: 'changeProductGroup',
                        title: `Change Category`,
                        value: {}
                      });
                    }}
                  >
                     <span className="material-icons md-24" style={{fontSize: 16}}>arrow_back</span>
                  </Fab>
                </GridCell>
              </GridContainer>
            </div>
          }
          {(dialogData?.type === 'changeNationalPostSchemeId' || dialogData?.type === 'changeInterationalPostSchemeId') &&
            <div style={{padding: 10}}>
              <GridContainer>
                <GridCell weight={1}>
                  <SearchStore
                    databaseArea='postSchemes'
                    processing={processing}
                    setProcessing={setProcessing}
                    expandSearch={false}
                    setExpandSearch={() => {}}
                    filter={(dialogData?.type === 'changeNationalPostSchemeId') ?
                            {organisationId: search?.products?.query?.organisationId, type: 'national'} :
                            {organisationId: search?.products?.query?.organisationId, type: 'international'}}
                  />
                </GridCell>
                <GridCell style={{padding: 11}}>
                  <Fab
                    size="small"
                    palette='primary'
                    onClick={() => {
                       setDialogData({
                         type: dialogData?.type === 'changeNationalPostSchemeId' ? 'createNationalPostScheme' : 'createInterationalPostScheme',
                         title: `Create ${dialogData?.type === 'changeNationalPostSchemeId' ? 'National' : 'International'} Post Price Scheme`,
                         value: {
                           type:  dialogData?.type === 'changeNationalPostSchemeId' ? 'national' : 'international'
                         }
                       });
                    }}
                  >
                     <span className="material-icons md-24" style={{fontSize: 16}}>add</span>
                  </Fab>
                </GridCell>
              </GridContainer>
              {!processing && postSchemes && postSchemes.map((ps, psIndex) => (
                <div
                  key={psIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!postSchemeReferences[ps._id]) {
                      setPostSchemeReference(ps);
                    }

                    const deliveryTemp = focusedProduct.delivery;
                    if(dialogData?.type === 'changeNationalPostSchemeId') {
                      deliveryTemp.nationalPostSchemeId = ps._id;
                    } else if(dialogData?.type === 'changeInterationalPostSchemeId') {
                      deliveryTemp.internationalPostSchemeId = ps._id;
                    }

                    handleChangeFocusedProduct('delivery', deliveryTemp);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  <GridContainer>
                    <GridCell style={{width: 120}}>
                      <Typography>
                        {
                          ps.type === 'national' ? 'National: ' :
                          ps.type === 'international' ? 'International: ' : ''
                        }
                      </Typography>
                    </GridCell>
                    <GridCell weight={1}>
                      <Typography>
                        {ps.name}
                      </Typography>
                    </GridCell>
                    <GridCell>
                      ${(focusedOrganisation?.gst ? ps.price*1.15 : ps.price).toFixed(2)} per {ps.bundle} units
                    </GridCell>
                  </GridContainer>
                </div>
              ))}
            </div>
          }
          {(dialogData?.type === 'createNationalPostScheme' || dialogData?.type === 'createInterationalPostScheme') &&
            <div style={{padding: 10}}>
              <GridContainer>
                <GridCell weight={1}>
                  <PostSchemeForm
                    disableType={true}
                    disableGstInclusive={true}
                    focusedOrganisation={focusedOrganisation}
                    postScheme={dialogData?.value}
                    handleChange={(name, value) => {
                      setDialogData({
                        ...(dialogData || {}),
                        value: {
                          ...(dialogData?.value || {}),
                          [name]: value,
                        }
                      })
                    }}
                  />
                </GridCell>
                <GridCell style={{padding: 7}}>
                  <Fab
                    size='small'
                    palette='primary'
                    onClick={() => {
                       setDialogData({
                         type: dialogData?.type === 'createNationalPostScheme' ? 'changeNationalPostSchemeId' : 'changeInterationalPostSchemeId',
                         title: `Change ${dialogData?.type === 'createNationalPostScheme' ? 'National' : 'International'} Post Price Scheme`,
                         value: {}
                       });
                    }}
                  >
                     <span className="material-icons md-24" style={{fontSize: 16}}>arrow_back</span>
                  </Fab>
                </GridCell>
              </GridContainer>
            </div>
          }
          {dialogData?.type === 'changeOnlineBookingCalendar' &&
            <div style={{padding: 10}}>
              <SearchStore databaseArea='bookingCalendars' processing={processing} setProcessing={setProcessing} expandSearch={false} setExpandSearch={() => {}} filter={{organisationId: focusedOrganisation?._id}}/>
              {bookingCalendars?.length > 0 && bookingCalendars.map((c, cIndex) => (
                <div key={cIndex} style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}} onClick={() => {
                  const deliveryProcessed = focusedProduct.delivery;
                  deliveryProcessed.onlineBookingCalendar =  c._id
                  handleChangeFocusedProduct('delivery', deliveryProcessed);
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}>
                  <Typography>
                    {c.name}
                  </Typography>
                </div>
              ))}
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                   setDialogData(undefined);
                   setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'createProductGroup' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.name || !(dialogData?.value?.imageId || imagePreview)}
                  palette='primary'
                  onClick={() => {
                    handleUploadImage(dialogData?.value, (image, error) => {
                      setProcessing(true);
                      const updateProductGroup = {
                         name: dialogData?.value?.name,
                         thumbnail: {
                           imageId: image.imageId,
                           alt: dialogData?.value?.name,
                         },
                         organisationId: focusedOrganisation?._id,
                         tags: [],
                      }
                      apiRequest({type: 'post', action: `productGroups/create`, data: {updateProductGroup}})
                      .then((result) => {
                        if(productGroups) {
                          addProductGroup(result.data.productGroupDatabaseResult);
                        }
                        setProductGroupReference(result.data.productGroupDatabaseResult);
                        handleChangeFocusedProduct('productGroupId', result.data.productGroupDatabaseResult._id)
                        setProcessing(false);
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }).catch((error) => {
                        setProcessing(false);
                        setDialogData({
                          type: 'message',
                          title: 'Update product group request denied',
                          message: handleErrorMessage(error),
                        });
                        setDialogOpen(true);
                      });
                    });
                  }}
                >
                  Create Product Group
                </Button>
              </GridCell>
            }
            {(dialogData?.type === 'createNationalPostScheme' || dialogData?.type === 'createInterationalPostScheme') &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.name || !dialogData?.value?.price || !dialogData?.value?.type || !dialogData?.value?.bundle}
                  palette='primary'
                   onClick={() => {
                     setProcessing(true);
                     const updatePostScheme = {
                       name: dialogData?.value?.name,
                       organisationId: focusedOrganisation._id,
                       type: dialogData?.value?.type,
                       price: dialogData?.value?.price,
                       bundle: dialogData?.value?.bundle,
                     }
                     apiRequest({type: 'post', action: `postSchemes/create`, data: {updatePostScheme}})
                     .then((result) => {
                       addPostScheme(result.data.postSchemeDatabaseResult);
                       setPostSchemeReference(result.data.postSchemeDatabaseResult);

                       const deliveryTemp = focusedProduct.delivery;
                       if(dialogData?.type === 'createNationalPostScheme') {
                         deliveryTemp.nationalPostSchemeId = result.data.postSchemeDatabaseResult._id;
                       } else if(dialogData?.type === 'createInterationalPostScheme') {
                         deliveryTemp.internationalPostSchemeId = result.data.postSchemeDatabaseResult._id;
                       }
                       handleChangeFocusedProduct('delivery', deliveryTemp);

                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     }).catch((error) => {
                       setProcessing(false);
                       setDialogData({
                         type: 'message',
                         title: 'Create post scheme request denied',
                         message: handleErrorMessage(error),
                       });
                       setDialogOpen(true);
                     });
                  }}
                >
                  Create Post Price Scheme
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditProductInfoSubPanel.propTypes = {
  focusedProduct: PropTypes.shape({}),
  handleChangeFocusedProduct: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  handleUploadImage: PropTypes.func,
};

export default EditProductInfoSubPanel;
