import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../../components/search/store';
import {paletteData, Button, ProgressBar, Typography, Dialog, Select, TextArea, Input} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

import apiRequest from '../../../../../tools/apiRequest';
import handleErrorMessage from '../../../../../tools/handleErrorMessage';
import {times, bookingTimes, createOffset, processBookings, processTimesRemoveOffeset} from '../../../../../tools/calendarTimes';

function SearchOrdersSubPanel({
    me,
    search,
    queryLimit,
    setSearch,
    processing,
    setProcessing,
    handleChangeFocusedOrder,

    orders,
    setOrders,
    addOrders,
    handleDeleteOrder,
    replaceOrder,
    setOrderReference,
    removeOrder,

    organisations,
    setOrganisations,
    addOrganisations,
    organisationReferences,
    setOrganisationReference,
    focusedOrganisation,
    setFocusedOrganisation,

    bookings,
    setBookings,
    addBooking,
    replaceBooking,
    bookingReferences,
    bookingCalendarReferences,
    setBookingReferences,
    setBookingReference,
    setBookingCalendarReferences,
    setBookingCalendarReference,
}) {
  const [expandSearch, setExpandSearch] = useState(false);
  const [overRideSearch, setOverRideSearch] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [filter, setFilter] = useState(undefined);
  const [filterStatus, setFilterStatus] = useState(undefined);
  const [filterStages, setFilterStages] = useState(undefined);

  const [bookingsMap, setBookingsMap] = useState(undefined);

  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if(!firstLoad && focusedOrganisation) {
      setFirstLoad(true);
      const filterProcessed = {
        provider: focusedOrganisation._id,
      };

      if(search?.orders?.query) {
        if(search?.orders?.query?.status) {
          setFilterStatus(search?.orders?.query?.status);
          filterProcessed.status = search?.orders?.query?.status;
        } else {
          setFilterStatus('all');
        }

        if(search?.orders?.query['stages.paid']) {
          setFilterStages('stages.paid');
          filterProcessed['stages.paid'] = true;
        } else if(search?.orders?.query['stages.packed']) {
          setFilterStages('stages.packed');
          filterProcessed['stages.packed'] = true;
        } else if(search?.orders?.query['stages.delivered']) {
          setFilterStages('stages.delivered');
          filterProcessed['stages.delivered'] = true;
        } else {
          setFilterStages('all');
        }
      } else {
        setFilterStatus('processing');
        filterProcessed.status = 'processing';
        setFilterStages('all');
      }

      setFilter(filterProcessed);
    }
  }, [firstLoad, search, focusedOrganisation]);

  const handleDownloadInvoice = async (order) => {
    setProcessing(true);
    apiRequest({
      type: 'get',
      action: `download/invoice/${order._id}`,
    })
    .then((result) => {
      setProcessing(false);
      const pfdBuffer = Buffer.from(result.data.pdfResult);
      const url = window.URL.createObjectURL(new Blob([pfdBuffer], {type: 'application/pdf'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${result.data.filename}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'download invoice request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleDownloadReceipt = (order) => {
    setProcessing(true);
    apiRequest({
      type: 'get',
      action: `download/receipt/${order._id}`,
    })
    .then((result) => {
      setProcessing(false);
      const pfdBuffer = Buffer.from(result.data.pdfResult);
      const url = window.URL.createObjectURL(new Blob([pfdBuffer], {type: 'application/pdf'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${result.data.filename}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'download receipt request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleMarkPaid = (orderIndex, orderId) => {
    setProcessing(true);
    apiRequest({type: 'patch', action: `orders/paid/${orderId}`})
    .then((result) => {
      setProcessing(false);
      const orderProcessing = orders[orderIndex];
      orderProcessing.stages.paid = true;
      if(orderProcessing.stages.confirmed && orderProcessing.stages.paid && orderProcessing.stages.packed && orderProcessing.stages.delivered) {
        orderProcessing.status = 'completed';
      }
      if((filterStages === 'all' || filterStages === 'stages.paid') && orderProcessing.status !== 'completed') {
        replaceOrder({index: orderIndex, order: orderProcessing});
        setOrderReference(orderProcessing);
      } else {
        removeOrder(orderIndex);
      }
      setDialogData({
        type: 'markPaid',
        title: 'Order marked as paid',
        message: 'Order marked as paid.  Do you wish to notify the client?',
        data: {orderId},
      });
      setDialogOpen(true);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update order request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleMarkPacked = (orderIndex, orderId) => {
    setProcessing(true);
    apiRequest({type: 'patch', action: `orders/packed/${orderId}`})
    .then((result) => {
      setProcessing(false);
      const orderProcessing = orders[orderIndex];
      orderProcessing.stages.packed = true;
      if(orderProcessing.stages.confirmed && orderProcessing.stages.paid && orderProcessing.stages.packed && orderProcessing.stages.delivered) {
        orderProcessing.status = 'completed';
      }
      if((filterStages === 'all' || filterStages === 'stages.packed') && orderProcessing.status !== 'completed') {
        replaceOrder({index: orderIndex, order: orderProcessing});
        setOrderReference(orderProcessing);
      } else {
        removeOrder(orderIndex);
      }
      setDialogData({
        type: 'markPacked',
        title: 'Order marked as packed / booked',
        message: 'Order marked as packed / booked.  Do you wish to notify the client?  If the order includes a service deleivery please contact the client directly with the details.',
        data: {orderId},
      });
      setDialogOpen(true);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update order request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleMarkDelivered = (orderIndex, orderId) => {
    setProcessing(true);
    apiRequest({type: 'patch', action: `orders/delivered/${orderId}`})
    .then((result) => {
      setProcessing(false);
      const orderProcessing = orders[orderIndex];
      orderProcessing.stages.delivered = true;
      if(orderProcessing.stages.confirmed && orderProcessing.stages.paid && orderProcessing.stages.packed && orderProcessing.stages.delivered) {
        orderProcessing.status = 'completed';
      }
      if((filterStages === 'all' || filterStages === 'stages.delivered') && orderProcessing.status !== 'completed') {
        replaceOrder({index: orderIndex, order: orderProcessing});
        setOrderReference(orderProcessing);
      } else {
        removeOrder(orderIndex);
      }
      setDialogData({
        type: 'markDelivered',
        title: 'Order marked as delivered',
        message: 'Order marked as delivered.  Do you wish to notify the client?  If the order includes extra details like tracking numbers please contact the client directly.',
        data: {orderId},
      });
      setDialogOpen(true);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update order request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const checkBookings = (o) => {
    const products = o.products;
    for(let i = 0; i < products.length; i += 1) {
      if(products[i].type === 'service' && products[i].delivery?.onlineBooking && !products[i].selectedBooking.bookingId) {
        return false;
      }
    }
    return true;
  }

  const filterAvailableTimes = (calendar, offset) => {
    let shift = Math.floor(offset / 15);
    while (shift > 95) {
      shift -= 96;
    }
    while (shift < -95) {
      shift += 96;
    }

    let minimumStartTime = calendar.minimumStartTime + shift;
    let maximumEndTime = calendar.maximumEndTime + shift;

    if(minimumStartTime > 95) {
      minimumStartTime -= 96;
    }
    if(maximumEndTime > 95) {
      maximumEndTime -= 96;
    }

    if(minimumStartTime < 0) {
      minimumStartTime += 96;
    }
    if(maximumEndTime < 0) {
      maximumEndTime += 96;
    }

    return times.filter((t, tIndex) => {
      if(minimumStartTime < maximumEndTime) {
        return t.value >= minimumStartTime && t.value <= maximumEndTime;
      } else {
        return t.value >= minimumStartTime || t.value <= maximumEndTime;
      }
    });
  }

  const checkBlockAvailability = (calendar, time, bookingId, bookingDate, bookingTime, bookingDuration, offset, bookingTimes) => {
    const duplicationCheck = (bookingTimes || []).filter((t, tIndex) => {
      return t.date === bookingDate;
    });
    if(duplicationCheck.length > 0) {
      const duplicationCheck2 = duplicationCheck.filter((t, tIndex) => {
        return time >= parseInt(t.start) && time < (parseInt(t.start) + parseInt(t.blocks));
      });
      if(duplicationCheck2.length > 1) {
        return 'red';
      }
    }

    let shift = Math.floor(offset / 15);
    let timeWithOffset = time - shift;
    let dateWithOffset = bookingDate;
    // const leadinTest = Math.ceil((new Date(`${bookingDate} ${times[bookingTime || 0].blockStart}`) - new Date()) / (1000 * 60 * 60 * 24));

    if(timeWithOffset > 95) {
      let dateShift = 0;
      while(timeWithOffset > 95) {
        timeWithOffset -= 96;
        shift += 96;
        dateShift += 1;
      }
      const dateTemp = new Date(`${bookingDate} 00:00 UTC`);
      dateTemp.setDate(dateTemp.getDate() + dateShift);
      dateWithOffset = `${dateTemp.getFullYear()}-${(dateTemp.getMonth() + 1) < 10 ? '0' : ''}${dateTemp.getMonth() + 1}-${dateTemp.getDate() < 10 ? '0' : ''}${dateTemp.getDate()}`;
    } else if(timeWithOffset < 0) {
      let dateShift = 0;
      while(timeWithOffset < 0) {
        timeWithOffset += 96;
        shift -= 96;
        dateShift -= 1;
      }
      const dateTemp = new Date(`${bookingDate} 00:00 UTC`);
      dateTemp.setDate(dateTemp.getDate() + dateShift);
      dateWithOffset = `${dateTemp.getFullYear()}-${(dateTemp.getMonth() + 1) < 10 ? '0' : ''}${dateTemp.getMonth() + 1}-${dateTemp.getDate() < 10 ? '0' : ''}${dateTemp.getDate()}`;
    }

    const calendarDate = new Date(`${dateWithOffset} ${times[timeWithOffset || 0].blockStart}`);
    const day = calendarDate.getDay();
    let minimumStartTime = calendar.minimumStartTime + shift;
    let maximumEndTime = calendar.maximumEndTime + shift;
    if(minimumStartTime > 95) {
      minimumStartTime -= 96;
    } else if(maximumEndTime > 95) {
      maximumEndTime -= 96;
    }

    if(minimumStartTime < 0) {
      minimumStartTime += 96;
    } else if(maximumEndTime < 0) {
      maximumEndTime += 96;
    }

    // booking time coinsides with block
    if(bookingTime !== undefined && bookingDuration !== undefined) {
      if(time >= bookingTime && timeWithOffset <= (parseInt(bookingTime) + parseInt(bookingDuration) -1)) {
        // check if outside buisness hours version 1
        if(minimumStartTime < maximumEndTime && (time < minimumStartTime || time > maximumEndTime)) {
          return 'red';
        }
        // check if outside buisness hours version 2
        if(minimumStartTime > maximumEndTime && (time < minimumStartTime && time > maximumEndTime)) {
          return 'red';
        }
        // check if buisness hours is invalid
        if(minimumStartTime === maximumEndTime) {
          return 'red';
        }
        // check if buisness day
        if(!calendar.availableDays.sunday && day === 0) return 'red';
        if(!calendar.availableDays.monday && day === 1) return 'red';
        if(!calendar.availableDays.tuesday && day === 2) return 'red';
        if(!calendar.availableDays.wednesday && day === 3) return 'red';
        if(!calendar.availableDays.thursday && day === 4) return 'red';
        if(!calendar.availableDays.friday && day === 5) return 'red';
        if(!calendar.availableDays.saturday && day === 6) return 'red';
        // does not need leadin test as admin can ignore
        /*
        if(leadinTest < 7) {
          return 'red';
        }
        */
        // check other apointments

        if(
          bookingsMap &&
          bookingsMap[dateWithOffset] &&
          bookingsMap[dateWithOffset][timeWithOffset] &&
          bookingsMap[dateWithOffset][timeWithOffset].bookings[calendar._id]?.filter((bId) => {
            return bId !== bookingId;
          })?.length > 0
        ) {
          return 'red';
        }
        return 'green';
      }
    }

    // booking time does not coinside with block
    // check if outside buisness hours version 1
    if(minimumStartTime < maximumEndTime && (time < minimumStartTime || time > maximumEndTime)) {
      return '#c6c6c6';
    }
    // check if outside buisness hours version 2
    if(minimumStartTime > maximumEndTime && (time < minimumStartTime && time > maximumEndTime)) {
      return '#c6c6c6';
    }
    // check if buisness hours is invalid
    if(minimumStartTime === maximumEndTime) {
      return '#c6c6c6';
    }
    // check if buisness day
    if(!calendar.availableDays.sunday && day === 0) return '#c6c6c6';
    if(!calendar.availableDays.monday && day === 1) return '#c6c6c6';
    if(!calendar.availableDays.tuesday && day === 2) return '#c6c6c6';
    if(!calendar.availableDays.wednesday && day === 3) return '#c6c6c6';
    if(!calendar.availableDays.thursday && day === 4) return '#c6c6c6';
    if(!calendar.availableDays.friday && day === 5) return '#c6c6c6';
    if(!calendar.availableDays.saturday && day === 6) return '#c6c6c6';
    // does not need leadin test as admin can ignore
    /*
    if(leadinTest < 7) {
      return '#565656';
    }
    */
    // check other apointments
    if(
      bookingsMap &&
      bookingsMap[dateWithOffset] &&
      bookingsMap[dateWithOffset][timeWithOffset] &&
      bookingsMap[dateWithOffset][timeWithOffset].bookings[calendar._id]?.filter((bId) => {
        return bId !== bookingId;
      })?.length > 0
    ) {
      return '#565656';
    }
    return '#eafedd';
  }

  const fetchDays = (daysToFetch) => {
    const bookingsProcessed = {};
    const bookingsMapProcessed = {};
    for(let i = 0; i < daysToFetch.length; i += 1) {
      if(!bookings || !bookings[daysToFetch[i]] || bookings[daysToFetch[i]].loaded !== true) {
        bookingsProcessed[daysToFetch[i]] = {
          loaded: true,
          list: [],
        }
      } else {
        for(let j = 0; j < bookings[daysToFetch[i]].list.length; j += 1) {
          const start = parseInt(bookings[daysToFetch[i]].list[j].time.start);
          const blocks = parseInt(bookings[daysToFetch[i]].list[j].time.blocks);
          if(!bookingsMapProcessed[daysToFetch[i]]) {
            bookingsMapProcessed[daysToFetch[i]] = {};
          }
          for(let k = 0; k < blocks; k += 1) {
            if(!bookingsMapProcessed[daysToFetch[i]][start + k]) {
              bookingsMapProcessed[daysToFetch[i]][start + k] = {
                booked: false,
                bookings: {},
              }
            }
            if(!bookingsMapProcessed[daysToFetch[i]][start + k].bookings[bookings[daysToFetch[i]].list[j].bookingCalendarId]) {
              bookingsMapProcessed[daysToFetch[i]][start + k].bookings[bookings[daysToFetch[i]].list[j].bookingCalendarId] = [];
            }
            bookingsMapProcessed[daysToFetch[i]][start + k].booked = true;
            bookingsMapProcessed[daysToFetch[i]][start + k].bookings[bookings[daysToFetch[i]].list[j].bookingCalendarId].push(bookings[daysToFetch[i]].list[j]._id);
          }
        }
      }
    }
    if(Object.keys(bookingsProcessed).length > 0) {
      setProcessing(true);
      const query = {
        organisationId: focusedOrganisation._id,
        'times.date': {$in: Object.keys(bookingsProcessed)},
      };
      apiRequest({type: 'get', action: 'bookings', data: {query, sort: {name: 1}, skip: 0}})
      .then((result) => {
        const bookingsTemp = processBookings(result.data || []);
        const bookingsKeys = Object.keys(bookingsTemp);
        for(let i = 0; i < bookingsKeys.length; i += 1) {
          if(bookingsProcessed[bookingsKeys[i]]) {
            bookingsProcessed[bookingsKeys[i]].list = bookingsTemp[bookingsKeys[i]].list;
            for(let j = 0; j < bookingsTemp[bookingsKeys[i]].list.length; j += 1) {
              const start = parseInt(bookingsTemp[bookingsKeys[i]].list[j].time.start);
              const blocks = parseInt(bookingsTemp[bookingsKeys[i]].list[j].time.blocks);
              if(!bookingsMapProcessed[bookingsKeys[i]]) {
                bookingsMapProcessed[bookingsKeys[i]] = {};
              }
              for(let k = 0; k < blocks; k += 1) {
                if(!bookingsMapProcessed[bookingsKeys[i]][start + k]) {
                  bookingsMapProcessed[bookingsKeys[i]][start + k] = {
                    booked: false,
                    bookings: {},
                  }
                }
                if(!bookingsMapProcessed[bookingsKeys[i]][start + k].bookings[bookingsTemp[bookingsKeys[i]].list[j].bookingCalendarId]) {
                  bookingsMapProcessed[bookingsKeys[i]][start + k].bookings[bookingsTemp[bookingsKeys[i]].list[j].bookingCalendarId] = [];
                }
                bookingsMapProcessed[bookingsKeys[i]][start + k].booked = true;
                bookingsMapProcessed[bookingsKeys[i]][start + k].bookings[bookingsTemp[bookingsKeys[i]].list[j].bookingCalendarId].push(bookingsTemp[bookingsKeys[i]].list[j]._id);
              }
            }
          }
        }
        setBookingsMap({
          ...(bookingsMap || {}),
          ...bookingsMapProcessed
        });
        setBookingReferences(result.data);
        setBookings(bookingsProcessed);
        setProcessing(false);
      }).catch((error) => {
        console.log(handleErrorMessage(error));
      });
    } else {
      setBookingsMap({
        ...bookingsMap,
        ...bookingsMapProcessed
      });
    }
  }

  const validateTimes = (t) => {
    if(t.length === 0) {
      return false;
    }
    for(let i = 0; i < t.length; i += 1) {
      if(!t[i].date || !t[i].start || !t[i].blocks) {
        return false;
      }
    }
    return true;
  }

  const processAvailableTimes = async (calendarId, daysToFetch) => {
    const availableTimes = {};
    if(bookingCalendarReferences[calendarId]) {
      for(let i = 0; i < daysToFetch.length; i += 1) {
        const offset = createOffset(bookingCalendarReferences[calendarId], daysToFetch[i]);
        const availableTimesOnDate = filterAvailableTimes(bookingCalendarReferences[calendarId], offset);
        availableTimes[daysToFetch[i]] = availableTimesOnDate;
      }
    } else {
      const result = await apiRequest({type: 'get', action: `bookingCalendars/${calendarId}`});
      setBookingCalendarReference(result.data.bookingCalendar);
      for(let i = 0; i < daysToFetch.length; i += 1) {
        const offset = createOffset(result.data.bookingCalendar, daysToFetch[i]);
        const availableTimesOnDate = filterAvailableTimes(result.data.bookingCalendar, offset);
        availableTimes[daysToFetch[i]] = availableTimesOnDate;
      }
    }
    return availableTimes;
  }

  return (
    <div>
      <GridContainer>
        <GridCell>
          <Select
            disabled={!focusedOrganisation}
            name="status"
            palette='secondary'
            label="Status"
            value={filterStatus || ''}
            onChange={(value) => {
              setProcessing(true);
              setFilterStatus(value);
              const filterProcessed = {provider: focusedOrganisation._id};
              if(value !== 'all') {
                filterProcessed.status = value;
              }
              if((value === 'processing' || value === 'all') && filterStages !== 'all') {
                filterProcessed[filterStages] = false;
              }
              setFilter({...filterProcessed});

              const query = {
                provider: focusedOrganisation._id,
                ...(filterProcessed || {}),
              };
              const sort = {createdAt: -1};
              setOverRideSearch(true);
              setSearch({name: 'orders', value: {text: '', queryDepth: 1, query, data: {usesQueryLimit: true}}});
              apiRequest({type: 'get', action: 'orders', data: {query, sort, skip: 0, limit: queryLimit}})
              .then((result) => {
                setOrders(result.data.orders || []);
                setProcessing(false);
              }).catch((error) => {
                setProcessing(false);
                setOrders([]);
                setDialogData({
                  type: 'message',
                  title: `View bookings request denied`,
                  message: handleErrorMessage(error),
                });
                setDialogOpen(true);
              });
            }}
          >
            <option value="all">All</option>
            <option value="initialising">Initialising</option>
            <option value="processing">Processing</option>
            <option value="completed">Completed</option>
          </Select>
        </GridCell>
        {(filterStatus === 'processing' || filterStatus === 'all') &&
          <GridCell>
            <Select
              disabled={!focusedOrganisation}
              name="stages"
              palette='secondary'
              label="Stages"
              value={filterStages || ''}
              onChange={(value) => {
                setProcessing(true);
                setFilterStages(value);
                const filterProcessed = {provider: focusedOrganisation._id};
                if(filterStatus !== 'all') {
                  filterProcessed.status = filterStatus;
                }
                if(value !== 'all') {
                  filterProcessed[value] = false;
                }
                setFilter({...filterProcessed});

                const query = {
                  provider: focusedOrganisation._id,
                  ...(filterProcessed || {}),
                };
                const sort = {createdAt: -1};
                setOverRideSearch(true);
                setSearch({name: 'orders', value: {text: '', queryDepth: 1, query, data: {usesQueryLimit: true}}});
                apiRequest({type: 'get', action: 'orders', data: {query, sort, skip: 0, limit: queryLimit}})
                .then((result) => {
                  setOrders(result.data.orders || []);
                  setProcessing(false);
                }).catch((error) => {
                  setProcessing(false);
                  setOrders([]);
                  setDialogData({
                    type: 'message',
                    title: `View bookings request denied`,
                    message: handleErrorMessage(error),
                  });
                  setDialogOpen(true);
                });
              }}
            >
              <option value="all">All</option>
              <option value="stages.paid">Not Paid</option>
              <option value="stages.packed">Not Packed / Booked</option>
              <option value="stages.delivered">Not Delivered</option>
            </Select>
          </GridCell>
        }
        <GridCell weight={1}>
          <div style={{display: 'none'}}>
            <SearchStore databaseArea='orders' processing={processing} setProcessing={setProcessing} expandSearch={expandSearch} setExpandSearch={setExpandSearch} overRideSearch={overRideSearch} setOverRideSearch={setOverRideSearch} filter={filter}/>
          </div>
        </GridCell>
        <GridCell style={{paddingTop: 7, paddingLeft: 10}}>
          <Button palette='primary' onClick={() => {
            setDialogData({
              type: 'changeProvider',
              title: 'Change Provider',
              value: {},
            });
            setDialogOpen(true);
          }}>
            {focusedOrganisation?.name || 'Choose Organisation'}
          </Button>
        </GridCell>
      </GridContainer>
      {focusedOrganisation && orders?.length > 0 &&
        <div>
          {orders.map((o, oIndex) => (
            <div
              key={oIndex}
              style={{margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5}}
            >
              <div style={{background: paletteData.primary.standard.background, padding: 10}}>
                <GridContainer>
                  <GridCell weight={1}>
                    <Typography style={{color: paletteData.primary.standard.foreground}}>
                      {o?.contact?.name || 'Unnamed Requester'}
                    </Typography>
                    <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
                      {o?.contact?.email}
                    </Typography>
                    <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
                      {o?.contact?.phone}
                    </Typography>
                    <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
                      {o?.contact?.address?.extra} {o?.contact?.address?.number} {o?.contact?.address?.street} {o?.contact?.address?.suburb} {o?.contact?.address?.city} {o?.contact?.address?.country} {o?.contact?.address?.postcode}
                    </Typography>
                  </GridCell>
                  <GridCell>
                    <div style={{background: 'white', padding: 10, borderRadius: 5}}>
                      <Typography size='subText'>
                        {new Date(o.createdAt).toDateString()}
                      </Typography>
                    </div>
                  </GridCell>
                </GridContainer>

              </div>
              <GridContainer>
                <GridCell weight={1}>
                  <div style={{padding: 10}}>
                    <Typography style={{fontWeight: 500}}>
                      Status
                    </Typography>
                    <Typography>
                      {o.status}
                    </Typography>
                    <Typography>
                      {o.stages?.confirmed ? 'Confirmed by client' : 'Not confirmed'}
                    </Typography>
                    <Typography>
                      {o.stages?.paid ? 'Paid' : 'Not paid'}
                    </Typography>
                    <Typography>
                      {o.stages?.packed ? 'Packed / Booked' : 'Not packed / booked'}
                    </Typography>
                    <Typography>
                      {o.stages?.delivered ? 'Delivered' : 'Not delivered'}
                    </Typography>
                  </div>
                  <div style={{padding: 10}}>
                    <Typography style={{fontWeight: 500}}>
                      Payment
                    </Typography>
                    <Typography>
                      {
                        o.type === 'po' ? 'Pay Online' :
                        o.type === 'poi' ? 'Pay On Invoice' :
                        o.type === 'pop' ? 'Pay On Pickup' : ''
                      }
                    </Typography>
                    <Typography>
                      ${o.payment?.total}
                    </Typography>
                  </div>
                  <div style={{padding: 10}}>
                    <Typography style={{fontWeight: 500}}>
                      Products
                    </Typography>
                    {o?.products?.length > 0 && o.products.map((p, pIndex) => (
                      <div
                        key={pIndex}
                        style={{
                          padding: 10,
                          borderStyle: 'solid',
                          borderWidth: 1,
                          borderRadius: 5,
                          borderColor: '#c6c6c6',
                          cursor: (p.type === 'service' && p?.delivery?.onlineBooking && p?.selectedBooking) ? 'pointer' : '',
                        }}
                        onClick={() => {
                          if(p.type === 'service' && p?.delivery?.onlineBooking && p?.selectedBooking) {
                            setProcessing(true);
                            const daysToFetch = [];
                            for(let i = 0; i < p?.selectedBooking?.times?.length; i += 1) {
                              daysToFetch.push(p.selectedBooking.times[i].date);
                            }
                            fetchDays(daysToFetch);
                            processAvailableTimes(p?.delivery?.onlineBookingCalendar, daysToFetch)
                            .then((availableTimes) => {
                              if(p.selectedBooking.bookingId) {
                                if(!bookingReferences[p.selectedBooking.bookingId]) {
                                  apiRequest({type: 'get', action: `bookings/${p.selectedBooking.bookingId}`})
                                  .then((result) => {
                                    if(result.data.booking) {
                                      setBookingReference(result.data.booking);
                                      setDialogData({
                                        type: 'editBooking',
                                        title: 'Edit Booking',
                                        data: {
                                          order: o,
                                          orderIndex: oIndex,
                                          productIndex: pIndex,
                                        },
                                        value: {
                                          ...(result.data.booking || {}),
                                          availableTimes,
                                        },
                                      });
                                      setDialogOpen(true);
                                      setProcessing(false);
                                    } else {
                                      // break deep reference
                                      const productProcessed = JSON.parse(JSON.stringify(p));
                                      setDialogData({
                                        type: 'confirmBooking',
                                        title: 'Confirm Booking',
                                        data: {
                                          order: o,
                                          orderIndex: oIndex,
                                          productIndex: pIndex,
                                        },
                                        value: {
                                          organisationId: productProcessed.organisationId,
                                          orderId: o._id,
                                          userId: productProcessed.userId,
                                          bookingCalendarId: productProcessed.delivery?.onlineBookingCalendar,
                                          type: 'user',
                                          state: 'confirmed',
                                          ignoreSimiltaniousBookings: false,
                                          notes: '',
                                          times: productProcessed?.selectedBooking.times,
                                          availableTimes,
                                        },
                                      });
                                      setDialogOpen(true);
                                      setProcessing(false);
                                    }
                                  }).catch((error) => {
                                    setDialogData({
                                      type: 'message',
                                      title: 'Error',
                                      message: handleErrorMessage(error),
                                    });
                                    setDialogOpen(true);
                                    setProcessing(false);
                                  });
                                } else {
                                  // break deep references
                                  const bookingProcessed = Object.assign({}, (bookingReferences[p.selectedBooking.bookingId] || {}));
                                  const timesProcessed = [];
                                  for(let i = 0; i < bookingProcessed.times.length; i += 1) {
                                    const timeProcessed = Object.assign({}, (bookingProcessed.times[i] || {}));
                                    timesProcessed.push(timeProcessed);
                                  }
                                  bookingProcessed.times = timesProcessed;
                                  setDialogData({
                                    type: 'editBooking',
                                    title: 'Edit Booking',
                                    data: {
                                      order: o,
                                      orderIndex: oIndex,
                                      productIndex: pIndex,
                                    },
                                    value: {
                                      ...bookingProcessed,
                                      availableTimes,
                                    },
                                  });
                                  setDialogOpen(true);
                                  setProcessing(false);
                                }
                              } else {
                                // break deep reference
                                const productProcessed = JSON.parse(JSON.stringify(p));
                                setDialogData({
                                  type: 'confirmBooking',
                                  title: 'Confirm Booking',
                                  data: {
                                    order: o,
                                    orderIndex: oIndex,
                                    productIndex: pIndex,
                                  },
                                  value: {
                                    organisationId: productProcessed.organisationId,
                                    orderId: o._id,
                                    userId: productProcessed.userId,
                                    productId: productProcessed._id,
                                    bookingCalendarId: productProcessed.delivery?.onlineBookingCalendar,
                                    type: 'user',
                                    state: 'confirmed',
                                    ignoreSimiltaniousBookings: false,
                                    notes: '',
                                    times: productProcessed?.selectedBooking.times,
                                    availableTimes,
                                  },
                                });
                                setDialogOpen(true);
                                setProcessing(false);
                              }
                            });
                          }
                        }}
                      >
                        <GridContainer>
                          <GridCell style={{width: 220}}>
                            <Typography>
                              {p.name} - {
                                p.type === 'good' && (o.type === 'pop' || p.selectedCollection === 'pickup') ? 'pickup' :
                                p.type === 'good' && (p.selectedCollection === 'national' || p.selectedCollection === 'international') ? `${p.selectedCollection} post` :
                                p.type === 'service' && p.selectedServiceDelivery === 'inStoreService' ? 'In Store Service' :
                                p.type === 'service' && p.selectedServiceDelivery === 'callout' ? 'Callout' : ''
                              }
                            </Typography>
                          </GridCell>
                          {!p?.delivery?.onlineBooking &&
                            <GridCell>
                              <Typography>
                                {p.selectedQuantity} {
                                  p.type === 'service' && p.payment?.servicePaymentFor === 'perPerson' ? 'People' :
                                  p.type === 'service' && p.payment?.servicePaymentFor === 'perHour' ? 'Hour(s)' :
                                  p.type === 'service' && p.payment?.servicePaymentFor === 'perBooking' ? 'Booking(s)' : ''
                                }
                              </Typography>
                            </GridCell>
                          }
                          <GridCell weight={1}/>
                          {p.type === 'service' && p?.selectedBooking?.times?.length > 0 &&
                            <GridCell>
                              <Typography>
                                {p?.selectedBooking?.bookingId ? 'Booked' : 'Unconfirmed'}
                              </Typography>
                            </GridCell>
                          }
                        </GridContainer>
                        {p?.selectedBooking?.times?.length > 0 && p?.selectedBooking?.times.map((t, tIndex) => (
                          <GridContainer key={tIndex}>
                            <GridCell style={{width: 220}}>
                              <Typography key={tIndex}>
                                - {new Date(t.date).toDateString()} {times[t.start].blockStartHuman}
                              </Typography>
                            </GridCell>
                            <GridCell style={{width: 100}}>
                              {
                                p.payment?.servicePaymentFor === 'perHour' ? t.blocks / 4 :
                                p.payment?.servicePaymentFor === 'perBooking' ? '1' : t.selectedQuantity
                              } {
                                p.payment?.servicePaymentFor === 'perPerson' ? 'People' :
                                p.payment?.servicePaymentFor === 'perHour' ? 'Hour(s)' :
                                p.payment?.servicePaymentFor === 'perBooking' ? 'Booking' : ''
                              }
                            </GridCell>
                          </GridContainer>
                        ))}
                        {processing &&
                          <div style={{paddingTop: 10}}>
                            <ProgressBar palette='secondary'/>
                          </div>
                        }
                      </div>
                    ))}
                  </div>
                  {o?.delivery?.pickupLocation &&
                    <div style={{padding: 10}}>
                      <Typography style={{fontWeight: 500}}>
                        Pickup Location
                      </Typography>
                      <Typography>
                        {o?.delivery?.pickupLocation}
                      </Typography>
                    </div>
                  }
                </GridCell>
                <GridCell>
                  {me?.roles?.global && me?.roles?.global['super-admin'] &&
                    <Button disabled={processing} palette='primary' onClick={() => handleDeleteOrder(o)} style={{margin: 10}}>
                      Delete Order
                    </Button>
                  }
                  {o?.stages?.confirmed &&
                    <Button disabled={processing} palette='primary' style={{margin: 10}} onClick={() => handleDownloadInvoice(o)}>
                      Download Invoice
                    </Button>
                  }
                  {o?.stages?.paid &&
                    <Button disabled={processing} palette='primary' style={{margin: 10}} onClick={() => handleDownloadReceipt(o)}>
                      Download Receipt
                    </Button>
                  }
                  {o?.stages?.confirmed && !o?.stages?.paid &&
                    <Button disabled={processing} palette='primary' style={{margin: 10}} onClick={() => handleMarkPaid(oIndex, o._id)}>
                      Mark as Paid
                    </Button>
                  }
                  {o?.stages?.confirmed && !o?.stages?.packed &&
                    <Button disabled={processing || !checkBookings(o)} palette='primary' style={{margin: 10}} onClick={() => handleMarkPacked(oIndex, o._id)}>
                      Mark as Packed / Booked
                    </Button>
                  }
                  {o?.stages?.confirmed && o?.stages?.packed && !o?.stages?.delivered &&
                    <Button disabled={processing} palette='primary' style={{margin: 10}} onClick={() => handleMarkDelivered(oIndex, o._id)}>
                      Mark as Delivered
                    </Button>
                  }
                </GridCell>
              </GridContainer>
            </div>
          ))}
          {processing && orders?.length > 9 &&
            <div>
              <Typography>
                Loading...
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {(orders.length >= search?.orders?.queryDepth * queryLimit) &&
            <div style={{padding: 5}}>
              <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                Load More
              </Button>
            </div>
          }
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'changeProvider' &&
            <div style={{padding: 10}}>
              <SearchStore
                databaseArea='organisations'
                processing={processing}
                setProcessing={setProcessing}
                expandSearch={dialogData.value.expandSearch}
                setExpandSearch={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...dialogData.value,
                      expandSearch: value,
                    }
                  });
                }}
              />
              {(!organisations || processing) &&
                <ProgressBar palette='secondary'/>
              }
              {!processing && organisations && organisations.map((o, oIndex) => (
                <div
                  key={oIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    setProcessing(true);
                    if(!organisationReferences[o._id]) setOrganisationReference(o);
                    setFocusedOrganisation(o);

                    const filterProcessed = {
                      provider: o._id,
                      status: 'processing',
                    };
                    setFilterStatus('processing');
                    setFilterStages('all');
                    setFilter(filterProcessed);
                    const query = {
                      provider: o._id,
                      ...(filterProcessed || {}),
                    };
                    const sort = {createdAt: -1};
                    setOverRideSearch(true);
                    setSearch({name: 'orders', value: {text: '', queryDepth: 1, query, data: {usesQueryLimit: true}}});
                    apiRequest({type: 'get', action: 'orders', data: {query, sort, skip: 0, limit: queryLimit}})
                    .then((result) => {
                      setOrders(result.data.orders || []);
                      setProcessing(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setOrders([]);
                      setDialogData({
                        type: 'message',
                        title: `View bookings request denied`,
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {o.name}
                </div>
              ))}
              {processing && organisations?.length > 15 &&
                <div>
                  <Typography>
                    Loading...
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {organisations?.length >= search?.organisations?.queryDepth * queryLimit &&
                <div style={{padding: 5}}>
                  <Button
                    palette='primary'
                    disabled={processing}
                    onClick={() => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...dialogData.value,
                          expandSearch: true,
                        }
                      });
                    }}
                  >
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
          {(dialogData?.type === 'confirmBooking' || dialogData?.type === 'editBooking') &&
            <div style={{padding: 10}}>
              <div style={{padding: 10, background: paletteData.primary.standard.background}}>
                <Typography style={{color: paletteData.primary.standard.foreground}}>
                  Details
                </Typography>
              </div>
              <TextArea
                name="notes"
                palette='secondary'
                label="Notes"
                type="text"
                value={dialogData?.value?.notes || ''}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      notes: value,
                    }
                  });
                }}
              />
              {dialogData?.value?.bookingCalendarId &&
                <div>
                  <div style={{padding: 10, background: paletteData.primary.standard.background}}>
                    <GridContainer>
                      <GridCell weight={1} center={true}>
                        <Typography style={{color: paletteData.primary.standard.foreground}}>
                          Dates / Times
                        </Typography>
                      </GridCell>
                    </GridContainer>
                  </div>
                  {dialogData?.value?.times?.length > 0 && dialogData?.value?.times?.map((t, tIndex) => (
                    <div key={tIndex} style={{padding: 10, marginTop: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5}}>
                      <GridContainer>
                        <GridCell>
                          <Input
                            forceShrink={true}
                            name="date"
                            palette='secondary'
                            label="Date"
                            type="date"
                            value={t.date}
                            onChange={(value) => {
                              fetchDays([value]);
                              const offset = createOffset(bookingCalendarReferences[dialogData?.value?.bookingCalendarId], value);
                              const dialogDataTemp = dialogData;
                              dialogDataTemp.value.times[tIndex].date = value;
                              dialogDataTemp.value.times[tIndex].offset = offset;
                              if(!dialogDataTemp.value.availableTimes[value]) {
                                const availableTimesOnDate = filterAvailableTimes(bookingCalendarReferences[dialogData?.value?.bookingCalendarId], offset);
                                dialogDataTemp.value.availableTimes[value] = availableTimesOnDate;
                              }

                              setDialogData({...dialogDataTemp});
                            }}
                          />
                        </GridCell>
                        {t.date &&
                          <GridCell>
                            <Select
                              style={{minWidth: 150}}
                              name="start"
                              palette='secondary'
                              label="Time"
                              type="text"
                              value={t.start}
                              onChange={(value) => {
                                const dialogDataTemp = dialogData;
                                dialogDataTemp.value.times[tIndex].start = value;
                                setDialogData({...dialogDataTemp});
                              }}
                            >
                              {dialogData?.value?.availableTimes[t.date]?.map((t, tIndex) => (
                                <option key={tIndex} value={t.value}>{t.blockStartHuman}</option>
                              ))}
                            </Select>
                          </GridCell>
                        }
                        {t.date &&
                          <GridCell>
                            <Select
                              style={{minWidth: 150}}
                              name="blocks"
                              palette='secondary'
                              label="Duration"
                              type="text"
                              value={t.blocks}
                              onChange={(value) => {
                                const dialogDataTemp = dialogData;
                                dialogDataTemp.value.times[tIndex].blocks = value;
                                setDialogData({...dialogDataTemp});
                              }}
                            >
                              {bookingTimes.map((bt, tIndex) => (
                                <option key={tIndex} value={bt.value}>{bt.text}</option>
                              ))}
                            </Select>
                          </GridCell>
                        }
                        <GridCell weight={1}/>
                      </GridContainer>
                      {t.date &&
                        <GridContainer style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#c6c6c6'}}>
                          {times?.length > 0 && times.map((tr, trIndex) => (
                            <GridCell
                              key={trIndex}
                              weight={1}
                              style={{
                                height: 10,
                                background: checkBlockAvailability(bookingCalendarReferences[dialogData?.value?.bookingCalendarId], tr.value, dialogData?.value?._id, t.date, t.start, t.blocks, t.offset),
                              }}
                            />
                          ))}
                        </GridContainer>
                      }
                      {t.offset !== 0 && t.offset !== undefined &&
                        <Typography size='subText' style={{padding: 5, color: 'red'}}>
                          this date's timezone is different from the booking calendar.  Showing times in your timezone.
                        </Typography>
                      }
                    </div>
                  ))}
                </div>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {
                  (dialogData?.type === 'markPaid' || dialogData?.type === 'markPacked' || dialogData?.type === 'markDelivered') ? 'Do Not Notify' :
                  dialogData?.type === 'message' ? 'OK' : 'Cancel'
                }
              </Button>
            </GridCell>
            {dialogData?.type === 'markPaid' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    apiRequest({type: 'post', action: `email/sendReceipt/${dialogData.data.orderId}`})
                    .then((result) => {
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: `Mail request denied`,
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Notify
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'markPacked' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    apiRequest({type: 'post', action: `email/sendProcessed/${dialogData.data.orderId}`})
                    .then((result) => {
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: `Mail request denied`,
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Notify
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'markDelivered' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    apiRequest({type: 'post', action: `email/sendDelivered/${dialogData.data.orderId}`})
                    .then((result) => {
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: `Mail request denied`,
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Notify
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'confirmBooking' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !validateTimes(dialogData?.value?.times) || !dialogData?.value?.bookingCalendarId || !dialogData?.value?.notes}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const timesProcessed = processTimesRemoveOffeset(bookingCalendarReferences[dialogData?.value?.bookingCalendarId], dialogData?.value?.times);
                    const updateBooking = {
                      organisationId: dialogData?.value?.organisationId,
                      orderId: dialogData?.value?.orderId,
                      productId: dialogData?.value?.productId,
                      userId: dialogData?.value?.userId,
                      bookingCalendarId: dialogData?.value?.bookingCalendarId,
                      type: dialogData?.value?.type,
                      state: 'confirmed',
                      ignoreSimiltaniousBookings: dialogData?.value?.ignoreSimiltaniousBookings,
                      times: timesProcessed,
                      notes: dialogData?.value?.notes,
                    };
                    apiRequest({type: 'post', action: `bookings/create`, data: {updateBooking}})
                    .then((result) => {
                      setBookingReference(result.data.booking);
                      addBooking(result.data.booking);

                      const productsProcessed = dialogData?.data?.order?.products || [];
                      const productProcessed = {
                        ...(productsProcessed[dialogData?.data?.productIndex] || {}),
                        selectedBooking: {
                          ...(productsProcessed[dialogData?.data?.productIndex]?.selectedBooking),
                          times: dialogData?.value?.times,
                          bookingId: result.data.booking._id,
                        }
                      }
                      productsProcessed.splice(dialogData?.data?.productIndex, 1, productProcessed);
                      const orderProcessing = {
                        ...(dialogData?.data?.order || {}),
                        products: productsProcessed,
                      }
                      const updateOrder = {
                        products: productsProcessed,
                      };
                      apiRequest({type: 'patch', action: `orders/update/${dialogData?.data?.order._id}`, data: {updateOrder}})
                      .then((result) => {
                        replaceOrder({index: dialogData?.data?.orderIndex, order: orderProcessing});
                        setOrderReference(orderProcessing);
                        setDialogData(undefined);
                        setDialogOpen(false);
                        setProcessing(false);
                      }).catch((error) => {
                        setProcessing(false);
                        setDialogData({
                          type: 'message',
                          title: 'Update order request denied',
                          message: handleErrorMessage(error),
                        });
                        setDialogOpen(true);
                      });
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Create booking request denied',
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Confirm Booking
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'editBooking' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !validateTimes(dialogData?.value?.times) || !dialogData?.value?.bookingCalendarId || !dialogData?.value?.notes}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const timesProcessed = processTimesRemoveOffeset(bookingCalendarReferences[dialogData?.value?.bookingCalendarId], dialogData?.value?.times);
                    const updateBooking = {
                      organisationId: dialogData?.value?.organisationId,
                      orderId: dialogData?.value?.orderId,
                      productId: dialogData?.value?.productId,
                      userId: dialogData?.value?.userId,
                      bookingCalendarId: dialogData?.value?.bookingCalendarId,
                      type: dialogData?.value?.type,
                      state: 'confirmed',
                      ignoreSimiltaniousBookings: dialogData?.value?.ignoreSimiltaniousBookings,
                      times: timesProcessed,
                      notes: dialogData?.value?.notes,
                    };
                    apiRequest({type: 'patch', action: `bookings/update/${dialogData?.value?._id}`, data: {updateBooking}})
                    .then((result) => {
                      replaceBooking({origonalBooking: bookingReferences[dialogData?.value?._id], newBooking: {_id: dialogData?.value?._id, ...updateBooking}});
                      setBookingReference({_id: dialogData?.value?._id, ...updateBooking});

                      const productsProcessed = dialogData?.data?.order?.products || [];
                      const productProcessed = {
                        ...(productsProcessed[dialogData?.data?.productIndex] || {}),
                        selectedBooking: {
                          ...(productsProcessed[dialogData?.data?.productIndex]?.selectedBooking),
                          times: dialogData?.value?.times,
                          bookingId: dialogData?.value?._id,
                        }
                      }
                      productsProcessed.splice(dialogData?.data?.productIndex, 1, productProcessed);
                      const orderProcessing = {
                        ...(dialogData?.data?.order || {}),
                        products: productsProcessed,
                      }
                      const updateOrder = {
                        products: productsProcessed,
                      };
                      apiRequest({type: 'patch', action: `orders/update/${dialogData?.data?.order._id}`, data: {updateOrder}})
                      .then((result) => {
                        replaceOrder({index: dialogData?.data?.orderIndex, order: orderProcessing});
                        setOrderReference(orderProcessing);
                        setDialogData(undefined);
                        setDialogOpen(false);
                        setProcessing(false);
                      }).catch((error) => {
                        setProcessing(false);
                        setDialogData({
                          type: 'message',
                          title: 'Update order request denied',
                          message: handleErrorMessage(error),
                        });
                        setDialogOpen(true);
                      });
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Create booking request denied',
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Edit Booking
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

SearchOrdersSubPanel.propTypes = {
  me: PropTypes.shape({}),
  orders: PropTypes.arrayOf(PropTypes.shape({})),
  addOrders: PropTypes.func,
  search: PropTypes.shape({}),
  setSearch: PropTypes.func,
  queryLimit: PropTypes.number,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  setFocusedOrder: PropTypes.func,
  setFocusedOrderIndex: PropTypes.func,
};

export default SearchOrdersSubPanel;
