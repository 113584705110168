import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Select, Button, Dialog, Typography, paletteData} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

import apiRequest from '../../../tools/apiRequest';
import {setOrganisationReferences, setAreaReferences, setCategoryReferences} from '../../../store/reducers/references/actions.js';

function RoleForm({
  me,
  user,
  handleChange,

  organisationReferences,
  setOrganisationReferences,

  areaReferences,
  setAreaReferences,

  categoryReferences,
  setCategoryReferences,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const [tempGlobalRoles, setTempGlobalRoles] = useState(Object.keys(user.roles?.global || {}));
  const [tempOrganisationalRoles, setTempOrganisationalRoles] = useState(undefined);
  const [tempAreaCommunityRoles, setTempAreaCommunityRoles] = useState(undefined);
  const [tempCategoryCommunityRoles, setTempCategoryCommunityRoles] = useState(undefined);

  const [orgInitalLoad, setOrgInitalLoad] = useState(false);
  const [orgLoading, setOrgLoading] = useState(true);
  const [areaInitalLoad, setAreaInitalLoad] = useState(false);
  const [areaLoading, setAreaLoading] = useState(true);
  const [categoryInitalLoad, setCategoryInitalLoad] = useState(false);
  const [categoryLoading, setCatgoryLoading] = useState(true);

  useEffect(() => {
    if(!tempOrganisationalRoles && !orgInitalLoad) {
      setOrgInitalLoad(true);
      const organisationKeys = Object.keys(user.roles?.organisational || {});
      const tempOrganisationalRolesProcessed = [];
      const organisationsToFetch = [];
      for(let i = 0; i < organisationKeys.length; i += 1) {
        const roleKeys = Object.keys(user.roles.organisational[organisationKeys[i]]);
        tempOrganisationalRolesProcessed.push({
          organsationId: organisationKeys[i],
          roles: roleKeys,
        });
        if(!organisationReferences[organisationKeys[i]]) {
          organisationsToFetch.push(organisationKeys[i]);
        }
      }

      if(organisationsToFetch.length > 0) {
        const fetchOrgsQuery = {_id: {$in: organisationsToFetch}};
        apiRequest({type: 'get', action: 'organisations', data: {query: fetchOrgsQuery, sort: {name: 1}}})
        .then((result) => {
          setOrganisationReferences(result.data.organisations);
          setTempOrganisationalRoles(tempOrganisationalRolesProcessed);
          setOrgLoading(false);
        });
      } else {
        setTempOrganisationalRoles(tempOrganisationalRolesProcessed);
        setOrgLoading(false);
      }
    } else if (!orgInitalLoad){
      setOrgInitalLoad(true);
      setOrgLoading(false);
    }

    if(!tempAreaCommunityRoles && !areaInitalLoad) {
      setAreaInitalLoad(true);
      const areaCommunityKeys = Object.keys(user?.roles?.community?.area || {});
      const tempAreaCommunityRolesProcessed = [];
      const areasToFetch = [];
      for(let i = 0; i < areaCommunityKeys.length; i +=1) {
        const roleKeys = Object.keys(user.roles.community.area[areaCommunityKeys[i]]);
        tempAreaCommunityRolesProcessed.push({
          areaId: areaCommunityKeys[i],
          roles: roleKeys,
        });
        if(!areaReferences[areaCommunityKeys[i]]) {
          areasToFetch.push(areaCommunityKeys[i]);
        }
      }

      if(areasToFetch.length > 0) {
        const fetchAreasQuery = {_id: {$in: areasToFetch}};
        apiRequest({type: 'get', action: 'areas', data: {query: fetchAreasQuery, sort: {name: 1}}})
        .then((result) => {
          setAreaReferences(result.data.areas);
          setTempAreaCommunityRoles(tempAreaCommunityRolesProcessed);
          setAreaLoading(false);
        });
      } else {
        setTempAreaCommunityRoles(tempAreaCommunityRolesProcessed);
        setAreaLoading(false);
      }
    } else if(!areaInitalLoad) {
      setAreaInitalLoad(true);
      setAreaLoading(false);
    }

    if(!tempCategoryCommunityRoles &&!categoryInitalLoad) {
      setCategoryInitalLoad(true);
      const categoryCommunityKeys = Object.keys(user?.roles?.community?.category || {});
      const tempCategoryCommunityRolesProcessed = [];
      const categoriesToFetch = [];
      for(let i = 0; i < categoryCommunityKeys.length; i +=1) {
        const roleKeys = Object.keys(user.roles.community.category[categoryCommunityKeys[i]]);
        tempCategoryCommunityRolesProcessed.push({
          categoryId: categoryCommunityKeys[i],
          roles: roleKeys,
        });
        if(!categoryReferences[categoryCommunityKeys[i]]) {
          categoriesToFetch.push(categoryCommunityKeys[i]);
        }
      }

      if(categoriesToFetch.length > 0) {
        const fetchCategoriesQuery = {_id: {$in: categoriesToFetch}};
        apiRequest({type: 'get', action: 'categories', data: {query: fetchCategoriesQuery, sort: {name: 1}}})
        .then((result) => {
          setCategoryReferences(result.data.categories);
          setTempCategoryCommunityRoles(tempCategoryCommunityRolesProcessed);
          setCatgoryLoading(false);
        });
      } else {
        setTempCategoryCommunityRoles(tempCategoryCommunityRolesProcessed);
        setCatgoryLoading(false);
      }
    } else if(!categoryInitalLoad) {
      setCategoryInitalLoad(true);
      setCatgoryLoading(false);
    }
  }, [
      tempOrganisationalRoles,
      tempAreaCommunityRoles,
      tempCategoryCommunityRoles,
      user,

      areaInitalLoad,
      areaReferences,
      setAreaReferences,

      categoryInitalLoad,
      categoryReferences,
      setCategoryReferences,

      orgInitalLoad,
      organisationReferences,
      setOrganisationReferences,
   ]);

  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center={true}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Roles
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              size="small"
              palette='secondary'
              onClick={() => {
                setDialogOpen(true);
                setDialogData({
                  type: 'addRole',
                  title: 'Add a role to user',
                  value: {
                    type: 'global',
                    organsation: '',
                    role: '',
                  }
                });
              }}
            >
              Add a role
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {(orgLoading || areaLoading || categoryLoading) &&
        <div style={{padding: 5}}>
          <Typography>
            ...loading
          </Typography>
        </div>
      }

      {!orgLoading && !areaLoading && !categoryLoading &&
        <div>
          {(!tempGlobalRoles || tempGlobalRoles.length === 0) &&
           (!tempOrganisationalRoles || tempOrganisationalRoles.length === 0) &&
           (!tempAreaCommunityRoles || tempAreaCommunityRoles.length === 0) &&
           (!tempCategoryCommunityRoles || tempCategoryCommunityRoles.length === 0) &&
            <div style={{margin: 5}}>
              No assigned roles
            </div>
          }

          {tempGlobalRoles && tempGlobalRoles.length > 0 &&
            <div style={{margin: 5}}>
              <GridContainer>
                <GridCell weight={1}>
                  Global Roles:
                </GridCell>
                <GridCell>
                  {tempGlobalRoles.map((r, rIndex) => (
                    <span
                      style={{cursor: 'pointer'}}
                      key={rIndex}
                      onClick={() => {
                        if(r === 'super-admin' && me._id === user._id) {
                          setDialogOpen(true);
                          setDialogData({
                            type: 'message',
                            title: 'Can not remove role',
                            message: 'You can not remove your own super-admin access',
                          });
                        } else {
                          setDialogOpen(true);
                          setDialogData({
                            type: 'removeRole',
                            title: 'Remove a role from user',
                            value: {
                              type: 'global',
                              role: r,
                              rIndex,
                            }
                          });
                        }
                      }}
                    >
                      {r}{rIndex < (tempGlobalRoles.length - 1) ? ', ' : ' '}
                    </span>
                  ))}
                </GridCell>
              </GridContainer>
            </div>
          }
          {tempOrganisationalRoles?.length > 0 &&
            <div>
              <Typography style={{margin: 5, paddingTop:10, fontWeight: 600}}>
                Organisational Roles
              </Typography>
              {tempOrganisationalRoles.map((o, oIndex) =>(
                <div key={oIndex} style={{margin: 5}}>
                  <GridContainer>
                    <GridCell weight={1}>
                      {organisationReferences[o.organsationId]?.name || '...loading'}
                    </GridCell>
                    <GridCell>
                      {o.roles.map((r, rIndex) => (
                        <span
                          key={rIndex}
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setDialogOpen(true);
                            setDialogData({
                              type: 'message',
                              title: 'Cant update organisation role',
                              message: 'Please update organisation roles within the manage organisation page',
                            });
                          }}
                        >
                          {r}{rIndex < (o.roles - 1) ? ', ' : ' '}
                        </span>
                      ))}
                    </GridCell>
                  </GridContainer>
                </div>
              ))}
            </div>
          }
          {(tempAreaCommunityRoles?.length > 0 || tempCategoryCommunityRoles?.length > 0) &&
            <Typography style={{margin: 5, paddingTop:10, fontWeight: 600}}>
              Community Roles
            </Typography>
          }
          {tempAreaCommunityRoles?.length > 0  &&
            <div>
              {tempAreaCommunityRoles.map((o, oIndex) =>(
                <div key={oIndex} style={{margin: 5}}>
                  <GridContainer>
                    <GridCell weight={1}>
                      {areaReferences[o.areaId]?.name || '...loading'}
                    </GridCell>
                    <GridCell>
                      {o.roles.map((r, rIndex) => (
                        <span
                          key={rIndex}
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setDialogOpen(true);
                            setDialogData({
                              type: 'message',
                              title: 'Cant update community role',
                              message: 'Please update commuinity roles under manage moderators',
                            });
                          }}
                        >
                          {r}{rIndex < (o.roles - 1) ? ', ' : ' '}
                        </span>
                      ))}
                    </GridCell>
                  </GridContainer>
                </div>
              ))}
            </div>
          }
          {tempCategoryCommunityRoles?.length > 0  &&
            <div>
              {tempCategoryCommunityRoles.map((o, oIndex) =>(
                <div key={oIndex} style={{margin: 5}}>
                  <GridContainer>
                    <GridCell weight={1}>
                      {categoryReferences[o.categoryId]?.name || '...loading'}
                    </GridCell>
                    <GridCell>
                      {o.roles.map((r, rIndex) => (
                        <span
                          key={rIndex}
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setDialogOpen(true);
                            setDialogData({
                              type: 'message',
                              title: 'Cant update community role',
                              message: 'Please update commuinity roles under manage moderators',
                            });
                          }}
                        >
                          {r}{rIndex < (o.roles - 1) ? ', ' : ' '}
                        </span>
                      ))}
                    </GridCell>
                  </GridContainer>
                </div>
              ))}
            </div>
          }
        </div>
      }

      <br/>
      {/*popouts and popups*/}
      <Dialog id='roleDialog' open={dialogOpen} handleClose={() => setDialogOpen(false)}>
        <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
          <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
            {dialogData?.title}
          </Typography>
        </div>
        {dialogData?.type === 'message' &&
          <Typography style={{padding: 10}}>
            {dialogData.message}
          </Typography>
        }
        {dialogData?.type === 'removeRole' &&
          <Typography style={{padding: 10}}>
            Are you sure you want to remove the {dialogData?.value?.type === 'global' ? 'global' : ''} {dialogData?.value?.role} role?
          </Typography>
        }
        {dialogData?.type === 'addRole' &&
          <div>
            <Select
              name="roleType"
              palette='secondary'
              label="Role Type"
              type="text"
              value={dialogData.value.type || ''}
              onChange={(value) => {
                const tempDialogData = {
                  ...dialogData,
                  value: {
                    ...dialogData.value,
                    type: value,
                  }
                };
                setDialogData(tempDialogData);
              }}
            >
              <option value="global">Global Roles</option>
            </Select>
            <Select
              name="role"
              palette='secondary'
              label="Role"
              type="text"
              value={dialogData.value.role || ''}
              onChange={(value) => {
                const tempDialogData = {
                  ...dialogData,
                  value: {
                    ...dialogData.value,
                    role: value,
                  }
                };
                setDialogData(tempDialogData);
              }}
            >
              {dialogData.value.type === 'global' &&
                <option value="super-admin">Super Admin</option>
              }
              <option value="admin">Admin</option>
              <option value="worker">Worker</option>
            </Select>
          </div>
        }
        <GridContainer>
          <GridCell weight={1}/>
          <GridCell style={{padding: 10}}>
            <Button
              palette='primary'
              onClick={() => {
                setDialogOpen(false);
                setDialogData(undefined);
              }}
            >
              {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
            </Button>
          </GridCell>
          <GridCell style={{padding: 10, display: dialogData?.type === 'addRole' ? 'block' : 'none'}}>
            <Button
              disabled={!dialogData?.value?.role}
              palette='primary'
              onClick={() => {
                const tempRoles = user.roles || {};
                if (dialogData.value.type === 'global') {
                  if(!tempRoles.global) {
                    tempRoles.global = {};
                  }
                  if(!tempRoles.global[dialogData.value.role]) {
                    tempRoles.global[dialogData.value.role] = true;
                    const tgr = tempGlobalRoles;
                    tgr.push(dialogData.value.role);
                    setTempGlobalRoles(tgr);
                    handleChange('roles', tempRoles);
                  }
                } else {

                }
                setDialogOpen(false);
                setDialogData(undefined);
              }}
            >
              Add Role
            </Button>
          </GridCell>
          <GridCell style={{padding: 10, display: dialogData?.type === 'removeRole' ? 'block' : 'none'}}>
            <Button
              palette='primary'
              onClick={() => {
                const tempRoles = user.roles || {};
                const tgr = tempGlobalRoles;

                if (dialogData.value.type === 'global') {
                  tgr.splice(dialogData.value.rIndex, 1);
                  setTempGlobalRoles(tgr);

                  delete tempRoles.global[dialogData.value.role];
                  handleChange('roles', tempRoles);
                } else {

                }
                setDialogOpen(false);
                setDialogData(undefined);
              }}
            >
              Remove Role
            </Button>
          </GridCell>
        </GridContainer>
      </Dialog>
    </div>
  );
}

RoleForm.propTypes = {
  me: PropTypes.shape({}),
  user: PropTypes.shape({}),
  organisationReferences: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    organisationReferences: state.references.organisations || {},
    areaReferences: state.references.areas || {},
    categoryReferences: state.references.categories || {},
  };
};

export default connect(mapStateToProps, {setOrganisationReferences, setAreaReferences, setCategoryReferences})(RoleForm);
