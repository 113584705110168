import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import SearchReviews from './SearchReviews';

import {GridContainer, GridCell} from '../../../../components/grid';
import {paletteData, Button, Dialog, Typography} from '../../../../components/styles';
import {setReviews, addReviews, addReview, replaceReview, removeReview, setSearch, setOrganisations, addOrganisations, replaceOrganisation} from '../../../../store/reducers/lists/actions.js';
import {setReviewReference, clearReviewReference, setOrganisationReference, setReviewReferences} from '../../../../store/reducers/references/actions.js';

function ManageReviewsPanel({
  me,
  auth,
  search,
  setSearch,
  queryLimit,

  reviews,
  setReviews,
  addReviews,
  addReview,
  replaceReview,
  removeReview,
  setReviewReference,
  clearReviewReference,
  setReviewReferences,

  organisations,
  organisationReferences,
  setOrganisations,
  addOrganisations,
  replaceOrganisation,
  setOrganisationReference,
}) {
  // page state
  const [focusedOrganisation, setFocusedOrganisation] = useState(search?.reviews?.query?.organisationId && organisationReferences[search?.reviews?.query?.organisationId] ? organisationReferences[search?.reviews?.query?.organisationId] : undefined);
  const [focusedReview, setFocusedReview] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleChangeFocusedReview = (name, value) => {
    setFocusedReview({
      ...focusedReview,
      [name]: value,
    });
  };

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center style={{height: 45}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Reviews
            </Typography>
          </GridCell>
        </GridContainer>
      </div>
      {!focusedReview &&
        <SearchReviews
          search={search}
          queryLimit={queryLimit}
          setSearch={setSearch}
          processing={processing}
          setProcessing={setProcessing}
          handleChangeFocusedReview={handleChangeFocusedReview}

          reviews={reviews}
          setReviews={setReviews}
          addReviews={addReviews}
          removeReview={removeReview}
          setFocusedReview={setFocusedReview}
          setReviewReferences={setReviewReferences}

          focusedOrganisation={focusedOrganisation}
          organisations={organisations}
          setOrganisations={setOrganisations}
          addOrganisations={addOrganisations}
          replaceOrganisation={replaceOrganisation}
          organisationReferences={organisationReferences}
          setOrganisationReference={setOrganisationReference}
          setFocusedOrganisation={setFocusedOrganisation}
        />
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10}}>
            <Typography size='title'>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ManageReviewsPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,

    reviews: state.lists.reviews,

    organisations: state.lists.organisations,
    organisationReferences: state.references.organisations || {},
  };
};

export default connect(mapStateToProps, {
  setSearch,

  setReviews,
  addReviews,
  addReview,
  replaceReview,
  removeReview,
  setReviewReference,
  clearReviewReference,
  setReviewReferences,

  setOrganisations,
  addOrganisations,
  replaceOrganisation,
  setOrganisationReference,
})(ManageReviewsPanel);
