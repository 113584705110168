import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { paletteData, Input, Button, ProgressBar, Typography, Dialog, TextArea } from '../../styles';
import { GridContainer, GridCell } from '../../grid';

function StaffForm({ staff, handleChange, handleRemoveStaff, handleUploadImage, processing, setProcessing, owner, verification, handleVerifyEmail }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);

  const imageUploadRef = useRef(null);

  const handleGeneratePreview = (file) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setProcessing(false);
      setImagePreview(reader.result)
    }
  }

  const handleUpdateThumbnailImage = (image, error) => {
    if (!error) {
      handleChange('profileImage', image?.imageId);
      setDialogData(undefined);
      setImagePreview(undefined);
      setDialogOpen(false);
    }
  }

  return (
    <div>
      <GridContainer>
        <GridCell>
          {!staff?.profileImage &&
            <GridContainer style={{ height: 220, width: 220 }}>
              <GridCell center={true} weight={1} style={{ background: '#c6c6c6', textAlign: 'center', borderRadius: 5 }}>
                No Image
              </GridCell>
            </GridContainer>
          }
          {staff?.profileImage &&
            <GridContainer>
              <GridCell center={true} weight={1} style={{ minHeight: 220 }}>
                <img
                  src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fit,q_auto:good,w_400,h_400/${staff.profileImage}`}
                  alt={staff.name}
                  style={{ width: 200 }}
                />
              </GridCell>
            </GridContainer>
          }
          {(processing) &&
            <ProgressBar palette='secondary' />
          }
          <div style={{ marginTop: 10, textAlign: 'center' }}>
            <Button palette='primary' onClick={() => {
              setImagePreview(undefined);
              setDialogData({
                type: 'editProfileImage',
                title: 'Change Profile Image',
                preview: undefined,
                value: {
                  file: undefined,
                  imageId: staff?.profileImage || '',
                }
              });
              setDialogOpen(true);
            }} disabled={processing}>
              Change Image
            </Button>
          </div>
        </GridCell>
        <GridCell weight={1} style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ height: 220 }}>
            <Input
              name="name"
              palette='secondary'
              label="Name"
              type="text"
              value={staff?.name || ''}
              onChange={(value) => handleChange('name', value)}
            />
            <Input
              name="role"
              palette='secondary'
              label="Role"
              type="text"
              value={staff?.role || ''}
              onChange={(value) => handleChange('role', value)}
            />
            <GridContainer>
              <GridCell weight={1}>
                <Input
                  name="email"
                  palette='secondary'
                  label="Email"
                  type="text"
                  value={staff?.email || ''}
                  onChange={(value) => handleChange('email', value)}
                />
                <Typography size='subText' style={{ color: 'grey', marginLeft: 5 }}>
                  The email field is required to uniquely identify the staff member
                </Typography>
              </GridCell>
              {handleVerifyEmail !== undefined && owner && verification && !verification[staff?.email] &&
                <GridCell style={{ marginTop: 7 }}>
                  <Button palette='primary' disabled={processing} onClick={() => handleVerifyEmail(staff?.email)}>
                    Verify Email
                  </Button>
                </GridCell>
              }
            </GridContainer>
            <Input
              name="phone"
              palette='secondary'
              label="Phone"
              type="text"
              value={staff?.phone || ''}
              onChange={(value) => handleChange('phone', value)}
            />
          </div>
          <div style={{ flex: 1 }} />
          <GridContainer>
            <GridCell weight={1} />
            <GridCell style={{ marginTop: 10 }}>
              <Button palette='primary' onClick={handleRemoveStaff}>
                Remove Staff Member
              </Button>
            </GridCell>
          </GridContainer>
        </GridCell>
      </GridContainer>

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => {
          setDialogData(undefined);
          setImagePreview(undefined);
          setDialogOpen(false);
        }}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
           <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
             {dialogData?.title}
           </Typography>
         </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {(dialogData?.type === 'addProfileImage' || dialogData?.type === 'editProfileImage') &&
            <div style={{ padding: 10 }}>
              <GridContainer>
                <GridCell style={{ width: 200 }}>
                  <div style={{ marginTop: 10, textAlign: 'center' }}>
                    {!imagePreview && !dialogData.value.imageId &&
                      <GridContainer style={{ height: 200, width: 200, marginTop: 10 }}>
                        <GridCell center={true} weight={1} style={{ background: '#c6c6c6', textAlign: 'center', borderRadius: 5 }}>
                          No Image
                        </GridCell>
                      </GridContainer>
                    }
                    {(imagePreview || dialogData.value.imageId) &&
                      <GridContainer style={{ marginTop: 10 }}>
                        <GridCell center={true} weight={1}>
                          <img src={imagePreview || `https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${dialogData.value.imageId}`} alt={dialogData?.value?.alt} style={{ width: 200 }} />
                        </GridCell>
                      </GridContainer>
                    }
                    <input
                      ref={imageUploadRef}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        handleGeneratePreview((event?.target?.files?.length) > 0 ? event.target.files[0] : undefined);
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            file: (event?.target?.files?.length) > 0 ? event.target.files[0] : undefined,
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                    <Button style={{ margin: 5 }} palette='primary' onClick={() => imageUploadRef.current.click()} disabled={processing}>
                      Change Image
                    </Button>
                    {(processing) &&
                      <ProgressBar palette='secondary' />
                    }
                    {dialogData?.value?.file && dialogData?.value?.file?.size > 1.1 * 1024 * 1024 &&
                      <div style={{ padding: 10 }}>
                        <Typography style={{ color: 'red' }}>
                          File too large, must be under 1 MB
                        </Typography>
                      </div>
                    }
                  </div>
                </GridCell>
                <GridCell weight={1}>
                  <Input
                    name="alt"
                    palette='secondary'
                    label="Alt Text"
                    type="text"
                    value={dialogData?.value?.alt || ''}
                    onChange={(value) => {
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          alt: value,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                  <Input
                    name="tags"
                    palette='secondary'
                    label="Tags"
                    type="text"
                    value={dialogData?.value?.tags || ''}
                    onChange={(value) => {
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          tags: value,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                  <TextArea
                    name="description"
                    palette='secondary'
                    label="Description"
                    type="text"
                    value={dialogData?.value?.description || ''}
                    onChange={(value) => {
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          description: value,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                </GridCell>
              </GridContainer>
            </div>
          }

          <GridContainer>
            <GridCell weight={1} />
            <GridCell style={{ padding: 10 }}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogData(undefined);
                  setImagePreview(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'editProfileImage' &&
              <GridCell style={{ padding: 10 }}>
                <Button
                  disabled={processing || dialogData?.value?.file?.size > 1000000}
                  palette='primary'
                  onClick={() => {
                    if (dialogData?.value?.file) {
                      handleUploadImage(dialogData?.value?.file, handleUpdateThumbnailImage);
                    } else {
                      handleUpdateThumbnailImage(dialogData?.value);
                    }
                  }}
                >
                  Edit Profile Image
                </Button>
                {processing &&
                  <ProgressBar palette='secondary' />
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

StaffForm.propTypes = {
  staff: PropTypes.shape({}),
  handleChange: PropTypes.func,
  handleRemoveStaff: PropTypes.func,
}

export default StaffForm;
