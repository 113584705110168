import React, {useState} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../../components/search/store';
import EventDetailsForm from '../../../../../components/forms/eventDetails';
import EventAreaForm from '../../../../../components/forms/eventArea';
import PositionForm from '../../../../../components/forms/position';
import ContactAddressForm from '../../../../../components/forms/contactAddress';
import DateTimeForm from '../../../../../components/forms/dateTime';

import {paletteData, Typography, Dialog, Button, ProgressBar} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function EditEventInfoSubPanel({
    focusedEvent,
    handleChangeFocusedEvent,
    handleChangeFocusedEventMultiple,
    processing,
    setProcessing,

    search,
    setSearch,
    queryLimit,

    areas,
    setAreas,
    areaReferences,
    setAreaReference,

    organisations,
    setOrganisations,
    organisationReferences,
    setOrganisationReference,
}) {
  const [expandSearch, setExpandSearch] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <div style={{padding: 10, borderStyle: 'solid', borderWidth: 1, background: paletteData.primary.standard.background}}>
        <Typography style={{color: paletteData.primary.standard.foreground}}>
          Details
        </Typography>
      </div>
      <EventDetailsForm
        focusedEvent={focusedEvent}
        organisationReferences={organisationReferences}
        handleChange={(name, value) => handleChangeFocusedEvent(name, value)}
      />
      <br/>
      <div style={{padding: 10, borderStyle: 'solid', borderWidth: 1, background: paletteData.primary.standard.background}}>
        <GridContainer>
          <GridCell weight={1} center={true}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Partners
            </Typography>
          </GridCell>
          {/*
            <GridCell>
              <Button
                palette="secondary"
                size='small'
                onClick={() => {}}
              >
                Invite Partner
              </Button>
            </GridCell>
          */}
        </GridContainer>
      </div>
      {(!focusedEvent.partners || focusedEvent?.partners?.length === 0) &&
        <div style={{padding: 10}}>
          <Typography>
            No partners registered
          </Typography>
        </div>
      }
      {focusedEvent?.partners?.length > 0 &&
        <div style={{padding: 10}}>
          <Typography>
            {focusedEvent.partners.map((p, pIndex) => (
              <span key={pIndex}>{organisationReferences[p]?.name || 'unnamed partner'}</span>
            ))}
          </Typography>
        </div>
      }
      <br/>
      <div style={{padding: 10, borderStyle: 'solid', borderWidth: 1, background: paletteData.primary.standard.background}}>
        <GridContainer>
          <GridCell weight={1} center={true}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              locations
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              palette="secondary"
              size='small'
              onClick={() => {
                const tempLocations = focusedEvent.locations || [];
                tempLocations.push({});
                handleChangeFocusedEvent('locations', tempLocations);
              }}
            >
              Add Location
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {(!focusedEvent.locations || focusedEvent?.locations?.length === 0) &&
        <div style={{padding: 10}}>
          No locations registered
        </div>
      }
      {focusedEvent?.locations?.length > 0 && focusedEvent.locations.map((l, lIndex) => (
        <div key={lIndex}>
          <div style={{marginTop: 10, padding: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: '5px 5px 0px 0px', background: paletteData.primary.standard.background}}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Location {lIndex + 1}
            </Typography>
          </div>
          <div style={{padding: 10, borderStyle: 'solid', borderWidth: 1,  background: '#e3e3e3'}}>
            <div style={{marginTop: 10, padding: 10, borderStyle: 'solid', borderWidth: 1, background: paletteData.secondary.standard.background}}>
              <Typography style={{color: paletteData.secondary.standard.foreground}}>
                Area
              </Typography>
            </div>
            <EventAreaForm
              location={l}
              areaReferences={areaReferences}
              handleChange={(name, value) => {
                if(name === 'area') {
                  setDialogData({
                    type: 'changeArea',
                    title: 'Change Area',
                    index: lIndex,
                  });
                  setDialogOpen(true);
                } else {
                  const tempLocations = focusedEvent.locations || [];
                  tempLocations[lIndex][name] = value;
                  handleChangeFocusedEvent('locations', tempLocations);
                }
              }}
            />
            <br/>
            <div style={{marginTop: 10, padding: 10, borderStyle: 'solid', borderWidth: 1, background: paletteData.secondary.standard.background}}>
              <Typography style={{color: paletteData.secondary.standard.foreground}}>
                Address
              </Typography>
            </div>
            <ContactAddressForm address={l.address} handleChange={(name, value) => {
              const tempLocations = focusedEvent.locations || [];
              if(!tempLocations[lIndex].address) {
                tempLocations[lIndex].address = {};
              }
              tempLocations[lIndex].address[name] = value;
              handleChangeFocusedEvent('locations', tempLocations);
            }}/>
            <PositionForm
              position={l.position}
              address={`${l.address?.extra ? `${l.address?.extra} ` : ''}${l.address?.number ? `${l.address?.number} ` : ''}${l.address?.street ? `${l.address?.street} ` : ''}${l.address?.suburb ? `${l.address?.suburb} ` : ''}${l.address?.city ? `${l.address?.city} ` : ''}${l.address?.country ? `${l.address?.country} ` : ''}`}
              handleChange={(name, value) => {
                if(name === 'position') {
                  const tempLocations = focusedEvent.locations || [];
                  tempLocations[lIndex].position = value;
                  handleChangeFocusedEvent('locations', tempLocations);
                } else {
                  const tempLocations = focusedEvent.locations || [];
                  if(!tempLocations[lIndex].position) {
                    tempLocations[lIndex].position = {};
                  }
                  tempLocations[lIndex].position[name] = value;
                  handleChangeFocusedEvent('locations', tempLocations);
                }
              }}
            />
            <br/>
            <div style={{marginTop: 10, padding: 10, borderStyle: 'solid', borderWidth: 1, background: paletteData.secondary.standard.background}}>
              <GridContainer>
                <GridCell weight={1} center={true}>
                  <Typography style={{color: paletteData.secondary.standard.foreground}}>
                    Date / Time
                  </Typography>
                </GridCell>
                <GridCell>
                  <Button
                    palette="primary"
                    size='small'
                    onClick={() => {
                      const tempLocations = focusedEvent.locations;
                      if(!tempLocations[lIndex].dates) {
                        tempLocations[lIndex].dates = [];
                      }
                      tempLocations[lIndex].dates.push({});
                      handleChangeFocusedEvent('locations', tempLocations);
                    }}
                  >
                    Add Date / Time
                  </Button>
                </GridCell>
              </GridContainer>
            </div>
            {(!l.dates || l.dates.length === 0) &&
              <div style={{padding: 10}}>
                No Dates Registered
              </div>
            }
            {l.dates?.length > 0 && l.dates.map((d, dIndex) => (
              <div key={dIndex}>
                <DateTimeForm
                  dateTime={d}
                  handleChange={(name, value) => {
                    const tempLocations = focusedEvent.locations;
                    if(!tempLocations[lIndex].dates) {
                      tempLocations[lIndex].dates = [];
                    }
                    tempLocations[lIndex].dates[dIndex][name] = value;
                    handleChangeFocusedEvent('locations', tempLocations);
                  }}
                />
              </div>
            ))}
            <br/>
            <GridContainer>
              <GridCell weight={1}/>
              <GridCell>
                <Button palette='primary' onClick={() => {
                  const tempLocations = focusedEvent.locations || [];
                  tempLocations.splice(lIndex, 1);
                  handleChangeFocusedEvent('locations', tempLocations);
                }}>
                  Remove Location
                </Button>
              </GridCell>
            </GridContainer>
          </div>
        </div>
      ))}

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
            setDialogData(undefined);
            setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'changeHost' &&
            <div style={{padding: 10}}>
              <SearchStore databaseArea='organisations' processing={processing} setProcessing={setProcessing} expandSearch={expandSearch} setExpandSearch={setExpandSearch}/>
              {(!organisations || processing) &&
                <ProgressBar palette='secondary'/>
              }
              {!processing && organisations && organisations.map((o, oIndex) => (
                <div
                  key={oIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!organisationReferences[o._id]) {
                      setOrganisationReference(o);
                    }
                    const tempPartners = o.partners || [];
                    if(o.host) {
                      const hIndex = tempPartners.findIndex((p, pIndex) => p === o.host);
                      if (hIndex >= 0) {
                        tempPartners.splice(hIndex, 1);
                      }
                    }
                    const pIndex = tempPartners.findIndex((p, pIndex) => p === o._id);
                    if(pIndex < 0) {
                      tempPartners.unshift(o._id);
                    }

                    handleChangeFocusedEventMultiple({
                      partners: tempPartners,
                      host:  o._id,
                    });

                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {o.name}
                </div>
              ))}
            </div>
          }

          {dialogData?.type === 'changeArea' &&
            <div style={{padding: 10}}>
              <SearchStore databaseArea='areas' processing={processing} setProcessing={setProcessing} expandSearch={expandSearch} setExpandSearch={setExpandSearch}/>
              {(!areas || processing) &&
                <ProgressBar palette='secondary'/>
              }
              {console.log(areas)}
              {!processing && areas && areas.map((a, aIndex) => (
                <div
                  key={aIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!areaReferences[a._id]) {
                      setAreaReference(a);
                    }
                    const tempLocations = focusedEvent.locations;
                    tempLocations[dialogData?.index].area = a._id;
                    handleChangeFocusedEvent('locations', tempLocations);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {a.name}
                </div>
              ))}
            </div>
          }

          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                   setDialogData(undefined);
                   setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditEventInfoSubPanel.propTypes = {
  focusedEvent: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedEvent: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditEventInfoSubPanel;
