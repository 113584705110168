import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import {paletteData, Typography, Button, Dialog, Input, TextArea, ProgressBar} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

import handleErrorMessage from '../../../../../tools/handleErrorMessage';

function ListingSubPanel({
  me,
  setFocusedFormSection,
  processing,
  setProcessing,
  search,
  setSearch,
  queryLimit,
  handleUploadImage,

  focusedOrganisation,
  handleChangeFocusedOrganisation,

  layoutGeneration,
  handleChangeLayoutGeneration,
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(undefined);
    const [imagePreview, setImagePreview] = useState(undefined);

    const imageUploadRef = useRef(null);

    const handleGeneratePreview = (file) => {
      setProcessing(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setProcessing(false);
        setImagePreview(reader.result)
      }
    }

    const handleUpdateLogoImage = (image) => {
      const item = {
        imageId: image?.imageId,
        tags: image?.tags,
        alt: image?.alt,
        description: image?.description,
      };
      handleChangeFocusedOrganisation('logo', item);
      setDialogOpen(false);
      setDialogData(undefined);
      setImagePreview(undefined);
    }

    const handleAddGalleryImage = (image, error) => {
      if(!error) {
        const item = {
          imageId: image?.imageId,
          tags: image?.tags,
          alt: image?.alt,
          description: image?.description,
        };
        const galleryProcessed = focusedOrganisation.gallery || [];
        galleryProcessed.push(item);
        handleChangeFocusedOrganisation('gallery', galleryProcessed);
        setDialogData(undefined);
        setImagePreview(undefined);
        setDialogOpen(false)
      } else {
        setDialogData({
          ...dialogData,
          message: handleErrorMessage(error),
        });
      }
    }

    const handleUpdateGalleryImage = (image, error) => {
      if(!error) {
        const item = {
          imageId: image?.imageId,
          tags: image?.tags,
          alt: image?.alt,
          description: image?.description,
        };
        const galleryProcessed = focusedOrganisation.gallery || [];
        galleryProcessed.splice(dialogData?.index, 1, item);
        handleChangeFocusedOrganisation('gallery', galleryProcessed);
        setDialogData(undefined);
        setImagePreview(undefined);
        setDialogOpen(false);
      } else {
        setDialogData({
          ...dialogData,
          message: handleErrorMessage(error),
        });
      }
    }

    return (
      <div>
        <div style={{background: paletteData.primary.standard.background, padding: 10}}>
          <Typography style={{color: paletteData.primary.standard.foreground}}>
            Images
          </Typography>
        </div>
        {(processing) &&
          <ProgressBar palette='secondary'/>
        }
        <br/>
        <GridContainer>
          <GridCell style={{width: 180, textAlign: 'center', marginRight: 10}}>
            <div>
              {!focusedOrganisation?.logo?.imageId &&
                <GridContainer style={{height: 180, width: 180}}>
                  <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5}}>
                    No Logo
                  </GridCell>
                </GridContainer>
              }
              {focusedOrganisation?.logo?.imageId &&
                <GridContainer>
                  <GridCell center={true} weight={1} style={{minHeight: 180, background: '#c6c6c6'}}>
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fit,q_auto:good,w_400,h_400/${focusedOrganisation.logo?.imageId}`}
                      alt={focusedOrganisation?.logo?.alt}
                      style={{width: 180}}
                    />
                  </GridCell>
                </GridContainer>
              }
            </div>
            <div style={{marginTop: 10, marginBottom: 10, textAlign: 'center'}}>
              <Button disabled={processing} palette='primary' onClick={() => {
                setImagePreview(undefined);
                setDialogData({
                  type: 'editLogoImage',
                  title: 'Change Logo',
                  preview: undefined,
                  value: {
                    file: undefined,
                    imageId: focusedOrganisation.logo?.imageId || '',
                    tags: focusedOrganisation.logo?.tags || '',
                    alt: focusedOrganisation.logo?.alt || '',
                    description: focusedOrganisation.logo?.description || '',
                  }
                });
                setDialogOpen(true);
              }}>
                Change Logo
              </Button>
            </div>
          </GridCell>
          {focusedOrganisation?.gallery?.length > 0 && focusedOrganisation.gallery.map((g, gIndex) => (
            <GridCell key={gIndex} style={{width: 180, display: 'flex', flexDirection: 'column', marginRight: 10, marginBottom: 10}}>
              <img src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${g.imageId}`} alt={g.alt} style={{width: '100%'}} />
              <div style={{flex: 1}}/>
              <div style={{marginTop: 10, textAlign: 'center'}}>
                <Button disabled={processing} palette='primary' onClick={() => {
                  setImagePreview(undefined);
                  setDialogData({
                    type: 'editGalleryImage',
                    title: 'Add an image to gallery',
                    preview: undefined,
                    index: gIndex,
                    value: {
                      file: undefined,
                      imageId: g.imageId,
                      tags: g.tags,
                      alt: g.alt,
                      description: g.description,
                    }
                  });
                  setDialogOpen(true);
                }}>
                  Edit Galery Item
                </Button>
              </div>
            </GridCell>
          ))}
          <GridCell style={{width: 180, display: 'flex', flexDirection: 'column', marginRight: 10, marginBottom: 10}}>
            <GridContainer style={{height: 180, width: 180}}>
              <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5}}>
                <span className="material-icons md-24" style={{fontSize: 50}}>add</span>
              </GridCell>
            </GridContainer>
            <div style={{flex: 1}}/>
            <div style={{marginTop: 10, textAlign: 'center'}}>
              <Button disabled={processing} palette='primary' onClick={() => {
                setImagePreview(undefined);
                setDialogData({
                  type: 'addGalleryImage',
                  title: 'Add an image to gallery',
                  preview: undefined,
                  value: {
                    file: undefined,
                    tags: '',
                    alt: '',
                    description: '',
                  }
                });
                setDialogOpen(true);
              }}>
                Add Galery Item
              </Button>
            </div>
          </GridCell>
        </GridContainer>
        <br/>
        <GridContainer>
          <GridCell weight={1}/>
          <GridCell>
            <Button palette='primary' style={{padding: 30}} onClick={() => {
              window.scrollTo(0, 0);
              setFocusedFormSection('summary');
            }}>
              Next Step
            </Button>
          </GridCell>
          <GridCell weight={1}/>
        </GridContainer>


        {/*popouts and popups*/}
        {dialogOpen &&
          <Dialog
            open={dialogOpen}
            handleClose={() => {
             setDialogData(undefined);
             setImagePreview(undefined);
             setDialogOpen(false);
           }}
          >
           <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
             <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
               {dialogData?.title}
             </Typography>
           </div>
            {(dialogData?.message) &&
              <div style={{padding: 10}}>
                <Typography>
                  {dialogData.message}
                </Typography>
              </div>
            }
            {(dialogData?.type === 'addGalleryImage' || dialogData?.type === 'editGalleryImage' || dialogData?.type === 'editLogoImage') &&
              <div style={{padding: 10}}>
                <GridContainer>
                  <GridCell style={{width: 200, textAlign: 'center'}}>
                    {!imagePreview && !dialogData.value.imageId &&
                      <GridContainer style={{height: 200, width: 200, marginTop: 10}}>
                        <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5}}>
                          No Image
                        </GridCell>
                      </GridContainer>
                    }
                    {(imagePreview || dialogData.value.imageId) &&
                      <GridContainer style={{marginTop: 10}}>
                        <GridCell center={true} weight={1}>
                          <img src={imagePreview || `https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${dialogData.value.imageId}`} alt={dialogData?.value?.alt} style={{width: 200}}/>
                        </GridCell>
                      </GridContainer>
                    }
                    <input
                      ref={imageUploadRef}
                      type="file"
                      style={{display: 'none'}}
                      onChange={(event) => {
                        handleGeneratePreview((event?.target?.files?.length) > 0 ? event.target.files[0] : undefined);
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            file: (event?.target?.files?.length) > 0 ? event.target.files[0] : undefined,
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                    <Button palette='primary' style={{marginTop: 10}} onClick={() => imageUploadRef.current.click()} disabled={processing}>
                      Change Image
                    </Button>
                    {dialogData?.value?.file && dialogData?.value?.file?.size > 1.1 * 1024 * 1024 &&
                      <div style={{padding: 10}}>
                        <Typography style={{color: 'red'}}>
                          File too large, must be under 1 MB
                        </Typography>
                      </div>
                    }
                    {(processing) &&
                      <ProgressBar palette='secondary'/>
                    }
                  </GridCell>
                  <GridCell weight={1}>
                    <Input
                      name="alt"
                      palette='secondary'
                      label="Alt Text"
                      type="text"
                      value={dialogData?.value?.alt || ''}
                      onChange={(value) => {
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            alt: value,
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                    <Input
                      name="tags"
                      palette='secondary'
                      label="Tags"
                      type="text"
                      value={dialogData?.value?.tags || ''}
                      onChange={(value) => {
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            tags: value,
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                    <TextArea
                      name="description"
                      palette='secondary'
                      label="Description"
                      type="text"
                      value={dialogData?.value?.description || ''}
                      onChange={(value) => {
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            description: value,
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                  </GridCell>
                </GridContainer>
              </div>
            }
            <GridContainer>
              <GridCell weight={1}/>
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                   onClick={() => {
                    setDialogData(undefined);
                    setImagePreview(undefined);
                    setDialogOpen(false);
                  }}
                >
                  {(dialogData?.type === 'message') ? 'OK' : 'Cancel'}
                </Button>
              </GridCell>
              {dialogData?.type === 'editLogoImage' &&
                <GridCell style={{padding: 10}}>
                  <Button
                    disabled={processing || dialogData?.value?.file?.size > 1.1 * 1024 * 1024}
                    palette='primary'
                    onClick={() => {
                      if(dialogData?.value?.file) {
                        handleUploadImage(dialogData?.value?.file, handleUpdateLogoImage);
                      } else {
                        handleUpdateLogoImage(dialogData?.value);
                      }
                    }}
                  >
                    Edit Logo data
                  </Button>
                  {processing &&
                    <ProgressBar palette='secondary'/>
                  }
                </GridCell>
              }
              {dialogData?.type === 'addGalleryImage' &&
                <GridCell style={{padding: 10}}>
                  <Button
                    disabled={processing || !dialogData?.value?.file || dialogData?.value?.file?.size > 1.1 * 1024 * 1024}
                    palette='primary'
                    onClick={() => handleUploadImage(dialogData?.value?.file, handleAddGalleryImage)}
                  >
                    Add to gallery
                  </Button>
                  {processing &&
                    <ProgressBar palette='secondary'/>
                  }
                </GridCell>
              }
              {dialogData?.type === 'editGalleryImage' &&
                <GridCell style={{padding: 10}}>
                  <Button
                    disabled={processing}
                    palette='primary'
                    onClick={() => {
                      const galleryProcessed = focusedOrganisation.gallery || [];
                      galleryProcessed.splice(dialogData?.index, 1);
                      handleChangeFocusedOrganisation('gallery', galleryProcessed);
                      setDialogData(undefined);
                      setImagePreview(undefined);
                      setDialogOpen(false)
                    }}
                  >
                    Remove Image
                  </Button>
                </GridCell>
              }
              {dialogData?.type === 'editGalleryImage' &&
                <GridCell style={{padding: 10}}>
                  <Button
                    disabled={processing || dialogData?.value?.file?.size > 1.1 * 1024 * 1024}
                    palette='primary'
                    onClick={() => {
                      if(dialogData?.value?.file) {
                        handleUploadImage(dialogData?.value?.file, handleUpdateGalleryImage);
                      } else {
                        handleUpdateGalleryImage(dialogData?.value);
                      }
                    }}
                  >
                    Edit Image data
                  </Button>
                  {processing &&
                    <ProgressBar palette='secondary'/>
                  }
                </GridCell>
              }
            </GridContainer>
          </Dialog>
        }
      </div>
  );
};

ListingSubPanel.propTypes = {
  me: PropTypes.shape({}),
  setFocusedFormSection: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,

  focusedOrganisation: PropTypes.shape({}),
  handleChangeFocusedOrganisation: PropTypes.func,
};

export default ListingSubPanel;
