import {SET_API_TOKEN} from "./actionTypes";

const initialState = {
  api: undefined,
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_API_TOKEN: {
      return {
        ...state,
        api: action.payload,
      }
    }
    default:
      return state
  }
}
