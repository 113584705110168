export const SET_USER = "reference/set_user";
export const SET_USERS = "reference/set_users";
export const CLEAR_USER = "reference/clear_user";

export const SET_PROFILE = "reference/set_profile";
export const CLEAR_PROFILE = "reference/clear_profile";

export const SET_AREA = "reference/set_area";
export const SET_AREAS = "reference/set_areas";
export const CLEAR_AREA = "reference/clear_area";

export const SET_ORGANISATION = "reference/set_organisation";
export const SET_ORGANISATIONS = "reference/set_organisations";
export const CLEAR_ORGANISATION = "reference/clear_organisation";

export const SET_CATEGORY = "reference/set_category";
export const SET_CATEGORIES = "reference/set_categories";
export const CLEAR_CATEGORY = "reference/clear_category";

export const SET_EVENT = "reference/set_event";
export const SET_EVENTS = "reference/set_events";
export const CLEAR_EVENT = "reference/clear_event";

export const SET_PRODUCT = "reference/set_product";
export const SET_PRODUCTS = "reference/set_products";
export const CLEAR_PRODUCT = "reference/clear_product";

export const SET_PRODUCTGROUP = "reference/set_productGroup";
export const SET_PRODUCTGROUPS = "reference/set_productGroups";
export const CLEAR_PRODUCTGROUP = "reference/clear_productGroup";

export const SET_POST_SCHEME = "reference/set_post_scheme";
export const SET_POST_SCHEMES = "reference/set_post_schemes";
export const CLEAR_POST_SCHEME = "reference/clear_post_scheme";

export const SET_BOOKING_CALENDAR = "reference/set_booking_calendar";
export const SET_BOOKING_CALENDARS = "reference/set_booking_calendars";
export const CLEAR_BOOKING_CALENDAR = "reference/clear_booking_calendar";

export const SET_REVIEW = "reference/set_review";
export const SET_REVIEWS = "reference/set_reviews";
export const CLEAR_REVIEW = "reference/clear_review";

export const SET_ORDER = "reference/set_order";
export const SET_ORDERS = "reference/set_orders";
export const CLEAR_ORDER = "reference/clear_order";
export const PRUNE_BOOKING_FROM_ORDER = "reference/prune_booking_from_order";

export const SET_ARTICLE = "reference/set_article";
export const SET_ARTICLES = "reference/set_articles";
export const CLEAR_ARTICLE = "reference/clear_article";

export const SET_BOOKING = "reference/set_booking";
export const SET_BOOKINGS = "reference/set_bookings";
export const CLEAR_BOOKING = "reference/clear_booking";

export const SET_COMMUNITY = "reference/set_community";
export const SET_COMMUNITIES = "reference/set_communities";
export const CLEAR_COMMUNITY = "reference/clear_community";

export const SET_POTENTIAL_COMMUNITY = "reference/set_potential_community";
export const SET_POTENTIAL_COMMUNITIES = "reference/set_potential_communities";
export const CLEAR_POTENTIAL_COMMUNITY = "reference/clear_potential_community";

export const RESET_REFERENCES = "list/reset_references";
