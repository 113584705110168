import React, {useState, useEffect} from 'react';

import apiRequest from '../../../../tools/apiRequest';
import {Typography, ProgressBar, Fab, DatePicker, Button, paletteData} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function ReportOrganisationCreation() {
  const [reportFetched, setReportFetched] = useState(false);
  const [report, setReport] = useState(undefined);
  const [start, setStart] = useState(undefined);
  const [end, setEnd] = useState(undefined);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if(!reportFetched) {
      setProcessing(true);
      let s = undefined;
      let e = undefined;
      if(!end) {
        e = new Date();
        setEnd(e);
      } else {
        e = end;
      }
      if(!start) {
        s = new Date((new Date()).getTime() - (7 * 24 * 60 * 60 * 1000));
        setStart(s); // 7 days ago
      } else {
        s = start;
      }
      setReportFetched(true);
      apiRequest({type: 'get', action: 'organisations/reportCreation', data: {start: s, end: e}})
      .then((result) => {
        setProcessing(false);
        setReport(result.data);
      }).catch((error) => {
        setProcessing(false);
        setReport({});
      });
    }
  }, [reportFetched, start, end]);

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Organisation Report
        </Typography>
      </div>
      <GridContainer>
        <GridCell weight={1}>
          <DatePicker
            label="start"
            value={start}
            onChange={(value) => setStart(value)}
          />
        </GridCell>
        <GridCell weight={1}>
          <DatePicker
            label="end"
            value={end}
            onChange={(value) => setEnd(value)}
          />
        </GridCell>
        <GridCell style={{marginTop: 10}}>
          <Button disabled={processing} palette='primary' onClick={() => {
            setReportFetched(false);
            setReport(undefined);
          }}>
            Generate
          </Button>
        </GridCell>
      </GridContainer>
      {!report && <ProgressBar palette='secondary'/>}
      {report && Object.keys(report || {}).length > 0 && Object.keys(report || {}).map((u, uIndex) => (
        <div key={uIndex}>
          <div style={{background: paletteData.primary.standard.background, padding: 10}}>
            <GridContainer>
              <GridCell weight={1}>
                <Typography style={{color: paletteData.primary.standard.foreground}}>
                  {report[u].name}
                </Typography>
              </GridCell>
              <GridCell>
                <Typography style={{color: paletteData.primary.standard.foreground}}>
                  {report[u].list?.length || 0}
                </Typography>
              </GridCell>
            </GridContainer>
          </div>
          {report[u].list?.length > 0 && report[u].list.map((o, oIndex) => (
            <GridContainer key={oIndex} style={{padding: 10}}>
              <GridCell weight={1} center={true}>
                {o.name}
              </GridCell>
              <GridCell center={true}>
                <Fab
                  palette='primary'
                  size='small'
                  href={`https://rerengatahi.nz/organisation/${o._id}`}
                  target="_blank"
                  rel='noopener noreferrer'
                >
                  <span className="material-icons md-24">visibility</span>
                </Fab>
              </GridCell>
            </GridContainer>
          ))}
        </div>
      ))}

    </div>
  );
}

export default ReportOrganisationCreation;
