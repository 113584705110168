import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Input, Select, Button, Typography, Dialog, ProgressBar, paletteData} from '../../styles';
import {GridContainer, GridCell} from '../../grid';
import apiRequest from '../../../tools/apiRequest';

function ProductDetailsForm({
  focusedProduct,
  handleChange,
  processing,
  setProcessing,

  focusedOrganisation,
  productGroups,
  productGroupReferences,
  setProductGroupReference,
  replaceProductGroup,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <Select
        name="type"
        palette='secondary'
        label="Product Type"
        type="text"
        value={focusedProduct?.type || ''}
        onChange={(value) => {
          handleChange('type', value);
        }}
      >
        <option value="good">Good</option>
        <option value="service">Service</option>
      </Select>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            disabled={true}
            name="area"
            palette='secondary'
            label="Category"
            type="text"
            value={focusedProduct?.productGroupId ? productGroupReferences[focusedProduct?.productGroupId]?.name || '...processing' : 'No category selected'}
          />
        </GridCell>
        <GridCell style={{marginTop: 7}}>
          <Button palette='primary' onClick={() => handleChange('productGroup', undefined)}>
            Change Category
          </Button>
        </GridCell>
      </GridContainer>
      {focusedProduct?.productGroupId && productGroupReferences[focusedProduct?.productGroupId] &&
        <div>
          <Typography style={{padding: 5, fontWeight: 500}}>
            Tags
          </Typography>
          <GridContainer>
            {focusedProduct?.tags?.length > 0 && focusedProduct.tags.map((t, tIndex) => (
              <GridCell key={tIndex} style={{margin: 5, padding: 10, background: '#c6c6c6', borderRadius: 5, cursor: 'pointer'}} onClick={() => {
                const tagsTemp = focusedProduct.tags || [];
                tagsTemp.splice(tIndex, 1);
                handleChange('tags', tagsTemp);
              }}>
                <Typography>
                  {t}
                </Typography>
              </GridCell>
            ))}
            <GridCell style={{margin: 5, padding: 10, background: '#c6c6c6', borderRadius: 5, cursor: 'pointer'}} onClick={() => {
              setDialogData({
                type: `addTag`,
                title: 'Add Tag',
                value: {
                  searchTags: '',
                },
              });
              setDialogOpen(true);
            }}>
              <Typography>
                Add Tag +
              </Typography>
            </GridCell>
          </GridContainer>
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
            setDialogData(undefined);
            setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'addTag' &&
            <div style={{padding: 10}}>
              <Input
                disabled={processing}
                name="search"
                autoComplete='off'
                palette='secondary'
                label="Search"
                type="text"
                value={dialogData?.value?.searchTags || ''}
                onChange={(value) => {
                  setDialogData({
                    ...(dialogData || {}),
                    value: {
                      ...(dialogData?.value || {}),
                      searchTags: value,
                    }
                  });
                }}
              />
              {!processing && productGroupReferences[focusedProduct?.productGroupId]?.tags?.length > 0 && productGroupReferences[focusedProduct?.productGroupId]?.tags?.filter((t, tIndex) => {
                return t.includes(dialogData?.value?.searchTags.toLowerCase());
              }).map((t, tIndex) => (
                <div key={tIndex} style={{padding: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, marginTop: 5, cursor: 'pointer'}} onClick={() => {
                  const tagsTemp = focusedProduct?.tags || [];
                  const tagsTest = tagsTemp.findIndex((compare) => compare === t);
                  if(tagsTest === -1) {
                    tagsTemp.push(t);
                    tagsTemp.sort();
                    handleChange('tags', tagsTemp);
                  }
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}>
                  <Typography>
                    {t}
                  </Typography>
                </div>
              ))}
              {dialogData?.value?.searchTags &&
                <div style={{padding: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, marginTop: 5, background: '#c6c6c6'}}>
                  <GridContainer>
                    <GridCell center weight={1}>
                      <Typography>
                        {dialogData?.value?.searchTags}
                      </Typography>
                    </GridCell>
                    <GridCell>
                      <Button palette='primary' onClick={() => {
                        const tagsTemp = focusedProduct?.tags || [];
                        const tagsTest = tagsTemp.findIndex((compare) => compare === dialogData?.value?.searchTags);
                        if(tagsTest === -1) {
                          tagsTemp.push(dialogData?.value?.searchTags);
                          tagsTemp.sort();
                          handleChange('tags', tagsTemp);
                        }

                        const productGroupTagsTemp = productGroupReferences[focusedProduct?.productGroupId].tags || [];
                        const productGroupTagsTest = productGroupTagsTemp.findIndex((pg) => pg === dialogData?.value?.searchTags);
                        if(productGroupTagsTest === -1) {
                          productGroupTagsTemp.push(dialogData?.value?.searchTags);
                          productGroupTagsTemp.sort();
                          const productGroupId = focusedProduct?.productGroupId;
                          const updateProductGroup = {
                            tags: productGroupTagsTemp,
                            organisationId: productGroupReferences[focusedProduct?.productGroupId].organisationId,
                          }
                          setProcessing(true);
                          apiRequest({type: 'patch', action: `productGroups/update/${productGroupId}`, data: {updateProductGroup}})
                          .then((result) => {
                            if(productGroups) {
                              const pgTest = productGroups.findIndex((pg) => pg._id === productGroupId);
                              if(pgTest !== -1) {
                                replaceProductGroup({index: pgTest, productGroup: {...productGroupReferences[productGroupId], ...updateProductGroup}});
                              }
                            }
                            setProductGroupReference({...productGroupReferences[productGroupId], ...updateProductGroup});
                            setProcessing(false);
                            setDialogData(undefined);
                            setDialogOpen(false);
                          }).catch((error) => {
                            setProcessing(false);
                          });
                        } else {
                          setDialogData(undefined);
                          setDialogOpen(false);
                        }
                      }}>
                        Create New Tag
                      </Button>
                    </GridCell>
                  </GridContainer>

                </div>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                   setDialogData(undefined);
                   setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
              {processing &&
                <ProgressBar />
              }
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ProductDetailsForm.propTypes = {
  focusedProduct: PropTypes.shape({}),
  handleChange: PropTypes.func,
  processing: PropTypes.bool,
}

export default ProductDetailsForm;
