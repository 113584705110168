import React, {useState, useEffect} from 'react';

import apiRequest from '../../../../tools/apiRequest';
import {Typography, ProgressBar, paletteData} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function ReportCategories() {
  const [reportFetched, setReportFetched] = useState(false);
  const [report, setReport] = useState(undefined);

  useEffect(() => {
    if(!reportFetched) {
      setReportFetched(true);
      apiRequest({type: 'get', action: 'categories/report', data: {}})
      .then((result) => {
        setReport(result.data);
      }).catch((error) => {
        setReport({});
      });
    }
  }, [reportFetched]);

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Category Report
        </Typography>
      </div>
      {!report && <ProgressBar palette='secondary'/>}
      {report && report?.totalOrgs > 0 &&
        <div style={{padding: 10}}>
          <Typography size='title'>
            Total Organisations
          </Typography>
          <Typography>
            {report.totalOrgs}
          </Typography>
        </div>
      }
      {report && report?.usage?.length > 0 &&
        <div style={{padding: 10}}>
          <Typography size='title'>
            Category Usage
          </Typography>
          {report.usage.map((c, cIndex) => (
            <GridContainer key={cIndex}>
              <GridCell style={{minWidth: 250}}>
                {c.name}
              </GridCell>
              <GridCell>
                {c.value}
              </GridCell>
            </GridContainer>
          ))}
        </div>
      }

    </div>
  );
}

export default ReportCategories;
