import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {paletteData, Typography, Button, Input, Dialog} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function BuyingAndSellingSubPanel({
  me,
  setFocusedFormSection,
  processing,
  setProcessing,
  search,
  setSearch,
  queryLimit,

  focusedOrganisation,
  handleChangeFocusedOrganisation,

  categories,
  setCategories,
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(undefined);

    return (
      <div>
        <div style={{background: paletteData.primary.standard.background, padding: 10}}>
          <Typography style={{color: paletteData.primary.standard.foreground}}>
            Buying and selling
          </Typography>
        </div>
        <Input
          name="gst"
          palette='secondary'
          label="GST Number"
          type="text"
          value={focusedOrganisation.gst || ''}
          onChange={(value) => handleChangeFocusedOrganisation('gst', value)}
        />
        <GridContainer>
          <GridCell weight={1}>
            <Input
              disabled={(focusedOrganisation.bank && focusedOrganisation.bank.substring(0, 11) === 'hidden-****')}
              name="bank"
              palette='secondary'
              label="Bank Account"
              type="text"
              value={(focusedOrganisation.bank && focusedOrganisation.bank.substring(0, 11) === 'hidden-****') ? (focusedOrganisation.bank.substring(7)) : (focusedOrganisation.bank || '')}
              onChange={(value) => handleChangeFocusedOrganisation('bank', value)}
            />
          </GridCell>
          {(focusedOrganisation.bank && focusedOrganisation.bank.substring(0, 11) === 'hidden-****') &&
            <GridCell style={{marginTop: 7}}>
              <Button palette='primary' onClick={() =>  handleChangeFocusedOrganisation('bank', '')}>
                Update
              </Button>
            </GridCell>
          }
        </GridContainer>
        {!focusedOrganisation?.bank &&
          <Typography size='subText' style={{color: 'red', paddingLeft: 5}}>
            bank account is required if you wish to sell on the platform
          </Typography>
        }
        {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
          <GridContainer>
            <GridCell weight={1}>
              <Input
                disabled={(focusedOrganisation.stripe && focusedOrganisation.stripe.substring(0, 11) === 'hidden-****')}
                name="stripe"
                palette='secondary'
                label="Stripe ID"
                type="text"
                value={(focusedOrganisation.stripe && focusedOrganisation.stripe.substring(0, 11) === 'hidden-****') ? (focusedOrganisation.stripe.substring(7)) : (focusedOrganisation.stripe || '')}
                onChange={(value) => handleChangeFocusedOrganisation('stripe', value)}
              />
            </GridCell>
            {(focusedOrganisation.stripe && focusedOrganisation.stripe.substring(0, 11) === 'hidden-****') &&
              <GridCell style={{marginTop: 7}}>
                <Button palette='primary' onClick={() =>  handleChangeFocusedOrganisation('stripe', '')}>
                  Update
                </Button>
              </GridCell>
            }
          </GridContainer>
        }
        <br/>
        <div style={{background: paletteData.primary.standard.background, padding: 10}}>
          <GridContainer>
            <GridCell center weight={1}>
              <Typography style={{color: paletteData.primary.standard.foreground}}>
                What areas does this organisation work in?
              </Typography>
            </GridCell>
            <GridCell>
              <Button palette='secondary' size='small' onClick={() => {
                setDialogData({
                  type: 'addCategory',
                  title: 'Add Category',
                  value: {}
                });
                setDialogOpen(true);
              }}>
                Add category
              </Button>
            </GridCell>
          </GridContainer>
        </div>
        <div style={{paddingTop: 10}}>
          <GridContainer>
            {focusedOrganisation?.categories?.length > 0 && focusedOrganisation.categories.map((c, cIndex) => (
              <GridCell key={cIndex} style={{background: '#c6c6c6', padding: 5, marginRight: 5, marginBottom: 5, borderRadius: 5}}>
                {c} <span className="material-icons md-24" style={{fontSize: 10, cursor: 'pointer'}} onClick={() => {
                  const tempCategories = focusedOrganisation.categories || [];
                  tempCategories.splice(cIndex, 1);
                  handleChangeFocusedOrganisation('categories', tempCategories);
                }}>close</span>
              </GridCell>
            ))}
          </GridContainer>
        </div>

        <GridContainer>
          <GridCell weight={1}/>
          <GridCell>
            <Button palette='primary' style={{padding: 30}} onClick={() => {
              window.scrollTo(0, 0);
              setFocusedFormSection('listing');
            }}>
              Next Step
            </Button>
          </GridCell>
          <GridCell weight={1}/>
        </GridContainer>


        {/*popouts and popups*/}
        {dialogOpen &&
          <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
            <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
              <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                {dialogData?.title}
              </Typography>
            </div>
            {(dialogData?.message) &&
              <div style={{padding: 10}}>
                <Typography>
                  {dialogData.message}
                </Typography>
              </div>
            }
            {dialogData?.type === 'addCategory' &&
              <div style={{padding: 10}}>
                <Input
                  label="search"
                  value={dialogData?.value?.search || ''}
                  onChange={(value) => {
                    setDialogData({
                      ...dialogData,
                      value: {
                        ...(dialogData?.value || {}),
                        search: value,
                      }
                    })
                  }}
                />
                {!processing && categories && categories.filter((c, cIndex) => {
                  return c.name.toLowerCase().includes((dialogData?.value?.search || '').toLowerCase());
                }).map((c, cIndex) => (
                  <div
                    key={cIndex}
                    style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                    onClick={() => {
                      const tempCategories = focusedOrganisation.categories || [];
                      let testCategories = true;
                      for(let i = 0; i < tempCategories.length; i += 1) {
                        if(tempCategories[i] === c.name) {
                          testCategories = false;
                        }
                      }
                      if(testCategories) {
                        tempCategories.push(c.name);
                        tempCategories.sort();
                        handleChangeFocusedOrganisation('categories', tempCategories);
                      }
                      setDialogOpen(false);
                      setDialogData(undefined);
                    }}
                  >
                    {c.name}
                  </div>
                ))}
              </div>
            }
            <GridContainer>
              <GridCell weight={1}/>
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                  onClick={() => {
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
                </Button>
              </GridCell>
            </GridContainer>
          </Dialog>
        }
      </div>
  );
};

BuyingAndSellingSubPanel.propTypes = {
  me: PropTypes.shape({}),
  setFocusedFormSection: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,

  focusedOrganisation: PropTypes.shape({}),
  handleChangeFocusedOrganisation: PropTypes.func,
};

export default BuyingAndSellingSubPanel;
