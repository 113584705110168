const generateVideoBlock = ({video}) => {
  const section = {
    section: 'videoBlock',
    type: 'GridContainer',
    options: {
      style: {},
    },
    children: [
      {
        type: 'GridCell',
        options: {
          weight: 1,
        },
        children: [],
      },
      {
        type: 'GridCell',
        options: {
          style: {width: '80%'},
        },
        children: [
          {
            type: 'Video',
            options: {
              video: video || {},
            },
          },
        ],
      },
      {
        type: 'GridCell',
        options: {
          weight: 1,
        },
        children: [],
      },
    ],
  };
  return section;
};

export default generateVideoBlock;
