import React, {useState} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../../components/search/store';
import {Button, ProgressBar, Typography} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';
import Hidden from '../../../../../components/hidden';

function SearchProductsSubPanel({
    me,
    products,
    setProducts,
    addProducts,
    search,
    setSearch,
    queryLimit,
    processing,
    setProcessing,

    setFocusedProduct,
    setFocusedProductIndex,
    handleChangeFocusedProduct,
    replaceProduct,
    removeProduct,
    setProductReference,
    setProductReferences,

    organisations,
    setOrganisations,
    addOrganisations,
    organisationReferences,
    setOrganisationReference,
    setFocusedOrganisation,
}) {
  const [expandSearch, setExpandSearch] = useState(false);
  const [refreshSearch, setRefreshSearch] = useState(false);

  return (
    <div>
      <SearchStore
        databaseArea='products'

        processing={processing}
        setProcessing={setProcessing}

        expandSearch={expandSearch}
        setExpandSearch={setExpandSearch}
        refreshSearch={refreshSearch}
        setRefreshSearch={setRefreshSearch}
      />
      {(search.products?.query?.organisationId || (me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker']))) &&
        products?.length > 0 &&
        <div>
          {products.map((p, pIndex) => (
            <GridContainer
              key={pIndex}
              style={{margin: 5}}
            >
              <Hidden breakpoint='hiddenlessthan1024'>
                <GridCell center={true} style={{height: 200, cursor: 'pointer'}} onClick={() => setFocusedProduct(p)}>
                  <img
                    src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${p.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                    alt={p?.thumbnail?.alt}
                    style={{width: 200}}
                  />
                </GridCell>
              </Hidden>
              <Hidden breakpoint='hiddenlessthan1024'>
                <GridCell weight={1} center style={{textAlign: 'left', padding: 10, height: 180, cursor: 'pointer'}} onClick={() => setFocusedProduct(p)}>
                  <Typography size='title'>
                    {p.name}
                  </Typography>
                  <Typography style={{color: '#333333'}}>
                    {p.tagline}
                  </Typography>
                  <Typography style={{marginTop: 5}}>
                    {p.blurb?.length > 350 ? `${p.blurb.substring(0, 350)}...` : p.blurb}
                  </Typography>
                </GridCell>
              </Hidden>
              <Hidden breakpoint='hiddengreaterthan1024'>
                <GridCell weight={1} style={{textAlign: 'left', padding: 10, cursor: 'pointer'}} onClick={() => setFocusedProduct(p)}>
                  <Typography size='title'>
                    {p.name}
                  </Typography>
                  <Typography style={{color: '#333333'}}>
                    {p.tagline}
                  </Typography>
                  <Typography style={{marginTop: 5}}>
                    {p.blurb?.length > 350 ? `${p.blurb.substring(0, 350)}...` : p.blurb}
                  </Typography>
                </GridCell>
              </Hidden>
            </GridContainer>
          ))}
          {processing && products?.length > 9 &&
            <div style={{padding: 5}}>
              <Typography>
                Loading...
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {!processing && search?.products?.hasMore &&
            <div style={{padding: 5}}>
              <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                Load More
              </Button>
            </div>
          }
        </div>
      }
    </div>
  );
};

SearchProductsSubPanel.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})),
  addProducts: PropTypes.func,
  search: PropTypes.shape({}),
  setSearch: PropTypes.func,
  queryLimit: PropTypes.number,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  setFocusedProduct: PropTypes.func,
  setFocusedProductIndex: PropTypes.func,
};

export default SearchProductsSubPanel;
