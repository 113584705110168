import React from 'react';
import PropTypes from 'prop-types';

import {paletteData, Typography, Button} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

import StaffForm from '../../../../../components/forms/staff';

function StaffSubPanel({
  me,
  setFocusedFormSection,
  processing,
  setProcessing,
  handleUploadImage,

  focusedOrganisation,
  handleChangeFocusedOrganisation,

  areaReferences,
}) {
    return (
      <div>
        {(!focusedOrganisation?.offices || focusedOrganisation?.offices?.length === 0) &&
          <div>
            <div style={{background: paletteData.primary.standard.background, padding: 10}}>
              <Typography style={{color: paletteData.primary.standard.foreground}}>
                Staff Details
              </Typography>
            </div>
            <div style={{padding: 10}}>
              <Typography>
                No offices registered for staff to be assigned to
              </Typography>
            </div>
          </div>
        }
        {focusedOrganisation?.offices?.length > 0 && focusedOrganisation.offices.map((o, oIndex) => (
          <div key={oIndex}>
            <div style={{background: paletteData.primary.standard.background, padding: 10}}>
              <GridContainer>
                <GridCell center weight={1}>
                  {o.name &&
                    <Typography style={{color: paletteData.primary.standard.foreground}}>
                      Staff in {o.name}
                    </Typography>
                  }
                  {!o.name &&
                    <Typography style={{color: paletteData.primary.standard.foreground}}>
                      Staff in {areaReferences[o.area] ? areaReferences[o.area].name : ''} office {!areaReferences[o.area] ? oIndex : ''}
                    </Typography>
                  }
                </GridCell>
                <GridCell>
                  <Button palette='secondary' size='small' onClick={() => {
                    const officesTemp = focusedOrganisation.offices || [];
                    if(!officesTemp[oIndex].staff) {
                      officesTemp[oIndex].staff = [];
                    }
                    officesTemp[oIndex].staff.push({});
                    handleChangeFocusedOrganisation('offices', officesTemp);
                  }}>
                    Add Staff Member
                  </Button>
                </GridCell>
              </GridContainer>
            </div>

            {(!o.staff || o?.staff?.length === 0) &&
              <div style={{padding: 10}}>
                No staff registered in this office
              </div>
            }
            {o?.staff?.length > 0 && o.staff.map((s, sIndex) => (
              <div key={sIndex} style={{marginTop: 10}}>
                <StaffForm
                  staff={s}
                  processing={processing}
                  setProcessing={setProcessing}
                  handleChange={(name, value) => {
                    const offices = focusedOrganisation.offices;
                    offices[oIndex].staff[sIndex][name] = value;
                    handleChangeFocusedOrganisation('offices', offices);
                  }}
                  handleRemoveStaff={() => {
                    const offices = focusedOrganisation.offices;
                    offices[oIndex].staff.splice(sIndex, 1);
                    handleChangeFocusedOrganisation('offices', offices);
                  }}
                  handleUploadImage={handleUploadImage}
                  owner={me._id === focusedOrganisation.owner}
                  verification={me?.verification?.contact || {}}
                />
              </div>
            ))}
            <br/>
          </div>
        ))}
        <GridContainer>
          <GridCell weight={1}/>
          <GridCell>
            <Button palette='primary' style={{padding: 30}} onClick={() => {
              window.scrollTo(0, 0);
              setFocusedFormSection('buyingAndSelling');
            }}>
              Next Step
            </Button>
          </GridCell>
          <GridCell weight={1}/>
        </GridContainer>
      </div>
  );
};

StaffSubPanel.propTypes = {
  me: PropTypes.shape({}),
  setFocusedFormSection: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,

  focusedOrganisation: PropTypes.shape({}),
  handleChangeFocusedOrganisation: PropTypes.func,
};

export default StaffSubPanel;
