import React from 'react';
import PropTypes from 'prop-types';

import { Input, Button, ProgressBar } from '../../styles';
import { GridContainer, GridCell } from '../../grid';

function PreviewForm({ preview, handleChange, processing }) {
  return (
    <div>
      <GridContainer>
        <GridCell style={{ width: 180 }}>
          <div>
            {!preview?.thumbnail?.imageId &&
              <GridContainer style={{ height: 180, width: 180 }}>
                <GridCell center={true} weight={1} style={{ background: '#c6c6c6', textAlign: 'center', borderRadius: 5 }}>
                  No Image
                </GridCell>
              </GridContainer>
            }
            {preview?.thumbnail?.imageId &&
              <GridContainer>
                <GridCell center={true} weight={1} style={{ minHeight: 180 }}>
                  <img
                    src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${preview.thumbnail?.imageId}`}
                    alt={preview?.thumbnail?.altText}
                    style={{ width: 180 }}
                  />
                </GridCell>
              </GridContainer>
            }
            <div style={{ marginTop: 10, textAlign: 'center' }}>
              <Button palette='primary' onClick={() => handleChange('thumbnail', preview?.thumbnail)} disabled={processing}>
                Edit Thumbnail
              </Button>
            </div>
          </div>
          {(processing) &&
            <ProgressBar palette='secondary' />
          }
        </GridCell>
        <GridCell weight={1}>
          <GridContainer>
            <GridCell weight={1}>
              <Input
                name="name"
                palette='secondary'
                label="Name - search safe"
                type="text"
                value={preview?.name || ''}
                onChange={(value) => handleChange('name', value)}
              />
            </GridCell>
            <GridCell weight={1}>
              <Input
                name="name"
                palette='secondary'
                label="Name - proper"
                type="text"
                value={preview?.nameProper || ''}
                onChange={(value) => handleChange('nameProper', value)}
              />
            </GridCell>
          </GridContainer>
        </GridCell>
      </GridContainer>
    </div>
  );
}

PreviewForm.propTypes = {
  preview: PropTypes.shape({}),
  handleChange: PropTypes.func,
  processing: PropTypes.bool,
}

export default PreviewForm;
