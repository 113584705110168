import React from 'react';
import PropTypes from 'prop-types';

import {Typography} from '../../../styles';

function FacebookEmbed({title, link, citation, credit, preview}) {
  return (
    <div class="fb-video" data-href={link} data-width="500" data-show-text="false" style={{width: '100%', paddingBottom: '56%', position: 'relative', pointerEvents: preview ? 'none' : 'auto'}}>
      <Typography size='subText'><a href={link}>{title}</a></Typography>
      <Typography size='subText'>Posted by <a href={citation}>{credit}</a></Typography>
    </div>
  );
}

FacebookEmbed.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  citation: PropTypes.string,
  credit: PropTypes.string,
  preview: PropTypes.bool,
};

export default FacebookEmbed;
