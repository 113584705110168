import React from 'react';
import PropTypes from 'prop-types';

function YoutubeEmbed({youtubeId, preview, style}) {
  return (
    <div
      style={{
        width: '100%', 
        paddingBottom: '56%',
        position: 'relative',
        pointerEvents: preview ? 'none' : 'auto',
        ...style,
      }}
    >
      <iframe title={youtubeId} style={{width: '100%', height: '100%', position: 'absolute', top: 0}} src={`https://www.youtube.com/embed/${youtubeId}?rel=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );
}

YoutubeEmbed.propTypes = {
  youtubeId: PropTypes.string,
  preview: PropTypes.bool,
};

export default YoutubeEmbed;
