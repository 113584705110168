import axios from 'axios';
import jwt from 'jsonwebtoken';
import store from '../../store';

axios.defaults.withCredentials = true;
const dblocation = (process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api/' : 'https://api.rerengatahi.nz/api/');

const handleApiRequest = ({type, action, data, headers, token}) => {
  if(type === 'get') {
    return axios.get(
      `${dblocation}${action}`,
      {
        params: data,
        withCredentials: true,
        headers: {
          ...headers,
          'Authorization': `Bearer ${token}`,
        },
      },
    );
  } else if (type === 'post') {
    return axios.post(
      `${dblocation}${action}`,
      data,
      {
        withCredentials: true,
        headers: {
          ...headers,
          'Authorization': `Bearer ${token}`,
        },
      },
    );
  } else if (type === 'patch') {
    return axios.patch(
      `${dblocation}${action}`,
      {...data},
      {
        withCredentials: true,
        headers: {
          ...headers,
          'Authorization': `Bearer ${token}`,
        },
      },
    );
  } else if (type === 'delete') {
    return axios.delete(
      `${dblocation}${action}`,
      {
        withCredentials: true,
        params: data,
        headers: {
          ...headers,
          'Authorization': `Bearer ${token}`,
        },
      },
    );
  }
}

async function handleVerification (token) {
  if(token) {
    const tokenExpiry = new Date((jwt.decode(token))?.exp * 1000);
    if(new Date() > tokenExpiry) {
      const result = await handleApiRequest({type: 'post', action: 'auth/refresh'});
      store.dispatch({type: 'auth/set_api_token', payload: result?.data?.auth || undefined});
      if(!result?.data?.auth) {
        store.dispatch({type: 'user/set_profile', payload: undefined});
      }
      return result?.data?.auth?.access_token || undefined;
    } else {
      return token;
    }
  } else {
    return undefined;
  }
}

async function apiRequest({type, action, data, headers}) {
  const state = store.getState();
  const token = state.auth?.api?.access_token;
  if(action === 'auth/refresh' || action === 'auth/login') {
    return handleApiRequest({type, action, data, headers, token});
  } else {
    const tokenProcessed = await handleVerification(token);
    return handleApiRequest({type, action, data, headers, token: tokenProcessed});
  }
}

export default apiRequest;
