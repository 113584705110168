import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import {GridContainer, GridCell} from '../../../grid';
import {Typography} from '../../../styles';
import Gallery from '../../../gallery';
import {YoutubeEmbed, VimeoEmbed} from '../../../video';

function AdvancedEdit({block, location, depth, onClick}) {
  if(block.type === 'GridContainer') {
    const styleProcessed = {};
    if(block.section === 'splashBlock') {
      styleProcessed.paddingTop = 100;
      styleProcessed.paddingBottom = 100;
    }
    return (
      <div style={{margin: 10}}>
        <GridContainer
          style={{
            zIndex: depth,
            pointerEvents: 'auto',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'red',
            ...block.options?.style,
            ...styleProcessed
          }}
          onClick={(event) => {
            event.stopPropagation();
            onClick('');
          }}
        >
          {block.children?.length > 0 && block.children.map((c, cIndex) => (
            <AdvancedEdit
              key={cIndex}
              block={c}
              location={`${location}.children.${cIndex}`}
              depth={(depth || 0) + 1}
              onClick={(componentPath) => onClick(`children.${cIndex}${componentPath !== '' ? `.${componentPath}` : ''}`)}
            />
          ))}
        </GridContainer>
      </div>
    );
  } else if(block.type === 'GridCell') {
    return (
      <GridCell
        weight={block?.options?.weight} center={block?.options?.center}
        centerWeights={block?.options?.centerWeights}
        style={{
          zIndex: depth,
          pointerEvents: 'auto',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'red',
          ...block.options?.style
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        <div style={{margin: 10}}>
          {block.children?.length > 0 && block.children.map((c, cIndex) => (
            <AdvancedEdit
              key={cIndex}
              block={c}
              location={`${location}.children.${cIndex}`}
              depth={(depth || 0) + 1}
              onClick={(componentPath) => onClick(`children.${cIndex}${componentPath !== '' ? `.${componentPath}` : ''}`)}
            />
          ))}
        </div>
      </GridCell>
    );
  } else if(block.type === 'div'){
    return (
      <div
        id={block.options?.id}
        style={{
          zIndex: depth,
          pointerEvents: 'auto',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'red',
          ...block.options?.style
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        <div style={{margin: 10}}>
          {block.children?.length > 0 && block.children.map((c, cIndex) =>  (
            <AdvancedEdit
              key={cIndex}
              block={c}
              location={`${location}.children.${cIndex}`}
              depth={(depth || 0) + 1}
              onClick={(componentPath) => onClick(`children.${cIndex}${componentPath !== '' ? `.${componentPath}` : ''}`)}
            />
          ))}
          {block.section === 'imageBlock' && block.options?.description &&
            <Typography size='subText' style={{zIndex: depth, padding: 5, fontStyle: 'italic', textAlign: 'center'}}>
              {block.options.description}
            </Typography>
          }
          {block.section === 'navigationElement' &&
            <div style={{
              zIndex: depth,
              pointerEvents: 'auto',
              padding: 10,
              borderStyle: 'solid',
              borderColor: 'red',
              borderRadius: 5,
              borderWidth: 1,
            }}>
              <Typography size='title' style={{textAlign: 'center'}}>
                {block.text}
              </Typography>
              <Typography style={{textAlign: 'center'}}>
                This element will be hidden on live site and used to build the navigation menu.  When the '{block.text}' item in the navigation menu is clicked it will scroll to this position.
              </Typography>
            </div>
          }
        </div>
      </div>
    );
  } else if(block.type === 'Typography') {
    return (
      <Typography
        size={block?.options?.size}
        style={{
          zIndex: depth,
          pointerEvents:'auto',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'red',
          ...block?.options?.style
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        {block.text}
      </Typography>
    );
  } else if (block.type === 'RichText') {
    return (
      <Typography
        style={{
          zIndex: depth,
          pointerEvents: 'auto',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'red',
          ...block.options.style
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        <ReactMarkdown children={block.markdown} />
      </Typography>
    );
  } else if(block.type === 'Image') {
    if(!block?.options?.src) {
      return (
        <GridContainer
          style={{
            zIndex: depth,
            pointerEvents: 'auto',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'red',
            ...block.options?.style,
          }}
          onClick={(event) => {
            event.stopPropagation();
            onClick('');
          }}
        >
          <GridCell center centerWeights={{top: 1, bottom: 1}} style={{background: '#c6c6c6', minWidth: 200, minHeight: 200}}>
            <Typography style={{textAlign: 'center'}}>
              No image Selected
            </Typography>
          </GridCell>
        </GridContainer>
      );
    } else {
      return (
        <img
          src={block?.options?.src}
          alt={block?.options?.alt}
          style={{
            zIndex: depth,
            pointerEvents:'auto',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'red',
            ...block?.options?.style
          }}
          onClick={(event) => {
            event.stopPropagation();
            onClick('');
          }}
        />
      );
    }
  } else if (block.type === 'Gallery') {
    return (
      <div
        style={{
          zIndex: depth,
          pointerEvents: 'auto',
          borderStyle:'solid',
          borderWidth: 1,
          borderColor: 'red',
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        <Gallery
          images={block.options.images}
          previewedImage={undefined}
          processing={false}
          setPreviewedImage={(value) => {}}
          setProcessing={(value) => {}}
        />
      </div>

    );
  } else if(block.type === 'Video') {
    if(block?.options?.video?.platform === 'youtube') {
      return (
        <div
          style={{
            zIndex: depth,
            pointerEvents: 'auto',
            borderStyle:'solid',
            borderWidth: 1,
            borderColor: 'red',
          }}
          onClick={(event) => {
            event.stopPropagation();
            onClick('');
          }}
        >
          <YoutubeEmbed youtubeId={block?.options?.video?.youtubeId} preview={true}/>
        </div>
      );
    } else if(block?.options?.video?.platform === 'vimeo') {
      return (
        <div
          style={{
            zIndex: depth,
            pointerEvents: 'auto',
            borderStyle:'solid',
            borderWidth: 1,
            borderColor: 'red',
          }}
          onClick={(event) => {
            event.stopPropagation();
            onClick('');
          }}
        >
          <VimeoEmbed vimeoId={block?.options?.video?.vimeoId} preview={true} onClick={() => onClick('')}/>
        </div>
      );
    } else {
      return (
        <div style={{pointerEvents: 'none'}}/>
      )
    }
  } else {
    return (
      <div style={{pointerEvents: 'none'}}/>
    )
  }
}

AdvancedEdit.propTypes = {
  block: PropTypes.shape({}),
  location: PropTypes.string,
  depth: PropTypes.number,
  onClick: PropTypes.func,
}

export default AdvancedEdit;
