import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {YoutubeEmbed} from '../../../../../components/video';

import {paletteData, Button, Typography, Dialog, Input, TextArea, Select} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function EditArticleCoreSubPanel({
    focusedArticle,
    handleChangeFocusedArticle,
    processing,
    setProcessing,
  }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Video Library
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              palette="secondary" size='small'
              onClick={() => {
                setDialogOpen(true);
                setDialogData({
                  type: 'addVideo',
                  title: 'Add a video to your library',
                  preview: undefined,
                  value: {
                    platform: 'youtube',
                    link: '',
                    title: '',
                    tags: '',
                    description: '',
                  }
                });
              }}
            >
              Add video
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {(!focusedArticle.videoLibrary || focusedArticle.videoLibrary.length === 0) &&
        <div style={{margin: 5}}>
          <Typography>
            Library empty
          </Typography>
        </div>
      }
      {focusedArticle.videoLibrary?.length > 0 &&
        <div style={{margin: 5}}>
          {focusedArticle.videoLibrary.map((v, vIndex) => (
            <div key={vIndex}>
              <GridContainer>
                <GridCell weight={1} style={{padding: 10}}>
                  {v.platform === 'youtube' &&
                    <YoutubeEmbed youtubeId={v.youtubeId} />
                  }
                </GridCell>
                <GridCell weight={2} style={{padding: 10}}>
                  <Typography size='title'>
                    {v.title}
                  </Typography>
                  <Typography style={{color: '#c6c6c6'}}>
                    {v.tags}
                  </Typography>
                  <br/>
                  <Typography style={{whiteSpace: 'pre-wrap'}}>
                    {v.description}
                  </Typography>
                </GridCell>
              </GridContainer>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogData({
                    type: 'editVideo',
                    title: 'Edit a video in your library',
                    index: vIndex,
                    value: {
                      ...v,
                      link: `https://www.youtube.com/watch?v=${v.youtubeId}`,
                    }
                  });
                  setDialogOpen(true);
                }}
              >
                Edit Video
              </Button>
            </div>
          ))}
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {(dialogData?.type === 'addVideo' || dialogData?.type === 'editVideo') &&
            <div style={{padding: 10}}>
              <Select
                name="article"
                palette='secondary'
                label="Platform"
                type="text"
                value={dialogData?.value?.platform || ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      platform: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              >
                <option value="youtube">Youtube</option>
              </Select>
              <Input
                name="link"
                palette='secondary'
                label="Link"
                type="text"
                value={dialogData?.value?.link || ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      link: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
              <Input
                name="title"
                palette='secondary'
                label="Title"
                type="text"
                value={dialogData?.value?.title || ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      title: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
              <Input
                name="tags"
                palette='secondary'
                label="Tags"
                type="text"
                value={dialogData?.value?.tags || ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      tags: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
              <TextArea
                name="description"
                palette='secondary'
                label="Description"
                type="text"
                value={dialogData?.value?.description || ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      description: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
            </div>
          }

          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'addVideo' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    const item = {
                      platform: dialogData?.value?.platform,
                      title: dialogData?.value?.title,
                      tags: dialogData?.value?.tags,
                      description: dialogData?.value?.description,
                    };
                    if(item.platform === 'youtube') {
                      item.youtubeId = (dialogData?.value?.link || '').replace('https://www.youtube.com/watch?v=', '');
                    }
                    const videoLibraryProcessed = focusedArticle.videoLibrary || [];
                    videoLibraryProcessed.push(item);
                    handleChangeFocusedArticle('videoLibrary', videoLibraryProcessed);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Add Video
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'editVideo' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    const videoLibraryProcessed = focusedArticle.videoLibrary || [];
                    videoLibraryProcessed.splice(dialogData?.index, 1);
                    handleChangeFocusedArticle('videoLibrary', videoLibraryProcessed);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Delete Video
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'editVideo' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    const item = {
                      platform: dialogData?.value?.platform,
                      title: dialogData?.value?.title,
                      tags: dialogData?.value?.tags,
                      description: dialogData?.value?.description,
                    };
                    if(item.platform === 'youtube') {
                      item.youtubeId = (dialogData?.value?.link || '').replace('https://www.youtube.com/watch?v=', '');
                    }
                    const videoLibraryProcessed = focusedArticle.videoLibrary || [];
                    videoLibraryProcessed.splice(dialogData?.index, 1, item);
                    handleChangeFocusedArticle('videoLibrary', videoLibraryProcessed);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Edit Video
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditArticleCoreSubPanel.propTypes = {
  focusedArticle: PropTypes.shape({}),
  handleChangeFocusedArticle: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
};

export default EditArticleCoreSubPanel;
