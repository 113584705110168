import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {paletteData, Button, Typography, Dialog} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function EditArticlePermissionsSubPanel({
    focusedArticle,
    handleChangeFocusedArticle,
    processing,
    handleUploadImage,
    setProcessing,
  }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Permissions to view article
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              palette="secondary" size='small'
              onClick={() => {
                setDialogOpen(true);
                setDialogData({
                  type: 'addAuthorisation',
                  title: 'Add authorisation',
                  message: 'Choose who can see this article',
                });
              }}
            >
              Add authorisation
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {(!focusedArticle.authorisation || focusedArticle.authorisation.length === 0) &&
        <div style={{margin: 5}}>
          <Typography>
            No users authroised
          </Typography>
        </div>
      }
      {focusedArticle.authorisation?.length > 0 &&
        <div style={{margin: 5}}>
          {focusedArticle.authorisation.map((a, aIndex) => (
            <Typography
              key={aIndex}
              style={{display: 'inline', cursor: 'pointer'}}
              onClick={() => {
                const tempAuthorisation = focusedArticle.authorisation;
                tempAuthorisation.splice(aIndex, 1);
                handleChangeFocusedArticle('authorisation', tempAuthorisation);
              }}
            >
              {a}{aIndex !== (focusedArticle.authorisation.length - 1) ? ', ' : ''}
            </Typography>
          ))}
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
       >
         <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
           <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
             {dialogData?.title}
           </Typography>
         </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'addAuthorisation' &&
            <div style={{padding: 10}}>
              {[
                {text: 'Public', value: 'public'},
                {text: 'Global Role - Super Admin', value: 'global.super-admin'},
                {text: 'Global Role - Admin', value: 'global.admin'},
                {text: 'Global Role - Worker', value: 'global.worker'},
                {text: 'Organisational Role - Owner', value: 'organisational.owner'},
                {text: 'Organisational Role - Admin', value: 'organisational.admin'},
                {text: 'Organisational Role - Worker', value: 'organisational.worker'},
              ].map((a, aIndex) => (
                <div
                  key={aIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    const tempAuthorisation = focusedArticle.authorisation || [];
                    let testAuthorisation = true;
                    for(let i = 0; i < tempAuthorisation.length; i += 1) {
                      if(tempAuthorisation[i] === a.value) {
                        testAuthorisation = false;
                      }
                    }
                    if(testAuthorisation) {
                      tempAuthorisation.push(a.value);
                      tempAuthorisation.sort();
                      handleChangeFocusedArticle('authorisation', tempAuthorisation);
                    }
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  {a.text}
                </div>
              ))}
            </div>
          }

          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditArticlePermissionsSubPanel.propTypes = {
  focusedArticle: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedArticle: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleUploadImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditArticlePermissionsSubPanel;
