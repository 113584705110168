const generateImageBlock = ({src, alt, description, styleContainer, styleImage}) => {
  const section = {
    section: 'imageBlock',
    type: 'div',
    options: {
      style: {
        ...styleContainer,
      },
      description: description || '',
    },
    children: [
      {
        type: 'Image',
        options: {
          src: src || '',
          alt: alt || '',
          style: {
            width: '100%',
            ...(styleImage || {}),
          },
        },
      },
    ],
  };
  return section;
};

export default generateImageBlock;
