import {generateImageBlock} from '../../../';

const generateSplashBlock = ({backgroundId, logoId, title, tagline}) => {
  const section = {
    section: 'splashBlock',
    type: 'GridContainer',
    options: {
      style: {
        backgroundImage: backgroundId ? `url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,w_2048,q_auto:good/${backgroundId}")` : undefined,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        height: '100%',
      },
    },
    children: [
      {
        type: 'GridCell',
        options: {
          style: {},
          weight: 1,
          center: true,
          centerWeights: {top: 1, bottom: 2},
        },
        children: [],
      }
    ],
  };

  if(logoId) {
    const imageBlock = generateImageBlock({
      src: `https://res.cloudinary.com/taitokerau-tatou/image/upload/q_auto:good,c_fill,w_2048/${logoId}`,
      alt: 'logo',
      description: '',
      styleContainer: {
        width: '100%',
        maxWidth: 400,
        margin: 'auto',
      },
    });
    section.children[0].children.push(imageBlock);
  }
  section.children[0].children.push({
    type: 'Typography',
    options: {
      style: {
        color: 'white',
        textShadow: '2px 2px black',
        textAlign: 'center',
      },
      size: 'heading',
    },
    text: title,
  });
  section.children[0].children.push({
    type: 'Typography',
    options: {
      style: {
        color: 'white',
        textShadow: '2px 2px black',
        textAlign: 'center',
      },
      size: 'subHeading',
    },
    text: tagline,
  });

  return section;
};

export default generateSplashBlock;
