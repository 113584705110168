import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {paletteData} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

function Fab({children, onClick, palette, style, disabled, size, href, target, rel}) {
  const [styleData] = useState({
    backgroundColor: palette ? paletteData[palette].standard.background : paletteData['blank'].standard.background,
    color: palette ? paletteData[palette].standard.foreground : paletteData['blank'].standard.foreground,
    border: 'none',
    outline: 'none',
    textDecoration: 'none',
    display: 'block',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: '0.3s',
    fontSize: size === 'small' ? '.8em' : '1em',
    textAlign: 'center',
    ...style,
  });

  const disabledButton =
  <GridContainer
    onClick={() => {}}
    style={{
      backgroundColor: '#c6c6c6',
      color: '#242424',
      border: 'none',
      outline: 'none',
      textDecoration: 'none',
      display: 'block',
      borderRadius: '50%',
      fontSize: size === 'small' ? '.8em' : '1em',
      textAlign: 'center',
      ...style,
    }}
  >
    <GridCell
      weight={1}
      center
      style={{
        width: size === 'small' ? 35 : 50,
        height: size === 'small' ? 35 : 50,
      }}
    >
      {children}
    </GridCell>
  </GridContainer>;

  const enabledButton =
  <GridContainer
    onClick={() => {
      if(onClick) {
        onClick();
      }
    }}
    style={styleData}
  >
    <GridCell
      weight={1}
      center
      style={{
        width: size === 'small' ? 35 : 50,
        height: size === 'small' ? 35 : 50,
      }}
    >
      {children}
    </GridCell>
  </GridContainer>;

  return (
          disabled ? disabledButton :
          href ? <a href={href} target={target} rel={rel} style={{textDecoration: 'none'}}>{enabledButton}</a> : enabledButton
  );
}

Fab.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape({}),
  palette: PropTypes.string,
  onClick: PropTypes.func,
};

export default Fab;
