import PropTypes from 'prop-types';

function Hidden({children, breakpoint}) {
  return (
      {...children,
        props: {
          ...children.props,
          className: breakpoint,
        }
      }
  );
}

Hidden.propTypes = {
  children: PropTypes.node,
  breakpoint: PropTypes.string,
};

export default Hidden;
