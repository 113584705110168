import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import SearchStore from '../../../../components/search/store';
import {Typography, TextArea, Button, Dialog, ProgressBar, Input, Select, paletteData} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';
import Calendar from '../../../../components/calendar';
import Hidden from '../../../../components/hidden';

import apiRequest from '../../../../tools/apiRequest';
import {setSearch, setOrganisations, addOrganisations, replaceOrganisation, setBookings, replaceBooking, addBooking, setBookingCalendars, addBookingCalendars, addBookingCalendar, replaceBookingCalendar, removeBookingCalendar, removeBookings, pruneBookingFromOrder, replaceOrder} from '../../../../store/reducers/lists/actions.js';
import {setOrganisationReference, setBookingReference, setBookingCalendarReference, clearBookingCalendarReference, setBookingCalendarReferences, clearBookingReference, setBookingReferences, pruneBookingFromOrderReference, setOrderReference, setProductReference} from '../../../../store/reducers/references/actions.js';
import {setApiToken} from '../../../../store/reducers/auth/actions.js';
import handleErrorMessage from '../../../../tools/handleErrorMessage';
import {
  times,
  bookingTimes,

  createOffset,
  processTimesRemoveOffeset,
  processTimesAddOffeset,
  convertDateToText,
  processBookings,
  generateFirstOfMonth,
  generateLastOfMonth,
  generateStartOfWeek,
  generateEndOfWeek,
} from '../../../../tools/calendarTimes';

function WorkflowAppointments({
  me,
  auth,
  setApiToken,
  queryLimit,

  setSearch,
  search,

  bookings,
  bookingReferences,
  setBookingReference,
  setBookings,
  addBooking,
  replaceBooking,
  removeBookings,
  pruneBookingFromOrder,
  pruneBookingFromOrderReference,

  bookingCalendars,
  bookingCalendarReferences,
  setBookingCalendars,
  addBookingCalendars,
  addBookingCalendar,
  replaceBookingCalendar,
  removeBookingCalendar,
  setBookingCalendarReference,
  setBookingCalendarReferences,
  clearBookingCalendarReference,

  organisations,
  organisationReferences,
  setOrganisationReference,
  setOrganisations,
  addOrganisations,
  replaceOrganisation,

  orders,
  orderReferences,
  setOrderReference,
  replaceOrder,

  products,
  productReferences,
  setProductReference,
}) {
  // page state
  const [focusedOrganisation, setFocusedOrganisation] = useState(undefined);
  const [selectedCalendar, setSelectedCalendar] = useState('all');
  const [refreshCalendar, setRefreshCalendar] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const [startDate, setStartDate] = useState(convertDateToText(generateFirstOfMonth(new Date())));
  const [endDate, setEndDate] = useState(convertDateToText(generateLastOfMonth(new Date())));
  const [weekDisplay, setWeekDisplay] = useState(convertDateToText(generateStartOfWeek(new Date())));
  const [monthDisplay, setMonthDisplay] = useState((generateFirstOfMonth(new Date())).getMonth());
  const [yearDisplay, setYearDisplay] = useState((generateFirstOfMonth(new Date())).getFullYear());
  const [displayDatesBy, setDisplayDatesBy] = useState('month');
  const [bookingsMap, setBookingsMap] = useState(undefined);

  const validateTimes = (t) => {
    if(t.length === 0) {
      return false;
    }
    for(let i = 0; i < t.length; i += 1) {
      if(!t[i].date || !t[i].start || !t[i].blocks) {
        return false;
      }
    }
    return true;
  }

  const filterAvailableTimes = (calendar, offset) => {
    let shift = Math.floor(offset / 15);
    while (shift > 95) {
      shift -= 96;
    }
    while (shift < -95) {
      shift += 96;
    }

    let minimumStartTime = calendar.minimumStartTime + shift;
    let maximumEndTime = calendar.maximumEndTime + shift;

    if(minimumStartTime > 95) {
      minimumStartTime -= 96;
    }
    if(maximumEndTime > 95) {
      maximumEndTime -= 96;
    }

    if(minimumStartTime < 0) {
      minimumStartTime += 96;
    }
    if(maximumEndTime < 0) {
      maximumEndTime += 96;
    }

    return times.filter((t, tIndex) => {
      if(minimumStartTime < maximumEndTime) {
        return t.value >= minimumStartTime && t.value <= maximumEndTime;
      } else {
        return t.value >= minimumStartTime || t.value <= maximumEndTime;
      }
    });
  }

  const checkBlockAvailability = (calendar, time, bookingId, bookingDate, bookingTime, bookingDuration, offset, bookingTimes) => {
    const duplicationCheck = (bookingTimes || []).filter((t, tIndex) => {
      return t.date === bookingDate;
    });
    if(duplicationCheck.length > 0) {
      const duplicationCheck2 = duplicationCheck.filter((t, tIndex) => {
        return time >= parseInt(t.start) && time < (parseInt(t.start) + parseInt(t.blocks));
      });
      if(duplicationCheck2.length > 1) {
        return 'red';
      }
    }

    let shift = Math.floor(offset / 15);
    let timeWithOffset = time - shift;
    let dateWithOffset = bookingDate;
    // const leadinTest = Math.ceil((new Date(`${bookingDate} ${times[bookingTime || 0].blockStart}`) - new Date()) / (1000 * 60 * 60 * 24));

    if(timeWithOffset > 95) {
      let dateShift = 0;
      while(timeWithOffset > 95) {
        timeWithOffset -= 96;
        shift += 96;
        dateShift += 1;
      }
      const dateTemp = new Date(`${bookingDate} 00:00 UTC`);
      dateTemp.setDate(dateTemp.getDate() + dateShift);
      dateWithOffset = `${dateTemp.getFullYear()}-${(dateTemp.getMonth() + 1) < 10 ? '0' : ''}${dateTemp.getMonth() + 1}-${dateTemp.getDate() < 10 ? '0' : ''}${dateTemp.getDate()}`;
    } else if(timeWithOffset < 0) {
      let dateShift = 0;
      while(timeWithOffset < 0) {
        timeWithOffset += 96;
        shift -= 96;
        dateShift -= 1;
      }
      const dateTemp = new Date(`${bookingDate} 00:00 UTC`);
      dateTemp.setDate(dateTemp.getDate() + dateShift);
      dateWithOffset = `${dateTemp.getFullYear()}-${(dateTemp.getMonth() + 1) < 10 ? '0' : ''}${dateTemp.getMonth() + 1}-${dateTemp.getDate() < 10 ? '0' : ''}${dateTemp.getDate()}`;
    }

    const calendarDate = new Date(`${dateWithOffset} ${times[timeWithOffset || 0].blockStart}`);
    const day = calendarDate.getDay();
    let minimumStartTime = calendar.minimumStartTime + shift;
    let maximumEndTime = calendar.maximumEndTime + shift;
    if(minimumStartTime > 95) {
      minimumStartTime -= 96;
    } else if(maximumEndTime > 95) {
      maximumEndTime -= 96;
    }

    if(minimumStartTime < 0) {
      minimumStartTime += 96;
    } else if(maximumEndTime < 0) {
      maximumEndTime += 96;
    }

    // booking time coinsides with block
    if(bookingTime !== undefined && bookingDuration !== undefined) {
      if(time >= bookingTime && timeWithOffset <= (parseInt(bookingTime) + parseInt(bookingDuration) -1)) {
        // check if outside buisness hours version 1
        if(minimumStartTime < maximumEndTime && (time < minimumStartTime || time > maximumEndTime)) {
          return '#c6c6c6';
        }
        // check if outside buisness hours version 2
        if(minimumStartTime > maximumEndTime && (time < minimumStartTime && time > maximumEndTime)) {
          return '#c6c6c6';
        }
        // check if buisness hours is invalid
        if(minimumStartTime === maximumEndTime) {
          return 'red';
        }
        // check if buisness day
        if(!calendar.availableDays.sunday && day === 0) return 'red';
        if(!calendar.availableDays.monday && day === 1) return 'red';
        if(!calendar.availableDays.tuesday && day === 2) return 'red';
        if(!calendar.availableDays.wednesday && day === 3) return 'red';
        if(!calendar.availableDays.thursday && day === 4) return 'red';
        if(!calendar.availableDays.friday && day === 5) return 'red';
        if(!calendar.availableDays.saturday && day === 6) return 'red';
        // does not need leadin test as admin can ignore
        /*
        if(leadinTest < 7) {
          return 'red';
        }
        */
        // check other apointments
        if(
          bookingsMap &&
          bookingsMap[dateWithOffset] &&
          bookingsMap[dateWithOffset][timeWithOffset] &&
          bookingsMap[dateWithOffset][timeWithOffset].bookings[calendar._id]?.filter((bId) => {
            return bId !== bookingId;
          })?.length > 0
        ) {
          return 'red';
        }
        return 'green';
      }
    }

    // booking time does not coinside with block
    // check if outside buisness hours version 1
    if(minimumStartTime < maximumEndTime && (time < minimumStartTime || time > maximumEndTime)) {
      return '#c6c6c6';
    }
    // check if outside buisness hours version 2
    if(minimumStartTime > maximumEndTime && (time < minimumStartTime && time > maximumEndTime)) {
      return '#c6c6c6';
    }
    // check if buisness hours is invalid
    if(minimumStartTime === maximumEndTime) {
      return '#c6c6c6';
    }
    // check if buisness day
    if(!calendar.availableDays.sunday && day === 0) return '#c6c6c6';
    if(!calendar.availableDays.monday && day === 1) return '#c6c6c6';
    if(!calendar.availableDays.tuesday && day === 2) return '#c6c6c6';
    if(!calendar.availableDays.wednesday && day === 3) return '#c6c6c6';
    if(!calendar.availableDays.thursday && day === 4) return '#c6c6c6';
    if(!calendar.availableDays.friday && day === 5) return '#c6c6c6';
    if(!calendar.availableDays.saturday && day === 6) return '#c6c6c6';
    // does not need leadin test as admin can ignore
    /*
    if(leadinTest < 7) {
      return '#565656';
    }
    */
    // check other apointments
    if(
      bookingsMap &&
      bookingsMap[dateWithOffset] &&
      bookingsMap[dateWithOffset][timeWithOffset] &&
      bookingsMap[dateWithOffset][timeWithOffset].bookings[calendar._id]?.filter((bId) => {
        return bId !== bookingId;
      })?.length > 0
    ) {
      return '#565656';
    }
    return '#eafedd';
  }

  const fetchDays = (daysToFetch) => {
    const bookingsProcessed = {};
    const bookingsMapProcessed = {};
    for(let i = 0; i < daysToFetch.length; i += 1) {
      if(!bookings || !bookings[daysToFetch[i]] || bookings[daysToFetch[i]].loaded !== true) {
        bookingsProcessed[daysToFetch[i]] = {
          loaded: true,
          list: [],
        }
      } else {
        for(let j = 0; j < bookings[daysToFetch[i]].list.length; j += 1) {
          const start = parseInt(bookings[daysToFetch[i]].list[j].time.start);
          const blocks = parseInt(bookings[daysToFetch[i]].list[j].time.blocks);
          if(!bookingsMapProcessed[daysToFetch[i]]) {
            bookingsMapProcessed[daysToFetch[i]] = {};
          }
          for(let k = 0; k < blocks; k += 1) {
            if(!bookingsMapProcessed[daysToFetch[i]][start + k]) {
              bookingsMapProcessed[daysToFetch[i]][start + k] = {
                booked: false,
                bookings: {},
              }
            }
            if(!bookingsMapProcessed[daysToFetch[i]][start + k].bookings[bookings[daysToFetch[i]].list[j].bookingCalendarId]) {
              bookingsMapProcessed[daysToFetch[i]][start + k].bookings[bookings[daysToFetch[i]].list[j].bookingCalendarId] = [];
            }
            bookingsMapProcessed[daysToFetch[i]][start + k].booked = true;
            bookingsMapProcessed[daysToFetch[i]][start + k].bookings[bookings[daysToFetch[i]].list[j].bookingCalendarId].push(bookings[daysToFetch[i]].list[j]._id);
          }
        }
      }
    }
    if(Object.keys(bookingsProcessed).length > 0) {
      setProcessing(true);
      const query = {
        organisationId: focusedOrganisation._id,
        'times.date': {$in: Object.keys(bookingsProcessed)},
      };
      apiRequest({type: 'get', action: 'bookings', data: {query, sort: {name: 1}, skip: 0}})
      .then((result) => {
        const bookingsTemp = processBookings(result.data || []);
        const bookingsKeys = Object.keys(bookingsTemp);
        for(let i = 0; i < bookingsKeys.length; i += 1) {
          if(bookingsProcessed[bookingsKeys[i]]) {
            bookingsProcessed[bookingsKeys[i]].list = bookingsTemp[bookingsKeys[i]].list;
            for(let j = 0; j < bookingsTemp[bookingsKeys[i]].list.length; j += 1) {
              const start = parseInt(bookingsTemp[bookingsKeys[i]].list[j].time.start);
              const blocks = parseInt(bookingsTemp[bookingsKeys[i]].list[j].time.blocks);
              if(!bookingsMapProcessed[bookingsKeys[i]]) {
                bookingsMapProcessed[bookingsKeys[i]] = {};
              }
              for(let k = 0; k < blocks; k += 1) {
                if(!bookingsMapProcessed[bookingsKeys[i]][start + k]) {
                  bookingsMapProcessed[bookingsKeys[i]][start + k] = {
                    booked: false,
                    bookings: {},
                  }
                }
                if(!bookingsMapProcessed[bookingsKeys[i]][start + k].bookings[bookingsTemp[bookingsKeys[i]].list[j].bookingCalendarId]) {
                  bookingsMapProcessed[bookingsKeys[i]][start + k].bookings[bookingsTemp[bookingsKeys[i]].list[j].bookingCalendarId] = [];
                }
                bookingsMapProcessed[bookingsKeys[i]][start + k].booked = true;
                bookingsMapProcessed[bookingsKeys[i]][start + k].bookings[bookingsTemp[bookingsKeys[i]].list[j].bookingCalendarId].push(bookingsTemp[bookingsKeys[i]].list[j]._id);
              }
            }
          }
        }
        setBookingsMap({
          ...bookingsMap,
          ...bookingsMapProcessed
        });
        setBookingReferences(result.data);
        setBookings(bookingsProcessed);
        setProcessing(false);
      }).catch((error) => {
        console.log(handleErrorMessage(error));
      });
    } else {
      setBookingsMap({
        ...bookingsMap,
        ...bookingsMapProcessed
      });
    }
  }

  const onClickBooking = async (booking) => {
    // break deep references
    const bookingProcessed = Object.assign({}, (bookingReferences[booking._id] || {}));
    const bookingTimesProcessed = [];
    const availableTimes = {};
    for(let i = 0; i < bookingProcessed.times.length; i += 1) {
      // break deep references
      const offset = createOffset(bookingCalendarReferences[booking.bookingCalendarId], bookingProcessed.times[i].date);
      const time = Object.assign({}, (bookingProcessed.times[i] || {}));
      bookingTimesProcessed.push({
        ...time,
        offset,
      });
      availableTimes[bookingProcessed.times[i].date] = filterAvailableTimes(bookingCalendarReferences[booking.bookingCalendarId], offset);
    }
    bookingProcessed.times = processTimesAddOffeset(bookingCalendarReferences[booking.bookingCalendarId], bookingTimesProcessed);

    // fetch unloaded days
    const daysToFetch = [];
    for(let i = 0; i < bookingProcessed.times.length; i += 1) {
      daysToFetch.push(bookingProcessed.times[i].date);
    }
    if(daysToFetch.length > 0) {
      fetchDays(daysToFetch);
    }

    setProcessing(true);
    console.log('boop');
    if(bookingProcessed.orderId && !orderReferences[bookingProcessed.orderId]) {
      console.log('flop');
      await apiRequest({type: 'get', action: `orders/${bookingProcessed.orderId}`})
      .then((result) => {
        console.log(result);
        setOrderReference(result.data.order);
      }).catch((error) => {
        console.log(handleErrorMessage(error));
      });
    }
    if(bookingProcessed.productId && !productReferences[bookingProcessed.productId]) {
      await apiRequest({type: 'get', action: `products/${bookingProcessed.productId}`})
      .then((result) => {
        setProductReference(result.data.product);
      }).catch((error) => {
        console.log(handleErrorMessage(error));
      });
    }

    console.log('woop');
    setProcessing(false);
    setDialogData({
      type: 'editBooking',
      title: 'Edit Booking',
      value: {
        ...bookingProcessed,
        availableTimes,
      },
    });
    setDialogOpen(true);
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Appointments
            </Typography>
          </GridCell>
          {focusedOrganisation &&
            <GridCell>
              <Button palette='secondary' onClick={() => {
                setFocusedOrganisation(undefined);
              }}>
                Back
              </Button>
            </GridCell>
          }
          {focusedOrganisation &&
            <GridCell style={{marginLeft: 5}}>
              <Button palette='secondary' onClick={() => {
                setDialogData({
                  type: 'addBooking',
                  title: 'Add Booking',
                  value: {times: [{}]},
                });
                setDialogOpen(true);
              }}>
                Add Booking
              </Button>
            </GridCell>
          }
        </GridContainer>
      </div>
      {!focusedOrganisation &&
        <GridContainer style={{flex: 1}}>
          <GridCell weight={1} center={true} centerWeights={{top: 1, bottom: 3}} style={{textAlign: 'center'}}>
            <Typography size='title'>
              No organisation selected
            </Typography>
            <br/>
            <GridContainer>
              <GridCell weight={1}/>
              <GridCell style={{padding: 10}}>
                <Button palette='primary' onClick={() => {
                  setDialogData({
                    type: 'changeOrganisation',
                    title: 'Change Organisation',
                    value: {},
                  });
                  setDialogOpen(true);
                }}>
                  Choose an organisation
                </Button>
              </GridCell>
              <GridCell weight={1}/>
            </GridContainer>
          </GridCell>
        </GridContainer>
      }
      {focusedOrganisation &&
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <GridContainer>
            {(displayDatesBy === 'day') &&
              <GridCell>
                <Input
                  name="startDate"
                  palette='secondary'
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(value) => {
                    setStartDate(value);
                    setEndDate(value);
                    setRefreshCalendar(true);
                  }}
                />
              </GridCell>
            }
            {(displayDatesBy === 'week') &&
              <GridCell>
                <Input
                  name="weekDisplay"
                  palette='secondary'
                  label="Week of"
                  type="date"
                  value={weekDisplay}
                  onChange={(value) => {
                    setWeekDisplay(value);
                    setStartDate(convertDateToText(generateStartOfWeek(value)));
                    setEndDate(convertDateToText(generateEndOfWeek(value)));
                    setRefreshCalendar(true);
                  }}
                />
              </GridCell>
            }
            {(displayDatesBy === 'month') &&
              <Select
                forceShrink
                style={{minWidth: 100}}
                name="monthDisplay"
                palette='secondary'
                label="Month"
                type="text"
                value={monthDisplay}
                onChange={(value) => {
                  setMonthDisplay(value);
                  let sd = new Date(startDate);
                  sd.setMonth(value);
                  sd = (generateFirstOfMonth(sd));
                  setStartDate(convertDateToText(sd));
                  setEndDate(convertDateToText(generateLastOfMonth(sd)));
                  setRefreshCalendar(true);
                }}
              >
                <option value={0}>January</option>
                <option value={1}>February</option>
                <option value={2}>March</option>
                <option value={3}>April</option>
                <option value={4}>May</option>
                <option value={5}>June</option>
                <option value={6}>July</option>
                <option value={7}>August</option>
                <option value={8}>September</option>
                <option value={9}>October</option>
                <option value={10}>November</option>
                <option value={11}>December</option>
              </Select>
            }
            {displayDatesBy === 'month' &&
              <div>
                <Input
                  style={{width: 100}}
                  name="yearDisplay"
                  palette='secondary'
                  label="Year"
                  type="number"
                  value={yearDisplay}
                  onChange={(value) => {
                    setYearDisplay(value);
                    if((value || 0).toString().length >= 4) {
                      const sd = new Date(startDate);
                      sd.setFullYear(value);
                      setStartDate(convertDateToText(sd));

                      const ed = new Date(endDate);
                      ed.setFullYear(value);
                      setEndDate(convertDateToText(ed));
                    }
                    setRefreshCalendar(true);
                  }}
                />
                {(yearDisplay.toString() || 0).length < 4 &&
                  <Typography size='subText' style={{marginLeft: 5, color: 'red'}}>
                    Invalid Year
                  </Typography>
                }
              </div>
            }

            {(displayDatesBy === 'dateRange') &&
              <GridCell>
                <Input
                  name="startDate"
                  palette='secondary'
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(value) => {
                    setStartDate(value);
                    setRefreshCalendar(true);
                  }}
                />
              </GridCell>
            }
            {displayDatesBy === 'dateRange' &&
              <GridCell>
                <Input
                  name="endDate"
                  palette='secondary'
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(value) => {
                    setEndDate(value);
                    setRefreshCalendar(true);
                  }}
                />
              </GridCell>
            }
            <GridCell weight={1}/>
            <GridCell>
              <Select
                forceShrink
                style={{minWidth: 150}}
                name="selectedCalendar"
                palette='secondary'
                label="Calendar"
                type="text"
                value={selectedCalendar}
                onChange={(value) => setSelectedCalendar(value)}
              >
                <option value='all'>All</option>
                {bookingCalendars?.length > 0 && bookingCalendars.map((c, cIndex) => (
                  <option key={cIndex} value={c._id}>{c.name}</option>
                ))}
              </Select>
            </GridCell>
            <GridCell>
              <Select
                forceShrink
                style={{minWidth: 150}}
                name="displayDatesBy"
                palette='secondary'
                label="Appointment Range"
                type="text"
                value={displayDatesBy}
                onChange={(value) => {
                  if(value === 'day') {
                    let sd = undefined;
                    if(displayDatesBy === 'week') {
                      sd = weekDisplay;
                    } else {
                      sd = startDate;
                    }
                    setStartDate(sd);
                    setEndDate(sd);
                  } else if(value === 'week') {
                    setWeekDisplay(startDate);
                    setStartDate(convertDateToText(generateStartOfWeek(startDate)));
                    setEndDate(convertDateToText(generateEndOfWeek(startDate)));
                  } else if(value === 'month') {
                    let sd = undefined;
                    if(displayDatesBy === 'week') {
                      sd = generateFirstOfMonth(weekDisplay);
                    } else {
                      sd = generateFirstOfMonth(startDate);
                    }
                    setMonthDisplay((sd).getMonth());
                    setYearDisplay((sd).getFullYear());

                    setStartDate(convertDateToText(sd));
                    setEndDate(convertDateToText(generateLastOfMonth(sd)));
                  }
                  setDisplayDatesBy(value);
                  setRefreshCalendar(true);
                }}
              >
                <option value='day'>Day</option>
                <option value='week'>Week</option>
                <option value='month'>Month</option>
                <option value='dateRange'>Date Range</option>
              </Select>
            </GridCell>
          </GridContainer>
          <div style={{padding: 5}}>
            <Calendar
              processing={processing}
              setProcessing={setProcessing}
              startDate={new Date(startDate)}
              endDate={new Date(endDate)}
              focusedOrganisation={focusedOrganisation}
              selectedCalendar={selectedCalendar}
              refreshCalendar={refreshCalendar}
              setRefreshCalendar={setRefreshCalendar}
              onClickBooking={(booking) => onClickBooking(booking)}
              onClickDay={(date) => {
                setStartDate(date);
                setEndDate(date);
                setDisplayDatesBy('day');
              }}
            />
          </div>
        </div>
      }
      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'changeOrganisation' &&
            <div style={{padding: 10}}>
              <SearchStore
                databaseArea='organisations'
                processing={processing}
                setProcessing={setProcessing}
                expandSearch={dialogData.value.expandSearch}
                setExpandSearch={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...dialogData.value,
                      expandSearch: value,
                    }
                  });
                }}
              />
              {(!organisations || processing) &&
                <ProgressBar palette='secondary'/>
              }
              {organisations && organisations.map((o, oIndex) => (
                <div
                  key={oIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!organisationReferences || !organisationReferences[o._id]) {
                      setOrganisationReference(o);
                    }
                    setFocusedOrganisation(o);
                    if(
                      !bookingCalendars ||
                      search?.bookingCalendars?.text ||
                      search?.bookingCalendars?.query?.organisationId !== o._id ||
                      Object.keys(search?.bookingCalendars?.query || {}).length !== 1 ||
                      search?.bookingCalendars?.data?.usesQueryLimit
                    ) {
                      setProcessing(true);
                      const query = {organisationId: o._id};
                      setSearch({name: 'bookingCalendars', value: {text: '', query, queryDepth: 1}});
                      apiRequest({type: 'get', action: 'bookingCalendars', data: {query, sort: {name: 1}, skip: 0}})
                      .then((result) => {
                        setBookingCalendarReferences(result.data.bookingCalendars || []);
                        setBookingCalendars(result.data.bookingCalendars || []);
                        setProcessing(false);
                      }).catch((error) => {
                        setBookingCalendars([]);
                        setProcessing(false);
                      });
                    }
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {o.name}
                </div>
              ))}
              {processing && organisations?.length > 15 &&
                <div>
                  <Typography>
                    Loading...
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {organisations?.length >= search?.organisations?.queryDepth * queryLimit &&
                <div style={{padding: 5}}>
                  <Button
                    palette='primary'
                    disabled={processing}
                    onClick={() => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...dialogData.value,
                          expandSearch: true,
                        }
                      });
                    }}
                  >
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
          {(dialogData?.type === 'addBooking' || dialogData?.type === 'editBooking') &&
            <div style={{padding: 10}}>
              <div style={{padding: 10, background: paletteData.primary.standard.background}}>
                <Typography style={{color: paletteData.primary.standard.foreground}}>
                  Details
                </Typography>
              </div>
              <Select
                style={{minWidth: 150}}
                name="selectedCalendar"
                palette='secondary'
                label="Calendar"
                type="text"
                value={dialogData?.value?.bookingCalendarId}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      bookingCalendarId: value,
                      times: [{}],
                    }
                  });
                }}
              >
                {bookingCalendars?.length > 0 && bookingCalendars.map((c, cIndex) => (
                  <option key={cIndex} value={c._id}>{c.name}</option>
                ))}
              </Select>
              <TextArea
                name="notes"
                palette='secondary'
                label="Notes"
                type="text"
                value={dialogData?.value?.notes || ''}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      notes: value,
                    }
                  });
                }}
              />
              {dialogData?.value?.orderId &&
                <div style={{marginTop: 5}}>
                  <div style={{padding: 10, background: paletteData.primary.standard.background}}>
                    <Typography style={{color: paletteData.primary.standard.foreground}}>
                      Order Details
                    </Typography>
                  </div>
                  <div style={{padding: 10}}>
                    <Typography>
                      {orderReferences[dialogData?.value?.orderId]?.contact?.name || 'Unnamed Requester'}
                    </Typography>
                    <Typography>
                      Order Created On:  {new Date(orderReferences[dialogData?.value?.orderId]?.createdAt).toDateString()}
                    </Typography>
                    <Typography>
                      {orderReferences[dialogData?.value?.orderId]?.contact?.email}
                    </Typography>
                    <Typography>
                      {orderReferences[dialogData?.value?.orderId]?.contact?.phone}
                    </Typography>
                    <Typography>
                      {orderReferences[dialogData?.value?.orderId]?.contact?.address?.extra} {orderReferences[dialogData?.value?.orderId]?.contact?.address?.number} {orderReferences[dialogData?.value?.orderId]?.contact?.address?.street} {orderReferences[dialogData?.value?.orderId]?.contact?.address?.suburb} {orderReferences[dialogData?.value?.orderId]?.contact?.address?.city} {orderReferences[dialogData?.value?.orderId]?.contact?.address?.country} {orderReferences[dialogData?.value?.orderId]?.contact?.address?.postcode}
                    </Typography>
                  </div>
                </div>
              }
              {dialogData?.value?.productId &&
                <div>
                  <div style={{padding: 10, background: paletteData.primary.standard.background}}>
                    <Typography style={{color: paletteData.primary.standard.foreground}}>
                      Product Details
                    </Typography>
                  </div>
                  <GridContainer style={{marginTop: 5}}>
                    <Hidden breakpoint='hiddenlessthan1024'>
                      <GridCell center={true} style={{height: 200}}>
                        <img
                          src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${productReferences[dialogData?.value?.productId].thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                          alt={productReferences[dialogData?.value?.productId]?.thumbnail?.alt}
                          style={{width: 200}}
                        />
                      </GridCell>
                    </Hidden>
                    <Hidden breakpoint='hiddenlessthan1024'>
                      <GridCell weight={1} center style={{textAlign: 'left', padding: 10, height: 180}}>
                        <Typography size='title'>
                          {productReferences[dialogData?.value?.productId].name}
                        </Typography>
                        <Typography style={{color: '#333333'}}>
                          {productReferences[dialogData?.value?.productId].tagline}
                        </Typography>
                        <Typography style={{marginTop: 5}}>
                          {productReferences[dialogData?.value?.productId].blurb?.length > 350 ? `${productReferences[dialogData?.value?.productId].blurb.substring(0, 350)}...` : productReferences[dialogData?.value?.productId].blurb}
                        </Typography>
                      </GridCell>
                    </Hidden>
                    <Hidden breakpoint='hiddengreaterthan1024'>
                      <GridCell weight={1} style={{textAlign: 'left', padding: 10}}>
                        <Typography size='title'>
                          {productReferences[dialogData?.value?.productId].name}
                        </Typography>
                        <Typography style={{color: '#333333'}}>
                          {productReferences[dialogData?.value?.productId].tagline}
                        </Typography>
                        <Typography style={{marginTop: 5}}>
                          {productReferences[dialogData?.value?.productId].blurb?.length > 350 ? `${productReferences[dialogData?.value?.productId].blurb.substring(0, 350)}...` : productReferences[dialogData?.value?.productId].blurb}
                        </Typography>
                      </GridCell>
                    </Hidden>
                  </GridContainer>
                </div>
              }
              {dialogData?.value?.bookingCalendarId &&
                <div style={{marginTop: 5}}>
                  <div style={{padding: 10, background: paletteData.primary.standard.background}}>
                    <GridContainer>
                      <GridCell weight={1} center={true}>
                        <Typography style={{color: paletteData.primary.standard.foreground}}>
                          Dates / Times
                        </Typography>
                      </GridCell>
                      <GridCell>
                        <Button size='small' palette='secondary' onClick={() => {
                          const dialogDataTemp = dialogData;
                          dialogDataTemp.value.times.push({});
                          setDialogData({...dialogDataTemp});
                        }}>
                          Add date/time
                        </Button>
                      </GridCell>
                    </GridContainer>
                  </div>
                  {dialogData?.value?.times?.length > 0 && dialogData?.value?.times?.map((t, tIndex) => (
                    <div key={tIndex} style={{padding: 10, marginTop: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5}}>
                      <GridContainer>
                        <GridCell>
                          <Input
                            forceShrink={true}
                            name="date"
                            palette='secondary'
                            label="Date"
                            type="date"
                            value={t.date}
                            onChange={(value) => {
                              fetchDays([value]);
                              const offset = createOffset(bookingCalendarReferences[dialogData?.value?.bookingCalendarId], value);
                              const dialogDataTemp = dialogData;
                              dialogDataTemp.value.times[tIndex].date = value;
                              dialogDataTemp.value.times[tIndex].offset = offset;
                              if(!dialogDataTemp?.value?.availableTimes) {
                                dialogDataTemp.value.availableTimes = {};
                              }
                              if(!dialogDataTemp?.value?.availableTimes[value]) {
                                dialogDataTemp.value.availableTimes[value] = filterAvailableTimes(bookingCalendarReferences[dialogData?.value?.bookingCalendarId], offset);
                              }
                              setDialogData({...dialogDataTemp});
                            }}
                          />
                        </GridCell>
                        {t.date &&
                          <GridCell>
                            <Select
                              style={{minWidth: 150}}
                              name="start"
                              palette='secondary'
                              label="Time"
                              type="text"
                              value={t.start}
                              onChange={(value) => {
                                const dialogDataTemp = dialogData;
                                dialogDataTemp.value.times[tIndex].start = value;
                                setDialogData({...dialogDataTemp});
                              }}
                            >
                              {dialogData?.value?.availableTimes && dialogData?.value?.availableTimes[t.date]?.map((t, tIndex) => (
                                <option key={tIndex} value={t.value}>{t.blockStartHuman}</option>
                              ))}
                            </Select>
                          </GridCell>
                        }
                        {t.date &&
                          <GridCell>
                            <Select
                              style={{minWidth: 150}}
                              name="blocks"
                              palette='secondary'
                              label="Duration"
                              type="text"
                              value={t.blocks}
                              onChange={(value) => {
                                const dialogDataTemp = dialogData;
                                dialogDataTemp.value.times[tIndex].blocks = value;
                                setDialogData({...dialogDataTemp});
                              }}
                            >
                              {bookingTimes.map((bt, tIndex) => (
                                <option key={tIndex} value={bt.value}>{bt.text}</option>
                              ))}
                            </Select>
                          </GridCell>
                        }
                        <GridCell weight={1}/>
                        <GridCell style={{paddingTop: 7}}>
                          <Button palette='primary' onClick={() => {
                            const dialogDataTemp = dialogData;
                            dialogDataTemp.value.times.splice(tIndex, 1);
                            setDialogData({...dialogDataTemp});
                          }}>
                            Remove date/time
                          </Button>
                        </GridCell>
                      </GridContainer>
                      {t.date &&
                        <GridContainer style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#c6c6c6'}}>
                          {times?.length > 0 && times.map((tr, trIndex) => (
                            <GridCell
                              key={trIndex}
                              weight={1}
                              style={{
                                height: 10,
                                background: checkBlockAvailability(bookingCalendarReferences[dialogData?.value?.bookingCalendarId], tr.value, dialogData?.value?._id, t.date, t.start, t.blocks, t.offset),
                              }}
                            />
                          ))}
                        </GridContainer>
                      }
                      {t.offset !== 0 && t.offset !== undefined &&
                        <Typography size='subText' style={{padding: 5, color: 'red'}}>
                          this date's timezone is different from the booking calendar.  Showing times in your timezone.
                        </Typography>
                      }
                      {productReferences[dialogData?.value?.productId]?.payment?.servicePaymentFor === 'perPerson' && t.selectedQuantity &&
                        <div style={{padding: 5}}>
                          <Typography>
                            {t.selectedQuantity} People
                          </Typography>
                        </div>
                      }
                    </div>
                  ))}

                </div>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'addBooking' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !validateTimes(dialogData?.value?.times) || !dialogData?.value?.bookingCalendarId || !dialogData?.value?.notes}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const timesProcessed = processTimesRemoveOffeset(bookingCalendarReferences[dialogData?.value?.bookingCalendarId], dialogData?.value?.times);
                    const updateBooking = {
                      organisationId: focusedOrganisation?._id,
                      orderId: undefined,
                      productId: undefined,
                      userId: me?._id,
                      bookingCalendarId:dialogData?.value?.bookingCalendarId,
                      type: 'admin',
                      state: 'confirmed',
                      ignoreSimiltaniousBookings: dialogData?.value?.ignoreSimiltaniousBookings,
                      times: timesProcessed,
                      notes: dialogData?.value?.notes,
                    };
                    apiRequest({type: 'post', action: `bookings/create`, data: {updateBooking}})
                    .then((result) => {
                      setBookingReference(result.data.booking);
                      addBooking(result.data.booking);
                      setRefreshCalendar(true);
                      setDialogData(undefined);
                      setDialogOpen(false);
                      setProcessing(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Create booking request denied',
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Add Booking
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'editBooking' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    apiRequest({type: 'delete', action: `bookings/delete/${dialogData?.value?._id}`})
                    .then((result) => {
                      const dates = [];
                      const bookingProcessed = bookingReferences[dialogData?.value?._id];
                      for(let i = 0; i < bookingProcessed.times.length; i += 1) {
                        dates.push(bookingProcessed.times[i].date);
                      }
                      removeBookings({dates, _id: bookingProcessed._id});
                      clearBookingReference(bookingProcessed._id);
                      if(dialogData?.value?.orderId) {
                        pruneBookingFromOrder({bookingId: dialogData?.value._id, orderId: dialogData?.value?.orderId});
                        pruneBookingFromOrderReference({bookingId: dialogData?.value._id, orderId: dialogData?.value?.orderId});
                        apiRequest({type: 'patch', action: `orders/prune_booking_from_order/${dialogData?.value?.orderId}/${dialogData?.value._id}`});
                      }
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Delete booking request denied',
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Delete Booking
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'editBooking' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const timesProcessed = processTimesRemoveOffeset(bookingCalendarReferences[dialogData?.value?.bookingCalendarId], dialogData?.value?.times);
                    const updateBooking = {
                      organisationId: dialogData?.value?.organisationId,
                      orderId: dialogData?.value?.orderId,
                      productId: dialogData?.value?.productId,
                      userId: dialogData?.value?.userId,
                      bookingCalendarId: dialogData?.value?.bookingCalendarId,
                      times: timesProcessed,
                      type: dialogData?.value?.type,
                      notes: dialogData?.value?.notes,
                    };
                    if(dialogData?.value?.orderId) {
                      const updateOrder = {
                        productId: dialogData?.value?.productId,
                        times: timesProcessed,
                      }
                      apiRequest({type: 'patch', action: `orders/update_product_booking_times/${dialogData?.value?.orderId}`, data: {updateOrder}});
                      const oIndex = (orders || []).findIndex((o) => {
                        return o._id === dialogData?.value?.orderId;
                      });
                      if(orderReferences[dialogData?.value?.orderId] || oIndex !== -1) {
                        const orderProcessed = orderReferences[dialogData?.value?.orderId] || orders[oIndex];
                        const productsProcessed = orderProcessed.products;
                        const pIndex = productsProcessed.findIndex((p) => {
                          return p._id === dialogData?.value?.productId;
                        });
                        if(pIndex !== -1) {
                          productsProcessed[pIndex].selectedBooking.times = timesProcessed;
                          orderProcessed.products = productsProcessed;
                          setOrderReference(orderProcessed);
                          if(oIndex !== -1) {
                            replaceOrder({index: oIndex, order: orderProcessed});
                          }
                        }
                      }
                    }
                    apiRequest({type: 'patch', action: `bookings/update/${dialogData?.value?._id}`, data: {updateBooking}})
                    .then((result) => {
                      replaceBooking({origonalBooking: bookingReferences[dialogData?.value?._id], newBooking: {_id: dialogData?.value?._id, ...updateBooking}});
                      setBookingReference({_id: dialogData?.value?._id, ...updateBooking});
                      setRefreshCalendar(true);
                      setDialogData(undefined);
                      setDialogOpen(false);
                      setProcessing(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Create booking request denied',
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Edit Booking
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

WorkflowAppointments.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
  setApiToken: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,

    organisations: state.lists.organisations,
    organisationReferences: state.references.organisations || {},

    bookings: state.lists.bookings,
    bookingReferences: state.references.bookings || {},

    bookingCalendars: state.lists.bookingCalendars,
    bookingCalendarReferences: state.references.bookingCalendars || {},

    orders: state.lists.orders,
    orderReferences: state.references.orders || {},

    products: state.lists.products,
    productReferences: state.references.products || {},
  };
};

export default connect(mapStateToProps, {
  setApiToken,
  setSearch,

  setOrganisationReference,
  setOrganisations,
  addOrganisations,
  replaceOrganisation,

  setBookingReference,
  setBookings,
  addBooking,
  replaceBooking,
  removeBookings,
  pruneBookingFromOrder,
  pruneBookingFromOrderReference,

  setBookingCalendars,
  addBookingCalendars,
  addBookingCalendar,
  replaceBookingCalendar,
  removeBookingCalendar,
  setBookingCalendarReference,
  clearBookingCalendarReference,
  setBookingCalendarReferences,

  setOrderReference,
  replaceOrder,

  setProductReference,
})(WorkflowAppointments);
