import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import apiRequest from '../../../../../tools/apiRequest';
import handleErrorMessage from '../../../../../tools/handleErrorMessage';
import {paletteData, Typography, Button, ProgressBar, Dialog} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function SummarySubPanel({
  me,
  setFocusedFormSection,
  processing,
  setProcessing,

  focusedOrganisation,
  handleChangeFocusedOrganisation,

  layoutGeneration,
  handleChangeLayoutGeneration,

  setApiToken,
  userReferences,
  setUserReference,
  addOrganisation,
  setOrganisationReference,
  replaceOrganisation,

  focusedOrganisationOriginalOwner,
  setFocusedOrganisation,
  setFocusedOrganisationOriginalOwner,
}) {
    const [hasLocation, setHasLocation] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(undefined);

    const [initialLoad, setInitialLoad] = useState({
      hasLocation: false,
      officeList: false,
    })
    const [hasLocationLoaded, setHasLocationLoaded] = useState(false);
    const [allLoaded, setAllLoaded] = useState(false);
    useEffect(() => {
      if(!allLoaded && hasLocationLoaded) {
        setAllLoaded(true);
      }
      if(!initialLoad.hasLocation) {
        setInitialLoad({
          ...initialLoad,
          hasLocation: true,
        });
        let test = false;
        for(let i = 0; i < focusedOrganisation?.offices?.length; i += 1) {
          if(focusedOrganisation?.offices[i]?.position?.lat && focusedOrganisation?.offices[i]?.position?.lng) {
            test = true;
            break;
          }
        }
        if(test) {
          setHasLocation(true);
        } else {
          setHasLocation(false);
        }
        setHasLocationLoaded(true);
      }
    }, [focusedOrganisation, initialLoad, allLoaded, hasLocationLoaded]);

    const handleSubmit = () => {
      setProcessing(true);

      const organisationId = focusedOrganisation._id;
      const updateOrganisation = {
        ...focusedOrganisation,
      }
      if(organisationId === 'new') {
        apiRequest({type: 'post', action: `organisations/create`, data: {updateOrganisation}})
        .then((result) => {
          if(focusedOrganisation?.owner === me._id) {
            if(result.data.auth) {
              setApiToken(result.data.auth);
            }
          }
          if (userReferences[updateOrganisation.owner]) {
            const tempUserReference = userReferences[updateOrganisation.owner];
            if(!tempUserReference.roles) {tempUserReference.roles = {}};
            if(!tempUserReference.roles.organisational) {tempUserReference.roles.organisational = {}};
            tempUserReference.roles.organisational[result.data.organisation._id] = {owner: true};
            setUserReference(tempUserReference);
          }
          addOrganisation(result.data.organisation);
          setOrganisationReference(result.data.organisation);
          setProcessing(false);
          setFocusedOrganisation(undefined);
        }).catch((error) => {
          setProcessing(false);
          setDialogData({
            type: 'message',
            title: 'Update organisation request denied',
            message: handleErrorMessage(error),
          });
          setDialogOpen(true);
        });
      } else {
        apiRequest({type: 'patch', action: `organisations/update/${organisationId}`, data: {updateOrganisation}})
        .then((result) => {
          if(focusedOrganisation?.owner === me._id) {
            if(result.data.auth) {
              setApiToken(result.data.auth);
            }
          }
          if(userReferences[focusedOrganisationOriginalOwner]) {
            const tempUserReference = userReferences[focusedOrganisationOriginalOwner];
            if(tempUserReference.roles?.organisational && tempUserReference.roles.organisational[organisationId]) {
              delete tempUserReference.roles.organisational[organisationId];
              setUserReference(tempUserReference);
            }
          }
          if (userReferences[updateOrganisation.owner]) {
            const tempUserReference = userReferences[updateOrganisation.owner];
            if(!tempUserReference.roles) {tempUserReference.roles = {}};
            if(!tempUserReference.roles.organisational) {tempUserReference.roles.organisational = {}};
            tempUserReference.roles.organisational[organisationId] = {owner: true};
            setUserReference(tempUserReference);
          }
          replaceOrganisation({organisation: {_id: organisationId, ...updateOrganisation}});
          setOrganisationReference({_id: organisationId, ...updateOrganisation});
          setProcessing(false);
          setFocusedOrganisation(undefined);
          setFocusedOrganisationOriginalOwner(undefined);
          setFocusedFormSection(undefined);
        }).catch((error) => {
          setProcessing(false);
          setDialogData({
            type: 'message',
            title: 'Update organisation request denied',
            message: handleErrorMessage(error),
          });
          setDialogOpen(true);
        });
      }
    }

    return (
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div style={{background: paletteData.primary.standard.background, padding: 10}}>
          <Typography style={{color: paletteData.primary.standard.foreground}}>
            Required Information Summary
          </Typography>
        </div>
        <div style={{padding: 10}}>
          <Typography style={{marginBottom: 10}}>
            Setting up an organisation
          </Typography>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.name ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Organisation name</strong></Typography>
            </GridCell>
          </GridContainer>
          {(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
            <GridContainer>
              <GridCell style={{width: 35}}>
                {focusedOrganisation?.owner ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
              </GridCell>
              <GridCell weight={1}>
                <Typography><strong>Username of the organisation owner</strong></Typography>
              </GridCell>
            </GridContainer>
          }
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.offices?.length > 0 ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Area(s) that the organisation operates in</strong></Typography>
            </GridCell>
          </GridContainer>
          <Typography style={{marginBottom: 10}}>
            Selling on the platform
          </Typography>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.email ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Email Address</strong></Typography>
            </GridCell>
          </GridContainer>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.bank ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Bank Account</strong></Typography>
            </GridCell>
          </GridContainer>
        </div>
        <br/>
        <div style={{background: paletteData.primary.standard.background, padding: 10}}>
          <Typography style={{color: paletteData.primary.standard.foreground}}>
            Optional Information Summary
          </Typography>
        </div>
        <div style={{padding: 10}}>
          <Typography style={{marginBottom: 10}}>
            Letting people get in contact
          </Typography>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.phone ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Phone number</strong></Typography>
            </GridCell>
          </GridContainer>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {hasLocation ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Physical Address</strong></Typography>
            </GridCell>
          </GridContainer>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.website ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Website</strong></Typography>
            </GridCell>
          </GridContainer>
          <Typography style={{marginBottom: 10}}>
            Selling on the platform
          </Typography>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.gst ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>GST Number</strong></Typography>
            </GridCell>
          </GridContainer>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.stripe ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Stripe ID</strong></Typography>
            </GridCell>
          </GridContainer>
          <Typography  style={{marginBottom: 10}}>
            Making the listing better
          </Typography>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.tagline ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Tagline</strong></Typography>
            </GridCell>
          </GridContainer>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.blurb ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Blurb</strong></Typography>
            </GridCell>
          </GridContainer>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.thumbnail?.imageId ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Thumbnail</strong></Typography>
            </GridCell>
          </GridContainer>
          <GridContainer>
            <GridCell style={{width: 35}}>
              {focusedOrganisation?.logo?.imageId ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
            </GridCell>
            <GridCell weight={1}>
              <Typography><strong>Logo</strong></Typography>
            </GridCell>
          </GridContainer>
          {focusedOrganisation?.layout?.sections?.length > 0 &&
            <GridContainer>
              <GridCell style={{width: 35}}>
                <span className="material-icons md-24">done</span>
              </GridCell>
              <GridCell weight={1}>
                <Typography><strong>Organisation page setup</strong></Typography>
              </GridCell>
            </GridContainer>
          }
          {(!focusedOrganisation?.layout?.sections || focusedOrganisation?.layout?.sections?.length === 0) &&
            <div>
              <GridContainer>
                <GridCell style={{width: 35}}>
                  {layoutGeneration?.splashId ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
                </GridCell>
                <GridCell weight={1}>
                  <Typography><strong>Splash Image</strong></Typography>
                </GridCell>
              </GridContainer>
              <GridContainer>
                <GridCell style={{width: 35}}>
                  {layoutGeneration?.createAboutSection && layoutGeneration?.aboutSectionText ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
                </GridCell>
                <GridCell weight={1}>
                  <Typography><strong>Organisation Information</strong></Typography>
                </GridCell>
              </GridContainer>
              <GridContainer>
                <GridCell style={{width: 35}}>
                  {layoutGeneration?.createStaffSection && layoutGeneration?.staff?.length > 0 ? <span className="material-icons md-24">done</span> : <span className="material-icons md-24">close</span>}
                </GridCell>
                <GridCell weight={1}>
                  <Typography><strong>Details of staff</strong></Typography>
                </GridCell>
              </GridContainer>
            </div>
          }
        </div>
        <br/>
        <GridContainer>
          <GridCell weight={1}/>
          <GridCell>
            <Button disabled={processing} palette='primary' style={{padding: 30}} onClick={() => handleSubmit()}>
              Complete Setup
            </Button>
            {processing &&
              <ProgressBar palette='secondary' />
            }
          </GridCell>
          <GridCell weight={1}/>
        </GridContainer>


        {dialogOpen &&
          <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
            <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
              <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                {dialogData?.title}
              </Typography>
            </div>
            {dialogData?.message &&
              <div style={{padding: 10}}>
                <Typography>
                  {dialogData.message}
                </Typography>
              </div>
            }
            <GridContainer>
              <GridCell weight={1}/>
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                  onClick={() => {
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
                </Button>
              </GridCell>
            </GridContainer>
          </Dialog>
        }
      </div>
  );
};

SummarySubPanel.propTypes = {
  me: PropTypes.shape({}),
  setFocusedFormSection: PropTypes.func,
};

export default SummarySubPanel;
