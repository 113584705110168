import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import SearchArticles from './SearchArticles';
import EditArticlePermissions from './EditArticlePermissions';
import EditArticlePreview from './EditArticlePreview';
import EditArticleGallery from './EditArticleGallery';
import EditArticleVideoLibrary from './EditArticleVideoLibrary';
import EditArticleLayout from './EditArticleLayout';

import {GridContainer, GridCell} from '../../../../components/grid';
import {paletteData, Button, ProgressBar, Dialog, Typography} from '../../../../components/styles';
import {setArticles, addArticles, addArticle, replaceArticle, removeArticle, setSearch} from '../../../../store/reducers/lists/actions.js';
import {setArticleReference, clearArticleReference} from '../../../../store/reducers/references/actions.js';
import Hidden from '../../../../components/hidden';

import apiRequest from '../../../../tools/apiRequest';
import handleErrorMessage from '../../../../tools/handleErrorMessage';

function ManageArticlesPanel({me, auth, articles, search, setArticles, addArticles, addArticle, replaceArticle, removeArticle, setArticleReference, clearArticleReference, setSearch, queryLimit}) {
  // page state
  const [focusedArticle, setFocusedArticle] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [focusedSubSection, setFocusedSubSection] = useState('preview');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleChangesFocusedArticle = (changes) => {
    setFocusedArticle({
      ...focusedArticle,
      ...changes,
    });
  }

  const handleChangeFocusedArticle = (name, value) => {
    setFocusedArticle({
      ...focusedArticle,
      [name]: value,
    });
  };

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({type: 'post', action: 'upload/image', data: {file: reader.result}}).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({imageId: result.data.public_id, alt}, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  const handleUpdateArticle = () => {
    setProcessing(true);
    const articleId = focusedArticle._id;
    const updateArticle = {
      name: focusedArticle?.name,
      tagline: focusedArticle?.tagline,
      blurb: focusedArticle?.blurb,
      thumbnail: focusedArticle?.thumbnail,
      gallery: focusedArticle?.gallery,
      videoLibrary: focusedArticle?.videoLibrary,
      layout: focusedArticle?.layout,
      authorisation: focusedArticle?.authorisation,
    }
    if(articleId === 'new') {
      apiRequest({type: 'post', action: `articles/create`, data: {updateArticle}})
      .then((result) => {
        addArticle(result.data.article);
        setArticleReference(result.data.article);
        setProcessing(false);
        setFocusedArticle(undefined);
        setFocusedSubSection('preview');
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Create article request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    } else {
      apiRequest({type: 'patch', action: `articles/update/${articleId}`, data: {updateArticle}})
      .then((result) => {
        replaceArticle({article: {_id: articleId, ...updateArticle}});
        setArticleReference({_id: articleId, ...updateArticle});
        setProcessing(false);
        setFocusedArticle(undefined);
        setFocusedSubSection('preview');
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Update article request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    }
  }

  const handleDeleteArticle = () => {
    setProcessing(true);
    const articleId = focusedArticle._id || 'new';
    apiRequest({type: 'delete', action: `articles/delete/${articleId}`})
    .then((result) => {
      setDialogOpen(false);
      setDialogData(undefined);
      setProcessing(false);
      removeArticle({article: focusedArticle});
      clearArticleReference(articleId);
      setFocusedArticle(undefined);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Delete article request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Article{focusedArticle ? '' : 's'}
            </Typography>
          </GridCell>
          {/*large buttons*/}
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell center style={{height: 45, display: 'block'}}>
              <Button
                disabled={processing}
                palette="secondary"
                onClick={() =>{
                  if(focusedArticle) {
                    setFocusedArticle(undefined);
                    setFocusedSubSection('preview');
                  } else {
                    setFocusedArticle({_id: 'new', gallery: [], videoLibrary: []});
                  }
                }}
              >
                {focusedArticle ? 'Cancel' : 'Add'}
              </Button>
            </GridCell>
          </Hidden>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell center style={{marginLeft: 5, height: 45, display: focusedArticle && focusedArticle._id !== 'new' ? 'block' : 'none'}}>
              <Button
                disabled={processing}
                palette='secondary'
                onClick={() => {
                  setDialogOpen(true);
                  setDialogData({
                    type: 'deleteArticle',
                    title: 'Delete Article',
                    message: 'Are you sure you wish to delete this article?  This is irreversible',
                  });
                }}
              >
                Delete Article
              </Button>
            </GridCell>
          </Hidden>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell center style={{marginLeft: 5, height: 45, display: focusedArticle ? 'block' : 'none'}}>
              <Button palette='secondary' onClick={() => handleUpdateArticle()} disabled={processing}>
                {focusedArticle?._id === 'new' ? 'Create' : 'Update'} Article
              </Button>
              {processing &&
                <ProgressBar palette='secondary'/>
              }
            </GridCell>
          </Hidden>

          {/*small buttons*/}
          <Hidden breakpoint='hiddengreaterthan650'>
            <GridCell center style={{height: 45, display: 'block'}}>
              <Button
                size='small'
                disabled={processing}
                palette="secondary"
                onClick={() =>{
                  if(focusedArticle) {
                    setFocusedArticle(undefined);
                    setFocusedSubSection('preview');
                  } else {
                    setFocusedArticle({_id: 'new', gallery: [], videoLibrary: []});
                  }
                }}
              >
                {focusedArticle ? 'Cancel' : 'Add'}
              </Button>
            </GridCell>
          </Hidden>
          <Hidden breakpoint='hiddengreaterthan650'>
            <GridCell center style={{marginLeft: 5, height: 45, display: focusedArticle && focusedArticle._id !== 'new' ? 'block' : 'none'}}>
              <Button
                size='small'
                disabled={processing}
                palette='secondary'
                onClick={() => {
                  setDialogOpen(true);
                  setDialogData({
                    type: 'deleteArticle',
                    title: 'Delete Article',
                    message: 'Are you sure you wish to delete this article?  This is irreversible',
                  });
                }}
              >
                Delete
              </Button>
            </GridCell>
          </Hidden>
          <Hidden breakpoint='hiddengreaterthan650'>
            <GridCell center style={{marginLeft: 5, height: 45, display: focusedArticle ? 'block' : 'none'}}>
              <Button size='small' palette='secondary' onClick={() => handleUpdateArticle()} disabled={processing}>
                {focusedArticle?._id === 'new' ? 'Create' : 'Update'}
              </Button>
              {processing &&
                <ProgressBar palette='secondary'/>
              }
            </GridCell>
          </Hidden>
        </GridContainer>
      </div>
      {!focusedArticle &&
        <SearchArticles
          articles={articles}
          addArticles={addArticles}
          setArticles={setArticles}
          search={search}
          setSearch={setSearch}
          queryLimit={queryLimit}
          processing={processing}
          setProcessing={setProcessing}
          setFocusedArticle={setFocusedArticle}
        />
      }

      {focusedArticle &&
        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
          <GridContainer style={{borderStyle: 'none none solid none', marginBottom: 10, marginTop: 10, flexWrap: 'nowrap'}}>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'preview' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('preview')}>
              Preview
            </GridCell>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'layout' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('layout')}>
              Layout
            </GridCell>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'permissions' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('permissions')}>
              Permissions
            </GridCell>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'gallery' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('gallery')}>
              Gallery
            </GridCell>
            <GridCell style={{padding: 10, borderRadius: '5px 5px 0px 0px', cursor: 'pointer', background: focusedSubSection === 'video' ? '#c6c6c6' : ''}} onClick={() => setFocusedSubSection('video')}>
              Videos
            </GridCell>
          </GridContainer>
          {focusedSubSection === 'permissions' &&
            <EditArticlePermissions
              focusedArticle={focusedArticle}
              processing={processing}
              handleChangeFocusedArticle={handleChangeFocusedArticle}
              setProcessing={setProcessing}
            />
          }
          {focusedSubSection === 'preview' &&
            <EditArticlePreview
              focusedArticle={focusedArticle}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedArticle={handleChangeFocusedArticle}
              handleChangesFocusedArticle={handleChangesFocusedArticle}
              handleUploadImage={handleUploadImage}
            />
          }
          {focusedSubSection === 'gallery' &&
            <EditArticleGallery
              focusedArticle={focusedArticle}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedArticle={handleChangeFocusedArticle}
              handleUploadImage={handleUploadImage}
            />
          }

          {focusedSubSection === 'video' &&
            <EditArticleVideoLibrary
              focusedArticle={focusedArticle}
              processing={processing}
              setProcessing={setProcessing}
              handleChangeFocusedArticle={handleChangeFocusedArticle}
              handleUploadImage={handleUploadImage}
            />
          }

          {focusedSubSection === 'layout' &&
            <EditArticleLayout
              me={me}
              focusedArticle={focusedArticle}
              handleChangeFocusedArticle={handleChangeFocusedArticle}
              processing={processing}
              setProcessing={setProcessing}
            />
          }
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'deleteArticle' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => handleDeleteArticle()}
                >
                  Delete Article
                </Button>
                {processing &&
                  <ProgressBar />
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ManageArticlesPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
}

const mapStateToProps = (state) => {
  return {
    articles: state.lists.articles,
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
  };
};

export default connect(mapStateToProps, {setArticles, addArticles, addArticle, replaceArticle, removeArticle, setArticleReference, clearArticleReference, setSearch})(ManageArticlesPanel);
