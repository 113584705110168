import React, { useState, useEffect, useRef } from 'react';
import { AutoResize } from './autoResize';

import { paletteData, textData } from '../../../styles';

function TextArea({ value, label, onChange, palette, inputStyle, forceShrink, labelSize, labelStyle, labelContainerStyle, style, onFocus, onBlur }) {
  const [focused, setFocused] = useState(false);
  const [labelHeight, setLabelHeight] = useState((forceShrink || value || focused) ? 0 : 28);
  const [labelFontSize, setLabelFontSize] = useState((forceShrink || value || focused) ? '.8em' : (labelSize || '1em'));
  const [labelColor, setLabelColor] = useState((focused) ? paletteData[palette || 'blank'].highlight.foreground : paletteData[palette || 'blank'].foreground);

  const textAreaRef = useRef(null);
  AutoResize(textAreaRef.current, value);

  useEffect(() => {
    setLabelHeight((forceShrink || value || focused) ? 5 : 28);
    setLabelColor((focused) ? paletteData[palette || 'blank'].highlight.foreground : paletteData[palette || 'blank'].foreground);
    setLabelFontSize((forceShrink || value || focused) ? '.8em' : (labelSize || '1em'));
  }, [
    focused, value, palette, forceShrink, labelSize,
    setLabelHeight, setLabelColor, setLabelFontSize,
  ]);

  return (
    <div
      style={{
        padding: 5, minHeight: 55, position: 'relative', ...style
      }}
    >
      <div style={{ position: 'absolute', left: 5, pointerEvents: 'none', width: '100%', ...labelContainerStyle, }}>
        <div style={{ height: labelHeight, transition: '0.2s', }} />
        <span
          style={{
            fontFamily: textData.text.fontFamily,
            fontSize: labelFontSize,
            color: labelColor,
            left: 2,
            ...labelStyle,
          }}
        >
          {label}
        </span>
      </div>

      <div style={{ position: 'absolute', bottom: 5, left: 5, pointerEvents: 'none', width: '100%', }}>
        <hr
          style={{
            margin: 0,
            marginBottom: focused ? 0 : 2,
            borderWidth: focused ? 2 : 1,
          }}
        />
      </div>

      <textarea
          rows={1}
          ref={textAreaRef}
          value={value !== undefined ? value : ''}

          style={{
            marginTop: 25,
            width: '100%',
            border: 'none',
            outline: 'none',
            fontSize: '1em',
            fontFamily: textData.text.fontFamily,
            color: paletteData[palette || 'blank'].foreground,
            background: 'rgba(0, 0, 0, 0.0)',
            ...inputStyle,
          }}
          onFocus={() => {
            // update look when input focused
            setFocused(true);
            setLabelHeight(28);
            setLabelColor(paletteData[palette || 'blank'].highlight.foreground);
            setLabelFontSize('.8em');
            if (onFocus) {
              onFocus();
            }
          }}
          onBlur={(e) => {
            // update look when input unfocused
            setFocused(false);
            setLabelHeight((value || forceShrink) ? 0 : 28);
            setLabelFontSize((value || forceShrink) ? '.8em' : '1em');
            setLabelColor(paletteData[palette || 'blank'].foreground);
            if (onBlur) {
              onBlur(e.target.value);
            }
          }}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
    </div>
  );
}

export default TextArea;