import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import {paletteData, Button, Typography, Dialog, ProgressBar, Input, TextArea} from '../../../../../components/styles';
import {GridContainer, GridCell} from '../../../../../components/grid';

function EditOrganisationGallerySubPanel({
    focusedOrganisation,
    handleChangeFocusedOrganisation,
    processing,
    handleUploadImage,
    setProcessing,
  }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);
  const [searchGallery, setSearchGallery] = useState('');

  const imageUploadRef = useRef(null);

  const handleGeneratePreview = (file) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setProcessing(false);
      setImagePreview(reader.result)
    }
  }

  const handleAddGalleryImage = (image) => {
    const galleryProcessed = focusedOrganisation.gallery || [];
    galleryProcessed.push(image);
    handleChangeFocusedOrganisation('gallery', galleryProcessed);
    setDialogData(undefined);
    setImagePreview(undefined);
    setDialogOpen(false)
  }

  const handleUpdateGalleryImage = (index, image) => {
    const galleryProcessed = focusedOrganisation.gallery || [];
    galleryProcessed.splice(index, 1, image);
    handleChangeFocusedOrganisation('gallery', galleryProcessed);
    setDialogData(undefined);
    setImagePreview(undefined);
    setDialogOpen(false);
  }

  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Gallery
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              palette="secondary" size='small'
              onClick={() => {
                setImagePreview(undefined);
                setDialogData({
                  type: 'addGalleryImage',
                  title: 'Add an image to gallery',
                  preview: undefined,
                  value: {
                    file: undefined,
                    tags: '',
                    alt: '',
                    description: '',
                  }
                });
                setDialogOpen(true);
              }}
            >
              Add image
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      <Input
        name="search"
        autoComplete='off'
        palette='secondary'
        label="Search"
        type="text"
        value={searchGallery || ''}
        onChange={(value) => setSearchGallery(value)}
      />
      <GridContainer style={{marginTop: 10}}>
        {focusedOrganisation?.gallery?.length > 0 && focusedOrganisation.gallery.filter((g, gIndex) => {
          return (g.alt || '').toLowerCase().includes(((searchGallery || '').toLowerCase())) || (g.tags || '')?.toLowerCase().includes(((searchGallery || '').toLowerCase()));
        }).map((g, gIndex) => (
          <GridCell
            key={gIndex}
            style={{width: 180, display: 'flex', flexDirection: 'column', marginRight: 10, marginBottom: 10, cursor: 'pointer'}}
            onClick={() => {
              setImagePreview(undefined);
              setDialogData({
                type: 'editGalleryImage',
                title: 'Edit Image',
                preview: undefined,
                data: {
                  index: gIndex,
                },
                value: {
                  file: undefined,
                  imageId: g.imageId,
                  tags: g.tags,
                  alt: g.alt,
                  description: g.description,
                }
              });
              setDialogOpen(true);
            }}
          >
            <img src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${g.imageId}`} alt={g.alt} style={{width: '100%'}} />
            <Typography style={{padding: 10, textAlign: 'center'}}>
              {g.alt}
            </Typography>
          </GridCell>
        ))}
        <GridCell
          style={{width: 180, display: 'flex', flexDirection: 'column', marginRight: 10, marginBottom: 10}}
          onClick={() => {
            setImagePreview(undefined);
            setDialogData({
              type: 'addGalleryImage',
              title: 'Add an image to gallery',
              preview: undefined,
              value: {
                file: undefined,
                tags: '',
                alt: '',
                description: '',
              }
            });
            setDialogOpen(true);
          }}
        >
          <GridContainer style={{height: 180, width: 180}}>
            <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5, cursor: 'pointer'}}>
              <span className="material-icons md-24" style={{fontSize: 50}}>add</span>
            </GridCell>
          </GridContainer>
          <Typography style={{padding: 10, textAlign: 'center'}}>
            New
          </Typography>
        </GridCell>
      </GridContainer>

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setImagePreview(undefined);
           setDialogOpen(false);
         }}
       >
         <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
           <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
             {dialogData?.title}
           </Typography>
         </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {(dialogData?.type === 'addGalleryImage' || dialogData?.type === 'editGalleryImage') &&
            <div style={{padding: 10}}>
              <GridContainer>
                <GridCell style={{width: 200}}>
                  <div style={{marginTop: 10, textAlign: 'center'}}>
                    {!imagePreview && !dialogData.value.imageId &&
                      <GridContainer style={{height: 200, width: 200, marginTop: 10}}>
                        <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5}}>
                          No Image
                        </GridCell>
                      </GridContainer>
                    }
                    {(imagePreview || dialogData.value.imageId) &&
                      <GridContainer style={{marginTop: 10}}>
                        <GridCell center={true} weight={1}>
                          <img src={imagePreview || `https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${dialogData.value.imageId}`} alt={dialogData?.value?.alt} style={{width: 200}}/>
                        </GridCell>
                      </GridContainer>
                    }
                    <input
                      ref={imageUploadRef}
                      type="file"
                      style={{display: 'none'}}
                      onChange={(event) => {
                        handleGeneratePreview((event?.target?.files?.length) > 0 ? event.target.files[0] : undefined);
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            file: (event?.target?.files?.length) > 0 ? event.target.files[0] : undefined,
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                    <Button style={{margin: 5}} palette='primary' onClick={() => imageUploadRef.current.click()} disabled={processing}>
                      Change Image
                    </Button>
                    {(processing) &&
                      <ProgressBar palette='secondary'/>
                    }
                    {dialogData?.value?.file && dialogData?.value?.file?.size > 1.1 * 1024 * 1024 &&
                      <div style={{padding: 10}}>
                        <Typography style={{color: 'red'}}>
                          File too large, must be under 1 MB
                        </Typography>
                      </div>
                    }
                  </div>
                </GridCell>
                <GridCell weight={1}>
                  <Input
                    name="alt"
                    palette='secondary'
                    label="Alt Text"
                    type="text"
                    value={dialogData?.value?.alt || ''}
                    onChange={(value) => {
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          alt: value,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                  <Input
                    name="tags"
                    palette='secondary'
                    label="Tags"
                    type="text"
                    value={dialogData?.value?.tags || ''}
                    onChange={(value) => {
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          tags: value,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                  <TextArea
                    name="description"
                    palette='secondary'
                    label="Description"
                    type="text"
                    value={dialogData?.value?.description || ''}
                    onChange={(value) => {
                      const dialogDataProcessed = {
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          description: value,
                        }
                      };
                      setDialogData(dialogDataProcessed);
                    }}
                  />
                </GridCell>
              </GridContainer>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setImagePreview(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'addGalleryImage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.file || dialogData?.value?.file?.size > 1.1 * 1024 * 1024}
                  palette='primary'
                  onClick={() => {
                    if(dialogData?.value?.file) {
                      handleUploadImage(dialogData.value.file, (response, error) => {
                        if(!error) {
                          const imageProcessed = {
                            imageId: response?.imageId,
                            alt: dialogData?.value?.alt,
                            tags: dialogData?.value?.tags,
                            description: dialogData?.value?.description,
                          }
                          handleAddGalleryImage(imageProcessed);
                        }
                      });
                    } else {
                      handleAddGalleryImage(dialogData?.value);
                    }
                  }}
                >
                  Add to gallery
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            }
            {dialogData?.type === 'editGalleryImage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    const galleryProcessed = focusedOrganisation.gallery || [];
                    galleryProcessed.splice(dialogData?.data?.index, 1);
                    handleChangeFocusedOrganisation('gallery', galleryProcessed);
                    setDialogData(undefined);
                    setImagePreview(undefined);
                    setDialogOpen(false)
                  }}
                >
                  Remove Image
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'editGalleryImage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || dialogData?.value?.file?.size > 1.1 * 1024 * 1024}
                  palette='primary'
                  onClick={() => {
                    if(dialogData?.value?.file) {
                      handleUploadImage(dialogData.value.file, (response, error) => {
                        if(!error) {
                          const imageProcessed = {
                            imageId: response?.imageId,
                            alt: dialogData?.value?.alt,
                            tags: dialogData?.value?.tags,
                            description: dialogData?.value?.description,
                          }
                          handleUpdateGalleryImage(dialogData?.data?.index, imageProcessed);
                        }
                      });
                    } else {
                      handleUpdateGalleryImage(dialogData?.data?.index, dialogData?.value);
                    }
                  }}
                >
                  Edit Image data
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditOrganisationGallerySubPanel.propTypes = {
  focusedOrganisation: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedOrganisation: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleUploadImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditOrganisationGallerySubPanel;
