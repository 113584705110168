import React from 'react';
import PropTypes from 'prop-types';

import {CheckBox, Input} from '../../styles';

function ProductAvailability({productType, availability, handleChange}) {
  return (
    <div>
      {productType === 'good' &&
        <div>
          <Input
            name="stock"
            palette='secondary'
            label="Stock"
            type="number"
            value={availability?.stock || ''}
            onChange={(value) => handleChange('stock', value)}
          />
        </div>
      }
      <CheckBox
        name='inStore'
        label='Show your location(s) on the listing'
        value={availability.inStore}
        onChange={(value) => handleChange('inStore', value)}
      />
      <CheckBox
        name='delivery'
        label={`Allow users to ${productType === 'good' ? 'purchase' : 'book'} online`}
        value={availability.online}
        onChange={(value) => handleChange('online', value)}
      />
    </div>
  );
}

ProductAvailability.propTypes = {
  availability: PropTypes.shape({}),
  handleChange: PropTypes.func,
  productType: PropTypes.string,
}

export default ProductAvailability;
