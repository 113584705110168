import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import SearchOrders from './SearchOrders';

import {GridContainer, GridCell} from '../../../../components/grid';
import {paletteData, Button, Dialog, Typography} from '../../../../components/styles';
import {setOrders, addOrders, addOrder, replaceOrder, removeOrder, setSearch, setOrganisations, addOrganisations, setBookings, addBooking, replaceBooking} from '../../../../store/reducers/lists/actions.js';
import {setOrderReference, clearOrderReference, setOrganisationReference, setBookingCalendarReferences, setBookingReferences, setBookingReference, setBookingCalendarReference} from '../../../../store/reducers/references/actions.js';

import apiRequest from '../../../../tools/apiRequest';
import handleErrorMessage from '../../../../tools/handleErrorMessage';

function ManageOrdersPanel({
  me,
  auth,
  search,
  setSearch,
  queryLimit,

  orders,
  setOrders,
  addOrders,
  addOrder,
  replaceOrder,
  removeOrder,
  setOrderReference,
  clearOrderReference,

  organisations,
  organisationReferences,
  setOrganisations,
  addOrganisations,
  setOrganisationReference,

  bookings,
  addBooking,
  replaceBooking,
  setBookings,
  bookingReferences,
  setBookingCalendarReference,

  bookingCalendars,
  bookingCalendarReferences,
  setBookingReferences,
  setBookingReference,
  setBookingCalendarReferences
}) {
  // page state
  const [focusedOrganisation, setFocusedOrganisation] = useState(search?.orders?.query?.provider && organisationReferences[search?.orders?.query?.provider] ? organisationReferences[search?.orders?.query?.provider] : undefined);
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleDeleteOrder = (order) => {
    setDialogData({
      type: 'deleteOrder',
      title: 'Delete Order',
      message: 'Are you sure you want to do this.  This action can not be undone',
      data: {order},
    });
    setDialogOpen(true);
  };

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1} center style={{height: 45}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Orders
            </Typography>
          </GridCell>
        </GridContainer>
      </div>
      <SearchOrders
        me={me}
        search={search}
        queryLimit={queryLimit}
        setSearch={setSearch}
        processing={processing}
        setProcessing={setProcessing}

        orders={orders}
        setOrders={setOrders}
        addOrders={addOrders}
        handleDeleteOrder={handleDeleteOrder}
        replaceOrder={replaceOrder}
        removeOrder={removeOrder}
        setOrderReference={setOrderReference}

        focusedOrganisation={focusedOrganisation}
        organisations={organisations}
        setOrganisations={setOrganisations}
        addOrganisations={addOrganisations}
        organisationReferences={organisationReferences}
        setOrganisationReference={setOrganisationReference}
        setFocusedOrganisation={setFocusedOrganisation}

        bookings={bookings}
        bookingReferences={bookingReferences}
        setBookings={setBookings}
        addBooking={addBooking}
        replaceBooking={replaceBooking}
        setBookingReference={setBookingReference}
        setBookingReferences={setBookingReferences}

        bookingCalendarReferences={bookingCalendarReferences}
        setBookingCalendarReferences={setBookingCalendarReferences}
        setBookingCalendarReference={setBookingCalendarReference}
      />

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => {
          setDialogOpen(false);
          setDialogData(undefined);
        }}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'deleteOrder' &&
              <GridCell style={{padding: 10}}>
                <Button disabled={processing} palette='primary' onClick={() => {
                  setProcessing(true);
                  apiRequest({type: 'delete', action: `orders/delete/${dialogData?.data?.order._id}`})
                  .then((result) => {
                    setProcessing(false);
                    removeOrder({order: dialogData?.data?.order});
                    clearOrderReference(dialogData?.data?.order._id);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }).catch((error) => {
                    setProcessing(false);
                    setDialogData({
                      type: 'message',
                      title: 'Delete order request denied',
                      message: handleErrorMessage(error),
                    });
                    setDialogOpen(true);
                  });
                }}>
                  Delete
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

ManageOrdersPanel.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,

    orders: state.lists.orders,

    organisations: state.lists.organisations,
    organisationReferences: state.references.organisations || {},

    areas: state.lists.areas,
    areaReferences: state.references.areas || {},

    bookings: state.lists.bookings,
    bookingReferences: state.references.bookings || {},

    bookingCalendars: state.lists.bookingCalendars,
    bookingCalendarReferences: state.references.bookingCalendars || {},
  };
};

export default connect(mapStateToProps, {
  setSearch,

  setOrders,
  addOrders,
  addOrder,
  replaceOrder,
  removeOrder,
  setOrderReference,
  clearOrderReference,

  setOrganisations,
  addOrganisations,
  setOrganisationReference,

  setBookingCalendarReferences,
  setBookingCalendarReference,
  setBookingReferences,
  setBookingReference,
  setBookings,
  addBooking,
  replaceBooking,
})(ManageOrdersPanel);
