import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Input, Select, Typography, Button, Dialog, paletteData} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function PostSchemeForm({postScheme, handleChange, focusedOrganisation, disableType, disableGstInclusive}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div>
      <Input
        name="name"
        palette='secondary'
        label="Name"
        type="text"
        value={postScheme?.name || ''}
        onChange={(value) => handleChange('name', value)}
      />
      <GridContainer>
        <GridCell style={{marginTop: 22, marginLeft: 5}}>
          <Typography>
            $
          </Typography>
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="price"
            palette='secondary'
            label="Price in NZD"
            type="number"
            value={postScheme?.price || ''}
            onChange={(value) => handleChange('price', value)}
          />
        </GridCell>
        {focusedOrganisation?.gst &&
          <GridCell style={{marginTop: 22, marginRight: 5}}>
            <Typography>
              + GST
            </Typography>

          </GridCell>
        }
        {focusedOrganisation?.gst &&
          <GridCell style={{marginTop: 12, marginRight: 5, textAlign: 'right'}}>
            {!disableGstInclusive &&
              <Button onClick={() => {
                setDialogData({
                  type: 'gstInclusive',
                  title: 'Set GST inclusive price',
                  value: {},
                });
                setDialogOpen(true);
              }}>
                (${((postScheme?.price || 0) * 1.15).toFixed(2)} including GST)
              </Button>
            }
            {disableGstInclusive &&
              <Typography style={{marginTop: 10}}>
                (${((postScheme?.price || 0) * 1.15).toFixed(2)} including GST)
              </Typography>
            }
          </GridCell>
        }
      </GridContainer>
      <GridContainer>
        <GridCell weight={1}>
          <Select
            disabled={disableType}
            style={{minWidth: 150}}
            name="type"
            palette='secondary'
            label="Price Scheme Type"
            type="text"
            value={postScheme?.type || ''}
            onChange={(value) => handleChange('type', value)}
          >
            <option value="national">National</option>
            <option value="international">International</option>
          </Select>
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="bundle"
            palette='secondary'
            label="How many can fit in one package"
            type="number"
            value={postScheme?.bundle || ''}
            onChange={(value) => handleChange('bundle', value)}
          />
        </GridCell>
      </GridContainer>


      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <Typography>
              {dialogData.message}
            </Typography>
          }
          {dialogData?.type === 'gstInclusive' &&
            <div style={{padding: 10}}>
              <Input
                name="gstInclusivePrice"
                palette='secondary'
                label="GST inclusive price in NZD"
                type="number"
                value={dialogData?.value?.gstInclusivePrice || ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      gstInclusivePrice: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'gstInclusive' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                   onClick={() => {
                     const priceTemp = +((dialogData?.value?.gstInclusivePrice || 0) * 20 / 23).toFixed(2);
                     handleChange('price', priceTemp);
                     setDialogOpen(false);
                     setDialogData(undefined);
                  }}
                >
                  Update Price
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

PostSchemeForm.propTypes = {
  focusedOrganisation: PropTypes.shape({}),
  postScheme: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default PostSchemeForm;
