import {
  SET_USER,
  SET_USERS,
  CLEAR_USER,

  SET_PROFILE,
  CLEAR_PROFILE,

  SET_AREA,
  SET_AREAS,
  CLEAR_AREA,

  SET_ORGANISATION,
  SET_ORGANISATIONS,
  CLEAR_ORGANISATION,

  SET_CATEGORY,
  SET_CATEGORIES,
  CLEAR_CATEGORY,

  SET_EVENT,
  SET_EVENTS,
  CLEAR_EVENT,

  SET_PRODUCT,
  SET_PRODUCTS,
  CLEAR_PRODUCT,

  SET_PRODUCTGROUP,
  SET_PRODUCTGROUPS,
  CLEAR_PRODUCTGROUP,

  SET_POST_SCHEME,
  SET_POST_SCHEMES,
  CLEAR_POST_SCHEME,

  SET_BOOKING_CALENDAR,
  SET_BOOKING_CALENDARS,
  CLEAR_BOOKING_CALENDAR,

  SET_REVIEW,
  SET_REVIEWS,
  CLEAR_REVIEW,

  SET_ORDER,
  SET_ORDERS,
  CLEAR_ORDER,
  PRUNE_BOOKING_FROM_ORDER,

  SET_ARTICLE,
  SET_ARTICLES,
  CLEAR_ARTICLE,

  SET_BOOKING,
  SET_BOOKINGS,
  CLEAR_BOOKING,

  SET_COMMUNITY,
  SET_COMMUNITIES,
  CLEAR_COMMUNITY,

  SET_POTENTIAL_COMMUNITY,
  SET_POTENTIAL_COMMUNITIES,
  CLEAR_POTENTIAL_COMMUNITY,

  RESET_REFERENCES,
} from "./actionTypes";

const initialState = {
  users: {},
  profiles: {},
  organisations: {},
  areas: {},
  categories: {},
  articles: {},
  events: {},
  products: {},
  productGroups: {},
  orders: {},
  postSchemes: {},
  bookingCalendars: {},
  bookings: {},
  reviews: {},
  communities: {},
  potentialCommunities: {},
}

export default function referenceReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_REFERENCES: {
      return {
        users: {},
        profiles: {},
        organisations: {},
        areas: {},
        categories: {},
        articles: {},
        events: {},
        products: {},
        productGroups: {},
        orders: {},
        postSchemes: {},
        bookingCalendars: {},
        bookings: {},
        reviews: {},
        communities: {},
        potentialCommunities: {},
      }
    }

    //--------------------- users --------------------
    case SET_USER: {
      const usersProcessed = state.users;
      usersProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        users: usersProcessed,
      }
    }
    case SET_USERS: {
      const usersProcessed = state.users;
      for(let i = 0; i < action.payload.length; i += 1) {
        usersProcessed[action.payload[i]._id] = {
          ...(usersProcessed[action.payload[i]._id] || {}),
          ...action.payload[i]
        };
      }
      return {
        ...state,
        users: usersProcessed,
      }
    }
    case CLEAR_USER: {
      const usersProcessed = state.users;
      delete usersProcessed[action.payload];
      return {
        ...state,
        users: usersProcessed,
      }
    }

    case SET_PROFILE: {
      const profilesProcessed = state.profiles;
      profilesProcessed[action.payload.userId] = action.payload;
      return {
        ...state,
        profiles: profilesProcessed,
      }
    }
    case CLEAR_PROFILE: {
      const profilesProcessed = state.profiles;
      delete profilesProcessed[action.payload];
      return {
        ...state,
        profiles: profilesProcessed,
      }
    }


    //--------------------- areas --------------------
    case SET_AREA: {
      const areasProcessed = state.areas;
      areasProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        areas: areasProcessed,
      }
    }
    case SET_AREAS: {
      const areasProcessed = state.areas;
      for(let i = 0; i < action.payload.length; i += 1) {
        areasProcessed[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        areas: areasProcessed,
      }
    }
    case CLEAR_AREA: {
      const areasProcessed = state.areas;
      delete areasProcessed[action.payload];
      return {
        ...state,
        areas: areasProcessed,
      }
    }

    //--------------------- organisations --------------------
    case SET_ORGANISATION: {
      const organisationsProcessed = state.organisations;
      organisationsProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        organisations: organisationsProcessed,
      }
    }
    case SET_ORGANISATIONS: {
      const organisationsProcessed = state.organisations;
      for(let i = 0; i < action.payload.length; i += 1) {
        organisationsProcessed[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        organisations: organisationsProcessed,
      }
    }
    case CLEAR_ORGANISATION: {
      const organisationsProcessed = state.organisations;
      delete organisationsProcessed[action.payload];
      return {
        ...state,
        organisations: organisationsProcessed,
      }
    }

    //--------------------- categories --------------------
    case SET_CATEGORY: {
      const categoriesProcessed = state.categories;
      categoriesProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        categories: categoriesProcessed,
      }
    }
    case SET_CATEGORIES: {
      const categoriesProcessed = state.categories;
      for(let i = 0; i < action.payload.length; i += 1) {
        categoriesProcessed[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        categories: categoriesProcessed,
      }
    }
    case CLEAR_CATEGORY: {
      const categoriesProcessed = state.categories;
      delete categoriesProcessed[action.payload];
      return {
        ...state,
        categories: categoriesProcessed,
      }
    }

    //--------------------- events --------------------
    case SET_EVENT: {
      const eventsProcessed = state.events;
      eventsProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        events: eventsProcessed,
      }
    }
    case SET_EVENTS: {
      const eventsProcessed = state.events;
      for(let i = 0; i < action.payload.length; i += 1) {
        eventsProcessed[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        events: eventsProcessed,
      }
    }
    case CLEAR_EVENT: {
      const eventsProcessed = state.events;
      delete eventsProcessed[action.payload];
      return {
        ...state,
        events: eventsProcessed,
      }
    }

    //--------------------- products --------------------
    case SET_PRODUCT: {
      const productsProcessed = state.products;
      productsProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        products: productsProcessed,
      }
    }
    case SET_PRODUCTS: {
      const productsProcessed = state.products;
      for(let i = 0; i < action.payload.length; i += 1) {
         productsProcessed[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        products: productsProcessed,
      }
    }
    case CLEAR_PRODUCT: {
      const productsProcessed = state.products;
      delete productsProcessed[action.payload];
      return {
        ...state,
        products: productsProcessed,
      }
    }

    //--------------------- productGroups --------------------
    case SET_PRODUCTGROUP: {
      const productGroupsProcessed = state.productGroups;
      productGroupsProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        productGroups: productGroupsProcessed,
      }
    }
    case SET_PRODUCTGROUPS: {
      const productGroupsProcessed = state.productGroups;
      for(let i = 0; i < action.payload.length; i += 1) {
         productGroupsProcessed[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        productGroups: productGroupsProcessed,
      }
    }
    case CLEAR_PRODUCTGROUP: {
      const productGroupsProcessed = state.productGroups;
      delete productGroupsProcessed[action.payload];
      return {
        ...state,
        productGroups: productGroupsProcessed,
      }
    }

    //--------------------- post shcemes --------------------
    case SET_POST_SCHEME: {
      const postSchemesProcessed = state.postSchemes;
      postSchemesProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        postSchemes: postSchemesProcessed,
      }
    }
    case SET_POST_SCHEMES: {
      const postSchemesProcessed = state.postSchemes;
      for(let i = 0; i < action.payload.length; i += 1) {
         postSchemesProcessed[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        postSchemes: postSchemesProcessed,
      }
    }
    case CLEAR_POST_SCHEME: {
      const postSchemesProcessed = state.postSchemes;
      delete postSchemesProcessed[action.payload];
      return {
        ...state,
        postSchemes: postSchemesProcessed,
      }
    }

    //--------------------- booking calendars --------------------
    case SET_BOOKING_CALENDAR: {
      const bookingCalendarsProcessed = state.bookingCalendars;
      bookingCalendarsProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        bookingCalendars: bookingCalendarsProcessed,
      }
    }
    case SET_BOOKING_CALENDARS: {
      const bookingCalendarsProcessed = state.bookingCalendars;
      const list = action.payload || [];
      for(let i = 0; i < list.length; i += 1) {
        bookingCalendarsProcessed[list[i]._id] = list[i];
      }
      return {
        ...state,
        bookingCalendars:bookingCalendarsProcessed,
      }
    }
    case CLEAR_BOOKING_CALENDAR: {
      const bookingCalendarProcessed = state.bookingCalendar;
      delete bookingCalendarProcessed[action.payload];
      return {
        ...state,
        postSchemes: bookingCalendarProcessed,
      }
    }

    //--------------------- reviews --------------------
    case SET_REVIEW: {
      const reviewsProcessed = state.reviews;
      reviewsProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        reviews: reviewsProcessed,
      }
    }
    case SET_REVIEWS: {
      const reviewsProcessed = state.reviews;
      for(let i = 0; i < action.payload.length; i += 1) {
        reviewsProcessed[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        reviews: reviewsProcessed,
      }
    }
    case CLEAR_REVIEW: {
      const reviewsProcessed = state.reviews;
      delete reviewsProcessed[action.payload];
      return {
        ...state,
        reviews: reviewsProcessed,
      }
    }

    //--------------------- orders --------------------
    case SET_ORDER: {
      const ordersProcessed = state.orders;
      ordersProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        orders: ordersProcessed,
      }
    }
    case SET_ORDERS: {
      const ordersProcessed = state.orders;
      for(let i = 0; i < action.payload.length; i += 1) {
        ordersProcessed[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        orders: ordersProcessed,
      }
    }
    case CLEAR_ORDER: {
      const ordersProcessed = state.orders;
      delete ordersProcessed[action.payload];
      return {
        ...state,
        orders: ordersProcessed,
      }
    }

    case PRUNE_BOOKING_FROM_ORDER: {
      const ordersProcessed = state.orders;
      const orderId = action.payload.orderId;
      const bookingId = action.payload.bookingId;
      const orderProcessed = ordersProcessed[orderId ];
      for(let i = 0; i < orderProcessed?.products?.length; i += 1) {
        if(orderProcessed.products[i]?.selectedBooking?.bookingId === bookingId) {
          delete orderProcessed.products[i].selectedBooking.bookingId;
        }
      }
      ordersProcessed[orderId] = orderProcessed;
      return {
        ...state,
        orders: ordersProcessed,
      }
    }


    //--------------------- aticles --------------------
    case SET_ARTICLE: {
      const articlesProcessed = state.articles;
      articlesProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        articles: articlesProcessed,
      }
    }
    case SET_ARTICLES: {
      const articlesProcessed = state.articles;
      for(let i = 0; i < action.payload.length; i += 1) {
        articlesProcessed[action.payload[i]._id] = action.payload[i];
      }
      return {
        ...state,
        articles: articlesProcessed,
      }
    }
    case CLEAR_ARTICLE: {
      const articlesProcessed = state.articles;
      delete articlesProcessed[action.payload];
      return {
        ...state,
        articles: articlesProcessed,
      }
    }

    //--------------------- bookings --------------------
    case SET_BOOKING: {
      const bookingsProcessed = state.bookings;
      bookingsProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        bookings: bookingsProcessed,
      }
    }
    case SET_BOOKINGS: {
      const bookingsProcessed = state.bookings;
      const list = action.payload || [];
      for(let i = 0; i < list.length; i += 1) {
        bookingsProcessed[list[i]._id] = list[i];
      }
      return {
        ...state,
        bookings: bookingsProcessed,
      }
    }
    case CLEAR_BOOKING: {
      const bookingsProcessed = state.bookings;
      delete bookingsProcessed[action.payload];
      return {
        ...state,
        bookings: bookingsProcessed,
      }
    }

    //--------------------- communities --------------------
    case SET_COMMUNITY: {
      const communitiesProcessed = state.communities;
      communitiesProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        communities: communitiesProcessed,
      }
    }
    case SET_COMMUNITIES: {
      const communitiesProcessed = state.communities;
      const list = action.payload || [];
      for(let i = 0; i < list.length; i += 1) {
        communitiesProcessed[list[i]._id] = list[i];
      }
      return {
        ...state,
        communities: communitiesProcessed,
      }
    }
    case CLEAR_COMMUNITY: {
      const communitiesProcessed = state.communities;
      delete communitiesProcessed[action.payload];
      return {
        ...state,
        communities: communitiesProcessed,
      }
    }

    //--------------------- potential communities --------------------
    case SET_POTENTIAL_COMMUNITY: {
      const potentialCommunitiesProcessed = state.potentialCommunities;
      potentialCommunitiesProcessed[action.payload._id] = action.payload;
      return {
        ...state,
        potentialCommunities: potentialCommunitiesProcessed,
      }
    }
    case SET_POTENTIAL_COMMUNITIES: {
      const potentialCommunitiesProcessed = state.potentialCommunities;
      const list = action.payload || [];
      for(let i = 0; i < list.length; i += 1) {
        potentialCommunitiesProcessed[list[i]._id] = list[i];
      }
      return {
        ...state,
        potentialCommunities: potentialCommunitiesProcessed,
      }
    }
    case CLEAR_POTENTIAL_COMMUNITY: {
      const potentialCommunitiesProcessed = state.potentialCommunities;
      delete potentialCommunitiesProcessed[action.payload];
      return {
        ...state,
        potentialCommunities: potentialCommunitiesProcessed,
      }
    }

    //--------------------- fallback ---------------------

    default:
      return state
  }
}
